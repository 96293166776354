import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
  isValidNumber,
} from "../../../utils/utils";
import { getBrandOfficesApi } from "../../../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AssetsForm from "../../../components/fact-find/AssetsForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  AccountDTO,
  AssetsSectionViewModel,
  FactFindAssetsSectionDTO,
  FactFindFactFindDTO,
  FullFactFindAssetsPostViewModel,
  PropertyDTO,
  Section,
  VehicleDTO,
} from "@dineiro/dineiro-sdk-mobile";
import { FACTFIND_TABS } from "../../../constants/constants";
import { Holding } from "../../../hooks/useHoldings";
import { updateFactFindSection } from "./ApiService";

const AssetsSession: React.FC<{
  properties: PropertyDTO[];
  banks: AccountDTO[];
  vehicles: VehicleDTO[];
  holdings: Holding[];
  supperAccounts: AccountDTO[];
  accounts: AccountDTO[];
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
}> = ({
  properties,
  banks,
  vehicles,
  holdings,
  supperAccounts,
  accounts,
  numberOfApplicants,
  factFind,
  refetchGet,
  shouldRefetchGet,
}) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindAssetsSectionDTO[]>([]);
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    unregister,
    watch,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);
  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const assetsSection = { ...s.assetsSection };
          return assetsSection;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const calculationTotalAssets = (asset: AssetsSectionViewModel) => {
    const totalBankAccounts = asset?.totalBankAccounts || 0;
    const otherCashAssets = asset?.otherCashAssets || 0;
    const totalShares = asset?.totalShares || 0;

    const totalValueOfVehicles = asset?.totalValueOfVehicles || 0;
    const totalOtherAssets = asset?.totalOtherAssets || 0;
    const totalSuperannuation = asset?.totalSuperannuation || 0;
    const totalProperties =
      asset?.properties && asset?.properties?.length > 0
        ? asset?.properties?.reduce((acc, obj) => acc + (obj?.value ?? 0), 0)
        : 0;

    return (
      totalBankAccounts +
      otherCashAssets +
      totalShares +
      totalValueOfVehicles +
      totalSuperannuation +
      totalOtherAssets +
      totalProperties
    );
  };

  const convertDataToSubmit = (data) => {
    let dataConverted: AssetsSectionViewModel[] = [];
    if (data) {
      for (let index = 0; index < numberOfApplicants; index++) {
        let asset = {
          applicantId: factFind.applicants[index].id,
          totalBankAccounts:
            data[index]?.totalBankAccounts !== undefined
              ? data[index]?.totalBankAccounts
              : dataSubmit[index].totalBankAccounts,
          otherCashAssets:
            data[index]?.otherCashAssets !== undefined
              ? data[index]?.otherCashAssets
              : dataSubmit[index].otherCashAssets,
          totalShares:
            data[index]?.totalShares !== undefined
              ? data[index]?.totalShares
              : dataSubmit[index].totalShares,
          numberOfVehicles:
            data[index]?.numberOfVehicles !== undefined
              ? data[index]?.numberOfVehicles
              : dataSubmit[index].numberOfVehicles,
          totalValueOfVehicles:
            data[index]?.totalValueOfVehicles !== undefined
              ? data[index]?.totalValueOfVehicles
              : dataSubmit[index].totalValueOfVehicles,
          totalSuperannuation:
            data[index]?.totalSuperannuation !== undefined
              ? data[index]?.totalSuperannuation
              : dataSubmit[index].totalSuperannuation,
          totalOtherAssets:
            data[index]?.totalOtherAssets !== undefined
              ? data[index]?.totalOtherAssets
              : dataSubmit[index].totalOtherAssets,
          properties:
            data[index]?.properties !== undefined
              ? data[index]?.properties.map((s, i) => {
                  const watchProperty =
                    watch(`assets[${index}].properties[${i}].value`) || 0;
                  s.address = s.address && s.address !== "" ? s.address : null;
                  s.value = watchProperty ?? null;
                  return s;
                })
              : dataSubmit[index].properties || [],
        } as AssetsSectionViewModel;

        asset.totalAssets = calculationTotalAssets(asset);
        dataConverted.push(asset);
      }
    }
    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.assets) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.assets?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.income, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value && value.assets ? value.assets : dataSubmit;
    let dataSent: FullFactFindAssetsPostViewModel = {
      id: factFind?.id,
      assetsSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindAssetsPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindAssetsPostViewModel: dataSent,
      });
      toast.success(`Submit assets data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSent.assetsSections);
      await updateFactFindSection(
        brandApi,
        factFind,
        officeid,
        id,
        Section.Income
      );
      refetchGet(!shouldRefetchGet);
      reset();
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to submit assets data.");
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();
    let dataForm = data && data.assets ? data.assets : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindAssetsPostViewModel = {
      id: factFind?.id,
      assetsSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindAssetsPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindAssetsPostViewModel: dataSent,
      });
      setIsShowButtonEdit(true);
      toast.success(`Save address data successfully .`);
      setDataSubmit(dataSent.assetsSections);
      reset();
    } catch (error) {
      toast.error("Failed to save address data.");
    }
  };
  const totalBanks = banks.reduce(
    (acc, obj) =>
      acc + (isValidNumber(obj.balance?.amount!) ? obj.balance?.amount : 0),
    0
  );
  const totalVehicles = vehicles.reduce(
    (acc, obj) =>
      acc +
      (isValidNumber(obj.estimationPrice?.amount)
        ? obj.estimationPrice?.amount
        : 0),
    0
  );
  const totalShares = holdings.reduce(
    (acc, obj) => acc + (isValidNumber(obj.amount) ? obj.amount : 0),
    0
  );
  const totalSupperAccounts = supperAccounts.reduce(
    (acc, obj) =>
      acc + (isValidNumber(obj.balance?.amount) ? obj.balance?.amount : 0),
    0
  );
  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <AssetsForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          unregister={unregister}
          isSubmitted={isSubmitted}
          totalSupperAccounts={totalSupperAccounts}
          totalShares={totalShares}
          totalVehicles={totalVehicles}
          totalBanks={totalBanks}
          vehicleCount={vehicles.length}
          properties={properties}
          watch={watch}
          control={control}
        ></AssetsForm>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default AssetsSession;
