import React, { useState } from "react";
import Button from "../common/button/Button";
import { formatDate } from "../../utils/utils";
import Modal from "../common/modal/Modal";
import useModal from "../../hooks/useModal";
import { ArticleDTO, OfficeArticleDTO } from "@dineiro/dineiro-sdk-mobile";

const PendingReleases: React.FC<{
  contents: OfficeArticleDTO[];
  handleApproveContent: (contentRecall) => void;
}> = ({ contents, handleApproveContent }) => {
  const { show: showApproveModal, toggle: toggleApproveModal } = useModal();
  const { show: showPreviewModal, toggle: togglePreviewModal } = useModal();
  const [contentRecall, setContentRecall] = useState("");
  const [previewContent, setPreviewContent] = useState<ArticleDTO>({});

  const onApproveMessage = (contentId) => {
    setContentRecall(contentId);
    toggleApproveModal();
  };

  const onPreViewContent = (content: ArticleDTO) => {
    setPreviewContent(content);
    togglePreviewModal();
  };

  const onSubmitApprove = () => {
    handleApproveContent(contentRecall);
    toggleApproveModal();
  };

  return (
    <div className="max-h-[500px] w-full min-h-[150px] mt-5">
      <span
        className="header-h3 font-bold"
        style={{ fontSize: "22px", lineHeight: "25px" }}
      >
        Pending Releases
      </span>
      <div className="w-full min-h-[150px] max-h-[500px] overflow-auto">
        <table className="border-collapse mt-4 table-auto w-full overflow-auto text-sm">
          <thead>
            <tr>
              <th className="font-bold py-2 w-1/2">HEADLINE</th>
              <th className="font-bold py-2">SENT ON</th>
              <th className="font-bold py-2 text-center">#SENT</th>
              <th className="font-bold py-2 text-center">#OPENED</th>
              <th className="font-bold w-1/5"></th>
            </tr>
          </thead>
          <tbody>
            {contents.map((content) => {
              return (
                <tr key={content.id}>
                  <td className="border-b p-2">{content.article.title}</td>
                  <td className="border-b">
                    {formatDate(content.article.createdAt)}
                  </td>
                  <td className="border-b py-2 text-center">
                    {content.officeArticleUsers.length}
                  </td>
                  <td className="border-b py-2 text-center">{0}</td>
                  <td className="border-b py-2 text-right uppercase">
                    <div className="flex gap-2 justify-center items-center mr-2">
                      <button
                        className="underline text-base"
                        onClick={() => onPreViewContent(content.article)}
                      >
                        Preview
                      </button>
                      <Button
                        px="20px"
                        py="10px"
                        borderRadius="4px"
                        h="30px"
                        onClick={() => onApproveMessage(content.id)}
                      >
                        <span className="text-center">Approve</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        show={showApproveModal}
        toggle={toggleApproveModal}
        content={
          <div className=" rounded-xl px-4 py-4">
            <div className="flex flex-col items-center">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
                  />
                </svg>
              </div>
              <div className="my-4 text-center">
                <p>Are you sure you want to approve this message?</p>
              </div>
              <div className="flex justify-between gap-4 mt-2">
                <Button
                  px="20px"
                  py="10px"
                  borderRadius="4px"
                  h="30px"
                  onClick={() => onSubmitApprove()}
                >
                  <span className="text-center">Approve</span>
                </Button>
                <button
                  className="h-[36px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
                  onClick={() => toggleApproveModal()}
                >
                  <span className="text-center">Cancel</span>
                </button>
              </div>
            </div>
          </div>
        }
      ></Modal>

      <Modal
        show={showPreviewModal}
        toggle={togglePreviewModal}
        content={
          <div className="rounded-xl px-4 py-4 min-w-[500px]">
            <div className="flex flex-col items-center">
              <div className="p-2 w-full space-y-2 min-h-[500px] max-h-[80vh] overflow-y-auto">
                <div className="border-b border-gray-500">
                  <span
                    className="header-h3 font-bold"
                    style={{ fontSize: "22px", lineHeight: "25px" }}
                  >
                    {previewContent.title}
                  </span>
                </div>
                <div>
                  <img src={previewContent.primaryImage} alt="" />
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: previewContent.content,
                  }}
                ></p>
              </div>
              <Button
                px="20px"
                py="10px"
                borderRadius="4px"
                h="30px"
                onClick={() => togglePreviewModal()}
              >
                ok
              </Button>
            </div>
          </div>
        }
      ></Modal>
    </div>
  );
};

export default PendingReleases;
