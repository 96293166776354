import React, { useEffect } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import {
  RequiredValidationMessage,
  checkObjectHasData,
  isValidNumber,
} from "../../utils/utils";
import { StyledButton } from "../common/change-themes/styles-component";
import { FactFindIncomeSectionDTO } from "@dineiro/dineiro-sdk-mobile";
import ReviewData from "../common/review-form/ReviewData";
import { NumericFormat } from "react-number-format";

const IncomeForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindIncomeSectionDTO;
  resetField;
  errors;
  watch: UseFormWatch<FieldValues>;
  isSubmitted?: boolean;
  control: Control<FieldValues, any>;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  resetField,
  watch,
  isSubmitted,
  control,
}) => {
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;
  const onEdit = () => {
    setIsShowButtonEdit(false);
  };
  let totalMonthlyIncome = 0;

  const grossSalary =
    watch(`incomes[${keyValue}].grossSalary`, dataSubmit?.grossSalary) || 0;
  const dividends =
    watch(`incomes[${keyValue}].dividends`, dataSubmit?.dividends) || 0;
  const regularOvertime =
    watch(
      `incomes[${keyValue}].regularOvertime`,
      dataSubmit?.regularOvertime
    ) || 0;
  const familyAllowance =
    watch(
      `incomes[${keyValue}].familyAllowance`,
      dataSubmit?.familyAllowance
    ) || 0;
  const rentalIncome =
    watch(`incomes[${keyValue}].rentalIncome`, dataSubmit?.rentalIncome) || 0;
  const governmentBenefits =
    watch(
      `incomes[${keyValue}].governmentBenefits`,
      dataSubmit?.governmentBenefits
    ) || 0;

  if (
    isValidNumber(grossSalary) ||
    isValidNumber(dividends) ||
    isValidNumber(regularOvertime) ||
    isValidNumber(familyAllowance) ||
    isValidNumber(rentalIncome) ||
    isValidNumber(governmentBenefits)
  ) {
    const totalTemp =
      grossSalary +
      dividends +
      regularOvertime +
      familyAllowance +
      rentalIncome +
      governmentBenefits;

    totalMonthlyIncome =
      dataSubmit &&
      dataSubmit.totalMonthlyIncome &&
      isShowButtonEdit &&
      totalTemp !== dataSubmit.totalMonthlyIncome
        ? dataSubmit.totalMonthlyIncome + totalTemp
        : totalTemp;
  } else if (dataSubmit && dataSubmit?.totalMonthlyIncome) {
    totalMonthlyIncome = dataSubmit?.totalMonthlyIncome;
  }
  return (
    <div className="mt-5">
      <form
        className=" max-w-sm mx-auto text-sm "
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div
            id="showData"
            className={`flex flex-col gap-1 mb-2  border-b-[1px] border-white/60 pb-4`}
          >
            {isValidNumber(dataSubmit?.grossSalary) ? (
              <ReviewData
                title={"Gross Salary"}
                value={dataSubmit?.grossSalary.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.regularOvertime) ? (
              <ReviewData
                title={"Regular Overtime"}
                value={dataSubmit?.regularOvertime.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.familyAllowance) ? (
              <ReviewData
                title={"Family Allowance"}
                value={dataSubmit?.familyAllowance.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.rentalIncome) ? (
              <ReviewData
                title={"Rental Income"}
                value={dataSubmit?.rentalIncome.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.dividends) ? (
              <ReviewData
                title={"Dividends"}
                value={dataSubmit?.dividends.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.governmentBenefits) ? (
              <ReviewData
                title={"Government Benefits"}
                value={dataSubmit?.governmentBenefits.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
          </div>
        )}
        <div className="space-y-1">
          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.grossSalary) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`incomes[${keyValue}].grossSalary`}
              defaultValue={dataSubmit?.grossSalary ?? null}
              rules={{
                required: RequiredValidationMessage("Gross Salary"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`incomes[${keyValue}].grossSalary`}
                    name={name}
                    placeholder={"Gross Salary"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.incomes?.[keyValue]?.grossSalary && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.incomes?.[
                          keyValue
                        ]?.grossSalary.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.regularOvertime) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`incomes[${keyValue}].regularOvertime`}
              defaultValue={dataSubmit?.regularOvertime ?? null}
              rules={{
                required: RequiredValidationMessage("Regular Overtime"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`incomes[${keyValue}].regularOvertime`}
                    name={name}
                    placeholder={"Regular Overtime"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.incomes?.[keyValue]?.regularOvertime && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.incomes?.[
                          keyValue
                        ]?.regularOvertime.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.familyAllowance) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`incomes[${keyValue}].familyAllowance`}
              defaultValue={dataSubmit?.familyAllowance ?? null}
              rules={{
                required: RequiredValidationMessage("Family Allowance"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`incomes[${keyValue}].familyAllowance`}
                    name={name}
                    placeholder={"Family Allowance"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.incomes?.[keyValue]?.familyAllowance && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.incomes?.[
                          keyValue
                        ]?.familyAllowance.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.rentalIncome) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`incomes[${keyValue}].rentalIncome`}
              defaultValue={dataSubmit?.rentalIncome ?? null}
              rules={{
                required: RequiredValidationMessage("Rental Income"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`incomes[${keyValue}].rentalIncome`}
                    name={name}
                    placeholder={"Rental Income"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.incomes?.[keyValue]?.rentalIncome && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.incomes?.[
                          keyValue
                        ]?.rentalIncome.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.dividends) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`incomes[${keyValue}].dividends`}
              defaultValue={dataSubmit?.dividends ?? null}
              rules={{
                required: RequiredValidationMessage("Dividends"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`incomes[${keyValue}].dividends`}
                    name={name}
                    placeholder={"Dividends"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.incomes?.[keyValue]?.dividends && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.incomes?.[
                          keyValue
                        ]?.dividends.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.governmentBenefits) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`incomes[${keyValue}].governmentBenefits`}
              defaultValue={dataSubmit?.governmentBenefits ?? null}
              rules={{
                required: RequiredValidationMessage("Government Benefits"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`incomes[${keyValue}].governmentBenefits`}
                    name={name}
                    placeholder={"Government Benefits"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.incomes?.[keyValue]?.governmentBenefits && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.incomes?.[
                          keyValue
                        ]?.governmentBenefits.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}
          <input
            className=" hidden"
            type="number"
            id={`incomes[${keyValue}].totalMonthlyIncome`}
            {...register(`incomes[${keyValue}].totalMonthlyIncome`, {
              value: totalMonthlyIncome,
              valueAsNumber: true,
            })}
          />
        </div>
      </form>
      <div className="flex flex-col max-w-sm mx-auto pb-2 font-bold my-5 gap-4">
        <div className="flex justify-between text-sm">
          <span>Total Monthly Income</span>
          <span>
            {totalMonthlyIncome.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
export default IncomeForm;
