import { useEffect, useState } from "react";
import { StyledComponent } from "../change-themes/styles-component";
import Modal from "./Modal";
import {
  AccountDTO,
  LivingStatusEnum,
  PropertyAccountViewModel,
  PropertyDTO,
} from "@dineiro/dineiro-sdk-mobile";
import { FieldValues, UseFormRegister } from "react-hook-form";
import Button from "../button/Button";
import { isValidNumber } from "../../../utils/utils";

interface GroupedAccountLoan {
  providerAccount: any;
  accounts: AccountDTO[];
}

type ButtonProps = {
  showEditPropertyModal?;
  toggleEditPropertyModal?;
  handleSubmit?;
  onSubmit?;
  register: UseFormRegister<FieldValues>;
  groupedAccounts?: GroupedAccountLoan[];
  accounts?: AccountDTO[];
  property: PropertyDTO;
  errors?;
  watchLivingStatus?;
};

const EditPropertyModal = ({
  showEditPropertyModal,
  toggleEditPropertyModal,
  handleSubmit,
  onSubmit,
  register,
  groupedAccounts,
  accounts,
  property,
  errors,
  watchLivingStatus,
}: ButtonProps) => {
  return (
    <Modal
      show={showEditPropertyModal}
      toggle={toggleEditPropertyModal}
      width={"w-[495px]"}
      content={
        <StyledComponent>
          <div className="p-5 space-y-5">
            <div className="flex flex-col m-2">
              <span className=" uppercase text-xs text-white/70">property</span>
              <p
                style={{ fontSize: "30px", lineHeight: "34px" }}
                className=" header-h3 w-[335px] font-bold"
              >
                Edit Property
              </p>
              <div className="h-1 bg-yellow-500 w-[100px]"></div>
            </div>
            <form className="text-xs" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-2 mb-4 m-2">
                <div className="flex flex-col">
                  <select
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                    id="livingStatus"
                    {...register("livingStatus", {
                      value: property?.livingStatus ?? null,
                      required: "Your must select Living Status ",
                    })}
                  >
                    <option value="">Living Status</option>
                    <option value={LivingStatusEnum.PrimaryDwelling}>
                      I live here (primary dwelling)
                    </option>
                    <option value={LivingStatusEnum.InvestmentProperty}>
                      This is an investment property
                    </option>
                    <option value={LivingStatusEnum.SecondHome}>
                      This is a secondary home
                    </option>
                  </select>
                  {errors?.livingStatus && (
                    <span className="text-red-400 text-sm font-bold  px-1 ">
                      {errors?.livingStatus.message.toString()}
                    </span>
                  )}
                </div>
                {watchLivingStatus === LivingStatusEnum.PrimaryDwelling && (
                  <div className="flex flex-col">
                    <input
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      type="number"
                      id="livedYears"
                      placeholder="Enter the number of years you have lived"
                      {...register("livedYears", {
                        value: isValidNumber(property?.livedYears)
                          ? property.livedYears
                          : null,
                        min: {
                          value: 0,
                          message: "Min value is 0",
                        },
                      })}
                    />
                    {errors?.livedYears && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors?.livedYears.message.toString()}
                      </span>
                    )}
                  </div>
                )}
                {watchLivingStatus === LivingStatusEnum.InvestmentProperty && (
                  <div className="flex flex-col">
                    <input
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      type="number"
                      id="rentPrice"
                      placeholder="Rent Price"
                      {...register("rentPrice", {
                        value: isValidNumber(property?.rentPrice?.amount)
                          ? property?.rentPrice.amount
                          : null,
                        min: {
                          value: 0,
                          message: "Min value is 0",
                        },
                      })}
                    />
                    {errors?.rentPrice && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors?.rentPrice.message.toString()}
                      </span>
                    )}
                  </div>
                )}

                <div className="flex flex-col">
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                    type="number"
                    id="estimatePrice"
                    placeholder="Estimate Price"
                    {...register("estimatePrice", {
                      value: isValidNumber(property?.estimate?.amount)
                        ? property?.estimate?.amount
                        : null,
                      required: "Your must enter estimate price ",
                      min: {
                        value: 0,
                        message: "Min value is 0",
                      },
                    })}
                  />
                  {errors?.estimatePrice && (
                    <span className="text-red-400 text-sm font-bold  px-1 ">
                      {errors?.estimatePrice?.message?.toString()}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                    type="number"
                    id="bedrooms"
                    placeholder="Bedrooms"
                    {...register("bedrooms", {
                      value: isValidNumber(property?.bedrooms)
                        ? property?.bedrooms
                        : null,
                      min: {
                        value: 0,
                        message: "Min value is 0",
                      },
                    })}
                  />
                  {errors?.bedrooms && (
                    <span className="text-red-400 text-sm font-bold  px-1 ">
                      {errors?.bedrooms.message.toString()}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                    type="number"
                    id="bathrooms"
                    placeholder="Bathrooms"
                    {...register("bathrooms", {
                      value: isValidNumber(property?.bathrooms)
                        ? property?.bathrooms
                        : null,
                      min: {
                        value: 0,
                        message: "Min value is 0",
                      },
                    })}
                  />
                  {errors?.bathrooms && (
                    <span className="text-red-400 text-sm font-bold  px-1 ">
                      {errors?.bathrooms.message.toString()}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                    type="number"
                    id="carParks"
                    placeholder="CarParks"
                    {...register("carParks", {
                      value: isValidNumber(property?.carParks)
                        ? property?.carParks
                        : null,
                      min: {
                        value: 0,
                        message: "Min value is 0",
                      },
                    })}
                  />
                  {errors?.carParks && (
                    <span className="text-red-400 text-sm font-bold  px-1 ">
                      {errors?.carParks.message.toString()}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-center gap-2">
                <Button
                  h="45px"
                  px="16px "
                  py="8px"
                  borderRadius="4px"
                  fontSize="14px"
                  lineHeight="20px"
                  // onClick={handleDeleteFolder}
                >
                  Save Property
                </Button>
              </div>
            </form>
          </div>
        </StyledComponent>
      }
    ></Modal>
  );
};

export default EditPropertyModal;
