import React, { useEffect } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { VALIDATION } from "../../constants/constants";
import {
  checkObjectHasData,
  checkObjectHasNull,
  isValidNumber,
} from "../../utils/utils";
import { StyledButton } from "../common/change-themes/styles-component";
import {
  FactFindAddressSectionDTO,
  CurrentLivingArrangements,
} from "@dineiro/dineiro-sdk-mobile";
import AddressInput from "../common/input/AddressInput";

const AddressForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindAddressSectionDTO;
  resetField;
  errors;
  isSubmitted?: boolean;
  watch?: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  resetField,
  isSubmitted,
  watch,
  setValue,
  control,
}) => {
  const watchLivingYear = watch(`addresses[${keyValue}].yearsAt`);
  const watchAdress = watch(`addresses[${keyValue}].address`);
  const watchMostRecentAddress = watch(
    `addresses[${keyValue}].mostRecentAddress`
  );
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;
  let isDataHasNull = dataSubmit ? checkObjectHasNull(dataSubmit) : false;
  const onEdit = () => {
    setIsShowButtonEdit(false);
  };

  useEffect(() => {
    if (dataSubmit?.yearsAt >= 2) {
      resetField(`addresses[${keyValue}].mostRecentAddress`);
      resetField(`addresses[${keyValue}].mostRecentMonthsAt`);
      resetField(`addresses[${keyValue}].mostRecentYearsAt`);
    }
  }, [dataSubmit?.yearsAt]);

  return (
    <div className="mt-5">
      <form
        className="max-w-sm mx-auto text-sm"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div
          className={`flex flex-col gap-1  ${
            isDataHasNull || !isShowButtonEdit
              ? "border-b-[1px] border-white/60 pb-4"
              : ""
          }`}
        >
          <div className=" flex justify-end">
            {checkData && isShowButtonEdit && !isSubmitted && (
              <StyledButton
                type="button"
                className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
                onClick={onEdit}
              >
                Edit
              </StyledButton>
            )}
          </div>
          {dataSubmit && checkData && isShowButtonEdit && (
            <div>
              <div id="showData" className="flex flex-col gap-1 mb-4">
                <div className="px-2 mb-4">
                  <div className=" font-bold">
                    {(dataSubmit?.currentLivingArrangements &&
                      dataSubmit?.currentLivingArrangements !== null) ||
                    (dataSubmit?.address && dataSubmit?.address !== null) ? (
                      <p>Current Living Arrangements:</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className=" font-light flex flex-col">
                    {dataSubmit?.currentLivingArrangements &&
                      dataSubmit?.currentLivingArrangements !== null && (
                        <span>{dataSubmit?.currentLivingArrangements}</span>
                      )}
                    {dataSubmit?.address !== "" &&
                      dataSubmit?.address !== null && (
                        <span>{dataSubmit?.address}</span>
                      )}
                  </div>
                </div>
                <div className="px-2">
                  <div className=" font-bold">
                    {isValidNumber(dataSubmit?.yearsAt) ||
                    isValidNumber(dataSubmit?.monthsAt) ? (
                      <p>Time at Current Address:</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    {isValidNumber(dataSubmit?.yearsAt) ? (
                      <span>
                        {dataSubmit?.yearsAt === 0
                          ? ""
                          : dataSubmit?.yearsAt < 2
                          ? `${dataSubmit?.yearsAt} year`
                          : `${dataSubmit?.yearsAt} years`}{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                    {isValidNumber(dataSubmit?.monthsAt) ? (
                      <span>
                        {" "}
                        {dataSubmit?.monthsAt === 0
                          ? ""
                          : dataSubmit?.monthsAt < 2
                          ? `${dataSubmit?.monthsAt} month`
                          : `${dataSubmit?.monthsAt} months`}{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className=" font-light flex flex-col">
                {dataSubmit?.mostRecentAddress &&
                  dataSubmit?.mostRecentAddress !== "" &&
                  dataSubmit?.mostRecentAddress !== null && (
                    <div className="px-2 mb-4">
                      <div className=" font-bold">
                        <p>Next Living Address:</p>
                      </div>
                      <div className=" font-light flex flex-col">
                        <span>{dataSubmit?.mostRecentAddress}</span>
                      </div>
                    </div>
                  )}
                {isValidNumber(dataSubmit?.mostRecentMonthsAt) &&
                  isValidNumber(dataSubmit?.mostRecentYearsAt) && (
                    <div className="px-2">
                      <div className=" font-bold">
                        {dataSubmit?.mostRecentYearsAt === 0 &&
                        dataSubmit?.mostRecentMonthsAt === 0 ? (
                          <></>
                        ) : (
                          <p>Time at Next Address:</p>
                        )}
                      </div>
                      <div>
                        <span>
                          {dataSubmit?.mostRecentYearsAt === 0
                            ? ""
                            : dataSubmit?.mostRecentYearsAt < 2
                            ? `${dataSubmit?.mostRecentYearsAt} year`
                            : `${dataSubmit?.mostRecentYearsAt} years`}{" "}
                        </span>

                        <span>
                          {" "}
                          {dataSubmit?.mostRecentMonthsAt === 0
                            ? ""
                            : dataSubmit?.mostRecentMonthsAt < 2
                            ? `${dataSubmit?.mostRecentMonthsAt} month`
                            : `${dataSubmit?.mostRecentMonthsAt} months`}{" "}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.currentLivingArrangements ||
          dataSubmit?.currentLivingArrangements === null ||
          !isShowButtonEdit ? (
            <div>
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`addresses[${keyValue}].currentLivingArrangements`}
                {...register(
                  `addresses[${keyValue}].currentLivingArrangements`,
                  {
                    value: dataSubmit?.currentLivingArrangements ?? null,
                    required: VALIDATION.required.currentLivingArrangements,
                  }
                )}
              >
                <option value="">Current Living Arrangements</option>
                <option value={CurrentLivingArrangements.Renting}>
                  Renting
                </option>
                <option value={CurrentLivingArrangements.OwnMortgage}>
                  Own/mortgage
                </option>
                <option value={CurrentLivingArrangements.Own}>Own</option>
                <option value={CurrentLivingArrangements.LivingWithRelative}>
                  Living with relative
                </option>
                <option value={CurrentLivingArrangements.Other}>Other</option>
              </select>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.addresses?.[keyValue]?.currentLivingArrangements && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.addresses?.[
                      keyValue
                    ]?.currentLivingArrangements.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.address ||
          dataSubmit?.address === "" ||
          dataSubmit?.address === null ||
          !isShowButtonEdit ? (
            <div className=" relative">
              <Controller
                control={control}
                name={`addresses[${keyValue}].address`}
                rules={{
                  required: VALIDATION.required.address,
                }}
                defaultValue={dataSubmit?.address ?? null}
                render={({ field: { onChange, name, value } }) => (
                  <div>
                    <AddressInput
                      data={value}
                      name={name}
                      onChange={onChange}
                      watchValue={watchAdress}
                    ></AddressInput>
                  </div>
                )}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.addresses?.[keyValue]?.address && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.addresses?.[keyValue]?.address.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          <div className="flex items-center">
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.monthsAt) ||
            dataSubmit?.monthsAt === null ||
            !isValidNumber(dataSubmit?.yearsAt) ||
            dataSubmit?.yearsAt === null ||
            !isShowButtonEdit ? (
              <div className="w-1/2 text-sm">
                <span>Time At Current Address</span>
              </div>
            ) : (
              <></>
            )}
            <div className=" w-1/2 grid grid-cols-2 gap-2">
              {dataSubmit === undefined ||
              !isValidNumber(dataSubmit?.yearsAt) ||
              dataSubmit?.yearsAt === null ||
              !isShowButtonEdit ? (
                <div>
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    type="number"
                    id={`addresses[${keyValue}].yearsAt`}
                    placeholder="Years"
                    {...register(`addresses[${keyValue}].yearsAt`, {
                      value: dataSubmit?.yearsAt ?? null,
                      min: {
                        value: 0,
                        message: "Years must be greater than 0",
                      },
                      required: VALIDATION.required.yearsAt,
                    })}
                  />
                </div>
              ) : (
                <></>
              )}
              {dataSubmit === undefined ||
              !isValidNumber(dataSubmit?.monthsAt) ||
              dataSubmit?.monthsAt === null ||
              !isShowButtonEdit ? (
                <div>
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    type="number"
                    id={`addresses[${keyValue}].monthsAt`}
                    placeholder="Months"
                    {...register(`addresses[${keyValue}].monthsAt`, {
                      value: dataSubmit?.monthsAt ?? null,
                      max: {
                        value: 11,
                        message: "Months must be less than 12",
                      },
                      min: {
                        value: 0,
                        message: "Months must be greater than 0",
                      },
                      required: VALIDATION.required.monthsAt,
                    })}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {keyValue !== null &&
            keyValue !== undefined &&
            (errors.addresses?.[keyValue]?.monthsAt ||
              errors.addresses?.[keyValue]?.yearsAt) && (
              <span className="text-red-400 text-sm font-bold px-2">
                Please provide the correct duration for your current address.
              </span>
            )}
        </div>

        {(dataSubmit || dataSubmit?.yearsAt) &&
          watchLivingYear !== null &&
          watchLivingYear !== "" &&
          dataSubmit?.monthsAt !== null &&
          0 <= watchLivingYear &&
          watchLivingYear < 2 && (
            <div className="flex flex-col gap-1 mt-2">
              {dataSubmit === undefined ||
              !dataSubmit?.mostRecentAddress ||
              dataSubmit?.mostRecentAddress === null ||
              !dataSubmit?.mostRecentYearsAt ||
              dataSubmit?.mostRecentYearsAt === null ||
              !dataSubmit?.mostRecentMonthsAt ||
              dataSubmit?.mostRecentMonthsAt === null ||
              !isShowButtonEdit ? (
                <div className="text-sm mt-4 px-2">
                  <p>
                    Because you have lived at this address for less than two
                    years, please provide the next most recent address.
                  </p>
                </div>
              ) : (
                <></>
              )}

              {dataSubmit === undefined ||
              !dataSubmit?.mostRecentAddress ||
              dataSubmit?.mostRecentAddress === "" ||
              dataSubmit?.mostRecentAddress === null ||
              !isShowButtonEdit ? (
                <div className=" relative">
                  <Controller
                    control={control}
                    name={`addresses[${keyValue}].mostRecentAddress`}
                    rules={{
                      required: VALIDATION.required.mostRecentAddress,
                    }}
                    defaultValue={dataSubmit?.mostRecentAddress ?? null}
                    render={({ field: { onChange, name, value } }) => (
                      <div>
                        <AddressInput
                          data={value}
                          name={name}
                          onChange={onChange}
                          watchValue={watchMostRecentAddress}
                        ></AddressInput>
                      </div>
                    )}
                  />

                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.addresses?.[keyValue]?.mostRecentAddress && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.addresses?.[
                          keyValue
                        ]?.mostRecentAddress.message.toString()}
                      </span>
                    )}
                </div>
              ) : (
                <></>
              )}
              <div className="flex items-center">
                {dataSubmit === undefined ||
                !isValidNumber(dataSubmit?.mostRecentYearsAt) ||
                dataSubmit?.mostRecentYearsAt === null ||
                !isValidNumber(dataSubmit?.mostRecentMonthsAt) ||
                dataSubmit?.mostRecentMonthsAt === null ||
                !isShowButtonEdit ? (
                  <div className="w-1/2 text-sm">
                    <span>Time At Current Address</span>
                  </div>
                ) : (
                  <></>
                )}
                <div className=" w-1/2 grid grid-cols-2 gap-2">
                  {dataSubmit === undefined ||
                  !isValidNumber(dataSubmit?.mostRecentYearsAt) ||
                  dataSubmit?.mostRecentYearsAt === null ||
                  !isShowButtonEdit ? (
                    <div>
                      <input
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        type="number"
                        id={`addresses[${keyValue}].mostRecentYearsAt`}
                        placeholder="Years"
                        {...register(
                          `addresses[${keyValue}].mostRecentYearsAt`,
                          {
                            min: {
                              value: 0,
                              message: "Years must be greater than 0",
                            },
                            value: dataSubmit?.mostRecentYearsAt ?? null,
                            required: VALIDATION.required.mostRecentYearsAt,
                          }
                        )}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {dataSubmit === undefined ||
                  !isValidNumber(dataSubmit?.mostRecentMonthsAt) ||
                  dataSubmit?.mostRecentMonthsAt === null ||
                  !isShowButtonEdit ? (
                    <div>
                      <input
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        type="number"
                        id={`addresses[${keyValue}].mostRecentMonthsAt`}
                        placeholder="Months"
                        {...register(
                          `addresses[${keyValue}].mostRecentMonthsAt`,
                          {
                            max: {
                              value: 11,
                              message: "Months must be less than 12",
                            },
                            min: {
                              value: 0,
                              message: "Months must be greater than 0",
                            },
                            value: dataSubmit?.mostRecentMonthsAt ?? null,
                            required: VALIDATION.required.mostRecentMonthsAt,
                          }
                        )}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {keyValue !== null &&
                keyValue !== undefined &&
                (errors.addresses?.[keyValue]?.mostRecentYearsAt ||
                  errors.addresses?.[keyValue]?.mostRecentMonthsAt) && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    Please provide the correct duration for your next address.
                  </span>
                )}
            </div>
          )}
      </form>
    </div>
  );
};
export default AddressForm;
