export const PAGE_URL = {
  BASE_URL: "/:brand/:brandid/:office/:officeid",
  LOGIN: "/:brand/:brandid/:office/:officeid/login",
  LOGIN_WITH_PHONE_NUMBER:
    "/:brand/:brandid/:office/:officeid/login-with-phone-number",
  VERIFY: "/:brand/:brandid/:office/:officeid/verify-email-code",
  HOME: "/:brand/:brandid/:office/:officeid",
  CUSTOMERS: "customers",
  ADMIN_CONTENT: "admin-content",
  ADMIN_MESSAGE: "admin-message",
  CUSTOMER_ASSETS: ":id/:customername/:tab",
  CUSTOMER_MAIN_PAGE:
    "customer/:id/:customername/:tab?/:subtab?/:providerAccountId?",
  CUSTOMERS_IN_APP:
    "/:brand/:brandid/:office/:officeid/:id/:customername/upload-documents",
};

export const API_URL = {
  BASE: "",
  LOGIN: "/login",
};

export const CUSTOMER_TABS = {
  assets: "assets",
  expenses: "expenses",
  liabilities: "liabilities",
  goalsInsights: "goals-insights",
  documents: "documents",
  //TODO: Reopen when it already
  factfind: "fact-find",
  accountSetup: "account-setup",
};

export const FACTFIND_TABS = {
  personal: "personal",
  address: "address",
  employment: "employment",
  companytrust: "companytrust",
  assets: "assets",
  income: "income",
  liabilities: "liabilities",
  expenses: "expenses",
  questions: "questions",
  disclaimer: "disclaimer",
};

export const ACCOUNT_SETUP_TABS = {
  acceptance: "acceptance",
  termAndCondition: "termAndCondition",
  fastLink: "fastLink",
  manageAccounts: "manage-accounts",
};

export const VALIDATION = {
  required: {
    regular: "This field is required.",
    email: "User name is required.",
    emailCustomer: "Email is required.",
    password: "Password is required.",
    firstName: "First name is required.",
    surname: "First name is required.",
    givenName: "First name is required.",
    lastName: "Last name is required.",
    middleName: "Middle name is required.",
    address: "Address is required.",
    state: "State name is required.",
    postcode: "Post Code is required.",
    phone: "Phone is required.",
    dateOfBirth: "Date of birth is required.",
    title: "Title is required.",
    maritalStatus: "Marital Status is required.",
    ageOfDependents: "Age of Dependents must be filled.",
    ofDependents: "#Of Dependents is required.",
    licenseNumber: "Drivers License Number is required.",
    issuingState: "Issuing State is required.",
    expiryDate: "Expiry Date is required.",
    citizenship: "Citizenship is required.",
    currentLivingArrangements: "Current Living Arrangements is required.",
    mostRecentAddress: "Next Address is required.",
    monthsAt: "Months is required.",
    yearsAt: "Years is required.",
    mostRecentMonthsAt: "Months is required.",
    mostRecentYearsAt: "Years is required.",
    companyName: "Company Name is required.",
    customerType: "Please select the customer type.",
    selectBroker: "Please select the broker.",
  },
  email: {
    value:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Doesn't match email format.",
  },
  phone: {
    value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    message: "Doesn't match phone format.",
  },
  date: {
    value: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
    message: "Doesn't match date format (DD/MM/YYYY).",
  },
  creditCardExp: {
    value: /^(0?[1-9]|1[0-2])(\/|-)([0-9]{4})$/,
    message: "Doesn't match date format.",
  },
  currencyValue: {
    value: /^\d+(\.\d{1,2})?$/,
    message: "Please enter a valid currency amount",
  },
};

export const BROKERTABS = [
  {
    id: "assets",
    name: "Assets",
  },
  {
    id: "expenses",
    name: "Expenses",
  },
  {
    id: "liabilities",
    name: "Liabilities",
  },
  {
    id: "goals-insights",
    name: "Goals & Insights",
  },
  {
    id: "documents",
    name: "Documents",
  },
];

export const CUSTOMERTABS = [
  {
    id: "account-setup",
    name: "Account Setup",
  },
  //TODO: Reopen when it already
  {
    id: "fact-find",
    name: "Fact Find",
  },
  {
    id: "documents",
    name: "Documents",
  },
];

export const FACTFIND_LIST_TABS = [
  {
    id: "personal",
    name: "Personal",
  },
  {
    id: "address",
    name: "Address",
  },
  {
    id: "employment",
    name: "Employment",
  },
  {
    id: "companytrust",
    name: "Company/Trust",
  },
  {
    id: "assets",
    name: "Assets",
  },
  {
    id: "income",
    name: "Income",
  },
  {
    id: "liabilities",
    name: "Liabilities",
  },
  {
    id: "expenses",
    name: "Expenses",
  },
  {
    id: "questions",
    name: "Questions",
  },
  {
    id: "disclaimer",
    name: "Disclaimer",
  },
];
export const STATES = [
  { code: "NSW", name: "New South Wales" },
  { code: "QLD", name: "Queensland" },
  { code: "SA", name: "South Australia" },
  { code: "TAS", name: "Tasmania" },
  { code: "VIC", name: "Victoria" },
  { code: "WA", name: "Western Australia" },
  { code: "ACT", name: "Australian Capital Territory" },
  { code: "NT", name: "Northern Territory" },
];

export const EMPLOYMENT_TYPES = {
  FullTime: "fullTime",
  PartTime: "partTime",
  SelfEmployee: "selfEmployed",
  Casual: "casual",
};

export const COUNTRY_OF_ESTABLISHMENT = {
  australian: "Australian",
  other: "Other",
};
