import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUserInfoTK from "../hooks/useUserInfoTK";
import Modal from "../components/common/modal/Modal";
import Button from "../components/common/button/Button";
import Spinner from "../components/common/spinner/Spinner";
import { useDispatch } from "react-redux";
import { removeAccessToken } from "../store/actions/authenticate-actions";
import { getLocalStorageElement } from "../utils/utils";
import { PAGE_URL } from "../constants/constants";

export interface RoleBaseGuardProps {
  accessibleRoles: string[];
  children: ReactNode;
}

const RoleGuard: FC<RoleBaseGuardProps> = ({ accessibleRoles, children }) => {
  const { role } = useUserInfoTK();
  const [spinner, setSpinner] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const onSubmitRecall = () => {
    navigate("/");
  };

  const closeErrorModal = () => {
    localStorage.setItem("idToken", "");
    localStorage.setItem("reFreshToken", "");
    dispatch(removeAccessToken());
    return navigate("/");
  };

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (storedIdToken === "") {
        return navigate(
          PAGE_URL.LOGIN.replace(":brand", param.brand)
            .replace(":brandid", param.brandid)
            .replace(":office", param.office)
            .replace(":officeid", param.officeid)
        );
      }
      setSpinner(false);
    }, 1500);
  }, [storedIdToken]);

  if (!accessibleRoles.some((r) => r === role) && role !== "") {
    return (
      <Modal
        show={!accessibleRoles.some((r) => r === role)}
        toggle={closeErrorModal}
        content={
          <div className=" rounded-xl px-4 py-4">
            <div className="flex flex-col items-center">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="mt-2 text-center">
                <p>You do not have permission to access this page</p>
              </div>
              <div className="flex justify-center gap-4 mt-4">
                <Button
                  px="6px"
                  borderRadius="8px"
                  h="30px"
                  w="60px"
                  onClick={() => onSubmitRecall()}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        }
      ></Modal>
    );
  } else if (accessibleRoles.some((r) => r === role) && role === "") {
    return <>{spinner && <Spinner></Spinner>}</>;
  }

  return (
    <>
      <>{children}</>
    </>
  );
};

export default RoleGuard;
