import PhoneSetUpImage from "./accSetupTab.png";
import {
  StyledButton,
  getStoredProps,
} from "../../../components/common/change-themes/styles-component";
import { useNavigate, useParams } from "react-router-dom";
import {
  ACCOUNT_SETUP_TABS,
  CUSTOMER_TABS,
  PAGE_URL,
} from "../../../constants/constants";

const AcceptancePage = () => {
  const navigate = useNavigate();
  const { brand, brandid, officeid, tab, id, customername, subtab } =
    useParams();
  const storedProps = getStoredProps();
  const onStartedSetup = () => {
    let navigateTab = `${PAGE_URL.HOME.replace(":brand", brand)
      .replace(":brandid", brandid)
      .replace(":office", customername.toLowerCase().replace(/\s/g, "-"))
      .replace(":officeid", officeid)}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
      ":customername",
      customername ? customername.replace(/\s/g, "-") : "undefind"
    )
      .replace(":id", id)
      .replace(":tab?", CUSTOMER_TABS.accountSetup)
      .replace(":subtab?", ACCOUNT_SETUP_TABS.termAndCondition)
      .replace("/:providerAccountId?", "")}`;
    navigate(navigateTab);
  };
  return (
    <div className="w-full">
      <div className="flex flex-col items-center">
        <p className=" uppercase text-xs">Financial data</p>
        <p
          style={{ fontSize: "48px", lineHeight: "48px" }}
          className=" w-[750px] header-acceptance text-center"
        >
          We will now use open banking to connect your accounts
        </p>
        <div className="h-1 bg-yellow-500 w-28"></div>
      </div>
      <div className=" mt-[70px] flex items-center flex-col">
        <p className="w-[500px] text-center text-white/80">
          You can securely connect bank accounts, credit cards, loans,
          superannuation and more...
        </p>
      </div>
      <div className="grid grid-cols-3 gap-3 mt-3">
        <div className="pt-3 text-right space-y-12">
          <div className=" flex flex-col items-end gap-2">
            <span className="uppercase text-sm text-white/80">Safety</span>
            <span
              style={{ fontSize: "28px", lineHeight: "28px" }}
              className=" font-bold"
            >
              Fully Encrypted
            </span>
            <div className="h-1 bg-yellow-500 w-14"></div>
            <p className=" w-[255px] text-sm text-white/80">
              Secure and encrypted end-to-end - we never store you bank login
              details
            </p>
          </div>
          <div className=" flex flex-col items-end gap-2">
            <span className="uppercase text-sm text-white/80">Privacy</span>
            <span
              style={{ fontSize: "28px", lineHeight: "28px" }}
              className=" font-bold"
            >
              Fully Private
            </span>
            <div className="h-1 bg-yellow-500 w-14"></div>
            <p className=" w-[255px] text-sm text-white/80">
              Bank grade security, using Australian servers to keep your data
              safe
            </p>
          </div>
        </div>
        <div className=" flex justify-center">
          <img
            className="h-[350px]"
            src={storedProps.portalAccountSetupImageUrl ?? PhoneSetUpImage}
            alt="Phone setup img"
          />
        </div>
        <div className="pt-3 text-left space-y-12">
          <div className=" flex flex-col items-start gap-2">
            <span className="uppercase text-sm text-white/80">
              ACCC Approved
            </span>
            <span
              style={{ fontSize: "28px", lineHeight: "28px" }}
              className=" font-bold"
            >
              CDR Data
            </span>
            <div className="h-1 bg-yellow-500 w-14"></div>
            <p className=" w-[255px] text-sm text-white/80">
              Australian regulator approved secure data sharing scheme
            </p>
          </div>
          <div className=" flex flex-col items-start gap-2">
            <span className="uppercase text-sm text-white/80">Security</span>
            <span
              style={{ fontSize: "28px", lineHeight: "28px" }}
              className=" font-bold"
            >
              Read-Only Data
            </span>
            <div className="h-1 bg-yellow-500 w-14"></div>
            <p className=" w-[255px] text-sm text-white/80">
              dosh is a read-only app - no transactions can be performed on your
              accounts
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center my-[100px]">
        <StyledButton
          type="button"
          className="flex font-bold text-xs justify-center items-center uppercase w-[170px] h-[45px] px-[18px] rounded-md"
          onClick={onStartedSetup}
        >
          Let’s Get Started
        </StyledButton>
      </div>
    </div>
  );
};

export default AcceptancePage;
