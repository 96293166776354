import React from "react";
import { calculatePercentage, formatDate } from "../../utils/utils";
import { StyledProperty } from "../common/change-themes/styles-component";
import { SavingGoalDTO } from "@dineiro/dineiro-sdk-mobile";

const GoalSavingComponent: React.FC<{
  item: SavingGoalDTO;
}> = ({ item }) => {
  return (
    <div className="mb-5 p-2">
      <div className="mb-2">
        <span className=" header-h3 text-xl font-[700]">
          {item.savingGoalCategory.name}
        </span>
      </div>
      <span className=" uppercase text-sm  font-semibold leading-6">
        Progress
      </span>
      <div className="grid grid-cols-3 text-sm py-1 font-semibold leading-6">
        <div className="flex justify-start ">
          <span>0$</span>
        </div>
        <div className="flex justify-center">
          <span>
            {calculatePercentage(
              item.account.balance.amount ?? 0,
              item.targetBalance.amount ?? 0,
              0
            )}
          </span>
        </div>

        <div className="flex justify-end">
          <span>
            {(item.targetBalance.amount ?? 0).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            })}
          </span>
        </div>
      </div>
      <StyledProperty className="w-full rounded-full h-[15px] dark:bg-gray-700 ">
        <div
          className="bg-[#78BE20] h-[16px] rounded-full"
          style={{
            width: calculatePercentage(
              item.account.balance.amount ?? 0,
              item.targetBalance.amount ?? 0,
              0
            ),
          }}
        ></div>
      </StyledProperty>
      <div className="flex justify-center mb-4 text-sm py-1 font-semibold leading-6">
        <span>
          {(item.account.balance.amount ?? 0).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          })}
        </span>
      </div>
      <div className="flex justify-center gap-5 text-sm">
        <div className="flex flex-col uppercase">
          <span className="upercase ">Start date</span>
          <span>{formatDate(item.createdAt)}</span>
        </div>
        <div className="flex flex-col uppercase">
          <span className="upercase ">Savings Goal</span>
          <span>
            {(item.targetBalance.amount ?? 0).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            })}
          </span>
        </div>
        <div className="flex flex-col uppercase">
          <span className="upercase ">{item.depositFrequency} Saving</span>
          <span>
            {(item.intervalSavingAmount ?? 0).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            })}
          </span>
        </div>
        <div className="flex flex-col uppercase">
          <span className="upercase ">{item.depositFrequency} Left</span>
          <span>{item.numberOfInterval}</span>
        </div>
      </div>
    </div>
  );
};

export default GoalSavingComponent;
