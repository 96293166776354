import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { getBrandOfficesApi } from "../../../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IncomeForm from "../../../components/fact-find/IncomeForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  FactFindFactFindDTO,
  FactFindIncomeSectionDTO,
  FullFactFindIncomePostViewModel,
  IncomeSectionViewModel,
  Section,
} from "@dineiro/dineiro-sdk-mobile";
import { FACTFIND_TABS } from "../../../constants/constants";
import { updateFactFindSection } from "./ApiService";

const IncomeSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
}> = ({ numberOfApplicants, factFind, refetchGet, shouldRefetchGet }) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindIncomeSectionDTO[]>([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const { officeid, tab, id, customername, subtab } = useParams();
  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    reset,
    resetField,
    clearErrors,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);

  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const incomeSection = { ...s.incomeSection };
          return incomeSection;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const calculationTotalIncomes = (income: IncomeSectionViewModel) => {
    const grossSalary = income.grossSalary || 0;
    const dividends = income.dividends || 0;
    const regularOvertime = income.regularOvertime || 0;
    const familyAllowance = income.familyAllowance || 0;
    const rentalIncome = income.rentalIncome || 0;
    const governmentBenefits = income.governmentBenefits || 0;

    return (
      grossSalary +
      dividends +
      regularOvertime +
      familyAllowance +
      rentalIncome +
      governmentBenefits
    );
  };

  const convertDataToSubmit = (data) => {
    let dataConverted: IncomeSectionViewModel[] = [];
    if (data) {
      for (let index = 0; index < numberOfApplicants; index++) {
        let income = {
          applicantId: factFind.applicants[index].id,
          grossSalary:
            data[index]?.grossSalary !== undefined
              ? parseInt(data[index]?.grossSalary) ?? null
              : dataSubmit[index].grossSalary,
          regularOvertime:
            data[index]?.regularOvertime !== undefined
              ? parseInt(data[index]?.regularOvertime) ?? null
              : dataSubmit[index].regularOvertime,
          familyAllowance:
            data[index]?.familyAllowance !== undefined
              ? parseInt(data[index]?.familyAllowance) ?? null
              : dataSubmit[index].familyAllowance,
          rentalIncome:
            data[index]?.rentalIncome !== undefined
              ? parseInt(data[index]?.rentalIncome) ?? null
              : dataSubmit[index].rentalIncome,
          dividends:
            data[index]?.dividends !== undefined
              ? parseInt(data[index]?.dividends) ?? null
              : dataSubmit[index].dividends,
          governmentBenefits:
            data[index]?.governmentBenefits !== undefined
              ? parseInt(data[index]?.governmentBenefits) ?? null
              : dataSubmit[index].governmentBenefits,
        } as IncomeSectionViewModel;
        income.totalMonthlyIncome = calculationTotalIncomes(income);
        dataConverted.push(income);
      }
    }
    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.incomes) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.incomes?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.liabilities, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value && value.incomes ? value.incomes : dataSubmit;
    let dataSent: FullFactFindIncomePostViewModel = {
      id: factFind?.id,
      incomeSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindIncomePost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindIncomePostViewModel: dataSent,
      });
      toast.success(`Submit incomes data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSent.incomeSections);
      await updateFactFindSection(
        brandApi,
        factFind,
        officeid,
        id,
        Section.Liabilities
      );
      reset();
      refetchGet(!shouldRefetchGet);
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to submit incomes data.");
    }
  };

  const onSaveAsDraft = async () => {
    setIsShowButtonEdit(true);
    clearErrors();
    const data = getValues();
    let dataForm = data && data.incomes ? data.incomes : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindIncomePostViewModel = {
      id: factFind?.id,
      incomeSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindIncomePost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindIncomePostViewModel: dataSent,
      });
      toast.success(`Save incomes data successfully .`);
      setDataSubmit(dataSent.incomeSections);
      reset();
    } catch (error) {
      toast.error("Failed to save incomes data.");
    }
  };

  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <IncomeForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          resetField={resetField}
          watch={watch}
          isSubmitted={isSubmitted}
          control={control}
        ></IncomeForm>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default IncomeSession;
