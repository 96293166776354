import React from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import {
  RequiredValidationMessage,
  getLocalStorageElement,
  checkObjectHasData,
  checkObjectHasNull,
  isValidNumber,
} from "../../../utils/utils";
import { StyledButton } from "../../common/change-themes/styles-component";
import ReviewData from "../../common/review-form/ReviewData";
import {
  FactFindEmploymentSectionDTO,
  EmploymentType,
} from "@dineiro/dineiro-sdk-mobile";
import SelfEmployedForm from "./SelfEmployedForm";
import BaseEmployementForm from "./BaseEmployementForm";
import BaseEmploymentReviewForm from "./BaseEmploymentReviewForm";
import { EMPLOYMENT_TYPES } from "../../../constants/constants";

const EmployeeForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindEmploymentSectionDTO;
  errors;
  watch: UseFormWatch<FieldValues>;
  isSubmitted?: boolean;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  watch,
  isSubmitted,
}) => {
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;
  const employeeType = watch(
    `employment[${keyValue}].employmentType`,
    dataSubmit?.employmentType
  );
  const onEdit = () => {
    setIsShowButtonEdit(false);
  };

  return (
    <div className="mt-5">
      <form
        className="max-w-sm mx-auto text-sm"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div>
            {employeeType === EmploymentType.SelfEmployed ||
            dataSubmit?.employmentType === EmploymentType.SelfEmployed ? (
              <div id="showData" className="flex flex-col gap-1 mb-4">
                {dataSubmit?.employmentType &&
                  dataSubmit?.employmentType !== null && (
                    <ReviewData
                      title={"EmploymentType"}
                      value={dataSubmit?.employmentType}
                    ></ReviewData>
                  )}
                {dataSubmit?.selfEmployed &&
                  dataSubmit?.selfEmployed?.companyName !== "" &&
                  dataSubmit?.selfEmployed?.companyName !== null && (
                    <ReviewData
                      title={"Company Name"}
                      value={dataSubmit?.selfEmployed?.companyName}
                    ></ReviewData>
                  )}
                {dataSubmit?.selfEmployed &&
                  dataSubmit?.selfEmployed?.abn !== "" &&
                  dataSubmit?.selfEmployed?.abn !== null && (
                    <ReviewData
                      title={"ABN"}
                      value={dataSubmit?.selfEmployed?.abn}
                    ></ReviewData>
                  )}
                {dataSubmit?.selfEmployed &&
                  dataSubmit?.selfEmployed?.natureOfBusiness !== "" &&
                  dataSubmit?.selfEmployed?.natureOfBusiness !== null && (
                    <ReviewData
                      title={"Nature Of Business"}
                      value={dataSubmit?.selfEmployed?.natureOfBusiness}
                    ></ReviewData>
                  )}
                {dataSubmit?.selfEmployed &&
                  isValidNumber(dataSubmit?.selfEmployed?.yearsInOperation) && (
                    <ReviewData
                      title={"Years In Operation"}
                      value={dataSubmit?.selfEmployed?.yearsInOperation}
                    ></ReviewData>
                  )}
                {dataSubmit?.selfEmployed &&
                  dataSubmit?.selfEmployed?.accountantName !== "" &&
                  dataSubmit?.selfEmployed?.accountantName !== null && (
                    <ReviewData
                      title={"Accountant's Name"}
                      value={dataSubmit?.selfEmployed?.accountantName}
                    ></ReviewData>
                  )}
                {dataSubmit?.selfEmployed &&
                  dataSubmit?.selfEmployed?.accountantPhoneNumber !== "" &&
                  dataSubmit?.selfEmployed?.accountantPhoneNumber !== null && (
                    <ReviewData
                      title={"Accountant's Phone Number"}
                      value={dataSubmit?.selfEmployed?.accountantPhoneNumber}
                    ></ReviewData>
                  )}
              </div>
            ) : employeeType === EmploymentType.FullTime ||
              dataSubmit?.employmentType === EmploymentType.FullTime ? (
              <BaseEmploymentReviewForm
                dataSubmit={dataSubmit?.fullTime}
                employmentType={dataSubmit?.employmentType}
              ></BaseEmploymentReviewForm>
            ) : employeeType === EmploymentType.PartTime ||
              dataSubmit?.employmentType === EmploymentType.PartTime ? (
              <BaseEmploymentReviewForm
                dataSubmit={dataSubmit?.partTime}
                employmentType={dataSubmit?.employmentType}
              ></BaseEmploymentReviewForm>
            ) : employeeType === EmploymentType.Casual ||
              dataSubmit?.employmentType === EmploymentType.Casual ? (
              <BaseEmploymentReviewForm
                dataSubmit={dataSubmit?.casual}
                employmentType={dataSubmit?.employmentType}
              ></BaseEmploymentReviewForm>
            ) : (
              <></>
            )}
          </div>
        )}
        <div className="flex flex-col  gap-1">
          {dataSubmit === undefined ||
          !dataSubmit?.employmentType ||
          dataSubmit?.employmentType === null ||
          !isShowButtonEdit ? (
            <div>
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`employment[${keyValue}].employmentType`}
                {...register(`employment[${keyValue}].employmentType`, {
                  value: dataSubmit?.employmentType,
                  required: RequiredValidationMessage("Employment Type"),
                })}
              >
                <option value="">EmploymentType</option>
                <option value={EmploymentType.FullTime}>Full-time</option>
                <option value={EmploymentType.PartTime}>Part-time</option>
                <option value={EmploymentType.SelfEmployed}>
                  Self Employee
                </option>
                <option value={EmploymentType.Casual}>Casual</option>
              </select>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.employment?.[keyValue]?.employmentType && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.employment?.[
                      keyValue
                    ]?.employmentType.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
        </div>
        {employeeType === "" &&
        !dataSubmit?.employmentType &&
        !dataSubmit?.employmentType !== null ? (
          <></>
        ) : employeeType === EmploymentType.SelfEmployed ? (
          <SelfEmployedForm
            key={"selfEmployed"}
            keyValue={keyValue}
            register={register}
            isShowButtonEdit={isShowButtonEdit}
            errors={errors}
            dataSubmit={dataSubmit?.selfEmployed}
          ></SelfEmployedForm>
        ) : employeeType === EmploymentType.FullTime ? (
          <BaseEmployementForm
            key={"fullTime"}
            keyValue={keyValue}
            register={register}
            isShowButtonEdit={isShowButtonEdit}
            errors={errors}
            dataSubmit={dataSubmit?.fullTime}
            employmentType={EMPLOYMENT_TYPES.FullTime}
          ></BaseEmployementForm>
        ) : employeeType === EmploymentType.PartTime ? (
          <BaseEmployementForm
            key={"partTime"}
            keyValue={keyValue}
            register={register}
            isShowButtonEdit={isShowButtonEdit}
            errors={errors}
            dataSubmit={dataSubmit?.partTime}
            employmentType={EMPLOYMENT_TYPES.PartTime}
          ></BaseEmployementForm>
        ) : employeeType === EmploymentType.Casual ? (
          <BaseEmployementForm
            key={"casual"}
            keyValue={keyValue}
            register={register}
            isShowButtonEdit={isShowButtonEdit}
            errors={errors}
            dataSubmit={dataSubmit?.casual}
            employmentType={EMPLOYMENT_TYPES.Casual}
          ></BaseEmployementForm>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};
export default EmployeeForm;
