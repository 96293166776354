import React, { useEffect } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { RequiredValidationMessage } from "../../../utils/utils";
import moment from "moment";

const BaseEmployementForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  isShowButtonEdit;
  dataSubmit?;
  errors;
  employmentType: string;
}> = ({
  keyValue,
  register,
  isShowButtonEdit,
  dataSubmit,
  errors,
  employmentType,
}) => {
  useEffect(() => {
    if (dataSubmit?.startedDate)
      if (moment(new Date(dataSubmit?.startedDate)).isValid())
        dataSubmit.startedDate = moment(
          new Date(dataSubmit?.startedDate)
        ).format("YYYY-MM-DD");
      else if (moment(dataSubmit?.startedDate, "YYYY-MM-DD").isValid()) {
      }
  }, [dataSubmit?.startedDate]);

  return (
    <div className="space-y-1 mt-1 font-bold">
      {!dataSubmit ||
      dataSubmit?.currentEmployer === "" ||
      !dataSubmit?.currentEmployer ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${employmentType}.currentEmployer`}
            placeholder="Current Employer"
            {...register(
              `employment[${keyValue}].${employmentType}.currentEmployer`,
              {
                value: dataSubmit?.currentEmployer ?? null,
                required: RequiredValidationMessage("Current Employer"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[employmentType]
              ?.currentEmployer && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  employmentType
                ]?.currentEmployer.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      dataSubmit?.hrPayrollContact === "" ||
      !dataSubmit?.hrPayrollContact ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${employmentType}.hrPayrollContact`}
            placeholder="HR/Payroll Contact"
            {...register(
              `employment[${keyValue}].${employmentType}.hrPayrollContact`,
              {
                value: dataSubmit?.hrPayrollContact ?? null,
                required: RequiredValidationMessage("HR/Payroll Contact"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[employmentType]
              ?.hrPayrollContact && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  employmentType
                ]?.hrPayrollContact.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      dataSubmit?.phoneNumber === "" ||
      !dataSubmit?.phoneNumber ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${employmentType}.phoneNumber`}
            placeholder="Phone Number"
            {...register(
              `employment[${keyValue}].${employmentType}.phoneNumber`,
              {
                value: dataSubmit?.phoneNumber ?? null,
                required: RequiredValidationMessage("Phone Number"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[employmentType]?.phoneNumber && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  employmentType
                ]?.phoneNumber.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      dataSubmit?.occupation === "" ||
      !dataSubmit?.occupation ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${employmentType}.occupation`}
            placeholder="Occupation"
            {...register(
              `employment[${keyValue}].${employmentType}.occupation`,
              {
                value: dataSubmit?.occupation ?? null,
                required: RequiredValidationMessage("Occupation"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[employmentType]?.occupation && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  employmentType
                ]?.occupation.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}
      {!dataSubmit ||
      dataSubmit?.timeAtCurrentJob === "" ||
      !dataSubmit?.timeAtCurrentJob ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            id={`employment[${keyValue}].${employmentType}.timeAtCurrentJob`}
            placeholder="Time At Current Job"
            type="text"
            {...register(
              `employment[${keyValue}].${employmentType}.timeAtCurrentJob`,
              {
                value: dataSubmit?.timeAtCurrentJob ?? null,
                required: RequiredValidationMessage("Time At Current Job"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[employmentType]
              ?.timeAtCurrentJob && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  employmentType
                ]?.timeAtCurrentJob.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      !dataSubmit?.startedDate ||
      dataSubmit?.startedDate === null ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="date"
            id={`employment[${keyValue}].${employmentType}.startedDate`}
            placeholder="Start Date"
            {...register(
              `employment[${keyValue}].${employmentType}.startedDate`,
              {
                value: dataSubmit?.startedDate ?? null,
                required: RequiredValidationMessage("Started Date"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[employmentType]?.startedDate && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  employmentType
                ]?.startedDate.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BaseEmployementForm;
