import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ACCOUNT_SETUP_TABS,
  CUSTOMER_TABS,
  CUSTOMERTABS,
  FACTFIND_TABS,
  FACTFIND_LIST_TABS,
  PAGE_URL,
} from "../../constants/constants";
import DocumentTab from "../customer-tabs/DocumentTab";
import { StyledTabBar } from "../../components/common/change-themes/styles-component";
import Spinner from "../../components/common/spinner/Spinner";
import useDocuments from "../../hooks/useDocuments";
import { Toaster } from "react-hot-toast";
import FactFind from "../customer-tabs/FactFindTabs";
import useGetFactFind from "../../hooks/useGetFactFind";
import { Section } from "@dineiro/dineiro-sdk-mobile";
import AccountSetupTabs from "../customer-tabs/AccountSetupTabs";
import useGetAccounts from "../../hooks/useGetAccounts";
import useGetCustomerById from "../../hooks/useGetCustomerById";

const CustomerMainPage = () => {
  const { brand, brandid, officeid, tab, id, customername, subtab } =
    useParams();
  const [openTab, setOpenTab] = React.useState(CUSTOMER_TABS.accountSetup);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [saveSection, setSaveSection] = useState("");
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const { factFind } = useGetFactFind({
    officeId: officeid,
    userId: id,
  });
  const { userInfoById, refetchGetUserInfo, shouldRefetchGetUserInfo } =
    useGetCustomerById({
      officeId: officeid,
      userId: id,
    });
  const tabsRef = useRef([]);
  const { accounts } = useGetAccounts({
    officeId: officeid,
    userId: id,
  });

  const { folders, files, shouldRefetch, refetch } = useDocuments({
    officeId: officeid,
    userId: id,
    query: searchValue,
  });

  const setActive = () => {
    setActiveTabIndex(CUSTOMERTABS.findIndex((x) => x.id === tab));
  };

  const validateURL = () => {
    let navigateTab = "";
    navigateTab = `${PAGE_URL.HOME.replace(":brand", brand)
      .replace(":brandid", brandid)
      .replace(":office", customername.toLowerCase().replace(/\s/g, "-"))
      .replace(":officeid", officeid)}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
      ":customername",
      customername ? customername.replace(/\s/g, "-") : "undefind"
    ).replace(":id", id)}`;
    if (!tab || tab === ":tab") {
      navigateTab = userInfoById?.isAcceptedDineiroTermsAndConditions
        ? navigateTab
            .replace(":tab?", CUSTOMER_TABS.accountSetup)
            .replace(":subtab?", ACCOUNT_SETUP_TABS.manageAccounts)
            .replace("/:providerAccountId?", "")
        : navigateTab
            .replace(":tab?", CUSTOMER_TABS.accountSetup)
            .replace(":subtab?", ACCOUNT_SETUP_TABS.acceptance)
            .replace("/:providerAccountId?", "");
      setActiveTabIndex(0);
      navigate(navigateTab);
    }
  };

  const setTabPosition = () => {
    setOpenTab(tab);
    const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp)
      document.title = `${customername.replace(/-/g, " ")} - ${storedProp}`;
  }, []);

  useEffect(() => {
    setActive();
    setTabPosition();
  }, [activeTabIndex]);

  ///TODO : Need to refactor to apply change tab
  // const handleNavigate = (tab) => {
  //   const currentUrl = window.location.pathname;
  //   const hasIdParam = currentUrl.includes("/");

  //   let cleanUrl;

  //   if (hasIdParam) {
  //     if (subtab) {
  //       const idSubParamIndex = currentUrl.lastIndexOf("/");
  //       cleanUrl = currentUrl.substring(0, idSubParamIndex);
  //       const idParamIndex = cleanUrl.lastIndexOf("/");
  //       cleanUrl = cleanUrl.substring(0, idParamIndex);
  //     } else {
  //       const idParamIndex = currentUrl.lastIndexOf("/");
  //       cleanUrl = currentUrl.substring(0, idParamIndex);
  //     }
  //   } else {
  //     cleanUrl = currentUrl;
  //   }

  //   return `${cleanUrl}/${tab}`;
  // };
  useEffect(() => {
    if (factFind && factFind?.isSubmitted) {
      setSaveSection(FACTFIND_TABS.personal);
    } else if (factFind && factFind?.lastSection) {
      const factFindLastSection =
        factFind?.lastSection === Section.Business ||
        factFind?.lastSection === Section.Trust
          ? FACTFIND_TABS.companytrust
          : factFind?.lastSection.toLowerCase();
      const lastSection = FACTFIND_LIST_TABS.find(
        (s) => s.id === factFindLastSection
      );
      setSaveSection(lastSection.id);
    }
  }, [factFind]);

  const handleTabClick = (index, tab) => {
    setActiveTabIndex(index);
    setOpenTab(tab);
    let navigateTab = `${PAGE_URL.HOME.replace(":brand", brand)
      .replace(":brandid", brandid)
      .replace(":office", customername.toLowerCase().replace(/\s/g, "-"))
      .replace(":officeid", officeid)}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
      ":customername",
      customername ? customername.replace(/\s/g, "-") : "undefind"
    ).replace(":id", id)}`;

    if (tab === CUSTOMER_TABS.accountSetup) {
      navigateTab = userInfoById?.isAcceptedDineiroTermsAndConditions
        ? navigateTab
            .replace(":tab?", CUSTOMER_TABS.accountSetup)
            .replace(":subtab?", ACCOUNT_SETUP_TABS.manageAccounts)
            .replace("/:providerAccountId?", "")
        : navigateTab
            .replace(":tab?", CUSTOMER_TABS.accountSetup)
            .replace(":subtab?", ACCOUNT_SETUP_TABS.acceptance)
            .replace("/:providerAccountId?", "");
    }
    // TODO: Reopen when it already
    else if (
      tab === CUSTOMER_TABS.factfind &&
      brandid === "2" &&
      officeid === "2"
    ) {
      navigateTab =
        saveSection !== ""
          ? navigateTab
              .replace(":tab?", CUSTOMER_TABS.factfind)
              .replace(":subtab?", saveSection)
              .replace("/:providerAccountId?", "")
          : navigateTab
              .replace(":tab?", CUSTOMER_TABS.factfind)
              .replace(":subtab?", FACTFIND_TABS.personal)
              .replace("/:providerAccountId?", "");
    } else
      navigateTab = navigateTab
        .replace(":tab?", tab)
        .replace("/:subtab?", "")
        .replace("/:providerAccountId?", "");

    navigate(navigateTab);
  };

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      validateURL();
      setSpinner(false);
    }, 2500);
  }, [id, accounts, userInfoById]);

  return (
    <div className="items-center justify-center p-3">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <StyledTabBar className="flex-row relative mx-auto flex h-12 w-max rounded-3xl backdrop-blur-sm my-3">
          <span
            className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-3xl transition-all duration-300"
            style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
          >
            <span className="h-full w-full rounded-3xl bg-white" />
          </span>
          {CUSTOMERTABS.map((tab, index) => {
            const isActive = activeTabIndex === index;
            if (
              tab.id === CUSTOMER_TABS.accountSetup &&
              userInfoById?.isAcceptedDineiroTermsAndConditions
            ) {
              return (
                <button
                  key={index}
                  ref={(el) => (tabsRef.current[index] = el)}
                  className={`text-sm min-w-[180px] font-bold uppercase px-3 py-3 rounded-full block transition-all duration-300 ${
                    isActive ? "text-dark bg-white" : "text-white"
                  }`}
                  onClick={() => handleTabClick(index, tab.id)}
                >
                  Manage Accounts
                </button>
              );
            }
            // TODO: Reopen when it already
            else if (
              tab.id === CUSTOMER_TABS.factfind &&
              brandid !== "2" &&
              officeid !== "2"
            )
              return <></>;
            else
              return (
                <button
                  key={index}
                  ref={(el) => (tabsRef.current[index] = el)}
                  className={`text-sm w-[180px] font-bold uppercase px-3 py-3 rounded-full block transition-all duration-300 ${
                    isActive
                      ? "text-dark bg-white"
                      : userInfoById?.isAcceptedDineiroTermsAndConditions
                      ? "text-white"
                      : "text-white/50"
                  }`}
                  disabled={!userInfoById?.isAcceptedDineiroTermsAndConditions}
                  onClick={() => handleTabClick(index, tab.id)}
                >
                  {tab.name}
                </button>
              );
          })}
        </StyledTabBar>

        <div className="relative break-words min-w-[70%] mb-6 rounded">
          <div className="mx-4 my-5 flex-auto">
            <div>
              <div
                className={
                  openTab === CUSTOMER_TABS.accountSetup ? "block" : "hidden"
                }
              >
                <AccountSetupTabs
                  refetchGetUserInfo={refetchGetUserInfo}
                  shouldRefetchGetUserInfo={shouldRefetchGetUserInfo}
                ></AccountSetupTabs>
              </div>

              {/* TODO: Reopen when it already */}
              <div
                className={
                  openTab === CUSTOMER_TABS.factfind &&
                  brandid === "2" &&
                  officeid === "2"
                    ? "block"
                    : "hidden"
                }
              >
                <FactFind></FactFind>
              </div>

              <div
                className={
                  openTab === CUSTOMER_TABS.documents ? "block" : "hidden"
                }
              >
                <DocumentTab
                  folders={folders}
                  files={files}
                  shouldRefetch={shouldRefetch}
                  refetch={refetch}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                ></DocumentTab>
              </div>
            </div>
          </div>
        </div>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default CustomerMainPage;
