import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Button from "../../../components/common/button/Button";
import { FACTFIND_TABS } from "../../../constants/constants";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { StyledCheckBox } from "../../../components/common/change-themes/styles-component";
import {
  FactFindFactFindDTO,
  FullFactFindDisclaimerPostViewModel,
} from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../../../services/api-service";

const DisclaimerSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
  setIsStartUpFactFind;
}> = ({
  numberOfApplicants,
  factFind,
  refetchGet,
  shouldRefetchGet,
  setIsStartUpFactFind,
}) => {
  const [dataSubmit, setDataSubmit] = useState(
    factFind?.isAcceptedTermsAndConditions ?? false
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  let dataSetup = factFind ? factFind.isSubmitted : false;

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);
  const storedIdToken = getLocalStorageElement("idToken");
  const { officeid, id, subtab } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const navigate = useNavigate();

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.personal, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindDisclaimerPostViewModel = {
      isAccept: true,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindDisclaimerPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindDisclaimerPostViewModel: dataSent,
      });
      toast.success(`Submit fact find successfully .`);
      setIsStartUpFactFind(true);
      setDataSubmit(dataSent.isAccept);
      refetchGet(!shouldRefetchGet);
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to submit fact find data.");
    }
  };

  return (
    <div className="mt-5">
      <form className="max-w-sm mx-auto text-sm">
        <div className="font-semibold text-base">
          <p>Legal Disclaimer</p>
        </div>
        <div className="flex flex-col leading-6">
          <p
            dangerouslySetInnerHTML={{
              __html: factFind?.user?.brand?.disclaimer?.content,
            }}
          ></p>
        </div>

        <div className="flex items-center p-3">
          <StyledCheckBox
            id="default-checkbox"
            type="checkbox"
            className="w-4 h-4 bg-gray-100 border-gray-300 rounded-none dark:bg-gray-700 dark:border-gray-600"
            checked={dataSubmit}
            {...register("acceptTermsAndConditions", {
              value: dataSubmit ?? false,
              required: "You need to Accept tearms & Conditions",
              onChange: () => setDataSubmit(!dataSubmit),
            })}
          ></StyledCheckBox>
          <label htmlFor="default-checkbox" className="ms-2 ">
            Accept Terms & Conditions Above
          </label>
        </div>
        {errors.acceptTermsAndConditions && (
          <span className="text-red-400 text-sm font-bold px-2">
            {errors.acceptTermsAndConditions.message.toString()}
          </span>
        )}
      </form>
      <div
        className={`flex flex-col max-w-sm mx-auto my-5 gap-2 ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <Button
          name="saveContinue"
          w="250px"
          h="45px"
          px="18px"
          borderRadius="6px"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
export default DisclaimerSession;
