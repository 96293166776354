import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { useEffect, useState } from "react";
import BusinessForm from "../../../components/fact-find/BusinessForm";
import TrustForm from "../../../components/fact-find/TrustForm";
import {
  ApplyThrough,
  BusinessSectionViewModel,
  FactFindFactFindDTO,
  FullFactFindBusinessPostViewModel,
  FullFactFindTrustPostViewModel,
  Section,
  TrustSectionViewModel,
} from "@dineiro/dineiro-sdk-mobile";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import { getBrandOfficesApi } from "../../../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FACTFIND_TABS } from "../../../constants/constants";
import { updateFactFindSection } from "./ApiService";

const CompanyOrTrustSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  applyingBy: ApplyThrough;
  refetchGet;
  shouldRefetchGet;
}> = ({
  numberOfApplicants,
  factFind,
  refetchGet,
  shouldRefetchGet,
  applyingBy,
}) => {
  const [dataSubmit, setDataSubmit] = useState([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const {
    register,
    handleSubmit,
    resetField,
    clearErrors,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);

  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          if (factFind.applyThrough === ApplyThrough.YesBusiness) {
            const businessSection = { ...s.businessSection };
            return businessSection;
          } else if (factFind.applyThrough === ApplyThrough.YesTrust) {
            const trustSection = { ...s.trustSection };
            return trustSection;
          }
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const convertDataToSubmit = (data) => {
    let dataConverted = [];
    if (data) {
      if (factFind.applyThrough === ApplyThrough.YesBusiness) {
        for (let index = 0; index < numberOfApplicants; index++) {
          dataConverted.push({
            applicantId: factFind.applicants[index].id,
            name:
              data[index]?.name !== undefined
                ? data[index]?.name
                : dataSubmit[index].name,
            abnacn:
              data[index]?.abnacn !== undefined
                ? data[index]?.abnacn
                : dataSubmit[index].abnacn,
            registedAs:
              data[index]?.registedAs !== undefined &&
              data[index]?.registedAs !== ""
                ? data[index]?.registedAs
                : dataSubmit[index].registedAs,
            tradingAs:
              data[index]?.tradingAs !== undefined
                ? data[index]?.tradingAs
                : dataSubmit[index].tradingAs,
            natureOfBusiness:
              data[index]?.natureOfBusiness !== undefined
                ? data[index]?.natureOfBusiness
                : dataSubmit[index].natureOfBusiness,
            address:
              data[index]?.address !== undefined
                ? data[index]?.address
                : dataSubmit[index].address,
            directors:
              data[index]?.directors !== undefined
                ? data[index]?.directors
                : dataSubmit[index].directors,
            shareholders:
              data[index]?.shareholders !== undefined
                ? data[index]?.shareholders
                : dataSubmit[index].shareholders,
          } as BusinessSectionViewModel);
        }
      } else if (factFind.applyThrough === ApplyThrough.YesTrust)
        for (let index = 0; index < numberOfApplicants; index++) {
          dataConverted.push({
            applicantId: factFind.applicants[index].id,
            name:
              data[index]?.name !== undefined
                ? data[index]?.name
                : dataSubmit[index].name,
            fullName:
              data[index]?.fullName !== undefined
                ? data[index]?.fullName
                : dataSubmit[index].fullName,
            trustType:
              data[index]?.trustType !== undefined
                ? data[index]?.trustType
                : dataSubmit[index].trustType,
            country:
              data[index]?.country !== undefined
                ? data[index]?.country
                : dataSubmit[index].country,
            registeredAs:
              data[index]?.registeredAs !== undefined &&
              data[index]?.registeredAs !== ""
                ? data[index]?.registeredAs
                : dataSubmit[index].registeredAs,
            abnacn:
              data[index]?.abnacn !== undefined
                ? data[index]?.abnacn
                : dataSubmit[index].abnacn,
            address:
              data[index]?.address !== undefined
                ? data[index]?.address
                : dataSubmit[index].address,
            trustees: data[index]?.trustees
              ? data[index]?.trustees?.map((s, i) => {
                  if (s.dateOfBirth !== undefined && s.dateOfBirth !== "")
                    s.dateOfBirth = moment
                      .utc(s.dateOfBirth, "DD/MM/YYYY")
                      .isValid()
                      ? moment.utc(s.dateOfBirth, "DD/MM/YYYY").toDate()
                      : null;
                  else if (
                    dataSubmit[index]?.trustees &&
                    dataSubmit[index]?.trustees[i] &&
                    dataSubmit[index]?.trustees[i]?.dateOfBirth
                  )
                    s.dateOfBirth = moment
                      .utc(
                        dataSubmit[index]?.trustees[i]?.dateOfBirth,
                        "DD/MM/YYYY"
                      )
                      .isValid()
                      ? moment
                          .utc(
                            dataSubmit[index]?.trustees[i]?.dateOfBirth,
                            "DD/MM/YYYY"
                          )
                          .toDate()
                      : null;
                  return s;
                })
              : dataSubmit[index]?.trustees.map((s) => {
                  if (s.dateOfBirth !== undefined && s.dateOfBirth !== "") {
                    if (
                      moment
                        .utc(s?.dateOfBirth.toString(), "DD/MM/YYYY")
                        .isValid()
                    ) {
                      s.dateOfBirth = moment
                        .utc(s?.dateOfBirth.toString(), "DD/MM/YYYY")
                        .toDate();
                    } else if (
                      moment.utc(new Date(s?.dateOfBirth.toString())).isValid()
                    )
                      s.dateOfBirth = moment
                        .utc(new Date(s?.dateOfBirth))
                        .toDate();
                  }

                  return s;
                }),
            beneficiaries:
              data[index]?.beneficiaries !== undefined
                ? data[index]?.beneficiaries
                : dataSubmit[index].beneficiaries,
            beneficialOwners:
              data[index]?.beneficialOwners !== undefined
                ? data[index]?.beneficialOwners
                : dataSubmit[index].beneficialOwners,
          } as TrustSectionViewModel);
        }
    }
    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.trusts) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.trusts?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    } else if (errors && errors?.business) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.business?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.assets, subtab));
  };

  const onSubmit = async (value) => {
    setIsShowButtonEdit(true);
    const brandApi = getBrandOfficesApi(storedIdToken);

    if (applyingBy === ApplyThrough.YesBusiness) {
      const dataForm = value && value?.business ? value?.business : dataSubmit;
      const dataSent = {
        id: factFind?.id,
        businessSections: convertDataToSubmit(dataForm),
        isSaveAndNext: true,
      } as FullFactFindBusinessPostViewModel;
      try {
        await brandApi.brandOfficeCustomersFactFindBusinessPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindBusinessPostViewModel: dataSent,
        });
        toast.success(`Save company/trust section data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.businessSections);
        await updateFactFindSection(
          brandApi,
          factFind,
          officeid,
          id,
          Section.Assets
        );
        refetchGet(!shouldRefetchGet);
        onNavigateToNextSection();
      } catch (error) {
        toast.error("Failed to save company/trust section data.");
      }
    } else if (applyingBy === ApplyThrough.YesTrust) {
      const dataForm = value && value?.trusts ? value?.trusts : dataSubmit;
      const dataSent = {
        id: factFind?.id,
        trustSections: convertDataToSubmit(dataForm),
        isSaveAndNext: true,
      } as FullFactFindTrustPostViewModel;

      try {
        await brandApi.brandOfficeCustomersFactFindTrustPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindTrustPostViewModel: dataSent,
        });
        toast.success(`Save company/trust section data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.trustSections);
        await updateFactFindSection(
          brandApi,
          factFind,
          officeid,
          id,
          Section.Assets
        );
        refetchGet(!shouldRefetchGet);
        onNavigateToNextSection();
      } catch (error) {
        toast.error("Failed to save company/trust section data.");
      }
    }
  };
  const onSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();
    const brandApi = getBrandOfficesApi(storedIdToken);
    if (applyingBy === ApplyThrough.YesBusiness) {
      const dataForm = data && data?.business ? data?.business : dataSubmit;
      const dataSent = {
        id: factFind?.id,
        businessSections: convertDataToSubmit(dataForm),
        isSaveAndNext: false,
      } as FullFactFindBusinessPostViewModel;
      try {
        await brandApi.brandOfficeCustomersFactFindBusinessPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindBusinessPostViewModel: dataSent,
        });
        toast.success(`Save company/trust section data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.businessSections);
      } catch (error) {
        toast.error("Failed to save company/trust section data.");
      }
    } else if (applyingBy === ApplyThrough.YesTrust) {
      const dataForm = data && data?.trusts ? data?.trusts : dataSubmit;
      const dataSent = {
        id: factFind?.id,
        trustSections: convertDataToSubmit(dataForm),
        isSaveAndNext: false,
      } as FullFactFindTrustPostViewModel;

      try {
        await brandApi.brandOfficeCustomersFactFindTrustPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindTrustPostViewModel: dataSent,
        });
        toast.success(`Save company/trust section data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.trustSections);
      } catch (error) {
        toast.error("Failed to save company/trust section data.");
      }
    }
  };

  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        {applyingBy === ApplyThrough.YesBusiness ? (
          <BusinessForm
            keyValue={i}
            isSubmitted={isSubmitted}
            register={register}
            handleSubmit={handleSubmit}
            onFinalSubmit={onSubmit}
            dataSubmit={dataSubmit[i]}
            isShowButtonEdit={isShowButtonEdit}
            setIsShowButtonEdit={setIsShowButtonEdit}
            errors={errors}
            resetField={resetField}
            watch={watch}
            numberOfDirectorsValue={2}
            numberOfShareholderValue={2}
          ></BusinessForm>
        ) : applyingBy === ApplyThrough.YesTrust ? (
          <TrustForm
            numberOfBeneficalOwner={2}
            numberOfBeneficiary={2}
            numberOfTrustees={2}
            keyValue={i}
            isSubmitted={isSubmitted}
            register={register}
            handleSubmit={handleSubmit}
            onFinalSubmit={onSubmit}
            dataSubmit={dataSubmit[i]}
            isShowButtonEdit={isShowButtonEdit}
            setIsShowButtonEdit={setIsShowButtonEdit}
            errors={errors}
            resetField={resetField}
            watch={watch}
          ></TrustForm>
        ) : (
          <></>
        )}
      </div>
    ));
  };
  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Laters
          </button>
        </div>
      </div>
    </div>
  );
};
export default CompanyOrTrustSession;
