import { useState, useEffect } from "react";
import { AccountDTO, CONTAINEREnum } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useUserSuperAccounts = ({ officeId, userId }) => {
  const [supperAccounts, setSupperAccounts] = useState<AccountDTO[]>([]);
  const [shouldRefetchSupperAccounts, refetchGetSupperAccount] =
    useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const userRole = user ? user["roles"][0]["role"]["name"] : null;

  const UserSuperAccountsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken && userRole !== "customer") {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersAccountsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                setSupperAccounts(
                  response.data!.filter(
                    (account) =>
                      account.container === CONTAINEREnum.Investment &&
                      account.accountType.includes("SUPER_ANNUATION")
                  )
                );
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, shouldRefetchSupperAccounts]);

    return null;
  };

  UserSuperAccountsComponent();

  return {
    supperAccounts: supperAccounts,
    refetchGetSupperAccount,
    shouldRefetchSupperAccounts: shouldRefetchSupperAccounts,
  };
};

export default useUserSuperAccounts;
