import { useState, useEffect } from "react";
import { AccountDTO, VehicleDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useGetVehicles = ({ officeId, userId }) => {
  const [vehicles, setVehicles] = useState<VehicleDTO[]>([]);
  const [banks, setBanks] = useState<AccountDTO[]>([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const UserGetVehiclesComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersVehiclesGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setVehicles(response.data);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId]);

    return null;
  };

  UserGetVehiclesComponent();

  return {
    vehicles: vehicles,
  };
};

export default useGetVehicles;
