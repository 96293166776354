import React, { useEffect, useState } from "react";
import {
  addSpaceBeforeCapitalLetters,
  calculatePercentage,
  getLocalStorageElement,
  isValidNumber,
} from "../../utils/utils";
import {
  StyledProperty,
  StyledSecondaryProperty,
} from "../common/change-themes/styles-component";
import BedroomIcon from "../../images/goal-insight/bedroom.svg";
import BathroomIcon from "../../images/goal-insight/bathroom.svg";
import GarageIcon from "../../images/goal-insight/house-garage.svg";
import OptionIcon from "../../images/goal-insight/option-edit-icon.svg";
import { getBrandOfficesApi } from "../../services/api-service";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AccountDTO, PropertyDTO } from "@dineiro/dineiro-sdk-mobile";
import Button from "../common/button/Button";
import useModal from "../../hooks/useModal";
import { useForm } from "react-hook-form";
import EditPropertyModal from "../common/modal/EditPropertyModal";

const MortgagedProperty: React.FC<{
  handleOptionChange?;
  mortgagedProperty: PropertyDTO;
  accounts: AccountDTO[];
  groupedAccounts?;
  shouldRefetchGetProperties;
  refetchGetProperties;
}> = ({
  mortgagedProperty,
  handleOptionChange,
  groupedAccounts,
  accounts,
  shouldRefetchGetProperties,
  refetchGetProperties,
}) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const { officeid, id } = useParams();
  const [totalProperty, setTotalProperty] = useState(0);
  const [disableAlert, setDisableAlert] = useState(
    mortgagedProperty.isEnableLVRNotification
  );
  const [lvrValue, setLvrValue] = useState(
    mortgagedProperty.alertLVR ? mortgagedProperty.alertLVR.toString() : "0"
  );
  const { show: showEditPropertyModal, toggle: toggleEditPropertyModal } =
    useModal();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const watchLivingStatus = watch("livingStatus");
  const getTotal = () => {
    if (mortgagedProperty) {
      const total = mortgagedProperty.propertyAccounts.reduce(
        (acc, obj) => acc + obj.account?.balance?.amount,
        0
      );
      setTotalProperty(total);
    }
  };

  const handleCheckboxChange = (itemId, value, alertLVR) => {
    const propertiesApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      propertiesApi
        .brandOfficeCustomersPropertiesLVRPut({
          id: parseInt(officeid),
          userId: id,
          propertyId: itemId,
          brandOfficeCustomersPropertiesLVRPutViewModel: {
            isEnableLVRNotification: value,
            alertLVR:
              alertLVR !== null && alertLVR !== undefined ? alertLVR : 0,
          },
        })
        .then((respone) => {
          setDisableAlert(value);
        }),
      {
        loading: "Saving...",
        success: <b>Change LVR notification successful.</b>,
        error: <b>Failed to change LVR notification status .</b>,
      }
    );
  };

  const onEditProperty = (propertyId) => {
    // setDeleteFolderId(propertyId.toString());
    toggleEditPropertyModal();
  };

  const onSubmitEdit = async (data) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    try {
      await brandApi.brandOfficeCustomersPropertiesPut({
        id: parseInt(officeid),
        userId: id,
        propertyId: mortgagedProperty.id,
        brandOfficeCustomersPropertiesPutViewModel: {
          livingStatus: data.livingStatus,
          address: mortgagedProperty.address,
          estimatePrice: data.estimatePrice,
          rentPrice: data.rentPrice ?? null,
          livedYears: data.livedYears ?? null,
          carParks: isValidNumber(parseInt(data.carParks))
            ? data.carParks
            : null,
          bathrooms: isValidNumber(parseInt(data.bathrooms))
            ? data.bathrooms
            : null,
          bedrooms: isValidNumber(parseInt(data.bedrooms))
            ? data.bedrooms
            : null,
        },
      });

      refetchGetProperties(!shouldRefetchGetProperties);
      toast.success(`Successfully save property.`);
      toggleEditPropertyModal();
    } catch (error) {
      toast.error(`Failed save property.`);
    }
  };

  useEffect(() => {
    getTotal();
  }, [mortgagedProperty]);
  return (
    <div
      className="max-w-full mx-auto h-full space-y-1 mb-5"
      key={mortgagedProperty.id}
    >
      <div className="flex">
        <div className="flex justify-between w-full bg-black rounded-l h-10 py-2 px-4 text-sm">
          <span>{mortgagedProperty.address}</span>
          <div className="flex w-1/5 h-5 gap-4 items-center justify-end">
            <div className="flex space-x-1">
              <img src={BedroomIcon} alt="" />
              <span>{mortgagedProperty.bedrooms ?? 0}</span>
            </div>
            <div className="flex space-x-1">
              <img src={BathroomIcon} alt="" />
              <span>{mortgagedProperty.bathrooms ?? 0}</span>
            </div>
            <div className="flex space-x-1">
              <img src={GarageIcon} alt="" />
              <span>{mortgagedProperty.carParks ?? 0}</span>
            </div>
          </div>
        </div>
        <div className="h-10 w-10 flex items-center">
          <Button
            borderTopRightRadius="4px"
            borderBottomRightRadius="4px"
            w="40px"
            onClick={() => onEditProperty(mortgagedProperty.id)}
          >
            <img className="" src={OptionIcon} alt="" />
          </Button>
        </div>
      </div>
      <div>
        <StyledSecondaryProperty className="w-full px-3 py-2 rounded-t grid grid-cols-3 text-sm">
          <div className="flex space-x-1">
            <span>Interest Rate:</span>
            <span>N/A</span>
          </div>
          <div className="flex space-x-1">
            <span>Loan Type:</span>
            <span>
              {addSpaceBeforeCapitalLetters(mortgagedProperty.livingStatus)}
            </span>
          </div>
          <div className="flex space-x-1">
            <span>LVR Notification </span>
          </div>
        </StyledSecondaryProperty>
        <StyledProperty className="w-full p-4 rounded-b flex justify-between flex-wrap">
          <div className="flex gap-[16px] text-xs flex-wrap">
            <div className=" flex flex-col uppercase">
              <span className="upercase">Purchase Price</span>
              <span className=" text-xl font-semibold">
                {(mortgagedProperty?.purchasePrice.amount ?? 0).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  }
                )}
              </span>
            </div>
            <div className=" flex flex-col uppercase">
              <span className="upercase ">Estimated Value*</span>
              <span className=" text-xl font-semibold">
                {(mortgagedProperty?.estimate.amount ?? 0).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  }
                )}
              </span>
            </div>
            <div className=" flex flex-col uppercase">
              <span className="upercase ">Lifetime equity</span>
              <span className=" text-xl font-semibold">
                {(
                  (mortgagedProperty?.estimate.amount ?? 0) -
                  (mortgagedProperty?.purchasePrice.amount ?? 0)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })}
              </span>
            </div>
            <div className=" flex flex-col uppercase">
              <span className="upercase ">Outstanding Debt</span>
              <span className=" text-xl font-semibold">
                {totalProperty.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })}
              </span>
            </div>
            <div className=" flex flex-col uppercase">
              <span className="upercase ">Current LVR</span>
              <span className=" text-xl font-semibold">
                {mortgagedProperty?.estimate?.amount
                  ? calculatePercentage(
                      totalProperty,
                      mortgagedProperty?.estimate?.amount,
                      0
                    )
                  : `0%`}
              </span>
            </div>
          </div>
          <div className="flex justify-between w-1/3 items-center text-sm">
            <label
              htmlFor={mortgagedProperty.id.toString()}
              className="flex cursor-pointer select-none items-center space-x-2"
            >
              <span className="label flex items-center text-sm font-medium">
                Off
              </span>
              <div className="relative">
                <input
                  id={mortgagedProperty.id.toString()}
                  type="checkbox"
                  checked={disableAlert}
                  onChange={(e) =>
                    handleCheckboxChange(
                      mortgagedProperty.id,
                      !disableAlert,
                      lvrValue
                    )
                  }
                  className="peer sr-only"
                />
                <div className="block h-4 w-8 rounded-full bg-gray-200"></div>
                <div className="absolute w-3 h-3 transition rounded-full dot left-1 top-[2px] peer-checked:translate-x-full peer-checked:bg-green-500"></div>
              </div>
              <span className="label flex items-center text-sm font-medium">
                On
              </span>
            </label>

            <div className="flex gap-3 uppercase items-center">
              <span className="upercase">Alert LVR</span>
              <div className="relative">
                <input
                  type="text"
                  id="percent"
                  value={lvrValue}
                  className="bg-gray-900 bg-opacity-30 w-24 min-h-[44px] rounded text-white placeholder-gray-200 dark:text-white border-none focus:ring-white"
                  disabled={disableAlert ? false : true}
                  onBlur={(e) =>
                    handleCheckboxChange(
                      mortgagedProperty.id,
                      disableAlert,
                      e.target.value
                    )
                  }
                  onChange={(e) => setLvrValue(e.target.value)}
                />
                <span className="absolute top-1/2 right-1/4 transform -translate-y-1/2">
                  %
                </span>
              </div>
            </div>
          </div>
        </StyledProperty>
      </div>
      <div>
        {mortgagedProperty.propertyAccounts &&
          mortgagedProperty.propertyAccounts.length > 0 &&
          mortgagedProperty.propertyAccounts.map((propertyAccount) => {
            return (
              <div
                className="flex justify-between w-full bg-black rounded h-10 py-2 px-4 text-sm mb-[2px]"
                key={propertyAccount.id}
              >
                <span>{`${propertyAccount?.account.providerName ?? ""} (
                      ${propertyAccount?.account.accountNumber ?? ""}
                      )`}</span>
                <span>
                  {propertyAccount?.account?.balance
                    ? propertyAccount?.account?.balance?.amount.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )
                    : "$0"}
                </span>
              </div>
            );
          })}
      </div>
      <EditPropertyModal
        showEditPropertyModal={showEditPropertyModal}
        toggleEditPropertyModal={toggleEditPropertyModal}
        handleSubmit={handleSubmit}
        register={register}
        onSubmit={onSubmitEdit}
        groupedAccounts={groupedAccounts}
        accounts={accounts}
        property={mortgagedProperty}
        errors={errors}
        watchLivingStatus={watchLivingStatus}
      ></EditPropertyModal>
    </div>
  );
};

export default MortgagedProperty;
