import React, { useState, useEffect, useRef } from "react";
import UploadIcon from "../../images/document/upload-icon.svg";
import DeleteIcon from "../../images/document/delete-icon.svg";
import LogoDosh from "../../images/logo/dosh-logo.svg";
import { formatBytes, getLocalStorageElement } from "../../utils/utils";
import { useForm } from "react-hook-form";
import Button from "../../components/common/button/Button";
import { getBrandOfficesApi } from "../../services/api-service";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useDocuments from "../../hooks/useDocuments";
import {
  StyledComponent,
  getStoredProps,
  setStoredProps,
} from "../../components/common/change-themes/styles-component";
import useBrandInfo from "../../hooks/useBrandInfo";
import Spinner from "../../components/common/spinner/Spinner";

const DocumentInApp: React.FC<{}> = ({}) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [spinner, setSpinner] = useState(false);
  const { officeid, id } = useParams();
  let officeId = parseInt(officeid);
  const { brandInfo, officeName } = useBrandInfo({ brandOfficeId: officeId });

  const { folders, files, shouldRefetch, refetch } = useDocuments({
    officeId: officeid,
    userId: id,
  });

  const storedProps = getStoredProps();

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (brandInfo) {
        try {
          setStored();
          localStorage.setItem("brandOfficeName", officeName);
          if (officeName) document.title = `Login - ${officeName}`;
        } catch (err) {
        } finally {
          setSpinner(false);
        }
      }
    }, 1200);
  }, [brandInfo]);

  const setStored = () => {
    localStorage.removeItem("styledComponentProps");
    if (brandInfo.isWhiteLabel) {
      setStoredProps({
        ...storedProps,
        topLeftColor: brandInfo.style.topLeftColor,
        bottomRightColor: brandInfo.style.bottomRightColor,
        buttonColor: brandInfo.style.buttonColor,
        logoUrl: brandInfo.style.logoUrl,
        portalAccountSetupImageUrl: brandInfo.style.portalAccountSetupImageUrl,
      });
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [readyToUpLoads, setReadyToUpLoads] = useState([]);
  const hiddenFileInput = useRef(null);
  const [fileStored, setFileStored] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const updatedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      updatedFiles.push(files[i]);
    }
    setUploadedFiles(updatedFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files;
    const updatedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      updatedFiles.push(files[i]);
    }

    setUploadedFiles(updatedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index, isUploadfile) => {
    if (isUploadfile) {
      const updatedFiles = [...uploadedFiles];
      updatedFiles.splice(index, 1);
      setUploadedFiles(updatedFiles);
    }
    const updatedFiles = [...fileStored];
    updatedFiles.splice(index, 1);
    setFileStored(updatedFiles);
  };

  const handleSelectFolder = async (index, event) => {
    const { value } = event.target;
    const updatedFiles = [...uploadedFiles];

    const path = `${value}/${updatedFiles[index].name}`;

    const blob = new Blob([updatedFiles[index]], {
      type: updatedFiles[index].type,
    });
    updatedFiles[index] = {
      path: path,
      file: blob,
      isDirectory: false,
    };

    if (readyToUpLoads.length > 0) {
      if (readyToUpLoads[index]) {
        readyToUpLoads[index] = updatedFiles[index];
      } else setReadyToUpLoads([...readyToUpLoads, updatedFiles[index]]);
    } else setReadyToUpLoads(updatedFiles);
  };

  const onSubmit = (data) => {
    updateFilesInFolders();
  };

  const updateFilesInFolders = () => {
    let checkExist = 0;
    readyToUpLoads.map((file) => {
      const existingFiles = files.filter(
        (exist) => exist.fullName === file.path
      );
      if (existingFiles.length > 0) checkExist = checkExist + 1;
    });
    if (checkExist > 0)
      return toast.error(
        "An existing file is found in the same folder. Please delete the old file before uploading."
      );
    else {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      toast
        .promise(
          Promise.all(
            readyToUpLoads.map((blob) =>
              userReportApi.brandOfficeCustomersFilesPost({
                id: parseInt(officeid),
                userId: id,
                file: blob.file,
                path: blob.path,
                isDirectory: false,
              })
            )
          ),
          {
            loading: "Uploading...",
            success: <b>Upload files successful.</b>,
            error: <b>Failed to upload files.</b>,
          }
        )
        .then(() => {
          // Handle successful upload scenario
          refetch(!shouldRefetch);
          setReadyToUpLoads([]);
          setUploadedFiles([]);
        })
        .catch((error) => {
          // Handle upload errors gracefully
          console.error("Error uploading files:", error); // Log the error for debugging
          toast.error(
            "An error occurred while uploading files. Please try again later."
          ); // Inform the user
        });
    }
  };

  return (
    <StyledComponent
      topLeftColor={storedProps.topLeftColor}
      bottomRightColor={storedProps.bottomRightColor}
    >
      <div className="my-10 min-h-[100vh]">
        <div className="flex justify-center text-sm font-semibold mb-5">
          <button className="max-w-[200px] p-3">
            <img src={storedProps.logoUrl ?? LogoDosh} alt="Logo" />
          </button>
        </div>
        <div className="col-span-3 text-sm mx-2">
          <div className=" mb-5 space-y-5">
            <div>
              <p className="text-base text-center">
                Upload your documents from your phone.
              </p>
            </div>
            {uploadedFiles.length > 0 && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center"
              >
                <h3 className=" font-bold text text-2xl">
                  Files to be uploaded
                </h3>
                <div className="my-4 w-full">
                  {uploadedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="grid grid-row-2 dark:text-gray-400 border-y border-dashed"
                    >
                      <div className="flex justify-between items-center gap-2">
                        <div className="flex flex-col py-2">
                          <span className="text-base truncate">
                            {file.name.substring(0, 35) + "..."}
                          </span>
                          <span className="truncate">
                            file size ({formatBytes(file.size)})
                          </span>
                        </div>
                        <img
                          className="cursor-pointer h-10 py-2"
                          src={DeleteIcon}
                          onClick={() => handleDeleteFile(index, true)}
                          alt=""
                        />
                      </div>
                      <div className="flex justify-end items-center py-2 gap-2">
                        <select
                          className="w-full h-11 px-3 py-2 input_bg dark:text-white bg-black/20 pl-10 pr-3 rounded-lg text-sm border-none focus:ring-white"
                          id={`folder-${index}`}
                          name={`folder-${index}`}
                          value={file.folder}
                          onChange={(event) => handleSelectFolder(index, event)}
                          required
                        >
                          <option value="">Choose Folder</option>
                          {folders.map((folder) => (
                            <option key={folder.id} value={folder.name}>
                              {folder.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
                <Button px="16px" borderRadius="6px" h="40px">
                  <span className="uppercase">Upload Files</span>
                </Button>
              </form>
            )}
          </div>

          <div
            className="flex items-start max-w-full"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-20 border-2 border-white/30 border-dashed rounded-lg cursor-pointer bg-transparent dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex items-center justify-center">
                <img className=" w-10" src={UploadIcon} alt="" />
                <p className="ml-4 text-xl dark:text-gray-400">
                  <span>Choose files to upload</span>
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                ref={hiddenFileInput}
                onClick={() => {
                  hiddenFileInput.current.value = null;
                }}
                onChange={handleFileUpload}
                multiple
              />
            </label>
          </div>
        </div>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false} />
    </StyledComponent>
  );
};

export default DocumentInApp;
