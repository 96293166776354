import React, { useRef, useState } from "react";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { VALIDATION, STATES } from "../../constants/constants";
import { useParams } from "react-router-dom";
import {
  checkObjectHasData,
  checkObjectHasNull,
  isValidNumber,
} from "../../utils/utils";
import { StyledButton } from "../common/change-themes/styles-component";
import ReviewData from "../common/review-form/ReviewData";
import { MaritalStatus, UserDTO } from "@dineiro/dineiro-sdk-mobile";
import moment from "moment";

interface PersonalSectionView {
  applicantId?: number;
  title?: string;
  surname?: string;
  givenName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  email?: string;
  maritalStatus?: MaritalStatus;
  numberOfDependents?: number;
  ageOfDependents?: number[];
  driverLicense?: {
    driverLicenseNo?: string;
    issuingState?: string;
    expiredDate?: string;
    citizenship?: string;
  };
}

const PersonalSingleForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: PersonalSectionView;
  errors;
  setValue?: UseFormSetValue<FieldValues>;
  isSubmitted?: boolean;
  userInfoById: UserDTO;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  setValue,
  isSubmitted,
  userInfoById,
}) => {
  const [otp, setOtp] = useState<string[]>(
    dataSubmit?.ageOfDependents && dataSubmit?.ageOfDependents?.length > 0
      ? dataSubmit?.ageOfDependents.toString().split(",")
      : ["", "", "", ""]
  );
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;
  let isDataHasNull = dataSubmit ? checkObjectHasNull(dataSubmit) : false;

  const onEdit = () => {
    setIsShowButtonEdit(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "") {
      const nextIndex = Math.min(index + 1, inputRefs.current.length - 1);
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index] = "";
      setValue(`personals[${keyValue}].ageOfDependents[${index}]`, "");
      setOtp(newOtp);
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  const generateInputAgeOfDependents = () => {
    return otp.map((digit, index) => {
      const { onChange, ref, ...rest } = {
        ...register(`personals[${keyValue}].ageOfDependents[${index}]`, {
          value:
            dataSubmit?.ageOfDependents &&
            isValidNumber(dataSubmit?.ageOfDependents[index])
              ? dataSubmit?.ageOfDependents[index]
              : null,
        }),
      };
      return (
        <div className="flex justify-end" key={index}>
          <input
            className="h-[45px] w-[45px] px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            value={digit}
            maxLength={1}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) => handleChange(e, index)}
            {...rest}
            ref={(el) => {
              ref(el);
              inputRefs.current[index] = el;
            }}
          />
        </div>
      );
    });
  };

  return (
    <div className="mt-5">
      <form
        className="flex flex-col max-w-sm mx-auto gap-y-5 text-sm"
        // onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div id="showData" className="flex flex-col gap-1">
            {dataSubmit?.title !== "" && dataSubmit?.title && (
              <ReviewData
                title={"Title"}
                value={dataSubmit?.title}
              ></ReviewData>
            )}
            {dataSubmit?.surname !== "" && dataSubmit?.surname && (
              <ReviewData
                title={"Surname"}
                value={dataSubmit?.surname}
              ></ReviewData>
            )}
            {dataSubmit?.givenName !== "" && dataSubmit?.givenName && (
              <ReviewData
                title={"Given Name"}
                value={dataSubmit?.givenName}
              ></ReviewData>
            )}
            {dataSubmit?.dateOfBirth && dataSubmit?.dateOfBirth && (
              <ReviewData
                title={"Date Of Birth"}
                value={moment(new Date(dataSubmit?.dateOfBirth))
                  .format("DD/MM/YYYY")
                  .toString()}
              ></ReviewData>
            )}
            {dataSubmit?.phoneNumber !== "" && dataSubmit?.phoneNumber && (
              <ReviewData
                title={"Mobile"}
                value={dataSubmit?.phoneNumber}
              ></ReviewData>
            )}
            {dataSubmit?.email !== "" && dataSubmit?.email && (
              <ReviewData
                title={"Email"}
                value={dataSubmit?.email}
              ></ReviewData>
            )}
            {dataSubmit?.maritalStatus && dataSubmit?.maritalStatus && (
              <ReviewData
                title={"Marital Status"}
                value={dataSubmit?.maritalStatus}
              ></ReviewData>
            )}
            {isValidNumber(dataSubmit?.numberOfDependents) ||
            (dataSubmit?.ageOfDependents &&
              dataSubmit?.ageOfDependents.length > 0 &&
              dataSubmit?.ageOfDependents.length === 4 &&
              !dataSubmit?.ageOfDependents.some(
                (value) => value.toString() === ""
              )) ? (
              <ReviewData
                title={"Dependents"}
                value={`${
                  dataSubmit?.numberOfDependents
                } dependents -${dataSubmit?.ageOfDependents?.toString()} years of age`}
              ></ReviewData>
            ) : (
              <></>
            )}
            {dataSubmit?.driverLicense &&
              dataSubmit?.driverLicense?.driverLicenseNo !== "" &&
              dataSubmit?.driverLicense?.driverLicenseNo && (
                <ReviewData
                  title={"Driver License Number"}
                  value={dataSubmit?.driverLicense?.driverLicenseNo}
                ></ReviewData>
              )}
            {dataSubmit?.driverLicense &&
              dataSubmit?.driverLicense?.issuingState !== "" &&
              dataSubmit?.driverLicense?.issuingState && (
                <ReviewData
                  title={"Issuing State"}
                  value={dataSubmit?.driverLicense?.issuingState}
                ></ReviewData>
              )}
            {dataSubmit?.driverLicense &&
              dataSubmit?.driverLicense?.expiredDate &&
              dataSubmit?.driverLicense?.expiredDate && (
                <ReviewData
                  title={"Expiry Date"}
                  value={dataSubmit?.driverLicense?.expiredDate}
                ></ReviewData>
              )}
            {dataSubmit?.driverLicense &&
              dataSubmit?.driverLicense?.citizenship !== "" &&
              dataSubmit?.driverLicense?.citizenship && (
                <ReviewData
                  title={"Citizenship"}
                  value={dataSubmit?.driverLicense?.citizenship}
                ></ReviewData>
              )}
          </div>
        )}

        <div
          className={`space-y-1   ${
            isDataHasNull || !isShowButtonEdit
              ? "border-b-[1px] border-white/60 pb-4"
              : ""
          }`}
        >
          {dataSubmit === undefined ||
          dataSubmit?.title === "" ||
          !dataSubmit?.title ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].title`}
                  className="my-1 px-2"
                >
                  Title
                </label>
              )}
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`personals[${keyValue}].title`}
                {...register(`personals[${keyValue}].title`, {
                  value: dataSubmit?.title ?? null,
                  required: VALIDATION.required.title,
                })}
              >
                <option value="">Title</option>
                <option value="Dr">Dr</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
              </select>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.title && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[keyValue]?.title.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.surname === "" ||
          !dataSubmit?.surname ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].surname`}
                  className="my-1 px-2"
                >
                  Surname
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`personals[${keyValue}].surname`}
                placeholder={keyValue === 0 ? "" : "Surname"}
                {...register(`personals[${keyValue}].surname`, {
                  value:
                    userInfoById.lastName &&
                    !dataSubmit?.surname &&
                    keyValue === 0
                      ? userInfoById.lastName
                      : dataSubmit?.surname,
                  required: VALIDATION.required.surname,
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.surname && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[keyValue]?.surname.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.givenName === "" ||
          !dataSubmit?.givenName ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].givenName`}
                  className="my-1 px-2"
                >
                  Given Name
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`personals[${keyValue}].givenName`}
                placeholder={keyValue === 0 ? "" : "Given Name"}
                {...register(`personals[${keyValue}].givenName`, {
                  value:
                    userInfoById.firstName &&
                    !dataSubmit?.givenName &&
                    keyValue === 0
                      ? userInfoById.firstName
                      : dataSubmit?.givenName,
                  required: VALIDATION.required.givenName,
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.givenName && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[keyValue]?.givenName.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.dateOfBirth ||
          !isShowButtonEdit ? (
            <div className="grid grid-cols-2 gap-2 items-center">
              <label
                htmlFor={`personals[${keyValue}].dateOfBirth`}
                className="px-2"
              >
                Date Of Birth
              </label>
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="date"
                  id={`personals[${keyValue}].dateOfBirth`}
                  {...register(`personals[${keyValue}].dateOfBirth`, {
                    value:
                      userInfoById.dateOfBirth &&
                      !dataSubmit?.dateOfBirth &&
                      keyValue === 0
                        ? moment(userInfoById.dateOfBirth)
                            .format("YYYY-MM-DD")
                            .toString()
                        : dataSubmit?.dateOfBirth,
                    required: VALIDATION.required.dateOfBirth,
                  })}
                />
                {keyValue !== null &&
                  keyValue !== undefined &&
                  errors.personals?.[keyValue]?.dateOfBirth && (
                    <span className="text-red-400 text-sm font-bold px-2">
                      {errors.personals?.[
                        keyValue
                      ]?.dateOfBirth.message.toString()}
                    </span>
                  )}
              </div>
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.phoneNumber === "" ||
          !dataSubmit?.phoneNumber ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].phoneNumber`}
                  className="my-1 px-2"
                >
                  Mobile
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`personals[${keyValue}].phoneNumber`}
                placeholder={keyValue === 0 ? "" : "Mobile"}
                {...register(`personals[${keyValue}].phoneNumber`, {
                  value:
                    userInfoById.phoneNumber &&
                    !dataSubmit?.phoneNumber &&
                    keyValue === 0
                      ? userInfoById.phoneNumber
                      : dataSubmit?.phoneNumber,
                  pattern: VALIDATION.phone,
                  required: VALIDATION.required.phone,
                })}
              />

              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.phoneNumber && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.phoneNumber.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.email === "" ||
          !dataSubmit?.email ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].email`}
                  className="my-1 px-2"
                >
                  Email
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`personals[${keyValue}].email`}
                placeholder={keyValue === 0 ? "" : "Email"}
                {...register(`personals[${keyValue}].email`, {
                  value:
                    userInfoById.email && !dataSubmit?.email && keyValue === 0
                      ? userInfoById.email
                      : dataSubmit?.email ?? null,
                  pattern: VALIDATION.email,
                  required: VALIDATION.required.email,
                })}
              />

              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.email && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[keyValue]?.email.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.maritalStatus ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].maritalStatus`}
                  className="my-1 px-2"
                >
                  Marital Status
                </label>
              )}
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`personals[${keyValue}].maritalStatus`}
                {...register(`personals[${keyValue}].maritalStatus`, {
                  value: dataSubmit?.maritalStatus ?? null,
                  required: VALIDATION.required.maritalStatus,
                })}
              >
                <option value="">Marital Status</option>
                <option value={MaritalStatus.Single}>Single</option>
                <option value={MaritalStatus.Married}>Married</option>
                <option value={MaritalStatus.Widowed}>Defacto</option>
                <option value={MaritalStatus.Divorced}>Divorced</option>
                <option value={MaritalStatus.Divorced}>Other</option>
              </select>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.maritalStatus && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.maritalStatus.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.numberOfDependents) ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].numberOfDependents`}
                  className="my-1 px-2"
                >
                  # Of Dependents
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="number"
                id={`personals[${keyValue}].numberOfDependents`}
                placeholder={keyValue === 0 ? "" : "# Of Dependents"}
                {...register(`personals[${keyValue}].numberOfDependents`, {
                  value: dataSubmit?.numberOfDependents ?? null,
                  required: VALIDATION.required.ofDependents,
                  valueAsNumber: true,
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.numberOfDependents && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.numberOfDependents.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          ((!dataSubmit?.ageOfDependents ||
            dataSubmit?.ageOfDependents?.length < 4 ||
            dataSubmit?.ageOfDependents.some(
              (value) => value.toString() === "" || !isValidNumber(value)
            )) &&
            dataSubmit?.numberOfDependents !== 0) ||
          !isShowButtonEdit ? (
            <div>
              <div className=" grid grid-cols-2 gap-1 items-center">
                <div className=" px-2">
                  <span>Age Of Dependents</span>
                </div>
                <div className=" grid grid-cols-4">
                  {generateInputAgeOfDependents()}
                </div>
              </div>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.ageOfDependents && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {VALIDATION.required.ageOfDependents}
                  </span>
                )}
              {dataSubmit?.ageOfDependents?.some(
                (value) => value.toString() !== ""
              ) &&
              dataSubmit?.ageOfDependents?.some(
                (value) => value.toString() === ""
              ) &&
              isShowButtonEdit ? (
                <span className="text-red-400 text-sm font-bold px-2">
                  Age Of Dependents must be filled
                </span>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="space-y-1">
          {(dataSubmit && dataSubmit?.driverLicense?.driverLicenseNo === "") ||
          !dataSubmit?.driverLicense ||
          dataSubmit?.driverLicense.issuingState === "" ||
          !dataSubmit?.driverLicense?.expiredDate ||
          dataSubmit?.driverLicense?.citizenship === "" ||
          dataSubmit?.driverLicense.issuingState === null ||
          dataSubmit?.driverLicense?.driverLicenseNo === null ||
          dataSubmit?.driverLicense?.expiredDate === null ||
          dataSubmit?.driverLicense?.citizenship === null ||
          !isShowButtonEdit ? (
            <div className=" mb-3">
              <span>ID Verification</span>
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.driverLicense ||
          dataSubmit?.driverLicense?.driverLicenseNo === "" ||
          !dataSubmit?.driverLicense?.driverLicenseNo ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].driverLicense.driverLicenseNo`}
                  className="my-1 px-2"
                >
                  Drivers License Number
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`personals[${keyValue}].driverLicense.driverLicenseNo`}
                placeholder={keyValue === 0 ? "" : "Drivers License Number"}
                {...register(
                  `personals[${keyValue}].driverLicense.driverLicenseNo`,
                  {
                    value: dataSubmit?.driverLicense?.driverLicenseNo ?? null,
                    required: VALIDATION.required.licenseNumber,
                  }
                )}
              />

              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.driverLicense
                  ?.driverLicenseNo && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.driverLicense?.driverLicenseNo.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.driverLicense ||
          !dataSubmit?.driverLicense?.issuingState ||
          dataSubmit?.driverLicense?.issuingState === "" ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].driverLicense.issuingState`}
                  className="my-1 px-2"
                >
                  Issuing State
                </label>
              )}
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`personals[${keyValue}].driverLicense.issuingState`}
                {...register(
                  `personals[${keyValue}].driverLicense.issuingState`,
                  {
                    value: dataSubmit?.driverLicense?.issuingState ?? null,
                    required: VALIDATION.required.issuingState,
                  }
                )}
              >
                <option value="">Issuing State</option>
                {STATES.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.code}
                  </option>
                ))}
              </select>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.driverLicense?.issuingState && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.driverLicense?.issuingState.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.driverLicense?.expiredDate ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].driverLicense.expiredDate`}
                  className="my-1 px-2"
                >
                  Expiry Date
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`personals[${keyValue}].driverLicense.expiredDate`}
                placeholder={keyValue === 0 ? "" : "Expiry Date"}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                {...register(
                  `personals[${keyValue}].driverLicense.expiredDate`,
                  {
                    value: dataSubmit?.driverLicense?.expiredDate ?? null,
                    required: VALIDATION.required.expiryDate,
                  }
                )}
              />

              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.driverLicense?.expiredDate && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.driverLicense?.expiredDate.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.driverLicense?.citizenship === "" ||
          !dataSubmit?.driverLicense?.citizenship ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`personals[${keyValue}].driverLicense.citizenship`}
                  className="my-1 px-2"
                >
                  Citizenship
                </label>
              )}
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`personals[${keyValue}].driverLicense.citizenship`}
                {...register(
                  `personals[${keyValue}].driverLicense.citizenship`,
                  {
                    value: dataSubmit?.driverLicense?.citizenship ?? null,
                    required: VALIDATION.required.citizenship,
                  }
                )}
              >
                <option value="">Citizenship</option>
                <option value="Australian">Australian</option>
                <option value="Resident">Resident</option>
                <option value="Non-resident">Non-resident</option>
              </select>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.personals?.[keyValue]?.driverLicense?.citizenship && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.personals?.[
                      keyValue
                    ]?.driverLicense?.citizenship.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
};
export default PersonalSingleForm;
