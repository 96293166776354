import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddUserForm from "../../components/home/AddUserForm";
import { PAGE_URL } from "../../constants/constants";
import Customers from "../../components/home/Customers";
import { getUniqueStartCharacters, handleNavigate } from "../../utils/utils";
import useCustomers from "../../hooks/useCustomers";
import useBrandInfo from "../../hooks/useBrandInfo";
import FilterBar from "../../components/home/FilterBar";
import Spinner from "../../components/common/spinner/Spinner";

const Home = () => {
  const [alphabet, setAlphabet] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { officeid } = useParams();
  const [spinner, setSpinner] = useState(false);

  const { brandInfo } = useBrandInfo({ brandOfficeId: officeid });
  const {
    customers,
    refetch,
    shouldRefetch,
    setSearch,
    setFirstFilter,
    setSecondFilter,
    setThirdFilter,
  } = useCustomers({
    officeId: officeid,
  });
  const navigate = useNavigate();

  const onContent = () => {
    navigate(handleNavigate(PAGE_URL.ADMIN_CONTENT));
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp) document.title = `Customers - ${storedProp}`;
  }, []);

  const onMessage = () => {
    navigate(handleNavigate(PAGE_URL.ADMIN_MESSAGE));
  };

  useEffect(() => {
    if (customers.length > 0) {
      const getAlphabet = getUniqueStartCharacters(customers);
      setAlphabet(getAlphabet);
    } else setAlphabet([]);
  }, [customers]);

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      setSpinner(false);
    }, 2000);
  }, [officeid]);

  const handleChange = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setSearchValue(e.target.value);
  };

  const onSearch = () => {
    setSearch(searchValue);
  };

  return (
    <div className="items-center justify-center p-3">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">DINEIRO ADMIN</h6>
        <span className=" text-5xl text-center font-bold">Adviser Portal</span>
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="flex items-center justify-center h-full">
        {/*TODO: <div className="space-y-2 py-4 w-2/3"> */}
        <div className="space-y-2 py-4 px-10 w-2/3">
          <FilterBar
            brand={brandInfo}
            setFirstFilter={setFirstFilter}
            setSecondFilter={setSecondFilter}
            setThirdFilter={setThirdFilter}
            onContent={onContent}
            handleChange={handleChange}
            searchValue={searchValue}
            onMessage={onMessage}
            onSearch={onSearch}
          ></FilterBar>
          <Customers data={customers} pageSize={25} alphabet={alphabet} />
        </div>
      </div>
      <div className="mt-3">
        <AddUserForm
          refetch={refetch}
          shouldRefetch={shouldRefetch}
        ></AddUserForm>
      </div>
      {spinner && <Spinner></Spinner>}
    </div>
  );
};

export default Home;
