import React, { useEffect, useRef, useState } from "react";
import "../../styles.scss";
import LogoImage from "../../images/logo/dosh-logo.svg";
import EmaiLIcon from "../../images/login/mail.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  genrateVerifyCode,
  logInWithPhone,
} from "../../components/common/firebase/firebase";
import { PAGE_URL, VALIDATION } from "../../constants/constants";
import { useForm } from "react-hook-form";
import Spinner from "../../components/common/spinner/Spinner";
import useBrandInfo from "../../hooks/useBrandInfo";
import {
  StyledComponent,
  getStoredProps,
  setStoredProps,
} from "../../components/common/change-themes/styles-component";
import { ConfirmationResult } from "firebase/auth";
import Button from "../../components/common/button/Button";
import useUserInfo from "../../hooks/useUserInfo";
import toast, { Toaster } from "react-hot-toast";
import { reformatPhoneNumber } from "../../utils/utils";
import pak from "../../../package.json";

const LoginWithPhoneNumber = () => {
  const { brand, brandid, office, officeid } = useParams();
  const navigate = useNavigate();
  const [failed, setFailed] = useState(false);
  const [checkPinSuccess, setCheckPinSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [show, setshow] = useState(false);
  const [result, setResult] = useState<ConfirmationResult>();
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { role, user } = useUserInfo();
  let brandOfficeId = parseInt(officeid);
  const { brandInfo, officeName } = useBrandInfo({ brandOfficeId });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data.phone === "") return;
    let applicationVerifier = genrateVerifyCode();
    toast.promise(
      logInWithPhone({
        phoneNumber: reformatPhoneNumber(data.phone),
        applicationVerifier,
      }).then((response) => {
        setResult(response);
        setshow(true);
      }),
      {
        loading: "Login...",
        success: <b>OTP sent successfully!</b>,
        error: <b>Send OTP is failed. Please try a again</b>,
      }
    );
  };

  const onLoginWithMail = () => {
    setSpinner(true);
    setTimeout(async () => {
      try {
        navigate(
          PAGE_URL.LOGIN.replace(":brand", brand)
            .replace(":brandid", brandid)
            .replace(":office", office)
            .replace(":officeid", officeid)
        );
      } catch (err) {
        setFailed(true);
      } finally {
        setSpinner(false);
      }
    }, 1500);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "") {
      const nextIndex = Math.min(index + 1, inputRefs.current.length - 1);
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const storedProps = getStoredProps();

  const setStored = () => {
    localStorage.removeItem("styledComponentProps");
    if (brandInfo.isWhiteLabel) {
      setStoredProps({
        ...storedProps,
        topLeftColor: brandInfo.style.topLeftColor,
        bottomRightColor: brandInfo.style.bottomRightColor,
        buttonColor: brandInfo.style.buttonColor,
        logoUrl: brandInfo.style.logoUrl,
        portalAccountSetupImageUrl: brandInfo.style.portalAccountSetupImageUrl,
      });
    }
  };

  const onLogin = () => {
    try {
      if (otp === null) return;

      result
        .confirm(otp.join(""))
        .then((result) => {
          result.user.getIdToken().then((idToken) => {
            localStorage.setItem("idToken", idToken);
            localStorage.setItem("reFreshToken", result.user.refreshToken);
          });
          toast.success("Verify OPT successful.");
          setCheckPinSuccess(true);
        })
        .catch((err) => {
          toast.error("Incorrect code");
          setCheckPinSuccess(false);
        });
      setStored();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.title = `Login with phone`;
    setSpinner(true);
    setTimeout(() => {
      if (brandInfo && user && role !== "") {
        return navigate(
          PAGE_URL.HOME.replace(
            ":brand",
            brand.toLowerCase().replace(/\s/g, "-")
          )
            .replace(":brandid", brandid.toString())
            .replace(":office", office.toLowerCase().replace(/\s/g, "-"))
            .replace(":officeid", officeid.toString())
        );
      }
      setSpinner(false);
    }, 1200);
  }, [user, role, checkPinSuccess]);

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (brandInfo) {
        try {
          setStored();
          localStorage.setItem("brandOfficeName", officeName);
          if (officeName) document.title = `Login - ${officeName}`;
        } catch (err) {
        } finally {
          setSpinner(false);
        }
      }
    }, 1200);
  }, [brandInfo]);

  return (
    <StyledComponent className=" min-h-screen flex flex-col justify-center items-center">
      <div className=" flex justify-center mb-16">
        <img src={storedProps.logoUrl ?? LogoImage} alt="Logo" />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: !show ? "block" : "none",
        }}
      >
        <div
          className=" flex justify-center header-h3 font-bold uppercase mb-6"
          style={{ lineHeight: "25px", fontSize: "16px" }}
        >
          <span> Client Login</span>
        </div>
        <div className="flex flex-col space-y-2 items-center w-[250px]">
          <div className="w-full flex-col">
            <input
              type="text"
              id="phone"
              className="bg-black/20 w-full h-11 rounded text-white placeholder-gray-200 dark:text-white border-transparent focus:border-white focus:ring-0"
              placeholder="Phone"
              {...register("phone", {
                required: VALIDATION.required.phone,
                pattern: VALIDATION.phone,
              })}
            />
            {errors.phone && (
              <span className="text-red-400 text-sm font-bold">
                {errors.phone.message.toString()}
              </span>
            )}
          </div>
        </div>
        <div id="recaptcha-container"></div>
        <div className="flex justify-center my-4">
          <Button
            w="33.333333%"
            h="40px"
            px="16px "
            py="8px"
            borderRadius="0.375rem"
            fontSize="12px"
            lineHeight="16px"
          >
            LOGIN
          </Button>
        </div>
      </form>
      <div
        className=" w-[250px] flex flex-col justify-center my-4 mt-3"
        style={{
          display: !show ? "block" : "none",
        }}
      >
        <div className="flex w-full justify-center items-center gap-2">
          <div className="w-[25%] bg-white h-[1px]"></div>
          <span className="w-1/2 text-center font-bold text-sm uppercase text-white">
            Advisor Login
          </span>
          <div className="w-[25%] bg-white h-[1px]"></div>
        </div>
        <div className="flex w-full justify-center items-center mt-3">
          <button
            className="border w-full min-h-[40px] text-white px-4 py-2 rounded-md font-bold text-xs flex items-center justify-center gap-1"
            onClick={() => onLoginWithMail()}
          >
            <img className="w-6" src={EmaiLIcon} alt="" />
            Mail
          </button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onLogin)}
        style={{
          display: show ? "block" : "none",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <span>Please enter the 6 digit PIN sent to your phone.</span>
          <div className="rounded text-center p-2">
            <div
              id="otp"
              className="flex flex-row justify-center text-center mt-2"
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className="m-1 border-white/40 h-16 max-w-[55px] text-center rounded verify-input bg-black/20 w-full min-h-[44px] text-white placeholder-gray-200 dark:text-white focus:border-white focus:ring-0"
                  type="text"
                  value={digit}
                  maxLength={1}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onChange={(e) => handleChange(e, index)}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4">
          <Button
            w="76px"
            h="40px"
            px="16px "
            py="8px"
            borderRadius="0.375rem"
            fontSize="12px"
            lineHeight="16px"
          >
            LOGIN
          </Button>
        </div>
      </form>

      <div className="flex justify-center p-2 absolute bottom-5">
        <span className="cursor-pointer no-underline text-sm text-white dark:text-blue-500 font-semibold">
          Powered by diñeiro - v{pak.version}
        </span>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      {spinner && <Spinner></Spinner>}
    </StyledComponent>
  );
};
export default LoginWithPhoneNumber;
