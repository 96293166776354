import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
  isValidNumber,
} from "../../../utils/utils";
import { useEffect, useState } from "react";
import ExpensesForm from "../../../components/fact-find/ExpensesForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  ExpensesSectionViewModel,
  FactFindExpensesSectionDTO,
  FactFindFactFindDTO,
  FullFactFindExpensesPostViewModel,
  Section,
  TransactionCategoryDTO,
} from "@dineiro/dineiro-sdk-mobile";
import { useNavigate, useParams } from "react-router-dom";
import { getBrandOfficesApi } from "../../../services/api-service";
import { FACTFIND_TABS } from "../../../constants/constants";
import { updateFactFindSection } from "./ApiService";

export interface PreFillExpense {
  homeLoanRepayment: number;
  creditCardRepayments: number;
  personalLoanRepayments: number;
  primaryResidence: number;
  communications: number;
  groceries: number;
  clothingPersonalCare: number;
  recreationEntertainment: number;
  petCare: number;
  transportation: number;
  childcare: number;
  education: number;
  medicalHealth: number;
  homeInsurance: number;
  sicknessInsurance: number;
  childSpousalMaintenance: number;
}

const calculatePreFillExpenseData = (
  expenses: TransactionCategoryDTO[],
  expenseDetails: TransactionCategoryDTO[]
): PreFillExpense => {
  const preFillExpense: PreFillExpense = {
    homeLoanRepayment: 0,
    creditCardRepayments: 0,
    personalLoanRepayments: 0,
    primaryResidence: 0,
    communications: 0,
    groceries: 0,
    clothingPersonalCare: 0,
    recreationEntertainment: 0,
    petCare: 0,
    transportation: 0,
    childcare: 0,
    education: 0,
    medicalHealth: 0,
    homeInsurance: 0,
    sicknessInsurance: 0,
    childSpousalMaintenance: 0,
  };

  const categoryMappings = {
    homeLoanRepayment: [18, 1137],
    creditCardRepayments: [26],
    personalLoanRepayments: [1704, 1039],
    primaryResidence: [12, 39],
    communications: [15, 18],
    groceries: [10],
    clothingPersonalCare: [5, 20],
    recreationEntertainment: [22, 7, 34],
    petCare: [42, 1040],
    transportation: [2, 8],
    childcare: [1163, 1526, 1529],
    education: [6],
    medicalHealth: [11, 1029],
    homeInsurance: [1512, 1022, 1025, 1032, 1045],
    sicknessInsurance: [1035, 1029],
    childSpousalMaintenance: [4],
  };

  expenses.forEach(({ id, amount }) => {
    const amountValue = amount?.amount ?? 0;

    for (const [category, ids] of Object.entries(categoryMappings)) {
      if (ids.includes(id)) {
        preFillExpense[category as keyof PreFillExpense] += amountValue;
      }
    }
  });

  expenseDetails.forEach(({ id, amount }) => {
    const amountValue = amount?.amount ?? 0;

    for (const [category, ids] of Object.entries(categoryMappings)) {
      if (ids.includes(id)) {
        preFillExpense[category as keyof PreFillExpense] += amountValue;
      }
    }
  });

  return preFillExpense;
};

const ExpensesSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
  expenses: TransactionCategoryDTO[];
  expenseDetails: TransactionCategoryDTO[];
}> = ({
  numberOfApplicants,
  factFind,
  refetchGet,
  shouldRefetchGet,
  expenses,
  expenseDetails,
}) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindExpensesSectionDTO[]>(
    []
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const expensesSection = { ...s.expensesSection };
          return expensesSection;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const storedIdToken = getLocalStorageElement("idToken");
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);
  const calculateExpensePreFill = calculatePreFillExpenseData(
    expenses,
    expenseDetails
  );

  const calculationTotalExpenses = (expense: ExpensesSectionViewModel) => {
    const homeInvestmentLoanRepayments =
      expense?.homeInvestmentLoanRepayments || 0;
    const creditCardRepayments = expense?.creditCardRepayments || 0;
    const personalLoans = expense?.personalLoans || 0;
    const primaryResidencePropertyRunningCosts =
      expense?.primaryResidencePropertyRunningCosts || 0;
    const groceries = expense?.groceries || 0;
    const communications = expense?.communications || 0;
    const clothingAndPersonalCare = expense?.clothingAndPersonalCare || 0;
    const recreationAndEntertainment = expense?.recreationAndEntertainment || 0;
    const petCare = expense?.petCare || 0;
    const transportation = expense?.transportation || 0;
    const childcare = expense?.childcare || 0;
    const education1 = expense?.education1 || 0;
    const education2 = expense?.education2 || 0;
    const medicalAndHealth = expense?.medicalAndHealth || 0;
    const homeInsurance = expense?.homeInsurance || 0;
    const childAndSpousalMaintenance = expense?.childAndSpousalMaintenance || 0;
    const privateSchool = expense?.privateSchool || 0;
    const sicknessInsurance = expense?.sicknessInsurance || 0;
    const primaryResidencePropertyBodyCorporate =
      expense?.primaryResidencePropertyBodyCorporate || 0;
    const secondaryResidenceCost = expense?.secondaryResidencePropertyExpenses
      ? expense?.secondaryResidencePropertyExpenses.reduce(
          (acc, obj) => acc + (obj?.costs ?? 0),
          0
        )
      : 0;
    const other = expense?.other || 0;

    return (
      homeInvestmentLoanRepayments +
      creditCardRepayments +
      personalLoans +
      primaryResidencePropertyRunningCosts +
      groceries +
      communications +
      clothingAndPersonalCare +
      recreationAndEntertainment +
      petCare +
      transportation +
      childcare +
      education1 +
      education2 +
      medicalAndHealth +
      homeInsurance +
      childAndSpousalMaintenance +
      privateSchool +
      sicknessInsurance +
      primaryResidencePropertyBodyCorporate +
      secondaryResidenceCost +
      other
    );
  };

  const convertDataToSubmit = (data) => {
    let dataConverted: ExpensesSectionViewModel[] = [];
    if (data) {
      for (let index = 0; index < numberOfApplicants; index++) {
        let expense = {
          applicantId: factFind.applicants[index]?.id,
          homeInvestmentLoanRepayments:
            data[index]?.homeInvestmentLoanRepayments !== undefined
              ? parseInt(data[index]?.homeInvestmentLoanRepayments) ?? null
              : dataSubmit[index]?.homeInvestmentLoanRepayments,
          creditCardRepayments:
            data[index]?.creditCardRepayments !== undefined
              ? parseInt(data[index]?.creditCardRepayments) ?? null
              : dataSubmit[index]?.creditCardRepayments,
          personalLoans:
            data[index]?.personalLoans !== undefined
              ? parseInt(data[index]?.personalLoans) ?? null
              : dataSubmit[index]?.personalLoans,

          primaryResidencePropertyRunningCosts:
            data[index]?.primaryResidencePropertyRunningCosts !== undefined
              ? parseInt(data[index]?.primaryResidencePropertyRunningCosts) ??
                null
              : dataSubmit[index]?.primaryResidencePropertyRunningCosts,

          communications:
            data[index]?.communications !== undefined
              ? parseInt(data[index]?.communications) ?? null
              : dataSubmit[index]?.communications,

          groceries:
            data[index]?.groceries !== undefined
              ? parseInt(data[index]?.groceries) ?? null
              : dataSubmit[index]?.groceries,

          clothingAndPersonalCare:
            data[index]?.clothingAndPersonalCare !== undefined
              ? parseInt(data[index]?.clothingAndPersonalCare) ?? null
              : dataSubmit[index]?.clothingAndPersonalCare,

          recreationAndEntertainment:
            data[index]?.recreationAndEntertainment !== undefined
              ? parseInt(data[index]?.recreationAndEntertainment) ?? null
              : dataSubmit[index]?.recreationAndEntertainment,

          petCare:
            data[index]?.petCare !== undefined
              ? parseInt(data[index]?.petCare) ?? null
              : dataSubmit[index]?.petCare,

          transportation:
            data[index]?.transportation !== undefined
              ? parseInt(data[index]?.transportation) ?? null
              : dataSubmit[index]?.transportation,

          childcare:
            data[index]?.childcare !== undefined
              ? parseInt(data[index]?.childcare) ?? null
              : dataSubmit[index]?.childcare,

          education1:
            data[index]?.education1 !== undefined
              ? parseInt(data[index]?.education1) ?? null
              : dataSubmit[index]?.education1,

          education2:
            data[index]?.education2 !== undefined
              ? parseInt(data[index]?.education2) ?? null
              : dataSubmit[index]?.education2,

          medicalAndHealth:
            data[index]?.medicalAndHealth !== undefined
              ? parseInt(data[index]?.medicalAndHealth) ?? null
              : dataSubmit[index]?.medicalAndHealth,

          homeInsurance:
            data[index]?.homeInsurance !== undefined
              ? parseInt(data[index]?.homeInsurance) ?? null
              : dataSubmit[index]?.homeInsurance,

          childAndSpousalMaintenance:
            data[index]?.childAndSpousalMaintenance !== undefined
              ? parseInt(data[index]?.childAndSpousalMaintenance) ?? null
              : dataSubmit[index]?.childAndSpousalMaintenance,

          privateSchool:
            data[index]?.privateSchool !== undefined
              ? parseInt(data[index]?.privateSchool) ?? null
              : dataSubmit[index]?.privateSchool,

          sicknessInsurance:
            data[index]?.sicknessInsurance !== undefined
              ? parseInt(data[index]?.sicknessInsurance) ?? null
              : dataSubmit[index]?.sicknessInsurance,

          primaryResidencePropertyBodyCorporate:
            data[index]?.primaryResidencePropertyBodyCorporate !== undefined
              ? parseInt(data[index]?.primaryResidencePropertyBodyCorporate) ??
                null
              : dataSubmit[index]?.primaryResidencePropertyBodyCorporate,

          secondaryResidencePropertyExpenses: data[index]
            ?.secondaryResidencePropertyExpenses
            ? data[index]?.secondaryResidencePropertyExpenses.map((s) => {
                s.address = s.address && s.address !== "" ? s.address : "";
                s.costs = isValidNumber(s.costs) ? s.costs : 0;
                return s;
              })
            : dataSubmit[index].secondaryResidencePropertyExpenses || [],

          other: data[index]?.other ? parseInt(data[index]?.other) : 0,
        } as ExpensesSectionViewModel;

        expense.totalExpenses = calculationTotalExpenses(expense);
        dataConverted.push(expense);
      }
    }
    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.expenses) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.expenses?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.questions, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value && value.expenses ? value.expenses : dataSubmit;
    let dataSent: FullFactFindExpensesPostViewModel = {
      id: factFind?.id,
      expensesSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindExpensesPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindExpensesPostViewModel: dataSent,
      });
      toast.success(`Submit expenses data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSent.expensesSections);
      await updateFactFindSection(
        brandApi,
        factFind,
        officeid,
        id,
        Section.Questions
      );
      reset();
      refetchGet(!shouldRefetchGet);
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to submit expenses data.");
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();
    let dataForm = data && data.expenses ? data.expenses : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindExpensesPostViewModel = {
      id: factFind?.id,
      expensesSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindExpensesPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindExpensesPostViewModel: dataSent,
      });
      toast.success(`Save expenses data successfully .`);
      setDataSubmit(dataSent.expensesSections);
      reset();
      setIsShowButtonEdit(true);
    } catch (error) {
      toast.error("Failed to save expenses data.");
    }
  };

  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <ExpensesForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          numberOfResidencesValue={2}
          watch={watch}
          isSubmitted={isSubmitted}
          control={control}
          expenseDataPrefill={calculateExpensePreFill}
        ></ExpensesForm>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default ExpensesSession;
