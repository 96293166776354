import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { isValidNumber, RequiredValidationMessage } from "../../../utils/utils";
import { SelfEmployedDataViewModel } from "@dineiro/dineiro-sdk-mobile";
import { EMPLOYMENT_TYPES } from "../../../constants/constants";

const SelfEmployedForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  isShowButtonEdit;
  dataSubmit?: SelfEmployedDataViewModel;
  errors;
}> = ({ keyValue, register, isShowButtonEdit, dataSubmit, errors }) => {
  return (
    <div className="space-y-1 mt-1 font-bold">
      {!dataSubmit ||
      dataSubmit?.companyName === "" ||
      dataSubmit?.companyName === null ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment.[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.companyName`}
            placeholder="Company Name"
            {...register(
              `employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.companyName`,
              {
                value: dataSubmit?.companyName ?? null,
                required: RequiredValidationMessage("Company Name"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[EMPLOYMENT_TYPES.SelfEmployee]
              ?.companyName && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  EMPLOYMENT_TYPES.SelfEmployee
                ]?.companyName.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      dataSubmit?.abn === "" ||
      dataSubmit?.abn === null ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.abn`}
            placeholder="ABN"
            {...register(
              `employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.abn`,
              {
                value: dataSubmit?.abn ?? null,
                required: RequiredValidationMessage("ABN"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[EMPLOYMENT_TYPES.SelfEmployee]
              ?.abn && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  EMPLOYMENT_TYPES.SelfEmployee
                ]?.abn.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      dataSubmit?.natureOfBusiness === "" ||
      dataSubmit?.natureOfBusiness === null ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.natureOfBusiness`}
            placeholder="Nature of Business"
            {...register(
              `employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.natureOfBusiness`,
              {
                value: dataSubmit?.natureOfBusiness ?? null,
                required: RequiredValidationMessage("Nature of Business"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[EMPLOYMENT_TYPES.SelfEmployee]
              ?.natureOfBusiness && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  EMPLOYMENT_TYPES.SelfEmployee
                ]?.natureOfBusiness.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      !isValidNumber(dataSubmit?.yearsInOperation) ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.yearsInOperation`}
            placeholder="Years in Operation"
            {...register(
              `employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.yearsInOperation`,
              {
                value: dataSubmit?.yearsInOperation,
                required: RequiredValidationMessage("Years in Operation"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[EMPLOYMENT_TYPES.SelfEmployee]
              ?.yearsInOperation && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  EMPLOYMENT_TYPES.SelfEmployee
                ]?.yearsInOperation.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}
      {!dataSubmit ||
      dataSubmit?.accountantName === "" ||
      dataSubmit?.accountantName === null ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.accountantName`}
            placeholder="Accountant’s Name"
            {...register(
              `employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.accountantName`,
              {
                value: dataSubmit?.accountantName ?? null,
                required: RequiredValidationMessage("Accountant’s Name"),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[EMPLOYMENT_TYPES.SelfEmployee]
              ?.accountantName && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  EMPLOYMENT_TYPES.SelfEmployee
                ]?.accountantName.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}

      {!dataSubmit ||
      dataSubmit?.accountantPhoneNumber === "" ||
      dataSubmit?.accountantPhoneNumber === null ||
      !isShowButtonEdit ? (
        <div>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            id={`employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.accountantPhoneNumber`}
            placeholder="Accountant’s Phone Number"
            {...register(
              `employment[${keyValue}].${EMPLOYMENT_TYPES.SelfEmployee}.accountantPhoneNumber`,
              {
                value: dataSubmit?.accountantPhoneNumber ?? null,
                required: RequiredValidationMessage(
                  "Accountant’s Phone Number"
                ),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.employment?.[keyValue]?.[EMPLOYMENT_TYPES.SelfEmployee]
              ?.accountantPhoneNumber && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.employment?.[keyValue]?.[
                  EMPLOYMENT_TYPES.SelfEmployee
                ]?.accountantPhoneNumber.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelfEmployedForm;
