import { useState, useEffect } from "react";
import { HoldingDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

export interface Holding {
  id?: number;
  title?: string;
  accountType?: string;
  accountContainer?: string;
  holding?: HoldingDTO[];
  amount?: number;
}

const useHoldings = ({ officeId, userId, accounts }) => {
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [shouldRefetchGetHolding, refetchRefetchGetHolding] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const userRole = user ? user["roles"][0]["role"]["name"] : null;

  const UserHoldingsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken && userRole !== "customer") {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersHoldingsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                setHoldings([]);
                if (response.data.length > 0 && accounts.length > 0) {
                  accounts.map((account) => {
                    const holdings = response.data?.filter(
                      (holding) => holding.accountId === account.id
                    );
                    if (holdings.length > 0) {
                      const totalShares = holdings.reduce(
                        (acc, obj) =>
                          acc +
                          (obj.price ? obj.price.amount! : 0) * obj.quantity!,
                        0
                      );

                      const newHolding = {
                        id: account.id,
                        title: `${account.providerName}-${account.accountName} (
                          ${
                            account.accountNumber
                              ? account.accountNumber.slice(-4)
                              : ""
                          } 
                          )`,
                        accountContainer: account.container,
                        accountType: account.accountType!,
                        holding: holdings,
                        amount: totalShares,
                      };
                      setHoldings((prevHoldings) => [
                        ...prevHoldings,
                        newHolding,
                      ]);
                    }
                  });
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, accounts, shouldRefetchGetHolding]);

    return null;
  };

  UserHoldingsComponent();

  return {
    holdings: holdings,
    shouldRefetchGetHolding: shouldRefetchGetHolding,
    refetchRefetchGetHolding,
  };
};

export default useHoldings;
