import React, { useState, useEffect } from "react";
import { Period, TransactionCategoryDTO } from "@dineiro/dineiro-sdk-mobile";
import Button from "../../components/common/button/Button";
import ExportIcon from "../../images/home/export-icon.svg";

const ExpensesTab: React.FC<{
  expenses: TransactionCategoryDTO[];
  setPeriod;
  period: Period;
  getExpenseReport;
}> = ({ expenses, setPeriod, period, getExpenseReport }) => {
  const [total, setTotal] = useState(0);

  const getTotal = () => {
    if (expenses.length > 0) {
      const totalLoans = expenses.reduce(
        (acc, obj) => acc + obj.amount.amount!,
        0
      );
      setTotal(totalLoans);
    }
  };

  useEffect(() => {
    getTotal();
  }, [expenses]);
  return (
    <div>
      {expenses && expenses.length > 0 ? (
        <div className="my-10 text-[15px]">
          <div className="flex justify-between px-4 py-2 mb-5">
            <select
              className=" w-1/4 h-11 px-3 py-2 bg-black/20 rounded text-sm placeholder-gray-200 dark:text-white border-none focus:ring-white"
              id="option3"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              <option value={Period.ThirtyDays}>30 Day Totals</option>
              <option value={Period.SixtyDays}>60 Day Totals</option>
              <option value={Period.NinetyDays}>90 Day Totals</option>
            </select>
            <Button
              h="40px"
              px="18px"
              borderRadius="0.375rem"
              onClick={() => getExpenseReport()}
            >
              <img src={ExportIcon} alt="" />
              <span>Export in Excel</span>
            </Button>
          </div>

          <div className="p-2">
            <div className="mb-5 px-4">
              {expenses.map((item) => {
                return (
                  <li
                    className="flex justify-between py-2 border-b border-white/40 font-semibold"
                    key={item.id}
                  >
                    <span>{item.name}</span>
                    <span>
                      {item.amount && item.amount.amount
                        ? item.amount.amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : 0}
                    </span>
                  </li>
                );
              })}
            </div>

            <div className="mt-5 px-2">
              <li className="flex justify-between py-2 font-semibold border-t border-white/40">
                <span>Total Expenses</span>
                <span>
                  {total.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center my-10 text-[15px]">
          This user does not have any expenses
        </div>
      )}
    </div>
  );
};

export default ExpensesTab;
