import { useLayoutEffect, useState } from "react";
import MyChat from "../../components/message/MessageBox";
import Spinner from "../../components/common/spinner/Spinner";
import { Toaster } from "react-hot-toast";

const AdminMessage = () => {
  const [spinner, setSpinner] = useState(false);

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp) document.title = `Message - ${storedProp}`;
  }, []);

  return (
    <div className="items-center justify-center p-3 mb-5">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">DINEIRO ADMIN</h6>
        <span className=" text-5xl text-center font-bold">
          Message Manager.
        </span>
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="flex space-y-2 px-2 w-2/3 mt-5 gap-[40px]">
          <div className="flex flex-col w-full p-2 mt-10">
            <MyChat />
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      {spinner && <Spinner></Spinner>}
    </div>
  );
};

export default AdminMessage;
