import React from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import {
  RequiredValidationMessage,
  checkObjectHasData,
} from "../../utils/utils";
import { StyledButton } from "../common/change-themes/styles-component";
import {
  FactFindBusinessSectionDTO,
  RegistedAs,
} from "@dineiro/dineiro-sdk-mobile";
import ReviewData from "../common/review-form/ReviewData";

const BusinessForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindBusinessSectionDTO;
  resetField;
  errors;
  numberOfDirectorsValue: number;
  numberOfShareholderValue: number;
  isSubmitted?: boolean;
  watch: UseFormWatch<FieldValues>;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  resetField,
  watch,
  isSubmitted,
  numberOfDirectorsValue = 2,
  numberOfShareholderValue = 2,
}) => {
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;

  const onEdit = () => {
    setIsShowButtonEdit(false);
  };

  const renderDirectorNameInputs = () => {
    return Array.from({ length: numberOfDirectorsValue }, (_, index) =>
      dataSubmit === undefined ||
      !dataSubmit?.directors ||
      !dataSubmit?.directors[index] ||
      dataSubmit?.directors[index]?.name === "" ||
      !dataSubmit?.directors[index]?.name ||
      !isShowButtonEdit ? (
        <div key={index}>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            placeholder={`Name Of Director (${index + 1})`}
            id={`business[${keyValue}].directors[${index}].name`}
            {...register(`business[${keyValue}].directors[${index}].name`, {
              value:
                dataSubmit?.directors &&
                dataSubmit?.directors[index] &&
                dataSubmit?.directors[index]?.name
                  ? dataSubmit?.directors[index]?.name
                  : null,
              required: RequiredValidationMessage(
                `Name Of Director (${index + 1})`
              ),
            })}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.business?.[keyValue]?.directors?.[index]?.name && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.business?.[keyValue]?.directors?.[
                  index
                ]?.name.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )
    );
  };

  const renderShareholderNameInputs = () => {
    return Array.from({ length: numberOfShareholderValue }, (_, index) =>
      dataSubmit === undefined ||
      !dataSubmit?.shareholders ||
      !dataSubmit?.shareholders[index] ||
      dataSubmit?.shareholders[index]?.name === "" ||
      !dataSubmit?.shareholders[index]?.name ||
      !isShowButtonEdit ? (
        <div className="grid grid-cols-4 gap-1" key={index}>
          <div className=" col-span-3">
            <input
              className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
              type="text"
              placeholder={`Name of Shareholder`}
              id={`business[${keyValue}].shareholders[${index}].name`}
              {...register(
                `business[${keyValue}].shareholders[${index}].name`,
                {
                  value:
                    dataSubmit?.shareholders &&
                    dataSubmit?.shareholders[index] &&
                    dataSubmit?.shareholders[index]?.name
                      ? dataSubmit?.shareholders[index]?.name
                      : null,
                  required: RequiredValidationMessage(`Name of Shareholder`),
                }
              )}
            />
          </div>
          <div>
            <input
              className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
              type="number"
              placeholder="%"
              id={`business[${keyValue}].shareholders[${index}].shareholderPercents`}
              {...register(
                `business[${keyValue}].shareholders[${index}].shareholderPercents`,
                {
                  value:
                    dataSubmit?.shareholders &&
                    dataSubmit?.shareholders[index] &&
                    dataSubmit?.shareholders[index]?.shareholderPercents
                      ? dataSubmit?.shareholders[index]?.shareholderPercents
                      : null,
                  required: RequiredValidationMessage(`Shareholder Percents`),
                }
              )}
            />
          </div>
          <div className=" col-span-4 flex flex-col">
            {keyValue !== null &&
              keyValue !== undefined &&
              errors.business?.[keyValue]?.shareholders?.[index] && (
                <span className="text-red-400 text-sm font-bold px-2">
                  Please fill in all shareholder information.
                </span>
              )}
          </div>
        </div>
      ) : (
        <></>
      )
    );
  };

  const renderSharreholderAddressInputs = () => {
    return Array.from({ length: numberOfShareholderValue }, (_, index) =>
      dataSubmit === undefined ||
      !dataSubmit?.shareholders ||
      !dataSubmit?.shareholders[index] ||
      dataSubmit?.shareholders[index]?.address === "" ||
      !dataSubmit?.shareholders[index]?.address ||
      !isShowButtonEdit ? (
        <div key={index}>
          <input
            className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
            type="text"
            placeholder={`Address of Beneficial Shareholder (${index + 1})`}
            id={`business[${keyValue}].shareholders[${index}].address`}
            {...register(
              `business[${keyValue}].shareholders[${index}].address`,
              {
                value:
                  dataSubmit?.shareholders &&
                  dataSubmit?.shareholders[index] &&
                  dataSubmit?.shareholders[index]?.address
                    ? dataSubmit?.shareholders[index]?.address
                    : null,
                required: RequiredValidationMessage(
                  `Address of Beneficial Shareholder (${index + 1})`
                ),
              }
            )}
          />
          {keyValue !== null &&
            keyValue !== undefined &&
            errors.business?.[keyValue]?.shareholders?.[index]?.address && (
              <span className="text-red-400 text-sm font-bold px-2">
                {errors.business?.[keyValue]?.shareholders?.[
                  index
                ]?.address.message.toString()}
              </span>
            )}
        </div>
      ) : (
        <></>
      )
    );
  };

  const renderDirectorReviews = () => {
    const numberOfBeneficiaryValue =
      dataSubmit?.directors && dataSubmit?.directors.length > 0
        ? dataSubmit?.directors.length
        : numberOfShareholderValue;
    return Array.from(
      { length: numberOfBeneficiaryValue },
      (_, index) =>
        dataSubmit?.directors &&
        dataSubmit?.directors[index] &&
        dataSubmit?.directors[index]?.name && (
          <ReviewData
            key={index}
            title={`Name Of Director (${index + 1})`}
            value={dataSubmit?.directors[index]?.name}
          ></ReviewData>
        )
    );
  };

  const renderShareholderReviews = () => {
    const numberOfBeneficiaryValue =
      dataSubmit?.shareholders && dataSubmit?.shareholders.length > 0
        ? dataSubmit?.shareholders.length
        : numberOfDirectorsValue;
    return Array.from({ length: numberOfBeneficiaryValue }, (_, index) =>
      dataSubmit?.shareholders &&
      dataSubmit?.shareholders[index] &&
      dataSubmit?.shareholders[index]?.address &&
      dataSubmit?.shareholders[index]?.name ? (
        <div key={index}>
          <div className="font-bold">
            <span>{`Shareholder (${index + 1})`}</span>
          </div>
          <div className="p-2">
            {dataSubmit?.shareholders[index]?.name && (
              <ReviewData
                title={`Name`}
                value={dataSubmit?.shareholders[index]?.name}
              ></ReviewData>
            )}
            {dataSubmit?.shareholders[index]?.shareholderPercents && (
              <ReviewData
                title={`Percents`}
                value={`${dataSubmit?.shareholders[index]?.shareholderPercents}%`}
              ></ReviewData>
            )}
            {dataSubmit?.shareholders[index]?.address && (
              <ReviewData
                title={`Address`}
                value={dataSubmit?.shareholders[index]?.address}
              ></ReviewData>
            )}
          </div>
        </div>
      ) : (
        <></>
      )
    );
  };

  return (
    <div className="mt-5">
      <form
        className="max-w-sm mx-auto text-sm"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div id="showData" className="flex flex-col gap-1">
            {dataSubmit?.name !== "" && dataSubmit?.name && (
              <ReviewData
                title={"Company Name"}
                value={dataSubmit?.name}
              ></ReviewData>
            )}
            {dataSubmit?.abnacn !== "" && dataSubmit?.abnacn && (
              <ReviewData
                title={"ABN/ACN"}
                value={dataSubmit?.abnacn}
              ></ReviewData>
            )}
            {dataSubmit?.registedAs && (
              <ReviewData
                title={"Registered As"}
                value={dataSubmit?.registedAs}
              ></ReviewData>
            )}
            {dataSubmit?.tradingAs?.toString() !== "" &&
              dataSubmit?.tradingAs && (
                <ReviewData
                  title={"Trading As"}
                  value={dataSubmit?.tradingAs}
                ></ReviewData>
              )}
            {dataSubmit?.natureOfBusiness !== "" &&
              dataSubmit?.natureOfBusiness && (
                <ReviewData
                  title={"Nature Of Business"}
                  value={dataSubmit?.natureOfBusiness}
                ></ReviewData>
              )}
            {renderDirectorReviews()}
            {dataSubmit?.address !== "" && dataSubmit?.address && (
              <ReviewData
                title={"Address"}
                value={dataSubmit?.address}
              ></ReviewData>
            )}
            {renderShareholderReviews()}
          </div>
        )}
        <div className="space-y-1 mt-1">
          {dataSubmit === undefined ||
          !dataSubmit?.name ||
          dataSubmit?.name === "" ||
          !isShowButtonEdit ? (
            <div>
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`business[${keyValue}].name`}
                placeholder="Company Name"
                {...register(`business[${keyValue}].name`, {
                  value: dataSubmit?.name ?? null,
                  required: RequiredValidationMessage("Company Name"),
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.business?.[keyValue]?.name && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.business?.[keyValue]?.name.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.abnacn === "" ||
          !dataSubmit?.abnacn ||
          !isShowButtonEdit ? (
            <div>
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`business[${keyValue}].abnacn`}
                placeholder="ABN/ACN"
                {...register(`business[${keyValue}].abnacn`, {
                  value: dataSubmit?.abnacn ?? null,
                  required: RequiredValidationMessage("ABN/ACN"),
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.business?.[keyValue]?.abnacn && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.business?.[keyValue]?.abnacn.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          !dataSubmit?.registedAs ||
          !isShowButtonEdit ? (
            <div>
              <select
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id={`business[${keyValue}].registedAs`}
                {...register(`business[${keyValue}].registedAs`, {
                  value: dataSubmit?.registedAs ?? null,
                  required: RequiredValidationMessage("Registered As"),
                })}
              >
                <option value="">Registered As</option>
                <option value={RegistedAs.Private}>Private</option>
                <option value={RegistedAs.Public}>Public</option>
              </select>

              {keyValue !== null &&
                keyValue !== undefined &&
                errors.business?.[keyValue]?.registedAs && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.business?.[keyValue]?.registedAs.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
          {dataSubmit === undefined ||
          dataSubmit?.tradingAs === "" ||
          !dataSubmit?.tradingAs ||
          !isShowButtonEdit ? (
            <div>
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`business[${keyValue}].tradingAs`}
                placeholder="Trading As"
                {...register(`business[${keyValue}].tradingAs`, {
                  value: dataSubmit?.tradingAs ?? null,
                  required: RequiredValidationMessage("Trading As"),
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.business?.[keyValue]?.tradingAs && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.business?.[keyValue]?.tradingAs.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          dataSubmit?.natureOfBusiness === "" ||
          !dataSubmit?.natureOfBusiness ||
          !isShowButtonEdit ? (
            <div>
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`business[${keyValue}].natureOfBusiness`}
                placeholder="Nature Of Business"
                {...register(`business[${keyValue}].natureOfBusiness`, {
                  value: dataSubmit?.natureOfBusiness ?? null,
                  required: RequiredValidationMessage("Nature Of Business"),
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.business?.[keyValue]?.natureOfBusiness && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.business?.[
                      keyValue
                    ]?.natureOfBusiness.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}

          {renderDirectorNameInputs()}

          {dataSubmit === undefined ||
          dataSubmit?.address === "" ||
          !dataSubmit?.address ||
          !isShowButtonEdit ? (
            <div>
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="text"
                id={`business[${keyValue}].address`}
                placeholder="Address"
                {...register(`business[${keyValue}].address`, {
                  value: dataSubmit?.address ?? null,
                  required: RequiredValidationMessage("Address"),
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.business?.[keyValue]?.address && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.business?.[keyValue]?.address.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}

          {renderShareholderNameInputs()}
          {renderSharreholderAddressInputs()}
        </div>
      </form>
    </div>
  );
};
export default BusinessForm;
