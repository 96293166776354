import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import {
  RequiredValidationMessage,
  checkObjectHasData,
  isValidNumber,
} from "../../utils/utils";
import {
  FactFindAssetsSectionDTO,
  PropertyDTO,
} from "@dineiro/dineiro-sdk-mobile";
import {
  StyledButton,
  StyledSecondButton,
} from "../common/change-themes/styles-component";
import ReviewData from "../common/review-form/ReviewData";
import { NumericFormat } from "react-number-format";

interface PropertyValue {
  index: number;
  value: number;
}

const AssetsForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindAssetsSectionDTO;
  errors;
  unregister?: UseFormUnregister<FieldValues>;
  isSubmitted?: boolean;
  totalSupperAccounts?: number;
  totalShares?: number;
  totalVehicles?: number;
  totalBanks?: number;
  vehicleCount?: number;
  properties: PropertyDTO[];
  watch: UseFormWatch<FieldValues>;
  control: Control<FieldValues, any>;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  unregister,
  watch,
  isSubmitted,
  totalSupperAccounts,
  totalShares,
  totalVehicles,
  totalBanks,
  vehicleCount,
  properties,
  control,
}) => {
  let setUpProperties =
    properties &&
    keyValue === 0 &&
    properties.filter((s) => checkObjectHasData(s)).length > 0 &&
    !(
      dataSubmit?.properties &&
      dataSubmit?.properties.filter((s) => checkObjectHasData(s)).length > 0
    )
      ? properties.length
      : dataSubmit?.properties &&
        dataSubmit?.properties.filter((s) => checkObjectHasData(s)).length > 0
      ? dataSubmit?.properties.length
      : 0;
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;
  let propertyInputs = [];
  let totalMonthlyAssets = 0;

  const [numberOfProperty, setNumberOfProperty] = useState(0);
  const [sumProperties, setSumProperties] = useState<PropertyValue[]>([]);
  const onEdit = () => {
    setIsShowButtonEdit(false);
  };

  useEffect(() => {
    setNumberOfProperty(setUpProperties);
  }, [dataSubmit]);

  const watchTotalBankAccounts =
    watch(`assets[${keyValue}].totalBankAccounts`) || 0;
  const watchOtherCashAssets =
    watch(`assets[${keyValue}].otherCashAssets`) || 0;
  const watchTotalShares = watch(`assets[${keyValue}].totalShares`) || 0;
  const watchTotalValueOfVehicles =
    watch(`assets[${keyValue}].totalValueOfVehicles`) || 0;
  const watchTotalSuperannuation =
    watch(`assets[${keyValue}].totalSuperannuation`) || 0;
  const watchTotalOtherAssets =
    watch(`assets[${keyValue}].totalOtherAssets`) || 0;
  const watchProperties = watch(`assets[${keyValue}].properties`) || [];
  let watchTotalProperties = 0;
  if (watchProperties?.length > 0) {
    let sum = 0;
    watchProperties.forEach((element, index) => {
      const watchProperty =
        watch(`assets[${keyValue}].properties[${index}].value`) || 0;
      sum = sum + watchProperty;
    });
    watchTotalProperties = sum;
  }

  if (
    watchTotalBankAccounts ||
    watchOtherCashAssets ||
    watchTotalShares ||
    watchTotalValueOfVehicles ||
    watchTotalOtherAssets ||
    watchTotalSuperannuation ||
    watchTotalProperties
  ) {
    const totalTemp =
      watchTotalBankAccounts +
      watchOtherCashAssets +
      watchTotalShares +
      watchTotalValueOfVehicles +
      watchTotalOtherAssets +
      watchTotalSuperannuation +
      watchTotalProperties;
    totalMonthlyAssets =
      dataSubmit &&
      dataSubmit.totalAssets &&
      isShowButtonEdit &&
      totalTemp !== dataSubmit.totalAssets
        ? dataSubmit.totalAssets + totalTemp
        : totalTemp;
  } else if (dataSubmit && dataSubmit.totalAssets) {
    totalMonthlyAssets = dataSubmit.totalAssets;
  }

  const onRemovePropertyField = (index) => {
    const properties = sumProperties.filter((p) => p.index !== index);
    setSumProperties(properties);
    if (
      dataSubmit?.properties &&
      dataSubmit?.properties.length > 0 &&
      dataSubmit?.properties[index]
    ) {
      unregister(`assets[${keyValue}].properties[${index}]`);
      dataSubmit.properties.splice(index, 1);
      setNumberOfProperty(numberOfProperty - 1);
    } else {
      unregister(`assets[${keyValue}].properties[${index}]`);
      propertyInputs.splice(index, 1);
      setNumberOfProperty(numberOfProperty - 1);
    }
  };

  const renderPropertyInputs = () => {
    const numberOfPropertyValue = numberOfProperty;
    if (numberOfProperty > 0) {
      Array.from({ length: numberOfPropertyValue }, (_, index) => {
        propertyInputs.push(
          (dataSubmit === undefined ||
            !dataSubmit?.properties ||
            !dataSubmit?.properties[index] ||
            dataSubmit?.properties[index]?.address === "" ||
            dataSubmit?.properties[index]?.address === null ||
            !isValidNumber(dataSubmit?.properties[index]?.value) ||
            !isShowButtonEdit) && (
            <div key={index}>
              {index === 0 && (
                <div className="mt-4 p-2 text-base font-bold">
                  Property Held
                </div>
              )}
              <div className="space-y-1 mt-4">
                {numberOfPropertyValue > 1 && (
                  <div className="w-full">
                    <div className="p-2">
                      <span>Property ({index + 1})</span>
                    </div>
                  </div>
                )}

                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].properties[${index}].address`}
                      className="my-1 px-2"
                    >
                      Address
                    </label>
                  )}
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    type="text"
                    id={`assets[${keyValue}].properties[${index}].address`}
                    placeholder={keyValue === 0 ? "" : "Address"}
                    {...register(
                      `assets[${keyValue}].properties[${index}].address`,
                      {
                        value:
                          properties.length > 0 &&
                          properties[index] &&
                          keyValue === 0 &&
                          !(
                            dataSubmit?.properties &&
                            dataSubmit?.properties?.length > 0 &&
                            dataSubmit?.properties[index]
                          )
                            ? properties[index].address
                            : dataSubmit?.properties &&
                              dataSubmit?.properties[index]
                            ? dataSubmit?.properties[index]?.address
                            : null,
                        required: RequiredValidationMessage(`Address`),
                      }
                    )}
                  />
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.properties?.[index]?.address && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[keyValue]?.properties?.[
                          index
                        ]?.address.message.toString()}
                      </span>
                    )}
                </div>
                <div>
                  <Controller
                    control={control}
                    name={`assets[${keyValue}].properties[${index}].value`}
                    rules={{
                      required: RequiredValidationMessage(`Value`),
                    }}
                    defaultValue={
                      isValidNumber(properties[index]?.estimate?.amount) &&
                      keyValue === 0 &&
                      !(
                        dataSubmit?.properties &&
                        isValidNumber(dataSubmit?.properties[index]?.value)
                      )
                        ? properties[index]?.estimate?.amount || 0
                        : dataSubmit?.properties &&
                          isValidNumber(dataSubmit?.properties[index]?.value)
                        ? dataSubmit?.properties[index]?.value || 0
                        : 0
                    }
                    render={({ field: { onChange, name, value } }) => (
                      <div>
                        {keyValue === 0 && (
                          <label
                            htmlFor={`assets[${keyValue}].properties[${index}].value`}
                            className="my-1 px-2"
                          >
                            Value
                          </label>
                        )}
                        <NumericFormat
                          className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                          id={`assets[${keyValue}].properties[${index}].value`}
                          name={name}
                          placeholder={keyValue === 0 ? "" : "Value"}
                          thousandSeparator=","
                          value={value}
                          prefix="$"
                          allowLeadingZeros
                          onValueChange={(values, sourceInfo) => {
                            const value = {
                              index: index,
                              value: values.floatValue,
                            };
                            let newList = sumProperties;
                            if (sumProperties[index]) {
                              newList[index].value = value.value;
                              setSumProperties(sumProperties);
                            } else {
                              newList.push(value);
                              setSumProperties(sumProperties);
                            }

                            onChange(
                              isValidNumber(values.floatValue)
                                ? values.floatValue
                                : null
                            );
                          }}
                          decimalScale={2}
                        ></NumericFormat>
                        {keyValue !== null &&
                          keyValue !== undefined &&
                          errors.assets?.[keyValue]?.properties?.[index]
                            ?.value && (
                            <span className="text-red-400 text-sm font-bold px-2">
                              {errors.assets?.[keyValue]?.properties?.[
                                index
                              ]?.value.message.toString()}
                            </span>
                          )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div
                className={`border-b-[1px] border-white/60 mt-4 pb-4 flex justify-between ${
                  isSubmitted ? "hidden" : ""
                }`}
              >
                {index + 1 === numberOfPropertyValue && (
                  <div>
                    <StyledButton
                      className="w-[220px] h-[45px] px-[18px] rounded-md flex font-semibold gap-2 justify-center items-center uppercase"
                      onClick={() => setNumberOfProperty(numberOfProperty + 1)}
                      type="button"
                    >
                      Add Another Property
                    </StyledButton>
                  </div>
                )}
                {index + 1 === numberOfPropertyValue &&
                  numberOfPropertyValue > 0 && (
                    <StyledSecondButton
                      className="w-[100px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
                      type="button"
                      onClick={() => onRemovePropertyField(index)}
                    >
                      Delete
                    </StyledSecondButton>
                  )}
              </div>
            </div>
          )
        );
      });
    } else if (!isSubmitted) {
      propertyInputs.push(
        <div key={`first_${keyValue}`}>
          <StyledButton
            className="w-[220px] h-[45px] px-[18px] rounded-md flex font-semibold gap-2 justify-center items-center uppercase"
            onClick={() => setNumberOfProperty(numberOfProperty + 1)}
            type="button"
          >
            Add Another Property
          </StyledButton>
        </div>
      );
    }
    return propertyInputs;
  };

  const renderPropertyReviews = () => {
    const numberOfPropertyValue = numberOfProperty;
    return Array.from(
      { length: numberOfPropertyValue },
      (_, index) =>
        dataSubmit?.properties &&
        dataSubmit?.properties[index] && (
          <div key={`review_${index}`}>
            <div className="col-span-1 font-bold ">
              <span>{`Property (${index + 1})`} :</span>
            </div>
            <div className="p-2">
              <div className="font-light flex flex-col">
                {dataSubmit?.properties[index]?.address !== null &&
                  dataSubmit?.properties[index]?.address && (
                    <ReviewData
                      title={"Address"}
                      value={dataSubmit?.properties[index]?.address}
                    ></ReviewData>
                  )}
                {isValidNumber(dataSubmit?.properties[index]?.value) && (
                  <ReviewData
                    title={"Value"}
                    value={dataSubmit?.properties[index]?.value.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  ></ReviewData>
                )}
              </div>
            </div>
          </div>
        )
    );
  };

  return (
    <div className="mt-5">
      <form
        className="flex flex-col gap-1 max-w-sm mx-auto  text-sm"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div id="showData" className="flex flex-col gap-1 mb-2">
            {isValidNumber(dataSubmit?.totalBankAccounts) ? (
              <ReviewData
                title={"Bank Account Totals"}
                value={dataSubmit?.totalBankAccounts.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.otherCashAssets) ? (
              <ReviewData
                title={"Other Cash Assets"}
                value={dataSubmit?.otherCashAssets.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.totalShares) ? (
              <ReviewData
                title={"Total Shares"}
                value={dataSubmit?.totalShares.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.numberOfVehicles) ? (
              <ReviewData
                title={"Number Of Vehicles"}
                value={dataSubmit?.numberOfVehicles}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.totalValueOfVehicles) ? (
              <ReviewData
                title={"Value Of Vehicles"}
                value={dataSubmit?.totalValueOfVehicles.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                )}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.totalSuperannuation) ? (
              <ReviewData
                title={"Total Superannuation"}
                value={dataSubmit?.totalSuperannuation.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.totalOtherAssets) ? (
              <ReviewData
                title={"Total Other Assets"}
                value={dataSubmit?.totalOtherAssets.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {renderPropertyReviews()}
          </div>
        )}
        <div className="space-y-1 border-b-[1px] border-white/60 pb-4">
          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.totalBankAccounts) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`assets[${keyValue}].totalBankAccounts`}
              defaultValue={
                isValidNumber(totalBanks) &&
                totalBanks !== 0 &&
                keyValue === 0 &&
                !isValidNumber(dataSubmit?.totalBankAccounts)
                  ? totalBanks
                  : dataSubmit?.totalBankAccounts
              }
              rules={{
                required: RequiredValidationMessage("Bank Account Totals"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].totalBankAccounts`}
                      className="my-1 px-2"
                    >
                      Bank Account Totals
                    </label>
                  )}
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`assets[${keyValue}].totalBankAccounts`}
                    name={name}
                    placeholder={keyValue === 0 ? "" : "Bank Account Totals"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.totalBankAccounts && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[
                          keyValue
                        ]?.totalBankAccounts.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.otherCashAssets) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`assets[${keyValue}].otherCashAssets`}
              defaultValue={dataSubmit?.otherCashAssets}
              rules={{
                required: RequiredValidationMessage("Other Cash Assets"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].otherCashAssets`}
                      className="my-1 px-2"
                    >
                      Other Cash Assets (Bonds)
                    </label>
                  )}
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`assets[${keyValue}].otherCashAssets`}
                    name={name}
                    placeholder={
                      keyValue === 0 ? "" : "Other Cash Assets (Bonds)"
                    }
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                    decimalScale={2}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.otherCashAssets && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[
                          keyValue
                        ]?.otherCashAssets.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.totalShares) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`assets[${keyValue}].totalShares`}
              defaultValue={
                isValidNumber(totalShares) &&
                totalShares !== 0 &&
                keyValue === 0 &&
                !isValidNumber(dataSubmit?.totalShares)
                  ? totalShares
                  : dataSubmit?.totalShares
              }
              rules={{
                required: RequiredValidationMessage("Total Shares"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].totalShares`}
                      className="my-1 px-2"
                    >
                      Total Shares
                    </label>
                  )}
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`assets[${keyValue}].totalShares`}
                    name={name}
                    placeholder={keyValue === 0 ? "" : "Total Shares"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                    decimalScale={2}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.totalShares && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[
                          keyValue
                        ]?.totalShares.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.numberOfVehicles) ||
          !isShowButtonEdit ? (
            <div>
              {keyValue === 0 && (
                <label
                  htmlFor={`assets[${keyValue}].numberOfVehicles`}
                  className="my-1 px-2"
                >
                  Number of Vehicles
                </label>
              )}
              <input
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                type="number"
                id={`assets[${keyValue}].numberOfVehicles`}
                placeholder={keyValue === 0 ? "" : "Number of Vehicles"}
                {...register(`assets[${keyValue}].numberOfVehicles`, {
                  value:
                    isValidNumber(vehicleCount) &&
                    vehicleCount !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.numberOfVehicles)
                      ? vehicleCount
                      : dataSubmit?.numberOfVehicles,
                  required: RequiredValidationMessage("Number of Vehicles"),
                  valueAsNumber: true,
                })}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.assets?.[keyValue]?.numberOfVehicles && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.assets?.[
                      keyValue
                    ]?.numberOfVehicles.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.totalValueOfVehicles) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`assets[${keyValue}].totalValueOfVehicles`}
              defaultValue={
                isValidNumber(totalVehicles) &&
                totalVehicles !== 0 &&
                keyValue === 0 &&
                !isValidNumber(dataSubmit?.totalValueOfVehicles)
                  ? totalVehicles
                  : dataSubmit?.totalValueOfVehicles
              }
              rules={{
                required: RequiredValidationMessage(
                  "Value of Vehicles (total)"
                ),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].totalValueOfVehicles`}
                      className="my-1 px-2"
                    >
                      Value of Vehicles (total)
                    </label>
                  )}
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`assets[${keyValue}].totalValueOfVehicles`}
                    name={name}
                    placeholder={
                      keyValue === 0 ? "" : "Value of Vehicles (total)"
                    }
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                    decimalScale={2}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.totalValueOfVehicles && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[
                          keyValue
                        ]?.totalValueOfVehicles.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.totalSuperannuation) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`assets[${keyValue}].totalSuperannuation`}
              defaultValue={
                isValidNumber(totalSupperAccounts) &&
                totalSupperAccounts !== 0 &&
                keyValue === 0 &&
                !isValidNumber(dataSubmit?.totalSuperannuation)
                  ? totalSupperAccounts
                  : dataSubmit?.totalSuperannuation
              }
              rules={{
                required: RequiredValidationMessage("Total Superannuation"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].totalSuperannuation`}
                      className="my-1 px-2"
                    >
                      Total Superannuation
                    </label>
                  )}
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`assets[${keyValue}].totalSuperannuation`}
                    name={name}
                    placeholder={keyValue === 0 ? "" : "Total Superannuation"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                    decimalScale={2}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.totalSuperannuation && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[
                          keyValue
                        ]?.totalSuperannuation.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.totalOtherAssets) ||
          !isShowButtonEdit ? (
            <Controller
              control={control}
              name={`assets[${keyValue}].totalOtherAssets`}
              defaultValue={dataSubmit?.totalOtherAssets}
              rules={{
                required: RequiredValidationMessage("Total other Assets"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  {keyValue === 0 && (
                    <label
                      htmlFor={`assets[${keyValue}].totalOtherAssets`}
                      className="my-1 px-2"
                    >
                      Total other Assets
                    </label>
                  )}
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`assets[${keyValue}].totalOtherAssets`}
                    name={name}
                    placeholder={keyValue === 0 ? "" : "Total other Assets"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                    decimalScale={2}
                  ></NumericFormat>
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.assets?.[keyValue]?.totalOtherAssets && (
                      <span className="text-red-400 text-sm font-bold px-2">
                        {errors.assets?.[
                          keyValue
                        ]?.totalOtherAssets.message.toString()}
                      </span>
                    )}
                </div>
              )}
            />
          ) : (
            <></>
          )}
        </div>

        {renderPropertyInputs()}
      </form>

      <div className="flex flex-col max-w-sm mx-auto pb-2 font-bold my-5 gap-4">
        <div className="flex justify-between text-sm">
          <span>Total Assets</span>
          <span>
            {totalMonthlyAssets.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
export default AssetsForm;
