import React, { useState } from "react";
import Button from "../common/button/Button";
import { formatDate } from "../../utils/utils";
import Modal from "../common/modal/Modal";
import useModal from "../../hooks/useModal";
import { OfficeArticleDTO } from "@dineiro/dineiro-sdk-mobile";

const ContentTable: React.FC<{
  contents: OfficeArticleDTO[];
  handleRecallMessage: (contentRecall) => void;
}> = ({ contents, handleRecallMessage }) => {
  const { show: showRecallModal, toggle: toggleRecallModal } = useModal();
  const [contentRecall, setContentRecall] = useState("");

  const onRecallMessage = (contentId) => {
    setContentRecall(contentId);
    toggleRecallModal();
  };

  const onSubmitRecall = () => {
    handleRecallMessage(contentRecall);
    toggleRecallModal();
  };

  return (
    <div className="max-h-[500px] w-full min-h-[150px] mt-5">
      <span
        className="header-h3 font-bold"
        style={{ fontSize: "22px", lineHeight: "25px" }}
      >
        Recent Content
      </span>
      <div className="w-full min-h-[150px] max-h-[500px] overflow-auto">
        <table className="border-collapse mt-4 table-auto w-full overflow-auto text-sm">
          <thead>
            <tr>
              <th className="font-bold py-2 w-1/2">HEADLINE</th>
              <th className="font-bold py-2">SENT ON</th>
              <th className="font-bold py-2 text-center">#SENT</th>
              <th className="font-bold py-2 text-center">#OPENED</th>
              <th className="font-bold text-left"></th>
            </tr>
          </thead>
          <tbody>
            {contents.map((content) => {
              return (
                <tr key={content.id}>
                  <td className="border-b p-2">{content.article.title}</td>
                  <td className="border-b">
                    {formatDate(content.article.createdAt)}
                  </td>
                  <td className="border-b py-2 text-center">
                    {content.officeArticleUsers.length}
                  </td>
                  <td className="border-b py-2 text-center">
                    {content.officeArticleUsers.length > 0
                      ? content.officeArticleUsers.filter(
                          (item) => item.isOpened
                        ).length
                      : 0}
                  </td>
                  <td className="border-b py-2 text-left uppercase">
                    {content.isPublish === true ? (
                      <Button
                        px="8px"
                        borderRadius="4px"
                        h="30px"
                        w="140px"
                        onClick={() => onRecallMessage(content.id)}
                      >
                        <span className="text-center">RECALL MESSAGE</span>
                      </Button>
                    ) : (
                      <button className="bg-gray-800 bg-opacity-40 min-h-[30px] px-[6px] rounded flex py-2 font-semibold text-sm items-center uppercase cursor-not-allowed">
                        RECALLED MESSAGE
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        show={showRecallModal}
        toggle={toggleRecallModal}
        content={
          <div className=" rounded-xl px-4 py-4">
            <div className="flex flex-col items-center">
              <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </div>
              <div className="mt-2 text-center ">
                <p>Are you sure you want to recall this message?</p>
              </div>
              <div className="flex justify-between gap-4 mt-4">
                <Button
                  px="20px"
                  py="10px"
                  borderRadius="4px"
                  h="30px"
                  onClick={() => onSubmitRecall()}
                >
                  Recall
                </Button>
                <button
                  className="h-[36px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
                  onClick={() => toggleRecallModal()}
                >
                  <span className="text-center">Cancel</span>
                </button>
              </div>
            </div>
          </div>
        }
      ></Modal>
    </div>
  );
};

export default ContentTable;
