import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { useEffect, useState } from "react";
import AddressForm from "../../../components/fact-find/AddressForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  AddressSectionViewModel,
  FactFindAddressSectionDTO,
  FactFindFactFindDTO,
  FullFactFindAddressPostViewModel,
  Section,
} from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../../../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import { FACTFIND_TABS } from "../../../constants/constants";
import { updateFactFindSection } from "./ApiService";

const AdressSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
}> = ({ numberOfApplicants, factFind, refetchGet, shouldRefetchGet }) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindAddressSectionDTO[]>([]);
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    clearErrors,
    getValues,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);

  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const addressSection = { ...s.addressSection };
          return addressSection;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const convertDataToSubmit = (data) => {
    let dataConverted: AddressSectionViewModel[] = [];

    if (data) {
      for (let index = 0; index < numberOfApplicants; index++) {
        let address = {
          applicantId: factFind.applicants[index].id,
          currentLivingArrangements:
            data[index]?.currentLivingArrangements !== undefined
              ? data[index]?.currentLivingArrangements ?? null
              : dataSubmit[index].currentLivingArrangements,
          address:
            data[index]?.address !== undefined
              ? data[index]?.address
              : dataSubmit[index].address,
          yearsAt:
            data[index]?.yearsAt !== undefined
              ? parseInt(data[index]?.yearsAt) ?? null
              : dataSubmit[index].yearsAt,
          monthsAt:
            data[index]?.monthsAt !== undefined
              ? parseInt(data[index]?.monthsAt) ?? null
              : dataSubmit[index].monthsAt,
          mostRecentAddress:
            data[index]?.mostRecentAddress !== undefined
              ? data[index]?.mostRecentAddress
              : dataSubmit[index].mostRecentAddress,
          mostRecentYearsAt:
            data[index]?.mostRecentYearsAt !== undefined
              ? parseInt(data[index]?.mostRecentYearsAt) ?? null
              : dataSubmit[index].mostRecentYearsAt,
          mostRecentMonthsAt:
            data[index]?.mostRecentMonthsAt !== undefined
              ? parseInt(data[index]?.mostRecentMonthsAt) ?? null
              : dataSubmit[index].mostRecentMonthsAt,
        } as AddressSectionViewModel;
        if (address.yearsAt && address.yearsAt > 2) {
          address.mostRecentAddress = null;
          address.mostRecentYearsAt = null;
          address.mostRecentMonthsAt = null;
        }
        dataConverted.push(address);
      }
    }

    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.addresses) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.addresses?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.employment, subtab));
  };

  const triggerNextAddress = (data: AddressSectionViewModel[]) => {
    for (let index = 0; index < numberOfApplicants; index++) {
      if (
        data[index] &&
        data[index].monthsAt &&
        data[index].yearsAt < 2 &&
        !data[index].mostRecentAddress &&
        !data[index].mostRecentYearsAt &&
        !data[index].mostRecentMonthsAt
      ) {
        return {
          canGoNext: false,
          indexValue: index,
        };
      }
    }
    return {
      canGoNext: true,
      indexValue: null,
    };
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value && value.addresses ? value.addresses : dataSubmit;
    let dataSent: FullFactFindAddressPostViewModel = {
      id: factFind?.id,
      addressSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };
    const checkCanGoNext = triggerNextAddress(dataSent.addressSections);
    if (checkCanGoNext.canGoNext)
      try {
        await brandApi.brandOfficeCustomersFactFindAddressPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindAddressPostViewModel: dataSent,
        });
        toast.success(`Submit address data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.addressSections);
        await updateFactFindSection(
          brandApi,
          factFind,
          officeid,
          id,
          Section.Employment
        );
        refetchGet(!shouldRefetchGet);
        onNavigateToNextSection();
      } catch (error) {
        toast.error("Failed to submit address data.");
      }
    else if (!checkCanGoNext.canGoNext) {
      toast("Please complete missing information before saving.", {
        icon: "❗️",
      });
      setDataSubmit(dataSent.addressSections);
      setIsActiveApp(checkCanGoNext.indexValue);
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();
    let dataForm = data && data.addresses ? data.addresses : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindAddressPostViewModel = {
      id: factFind?.id,
      addressSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };
    try {
      await brandApi.brandOfficeCustomersFactFindAddressPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindAddressPostViewModel: dataSent,
      });
      toast.success(`Save address data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSent.addressSections);
    } catch (error) {
      toast.error("Failed to save address data.");
    }
  };

  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <AddressForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          resetField={resetField}
          isSubmitted={isSubmitted}
          watch={watch}
          setValue={setValue}
          control={control}
        ></AddressForm>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default AdressSession;
