const ReviewData = ({ title, value }) => {
  return (
    <div className="grid grid-cols-2 my-1 gap-2">
      <div className="col-span-1 font-bold">
        <span>{title}:</span>
      </div>
      <div className={`col-span-1 font-light`}>{value}</div>
    </div>
  );
};

export default ReviewData;
