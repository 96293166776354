import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Button from "../../../components/common/button/Button";
import { FACTFIND_TABS, VALIDATION } from "../../../constants/constants";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { getBrandOfficesApi } from "../../../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { ApplyThrough, FactFindFactFindDTO } from "@dineiro/dineiro-sdk-mobile";

const StartUpTab: React.FC<{
  setIsStartUpFactFind;
  setNumberOfApplicants;
  setApplyingBy;
  existFactFind: FactFindFactFindDTO;
  shouldRefetchGet;
  refetchGet;
  isSubmited;
}> = ({
  setIsStartUpFactFind,
  setNumberOfApplicants,
  setApplyingBy,
  existFactFind,
  shouldRefetchGet,
  refetchGet,
  isSubmited,
}) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { officeid, id, tab, subtab } = useParams();
  const [isCreateNew, setIsCreateNew] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setNumberOfApplicants(data.applicants);
    setApplyingBy(data.applying);
    const brandApi = getBrandOfficesApi(storedIdToken);
    try {
      const response = await brandApi.brandOfficeCustomersFactFindPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindPostViewModel: {
          numberOfApplicants: data.applicants,
          applyThrough: data.applying,
        },
      });

      if (response.data.id) {
        toast.success(`Successfully added new Fact Find.`);
        refetchGet(!shouldRefetchGet);
        setIsStartUpFactFind(false);
        navigate(handleFactFindNavigate(FACTFIND_TABS.personal, subtab));
      }
    } catch (error) {
      error.response
        ? error.response.json().then((x: any) => {
            toast.error(x.errors[""][0]);
          })
        : toast.error(`Failed added new Fact Find.`);
    }
  };

  const onCreateNewFactFind = () => {
    setIsCreateNew(true);
  };

  const onContinue = () => {
    setNumberOfApplicants(existFactFind.numberOfApplicants);
    setApplyingBy(existFactFind.applyThrough);
    setIsCreateNew(false);
    setIsStartUpFactFind(false);
  };

  return (
    <div className="mt-5">
      <div>
        <div className="flex flex-col max-w-sm mx-auto mb-4 gap-y-5">
          <div>
            <span className=" text-4xl font-semibold">
              Prepare to submit your fact find
            </span>
            <div className="items-center mt-2">
              <div className="h-1 bg-yellow-600 w-24"></div>
            </div>
          </div>
          {isSubmited && !isCreateNew ? (
            <>
              <span className="text-base">
                You have recently completed a fact find. To start a new fact
                find, click the button below.
              </span>
            </>
          ) : !existFactFind || isCreateNew ? (
            <>
              <span className="text-base">
                Brokers are required to provide you with the best possible loan
                options based on your specific needs and circumstances.
              </span>
              <span>
                Where possible, we have completed as much detail as possible.
                Please review, make any edits and fill in any missing items.
              </span>
            </>
          ) : (
            <>
              <span>
                It looks like you have previously started an face find. Please
                select if you wish to continue that document or start a new one
                below:
              </span>
            </>
          )}
        </div>
        {isSubmited && !isCreateNew ? (
          <div className="max-w-sm mx-auto flex items-center">
            <Button
              w="250px"
              h="45px"
              px="18px"
              borderRadius="6px"
              onClick={onCreateNewFactFind}
            >
              Start A new Fact Find
            </Button>
          </div>
        ) : !existFactFind || isCreateNew ? (
          <form className="max-w-sm mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <select
                className="w-full h-12 px-3 py-2 my-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id="applicants"
                {...register("applicants", {
                  required: VALIDATION.required.regular,
                })}
              >
                <option value="">Number of Applicants</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </select>
              {errors.applicants && (
                <span className="text-red-400 text-sm font-bold px-2">
                  {errors.applicants.message.toString()}
                </span>
              )}
            </div>

            <div>
              <select
                className="w-full h-12 px-3 py-2 my-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                id="applying"
                {...register("applying", {
                  required: VALIDATION.required.regular,
                })}
              >
                <option value="">
                  Are you applying through a business or trust?
                </option>
                <option value={ApplyThrough.YesBusiness}>
                  Yes, through a business
                </option>
                <option value={ApplyThrough.YesTrust}>
                  Yes, through a trust
                </option>
                <option value={ApplyThrough.No}>No</option>
              </select>
              {errors.applying && (
                <span className="text-red-400 text-sm font-bold px-2">
                  {errors.applying.message.toString()}
                </span>
              )}
            </div>
            <div className="flex justify-center my-5">
              <Button w="250px" h="45px" px="18px" borderRadius="6px">
                Get Started
              </Button>
            </div>
          </form>
        ) : (
          <div className="max-w-sm mx-auto flex flex-col items-center gap-y-2">
            <Button
              w="250px"
              h="45px"
              px="18px"
              borderRadius="6px"
              onClick={onContinue}
            >
              Continue My Fact Find
            </Button>
            <button
              className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
              onClick={onCreateNewFactFind}
            >
              Start A new Fact Find
            </button>
          </div>
        )}
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};
export default StartUpTab;
