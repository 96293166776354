import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const EditorComponent = ({ setContent, reloadContent }) => {
  const [editorKey, setEditorKey] = useState(Date.now());
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorKey(Date.now());
  }, [reloadContent]);

  return (
    <div
      className="w-full"
      style={{ backgroundColor: "white", color: "black" }}
    >
      <Editor
        key={editorKey}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: "750px",
          width: "100%",
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
            "emoticons",
          ],
          toolbar:
            "undo redo| blocks fontfamily fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | " +
            "bullist numlist outdent indent | link image | table media | print preview fullscreen | " +
            "forecolor backcolor | charmap emoticons | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          image_title: true,
          powerpaste_allow_local_images: true,
          automatic_uploads: true,
          file_picker_types: "image",
          file_picker_callback: (cb, value, meta) => {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            if (meta.filetype === "image") {
              input.setAttribute("accept", "image/*");
            }

            input.addEventListener("change", (e) => {
              var target = e.target as HTMLInputElement;
              var file = target.files[0];
              var reader = new FileReader();
              reader.addEventListener("load", () => {
                var id = "blobid" + new Date().getTime();
                const editor = editorRef.current;
                if (!editor) return;

                const blobCache = editor.editorUpload.blobCache;
                var base64 = reader.result?.toString().split(",")[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
        }}
        onEditorChange={(newValue, editor) => setContent(newValue)}
      />
    </div>
  );
};

export default EditorComponent;
