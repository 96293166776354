import { useState, useEffect } from "react";
import { PropertyDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useGetProperties = ({ officeId, userId }) => {
  const [properties, setProperties] = useState<PropertyDTO[]>([]);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const [shouldRefetchGetProperties, refetchGetProperties] = useState(false);

  const UserGetPropertiesComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersPropertiesGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setProperties(response.data);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, shouldRefetchGetProperties]);

    return null;
  };

  UserGetPropertiesComponent();

  return {
    properties: properties,
    shouldRefetchGetProperties: shouldRefetchGetProperties,
    refetchGetProperties: refetchGetProperties,
  };
};

export default useGetProperties;
