import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import {
  RequiredValidationMessage,
  checkObjectHasData,
  isValidNumber,
} from "../../utils/utils";
import { FactFindExpensesSectionDTO } from "@dineiro/dineiro-sdk-mobile";
import { StyledButton } from "../common/change-themes/styles-component";
import ReviewData from "../common/review-form/ReviewData";
import { NumericFormat } from "react-number-format";
import { PreFillExpense } from "../../pages/customer-tabs/FactFindTabs/ExpensesSession";

const ExpensesForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindExpensesSectionDTO;
  errors;
  watch?: UseFormWatch<FieldValues>;
  numberOfResidencesValue?: number;
  isSubmitted?: boolean;
  control: Control<FieldValues, any>;
  expenseDataPrefill: PreFillExpense;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  numberOfResidencesValue,
  isSubmitted,
  watch,
  control,
  expenseDataPrefill,
}) => {
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;

  const onEdit = () => {
    setIsShowButtonEdit(false);
  };
  let totalExpensesValue = 0;

  const homeInvestmentLoanRepayments =
    watch(`expenses[${keyValue}].homeInvestmentLoanRepayments`) || 0;
  const personalLoans = watch(`expenses[${keyValue}].personalLoans`) || 0;
  const creditCardRepayments =
    watch(`expenses[${keyValue}].creditCardRepayments`) || 0;
  const primaryResidencePropertyRunningCosts =
    watch(`expenses[${keyValue}].primaryResidencePropertyRunningCosts`) || 0;
  const groceries = watch(`expenses[${keyValue}].groceries`) || 0;
  const communications = watch(`expenses[${keyValue}].communications`) || 0;
  const clothingAndPersonalCare =
    watch(`expenses[${keyValue}].clothingAndPersonalCare`) || 0;
  const recreationAndEntertainment =
    watch(`expenses[${keyValue}].recreationAndEntertainment`) || 0;
  const petCare = watch(`expenses[${keyValue}].petCare`) || 0;
  const transportation = watch(`expenses[${keyValue}].transportation`) || 0;
  const childcare = watch(`expenses[${keyValue}].childcare`) || 0;
  const education1 = watch(`expenses[${keyValue}].education1`) || 0;
  const education2 = watch(`expenses[${keyValue}].education2`) || 0;
  const medicalAndHealth = watch(`expenses[${keyValue}].medicalAndHealth`) || 0;
  const homeInsurance = watch(`expenses[${keyValue}].homeInsurance`) || 0;
  const childAndSpousalMaintenance =
    watch(`expenses[${keyValue}].childAndSpousalMaintenance`) || 0;
  const privateSchool = watch(`expenses[${keyValue}].privateSchool`) || 0;
  const sicknessInsurance =
    watch(`expenses[${keyValue}].sicknessInsurance`) || 0;
  const primaryResidencePropertyBodyCorporate =
    watch(`expenses[${keyValue}].primaryResidencePropertyBodyCorporate`) || 0;
  const watchSecondaryResidenceProperty =
    watch(`expenses[${keyValue}].secondaryResidencePropertyExpenses`) || [];
  let watchTotalSecondaryProperties = 0;
  if (watchSecondaryResidenceProperty?.length > 0) {
    let sum = 0;
    watchSecondaryResidenceProperty.forEach((element, index) => {
      const watchProperty =
        watch(
          `expenses[${keyValue}].secondaryResidencePropertyExpenses[${index}].costs`
        ) || 0;
      sum = sum + watchProperty;
    });
    watchTotalSecondaryProperties = sum;
  }
  const other = watch(`expenses[${keyValue}].other`) || 0;

  if (
    homeInvestmentLoanRepayments ||
    creditCardRepayments ||
    personalLoans ||
    primaryResidencePropertyRunningCosts ||
    groceries ||
    communications ||
    clothingAndPersonalCare ||
    recreationAndEntertainment ||
    petCare ||
    transportation ||
    childcare ||
    education1 ||
    education2 ||
    medicalAndHealth ||
    homeInsurance ||
    childAndSpousalMaintenance ||
    privateSchool ||
    sicknessInsurance ||
    primaryResidencePropertyBodyCorporate ||
    watchTotalSecondaryProperties ||
    other
  ) {
    const totalTemp =
      homeInvestmentLoanRepayments +
      personalLoans +
      creditCardRepayments +
      primaryResidencePropertyRunningCosts +
      groceries +
      communications +
      clothingAndPersonalCare +
      recreationAndEntertainment +
      petCare +
      transportation +
      childcare +
      education1 +
      education2 +
      medicalAndHealth +
      homeInsurance +
      childAndSpousalMaintenance +
      privateSchool +
      sicknessInsurance +
      primaryResidencePropertyBodyCorporate +
      watchTotalSecondaryProperties +
      other;

    totalExpensesValue =
      dataSubmit &&
      dataSubmit.totalExpenses &&
      isShowButtonEdit &&
      totalTemp !== dataSubmit.totalExpenses
        ? dataSubmit.totalExpenses + totalTemp
        : totalTemp;
  } else if (dataSubmit && dataSubmit?.totalExpenses) {
    totalExpensesValue = dataSubmit?.totalExpenses;
  }

  const renderResidencesInputs = () => {
    return Array.from({ length: numberOfResidencesValue }, (_, index) =>
      dataSubmit === undefined ||
      !dataSubmit?.secondaryResidencePropertyExpenses ||
      !dataSubmit?.secondaryResidencePropertyExpenses[index] ||
      !dataSubmit?.secondaryResidencePropertyExpenses[index]?.address ||
      !(
        dataSubmit?.secondaryResidencePropertyExpenses[index]?.address !== ""
      ) ||
      !isValidNumber(
        dataSubmit?.secondaryResidencePropertyExpenses[index]?.costs
      ) ||
      !isShowButtonEdit ? (
        <div className=" grid grid-cols-3 gap-1 items-center" key={index}>
          <div className="col-span-2">
            <input
              className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
              type="text"
              id={`expenses[${keyValue}].secondaryResidencePropertyExpenses[${index}].address`}
              placeholder="Address"
              {...register(
                `expenses[${keyValue}].secondaryResidencePropertyExpenses[${index}].address`,
                {
                  value:
                    dataSubmit?.secondaryResidencePropertyExpenses &&
                    dataSubmit?.secondaryResidencePropertyExpenses[index]
                      ? dataSubmit?.secondaryResidencePropertyExpenses[index]
                          ?.address
                      : null,
                }
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name={`expenses[${keyValue}].secondaryResidencePropertyExpenses[${index}].costs`}
              defaultValue={
                dataSubmit?.secondaryResidencePropertyExpenses &&
                dataSubmit?.secondaryResidencePropertyExpenses[index]
                  ? dataSubmit?.secondaryResidencePropertyExpenses[index]?.costs
                  : null
              }
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`expenses[${keyValue}].secondaryResidencePropertyExpenses[${index}].costs`}
                    name={name}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                </div>
              )}
            />
          </div>
        </div>
      ) : (
        <></>
      )
    );
  };

  const renderResidencesReviews = () => {
    return Array.from(
      { length: numberOfResidencesValue },
      (_, index) =>
        dataSubmit?.secondaryResidencePropertyExpenses &&
        dataSubmit?.secondaryResidencePropertyExpenses[index] &&
        dataSubmit?.secondaryResidencePropertyExpenses[index]?.address !==
          null &&
        dataSubmit?.secondaryResidencePropertyExpenses[index]?.address !== "" &&
        isValidNumber(
          dataSubmit?.secondaryResidencePropertyExpenses[index]?.costs
        ) && (
          <div key={index}>
            <div className="font-bold ">
              <span>{`Property (Secondary Residence) (${index + 1})`} :</span>
            </div>
            <div className=" font-light">
              {" "}
              <div className=" font-light flex flex-col m-2">
                <ReviewData
                  title={"Address"}
                  value={
                    dataSubmit?.secondaryResidencePropertyExpenses[index]
                      ?.address
                  }
                ></ReviewData>
                <ReviewData
                  title={"Costs"}
                  value={dataSubmit?.secondaryResidencePropertyExpenses[
                    index
                  ]?.costs.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                ></ReviewData>
              </div>
            </div>
          </div>
        )
    );
  };
  return (
    <div className="mt-5">
      <form
        className="max-w-sm mx-auto text-sm"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div id="showData" className="flex flex-col gap-1 mb-2 ">
            {isValidNumber(dataSubmit?.homeInvestmentLoanRepayments) ||
            isValidNumber(dataSubmit?.creditCardRepayments) ||
            isValidNumber(dataSubmit?.personalLoans) ? (
              <div className="py-3">
                <div className=" font-bold">Credit Commitments (monthly):</div>
                <div className="flex flex-col gap-1 m-2">
                  {isValidNumber(dataSubmit?.homeInvestmentLoanRepayments) ? (
                    <ReviewData
                      title={"Home Loans Repayments"}
                      value={dataSubmit?.homeInvestmentLoanRepayments.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.creditCardRepayments) ? (
                    <ReviewData
                      title={"Credit Card Repayments"}
                      value={dataSubmit?.creditCardRepayments.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.personalLoans) ? (
                    <ReviewData
                      title={"Personal Loans"}
                      value={dataSubmit?.personalLoans.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.primaryResidencePropertyRunningCosts) ||
            isValidNumber(dataSubmit?.groceries) ||
            isValidNumber(dataSubmit.communications) ||
            isValidNumber(dataSubmit.clothingAndPersonalCare) ||
            isValidNumber(dataSubmit.recreationAndEntertainment) ||
            isValidNumber(dataSubmit.petCare) ||
            isValidNumber(dataSubmit.transportation) ||
            isValidNumber(dataSubmit.childcare) ||
            isValidNumber(dataSubmit.education1) ||
            isValidNumber(dataSubmit.education2) ||
            isValidNumber(dataSubmit.medicalAndHealth) ||
            isValidNumber(dataSubmit.homeInsurance) ? (
              <div className="py-3">
                <div className=" font-bold">Living Expenses:</div>
                <div className="flex flex-col gap-1 m-2">
                  {isValidNumber(
                    dataSubmit?.primaryResidencePropertyRunningCosts
                  ) ? (
                    <ReviewData
                      title={"Primary Residence (Running Costs)"}
                      value={dataSubmit?.primaryResidencePropertyRunningCosts.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.communications) ? (
                    <ReviewData
                      title={
                        "Communications (Telephone, Internet, TV, Streaming)"
                      }
                      value={dataSubmit?.communications.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.groceries) ? (
                    <ReviewData
                      title={"Groceries"}
                      value={dataSubmit?.groceries.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.clothingAndPersonalCare) ? (
                    <ReviewData
                      title={"Clothing & Personal Care"}
                      value={dataSubmit?.clothingAndPersonalCare.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.recreationAndEntertainment) ? (
                    <ReviewData
                      title={"Recreation & Entertainment"}
                      value={dataSubmit?.recreationAndEntertainment.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.petCare) ? (
                    <ReviewData
                      title={"Pet Care"}
                      value={dataSubmit?.petCare.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.transportation) ? (
                    <ReviewData
                      title={"Transportation"}
                      value={dataSubmit?.transportation.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.childcare) ? (
                    <ReviewData
                      title={"Childcare"}
                      value={dataSubmit?.childcare.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.education1) ? (
                    <ReviewData
                      title={"Education (Public, Primary & Secondary schools)"}
                      value={dataSubmit?.education1.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.education2) ? (
                    <ReviewData
                      title={"Education (Higher Education)"}
                      value={dataSubmit?.education2.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.medicalAndHealth) ? (
                    <ReviewData
                      title={"Medical & Health"}
                      value={dataSubmit?.medicalAndHealth.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.homeInsurance) ? (
                    <ReviewData
                      title={"Insurance (Home, Contents, Vehicle, Health)"}
                      value={dataSubmit?.homeInsurance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit.childAndSpousalMaintenance) ||
            isValidNumber(dataSubmit.privateSchool) ||
            isValidNumber(dataSubmit.sicknessInsurance) ||
            isValidNumber(dataSubmit.primaryResidencePropertyBodyCorporate) ||
            (isValidNumber(dataSubmit.other) && dataSubmit.other !== 0) ||
            (dataSubmit.secondaryResidencePropertyExpenses &&
              dataSubmit.secondaryResidencePropertyExpenses[0] &&
              dataSubmit.secondaryResidencePropertyExpenses[0].address &&
              isValidNumber(
                dataSubmit.secondaryResidencePropertyExpenses[0].costs
              )) ||
            (dataSubmit.secondaryResidencePropertyExpenses &&
              dataSubmit.secondaryResidencePropertyExpenses[1] &&
              dataSubmit.secondaryResidencePropertyExpenses[1].address &&
              isValidNumber(
                dataSubmit.secondaryResidencePropertyExpenses[1].costs
              )) ? (
              <div className="py-3">
                <div className=" font-bold">Other Commitments:</div>
                <div className="flex flex-col gap-1 m-2">
                  {isValidNumber(dataSubmit?.childAndSpousalMaintenance) ? (
                    <ReviewData
                      title={"Child & Spousal Maintenance"}
                      value={dataSubmit?.childAndSpousalMaintenance.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.privateSchool) ? (
                    <ReviewData
                      title={"Private School"}
                      value={dataSubmit?.privateSchool.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(dataSubmit?.sicknessInsurance) ? (
                    <ReviewData
                      title={"Sickness, Accident & Life Insurance"}
                      value={dataSubmit?.sicknessInsurance.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {isValidNumber(
                    dataSubmit?.primaryResidencePropertyBodyCorporate
                  ) ? (
                    <ReviewData
                      title={
                        "Body Corporate, Strata, Land Tax & primary residence"
                      }
                      value={dataSubmit?.primaryResidencePropertyBodyCorporate.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                  {renderResidencesReviews()}
                  {isValidNumber(dataSubmit?.other) ? (
                    <ReviewData
                      title={"Other"}
                      value={dataSubmit?.other.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    ></ReviewData>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        {dataSubmit === undefined ||
        !isValidNumber(dataSubmit?.homeInvestmentLoanRepayments) ||
        !isValidNumber(dataSubmit?.creditCardRepayments) ||
        !isValidNumber(dataSubmit?.personalLoans) ||
        !isShowButtonEdit ? (
          <div className=" pb-4 border-b-[1px] border-white/60 space-y-1">
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.homeInvestmentLoanRepayments) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Home Loans Repayments</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].homeInvestmentLoanRepayments`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.homeLoanRepayment) &&
                    expenseDataPrefill.homeLoanRepayment !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.homeInvestmentLoanRepayments)
                      ? expenseDataPrefill.homeLoanRepayment
                      : dataSubmit?.homeInvestmentLoanRepayments
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Home Loans Repayments"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].homeInvestmentLoanRepayments`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />

                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]
                      ?.homeInvestmentLoanRepayments && (
                      <span className="text-red-400 text-sm font-bold">
                        {errors.expenses?.[
                          keyValue
                        ]?.homeInvestmentLoanRepayments.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.creditCardRepayments) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Credit Card Repayments</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].creditCardRepayments`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.creditCardRepayments) &&
                    expenseDataPrefill.creditCardRepayments !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.creditCardRepayments)
                      ? expenseDataPrefill.creditCardRepayments
                      : dataSubmit?.creditCardRepayments ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Credit Card Repayments"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].creditCardRepayments`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.creditCardRepayments && (
                      <span className="text-red-400 text-sm font-bold">
                        {errors.expenses?.[
                          keyValue
                        ]?.creditCardRepayments.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.personalLoans) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Personal Loans</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].personalLoans`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.personalLoanRepayments) &&
                    expenseDataPrefill.personalLoanRepayments !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.personalLoans)
                      ? expenseDataPrefill.personalLoanRepayments
                      : dataSubmit?.personalLoans ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Personal Loans"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].personalLoans`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.personalLoans && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.personalLoans.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        {dataSubmit === undefined ||
        !isValidNumber(dataSubmit?.primaryResidencePropertyRunningCosts) ||
        !isValidNumber(dataSubmit?.groceries) ||
        !isValidNumber(dataSubmit?.communications) ||
        !isValidNumber(dataSubmit?.clothingAndPersonalCare) ||
        !isValidNumber(dataSubmit?.recreationAndEntertainment) ||
        !isValidNumber(dataSubmit?.petCare) ||
        !isValidNumber(dataSubmit?.transportation) ||
        !isValidNumber(dataSubmit?.childcare) ||
        !isValidNumber(dataSubmit?.education1) ||
        !isValidNumber(dataSubmit?.education2) ||
        !isValidNumber(dataSubmit?.medicalAndHealth) ||
        !isValidNumber(dataSubmit?.homeInsurance) ||
        !isShowButtonEdit ? (
          <div className="pb-4 border-b-[1px] border-white/60 space-y-1">
            <div className="mb-2 mt-4">
              <span>Living Expenses</span>
            </div>
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.primaryResidencePropertyRunningCosts) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Primary Residence</span>
                  <span className="font-thin italic">(Running Costs)</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].primaryResidencePropertyRunningCosts`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.primaryResidence) &&
                    expenseDataPrefill.primaryResidence !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(
                      dataSubmit?.primaryResidencePropertyRunningCosts
                    )
                      ? expenseDataPrefill.primaryResidence
                      : dataSubmit?.primaryResidencePropertyRunningCosts ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Primary Residence"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].primaryResidencePropertyRunningCosts`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]
                      ?.primaryResidencePropertyRunningCosts && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.primaryResidencePropertyRunningCosts.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.communications) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Communications</span>
                  <span className="font-thin italic">
                    (Telephone, Internet, TV, Streaming)
                  </span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].communications`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.communications) &&
                    expenseDataPrefill.communications !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.communications)
                      ? expenseDataPrefill.communications
                      : dataSubmit?.communications ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Communications (Telephone, Internet, TV, Streaming)"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].communications`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.communications && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.communications.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.groceries) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Groceries</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].groceries`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.groceries) &&
                    expenseDataPrefill.groceries !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.groceries)
                      ? expenseDataPrefill.groceries
                      : dataSubmit?.groceries ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Groceries"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].groceries`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.groceries && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.groceries.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.clothingAndPersonalCare) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Clothing & Personal Care</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].clothingAndPersonalCare`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.clothingPersonalCare) &&
                    expenseDataPrefill.clothingPersonalCare !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.clothingAndPersonalCare)
                      ? expenseDataPrefill.clothingPersonalCare
                      : dataSubmit?.clothingAndPersonalCare ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Clothing & Personal Care"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].clothingAndPersonalCare`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.clothingAndPersonalCare && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.clothingAndPersonalCare.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.recreationAndEntertainment) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Recreation & Entertainment</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].recreationAndEntertainment`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.recreationEntertainment) &&
                    expenseDataPrefill.recreationEntertainment !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.recreationAndEntertainment)
                      ? expenseDataPrefill.recreationEntertainment
                      : dataSubmit?.recreationAndEntertainment ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Recreation & Entertainment"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].recreationAndEntertainment`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />

                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.recreationAndEntertainment && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.recreationAndEntertainment.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.petCare) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Pet Care</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].petCare`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.petCare) &&
                    expenseDataPrefill.petCare !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.petCare)
                      ? expenseDataPrefill.petCare
                      : dataSubmit?.petCare ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Pet Care"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].petCare`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.petCare && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.petCare.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.transportation) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Transportation</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].transportation`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.transportation) &&
                    expenseDataPrefill.transportation !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.transportation)
                      ? expenseDataPrefill.transportation
                      : dataSubmit?.transportation ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Transportation"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].transportation`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.transportation && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.transportation.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.childcare) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Childcare</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].childcare`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.childcare) &&
                    expenseDataPrefill.childcare !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.childcare)
                      ? expenseDataPrefill.childcare
                      : dataSubmit?.childcare ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Childcare"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].childcare`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.childcare && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.childcare.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.education1) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Education</span>
                  <span className="font-thin italic">
                    (Public, Primary & Secondary schools)
                  </span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].education1`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.education) &&
                    expenseDataPrefill.education !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.education1)
                      ? expenseDataPrefill.education
                      : dataSubmit?.education1 ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Education (Public, Primary & Secondary schools)"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].education1`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.education1 && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.education1.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.education2) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Education</span>
                  <span className="font-thin italic">(Higher Education)</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].education2`}
                  defaultValue={dataSubmit?.education2 ?? null}
                  rules={{
                    required: RequiredValidationMessage(
                      "Education (Higher Education)"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].education2`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.education2 && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.education2.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.medicalAndHealth) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="col-span-2">
                  <span>Medical & Health</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].medicalAndHealth`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.medicalHealth) &&
                    expenseDataPrefill.medicalHealth !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.medicalAndHealth)
                      ? expenseDataPrefill.medicalHealth
                      : dataSubmit?.medicalAndHealth ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage("Medical & Health"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].medicalAndHealth`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.medicalAndHealth && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.medicalAndHealth.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.homeInsurance) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Insurance</span>
                  <span className="font-thin italic">
                    (Home, Contents, Vehicle, Health)
                  </span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].homeInsurance`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.homeInsurance) &&
                    expenseDataPrefill.homeInsurance !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.homeInsurance)
                      ? expenseDataPrefill.homeInsurance
                      : dataSubmit?.homeInsurance ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Insurance(Home, Contents, Vehicle, Health)"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].homeInsurance`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.homeInsurance && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.homeInsurance.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        {dataSubmit === undefined ||
        !isValidNumber(dataSubmit.childAndSpousalMaintenance) ||
        !isValidNumber(dataSubmit.privateSchool) ||
        !isValidNumber(dataSubmit.sicknessInsurance) ||
        !isValidNumber(dataSubmit.primaryResidencePropertyBodyCorporate) ||
        !dataSubmit.secondaryResidencePropertyExpenses ||
        !dataSubmit.secondaryResidencePropertyExpenses[0] ||
        !(
          dataSubmit?.secondaryResidencePropertyExpenses[0]?.address !== null
        ) ||
        !(
          dataSubmit?.secondaryResidencePropertyExpenses[1]?.address !==
          undefined
        ) ||
        !isValidNumber(
          dataSubmit.secondaryResidencePropertyExpenses[0].costs
        ) ||
        !dataSubmit.secondaryResidencePropertyExpenses[1] ||
        !isValidNumber(
          dataSubmit.secondaryResidencePropertyExpenses[1].costs
        ) ||
        !isValidNumber(dataSubmit.other) ||
        !isShowButtonEdit ? (
          <div className="pb-4 space-y-1">
            <div className="mb-2 mt-4">
              <span>Other Commitments</span>
            </div>
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.childAndSpousalMaintenance) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Child & Spousal Maintenance</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].childAndSpousalMaintenance`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.childSpousalMaintenance) &&
                    expenseDataPrefill.childSpousalMaintenance !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.childAndSpousalMaintenance)
                      ? expenseDataPrefill.childSpousalMaintenance
                      : dataSubmit?.childAndSpousalMaintenance ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Child & Spousal Maintenance"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].childAndSpousalMaintenance`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.childAndSpousalMaintenance && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.childAndSpousalMaintenance.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.privateSchool) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className=" col-span-2">
                  <span>Private School</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].privateSchool`}
                  defaultValue={dataSubmit?.privateSchool ?? null}
                  rules={{
                    required: RequiredValidationMessage("Private School"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].privateSchool`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.privateSchool && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.privateSchool.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.sicknessInsurance) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Insurance</span>
                  <span className="font-thin italic">
                    (Sickness, Accident & Life)
                  </span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].sicknessInsurance`}
                  defaultValue={
                    isValidNumber(expenseDataPrefill.sicknessInsurance) &&
                    expenseDataPrefill.sicknessInsurance !== 0 &&
                    keyValue === 0 &&
                    !isValidNumber(dataSubmit?.sicknessInsurance)
                      ? expenseDataPrefill.sicknessInsurance
                      : dataSubmit?.sicknessInsurance ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Insurance (Sickness, Accident & Life)"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].sicknessInsurance`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.sicknessInsurance && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.sicknessInsurance.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.primaryResidencePropertyBodyCorporate) ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="flex flex-col col-span-2">
                  <span>Property (Primary Residence)</span>
                  <span className="font-thin italic">
                    (Body Corporate, Strata, Land etc.)
                  </span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].primaryResidencePropertyBodyCorporate`}
                  defaultValue={
                    dataSubmit?.primaryResidencePropertyBodyCorporate ?? null
                  }
                  rules={{
                    required: RequiredValidationMessage(
                      "Property (Primary Residence)"
                    ),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].primaryResidencePropertyBodyCorporate`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]
                      ?.primaryResidencePropertyBodyCorporate && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[
                          keyValue
                        ]?.primaryResidencePropertyBodyCorporate.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="flex flex-col col-span-2">
              <span>Property (Secondary Residence)</span>
              <span className="font-thin italic">(Running Costs)</span>
            </div>
            {renderResidencesInputs()}
            {/* Other */}
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.other) ||
            dataSubmit?.other === 0 ||
            !isShowButtonEdit ? (
              <div className=" grid grid-cols-3 items-center">
                <div className="col-span-2">
                  <span>Other</span>
                </div>
                <Controller
                  control={control}
                  name={`expenses[${keyValue}].other`}
                  defaultValue={dataSubmit?.other ?? null}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`expenses[${keyValue}].other`}
                        name={name}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                        decimalScale={2}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div className="col-span-3">
                  {keyValue !== null &&
                    keyValue !== undefined &&
                    errors.expenses?.[keyValue]?.other && (
                      <span className="text-red-400 text-sm font-bold ">
                        {errors.expenses?.[keyValue]?.other.message.toString()}
                      </span>
                    )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </form>
      <div className="flex flex-col max-w-sm mx-auto pb-2 font-bold my-5 gap-4">
        <div className="flex justify-between text-sm">
          <span>Total Expenses</span>
          <span>
            {totalExpensesValue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ExpensesForm;
