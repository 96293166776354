import React, { useEffect, useState } from "react";
import {
  Controller,
  UseFormRegister,
  FieldValues,
  Control,
} from "react-hook-form";
import {
  RequiredValidationMessage,
  checkObjectHasData,
} from "../../utils/utils";
import {
  Answer,
  FactFindQuestionsSectionDTO,
  FactFindQuestionsSectionQuestionDTO,
  QuestionType,
} from "@dineiro/dineiro-sdk-mobile";
import {
  StyledButton,
  StyledCheckBox,
  StyledCheckSVG,
} from "../common/change-themes/styles-component";
import ReviewData from "../common/review-form/ReviewData";

interface GroupedQuestion {
  questionCategory: any;
  questions: FactFindQuestionsSectionQuestionDTO[];
}

const QuestionsForm: React.FC<{
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindQuestionsSectionDTO;
  resetField;
  errors;
  control: Control<FieldValues, any>;
  isSubmitted?: boolean;
}> = ({
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  resetField,
  control,
  isSubmitted,
}) => {
  const [groupQuestion, setGroupQuestion] = useState<GroupedQuestion[]>([]);

  let checkData =
    dataSubmit && dataSubmit?.conveyancer
      ? checkObjectHasData(dataSubmit?.conveyancer)
      : false;

  const onEdit = () => {
    setIsShowButtonEdit(false);
  };
  const [selectedOptions, setSelectedOptions] = useState({});

  const hanldeSaveQuestion = () => {
    if (
      dataSubmit &&
      dataSubmit?.questionsSectionQuestions &&
      dataSubmit?.questionsSectionQuestions?.length > 0
    ) {
      dataSubmit?.questionsSectionQuestions.forEach((value) => {
        setSelectedOptions((prev) => ({
          ...prev,
          [value.id]: value.answer ?? null,
        }));
      });
    }
  };

  const handleOptionChange = (questionId, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };
  const groupQuestions = () => {
    if (dataSubmit?.questionsSectionQuestions.length > 0) {
      const groupedQuestion = new Map<
        any,
        FactFindQuestionsSectionQuestionDTO[]
      >();

      dataSubmit?.questionsSectionQuestions.forEach((question) => {
        groupedQuestion.has(question?.question?.questionCategory?.name)
          ? groupedQuestion
              .get(question?.question?.questionCategory?.name)
              .push(question)
          : groupedQuestion.set(question?.question?.questionCategory?.name, [
              question,
            ]);
      });

      const result = Array.from(groupedQuestion.entries()).map<GroupedQuestion>(
        ([questionCategory, questionList]) => ({
          questionCategory,
          questions: questionList,
        })
      );
      setGroupQuestion(result);
    }
  };

  useEffect(() => {
    if (
      dataSubmit &&
      dataSubmit?.questionsSectionQuestions &&
      dataSubmit?.questionsSectionQuestions.length > 0
    ) {
      groupQuestions();
    }
    hanldeSaveQuestion();
  }, [dataSubmit]);

  return (
    <div className="mt-5">
      <form
        className="max-w-sm mx-auto text-sm "
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        {groupQuestion.map((category, index) => (
          <div
            className="pb-3 border-b-[1px] border-white/60"
            key={`groups_${index}`}
          >
            <div className="p-2 font-semibold">
              <span>{category.questionCategory ?? ""}</span>
            </div>

            <div className="mt-2">
              <ol className="list-decimal font-semibold text-sm ">
                {category.questions.map((q) => (
                  <li className="mb-4 space-y-1" key={q.id}>
                    <span>{q.question?.title}</span>
                    <div className="text-sm space-y-2">
                      <p className="font-light">{q.question?.content}</p>
                      <Controller
                        name={`questions.q_${q.id}`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={selectedOptions[q.id]}
                        render={({ field }) => (
                          <div {...field} className="flex gap-3">
                            {q.question?.type === QuestionType.YesNoNotEssential
                              ? [
                                  Answer.Yes,
                                  Answer.No,
                                  Answer.NotEssential,
                                ].map((option) => (
                                  <div className="space-y-2" key={option}>
                                    <label className="inline-flex items-center">
                                      <div
                                        className={`flex w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 rounded-none dark:border-gray-600 items-center}`}
                                      >
                                        {selectedOptions[q.id] === option && (
                                          <StyledCheckSVG
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="2"
                                            className="w-4 h-4"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="m4.5 12.75 6 6 9-13.5"
                                            />
                                          </StyledCheckSVG>
                                        )}
                                      </div>
                                      <StyledCheckBox
                                        type="radio"
                                        value={option}
                                        checked={
                                          selectedOptions[q.id] === option
                                        }
                                        className="invisible"
                                        onChange={() =>
                                          handleOptionChange(q.id, option)
                                        }
                                      />

                                      <span>{option}</span>
                                    </label>
                                  </div>
                                ))
                              : [Answer.Yes, Answer.No].map((option) => (
                                  <div className="space-y-2" key={option}>
                                    <label className="inline-flex items-center">
                                      <div
                                        className={`flex w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 rounded-none dark:border-gray-600 items-center}`}
                                      >
                                        {selectedOptions[q.id] === option && (
                                          <StyledCheckSVG
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="2"
                                            className="w-4 h-4"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="m4.5 12.75 6 6 9-13.5"
                                            />
                                          </StyledCheckSVG>
                                        )}
                                      </div>
                                      <StyledCheckBox
                                        type="radio"
                                        value={option}
                                        checked={
                                          selectedOptions[q.id] === option
                                        }
                                        className="invisible"
                                        onChange={() =>
                                          handleOptionChange(q.id, option)
                                        }
                                      />

                                      <span>{option}</span>
                                    </label>
                                  </div>
                                ))}
                          </div>
                        )}
                      />
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        ))}

        <div>
          <div className="px-2 my-3 font-semibold">
            <span>Conveyancer Details</span>
          </div>
          <div className=" flex justify-end">
            {checkData && isShowButtonEdit && !isSubmitted && (
              <StyledButton
                type="button"
                className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
                onClick={onEdit}
              >
                Edit
              </StyledButton>
            )}
          </div>
          {dataSubmit && checkData && isShowButtonEdit && (
            <div id="showData" className="flex flex-col gap-1 mb-2">
              {dataSubmit?.conveyancer &&
              dataSubmit?.conveyancer?.nameOfFirm ? (
                <ReviewData
                  title={"Name Of Firm"}
                  value={dataSubmit?.conveyancer?.nameOfFirm}
                ></ReviewData>
              ) : (
                <></>
              )}
              {dataSubmit?.conveyancer &&
              dataSubmit?.conveyancer?.contactName ? (
                <ReviewData
                  title={"Contact Name"}
                  value={dataSubmit?.conveyancer?.contactName}
                ></ReviewData>
              ) : (
                <></>
              )}
              {dataSubmit?.conveyancer &&
              dataSubmit?.conveyancer?.phoneNumber ? (
                <ReviewData
                  title={"Phone"}
                  value={dataSubmit?.conveyancer?.phoneNumber}
                ></ReviewData>
              ) : (
                <></>
              )}
              {dataSubmit?.conveyancer && dataSubmit?.conveyancer?.email ? (
                <ReviewData
                  title={"Email"}
                  value={dataSubmit?.conveyancer?.email}
                ></ReviewData>
              ) : (
                <></>
              )}
              {dataSubmit?.conveyancer && dataSubmit?.conveyancer?.address ? (
                <ReviewData
                  title={"Address"}
                  value={dataSubmit?.conveyancer?.address}
                ></ReviewData>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="space-y-1">
            {dataSubmit === undefined ||
            !dataSubmit?.conveyancer ||
            !dataSubmit?.conveyancer?.nameOfFirm ||
            dataSubmit?.conveyancer?.nameOfFirm === "" ||
            !isShowButtonEdit ? (
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id={`conveyancer.nameOfFirm`}
                  placeholder="Name of Firm"
                  {...register(`conveyancer.nameOfFirm`, {
                    value: dataSubmit?.conveyancer?.nameOfFirm ?? null,
                    required: RequiredValidationMessage("Name of Firm"),
                  })}
                />
                {errors.conveyancer?.nameOfFirm && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.conveyancer?.nameOfFirm.message.toString()}
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}
            {dataSubmit === undefined ||
            !dataSubmit?.conveyancer ||
            !dataSubmit?.conveyancer?.contactName ||
            dataSubmit?.conveyancer?.contactName === "" ||
            !isShowButtonEdit ? (
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id={`conveyancer.contactName`}
                  placeholder="Contact Name"
                  {...register(`conveyancer.contactName`, {
                    value: dataSubmit?.conveyancer?.contactName ?? null,
                    required: RequiredValidationMessage("Contact Name"),
                  })}
                />
                {errors.conveyancer?.contactName && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.conveyancer?.contactName.message.toString()}
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}

            {dataSubmit === undefined ||
            !dataSubmit?.conveyancer ||
            !dataSubmit?.conveyancer?.phoneNumber ||
            dataSubmit?.conveyancer?.phoneNumber === "" ||
            !isShowButtonEdit ? (
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id={`conveyancer.phoneNumber`}
                  placeholder="Phone"
                  {...register(`conveyancer.phoneNumber`, {
                    value: dataSubmit?.conveyancer?.phoneNumber ?? null,
                    required: RequiredValidationMessage("Phone"),
                  })}
                />
                {errors.conveyancer?.phoneNumber && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.conveyancer?.phoneNumber.message.toString()}
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}

            {dataSubmit === undefined ||
            !dataSubmit?.conveyancer ||
            !dataSubmit?.conveyancer?.email ||
            dataSubmit?.conveyancer?.email === "" ||
            !isShowButtonEdit ? (
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id={`conveyancer.email`}
                  placeholder="Email"
                  {...register(`conveyancer.email`, {
                    value: dataSubmit?.conveyancer?.email ?? null,
                    required: RequiredValidationMessage("Email"),
                  })}
                />
                {errors.conveyancer?.email && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.conveyancer?.email.message.toString()}
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}

            {dataSubmit === undefined ||
            !dataSubmit?.conveyancer ||
            !dataSubmit?.conveyancer?.address ||
            dataSubmit?.conveyancer?.address === "" ||
            !isShowButtonEdit ? (
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id={`conveyancer.address`}
                  placeholder="Address"
                  {...register(`conveyancer.address`, {
                    value: dataSubmit?.conveyancer?.address ?? null,
                    required: RequiredValidationMessage("Address"),
                  })}
                />
                {errors.conveyancer?.address && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.conveyancer?.address.message.toString()}
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default QuestionsForm;
