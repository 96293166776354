import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { useEffect, useState } from "react";
import PersonalSingleForm from "../../../components/fact-find/PersonalSingleForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  FactFindFactFindDTO,
  FullFactFindPersonalPostViewModel,
  MaritalStatus,
  PersonalSectionViewModel,
  Section,
  UserDTO,
} from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../../../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FACTFIND_TABS } from "../../../constants/constants";
import { updateFactFindSection } from "./ApiService";

interface PersonalSectionView {
  applicantId?: number;
  title?: string;
  surname?: string;
  givenName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  email?: string;
  maritalStatus?: MaritalStatus;
  numberOfDependents?: number;
  ageOfDependents?: number[];
  driverLicense?: {
    driverLicenseNo?: string;
    issuingState?: string;
    expiredDate?: string;
    citizenship?: string;
  };
}

const PersonalSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
  userInfoById: UserDTO;
}> = ({
  numberOfApplicants,
  factFind,
  refetchGet,
  shouldRefetchGet,
  userInfoById,
}) => {
  const [dataSubmit, setDataSubmit] = useState<PersonalSectionView[]>([]);
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);
  const convertDataView = (personalSection) => {
    return {
      applicantId: personalSection.applicantId ?? null,
      title: personalSection.title ?? null,
      surname: personalSection.surname ?? null,
      givenName: personalSection.givenName ?? null,
      dateOfBirth: personalSection.dateOfBirth
        ? moment(personalSection.dateOfBirth).format("YYYY-MM-DD").toString()
        : null,
      phoneNumber: personalSection.phoneNumber ?? null,
      email: personalSection.email ?? null,
      maritalStatus: personalSection.maritalStatus ?? null,
      numberOfDependents: personalSection.numberOfDependents ?? null,
      ageOfDependents: personalSection.ageOfDependents ?? null,
      driverLicense: personalSection.driverLicense
        ? {
            driverLicenseNo:
              personalSection.driverLicense?.driverLicenseNo ?? null,
            issuingState: personalSection.driverLicense?.issuingState ?? null,
            expiredDate: personalSection.driverLicense?.expiredDate
              ? moment(personalSection.driverLicense?.expiredDate)
                  .format("YYYY-MM-DD")
                  .toString()
              : null,
            citizenship: personalSection.driverLicense?.citizenship ?? null,
          }
        : null,
    } as PersonalSectionView;
  };
  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const personalSection = { ...s.personalSection };
          const converToView = personalSection
            ? convertDataView(personalSection)
            : null;
          return converToView;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const convertDataToSubmit = (data) => {
    let dataConverted: PersonalSectionViewModel[] = [];
    if (data) {
      for (let index = 0; index < numberOfApplicants; index++) {
        dataConverted.push({
          applicantId: factFind.applicants[index].id,
          title:
            data[index]?.title !== undefined
              ? data[index]?.title
              : dataSubmit[index].title,
          surname:
            data[index]?.surname !== undefined
              ? data[index]?.surname
              : dataSubmit[index].surname,
          givenName:
            data[index]?.givenName !== undefined
              ? data[index]?.givenName
              : dataSubmit[index].givenName,
          dateOfBirth: data[index]?.dateOfBirth
            ? new Date(data[index]?.dateOfBirth)
            : dataSubmit[index]?.dateOfBirth
            ? new Date(dataSubmit[index]?.dateOfBirth)
            : null,
          phoneNumber:
            data[index]?.phoneNumber !== undefined
              ? data[index]?.phoneNumber
              : dataSubmit[index].phoneNumber,
          email:
            data[index]?.email !== undefined
              ? data[index]?.email
              : dataSubmit[index].email,
          maritalStatus:
            data[index]?.maritalStatus !== undefined
              ? data[index]?.maritalStatus
              : dataSubmit[index].maritalStatus,
          numberOfDependents:
            data[index]?.numberOfDependents !== undefined
              ? data[index]?.numberOfDependents || 0
              : dataSubmit[index].numberOfDependents,
          ageOfDependents:
            data[index]?.ageOfDependents !== undefined
              ? data[index]?.ageOfDependents.map((d) => {
                  return Number(d) || 0;
                })
              : dataSubmit[index].ageOfDependents,
          driverLicense: {
            driverLicenseNo:
              data[index]?.driverLicense?.driverLicenseNo !== undefined
                ? data[index]?.driverLicense?.driverLicenseNo
                : dataSubmit[index].driverLicense?.driverLicenseNo,
            issuingState:
              data[index]?.driverLicense?.issuingState !== undefined
                ? data[index]?.driverLicense?.issuingState
                : dataSubmit[index].driverLicense?.issuingState,
            expiredDate: data[index]?.driverLicense?.expiredDate
              ? new Date(data[index]?.driverLicense?.expiredDate)
              : dataSubmit[index].driverLicense?.expiredDate
              ? new Date(dataSubmit[index].driverLicense?.expiredDate)
              : null,
            citizenship:
              data[index]?.driverLicense?.citizenship !== undefined
                ? data[index]?.driverLicense?.citizenship
                : dataSubmit[index].driverLicense?.citizenship,
          },
        } as PersonalSectionViewModel);
      }
    }
    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.personals) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.personals?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.address, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value && value.personals ? value.personals : dataSubmit;

    let dataSent: FullFactFindPersonalPostViewModel = {
      id: factFind?.id,
      personalSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };
    try {
      await brandApi.brandOfficeCustomersFactFindPersonalPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindPersonalPostViewModel: dataSent,
      });
      toast.success(`Submit personal data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSent.personalSections.map((s) => convertDataView(s)));
      await updateFactFindSection(
        brandApi,
        factFind,
        officeid,
        id,
        Section.Address
      );
      refetchGet(!shouldRefetchGet);
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to save personal data.");
    }
  };

  const onSaveAsDraft = async () => {
    const data = getValues();
    let dataForm = data && data.personals ? data.personals : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindPersonalPostViewModel = {
      id: factFind?.id,
      personalSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };
    clearErrors();
    try {
      await brandApi.brandOfficeCustomersFactFindPersonalPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindPersonalPostViewModel: dataSent,
      });
      toast.success(`Save personal data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSent.personalSections.map((s) => convertDataView(s)));
    } catch (error) {
      toast.error("Failed to save personal data.");
    }
  };
  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <PersonalSingleForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          setValue={setValue}
          isSubmitted={isSubmitted}
          userInfoById={userInfoById}
        ></PersonalSingleForm>
      </div>
    ));
  };
  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="button"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default PersonalSession;
