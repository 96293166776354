import React, { useState, useEffect, useRef, useCallback } from "react";
import Spinner from "../common/spinner/Spinner";
import BuldIcon from "./../../images/home/buld.svg";
import DocumentIcon from "./../../images/home/document-icon.svg";
import ExportIcon from "./../../images/home/export-icon.svg";
import EmailIcon from "./../../images/home/invitationIcon.svg";
import ExportLoanStatusIcon from "./../../images/export/loan-status.svg";
import FinancialIcon from "./../../images/home/financial.svg";
import SentIcon from "./../../images/home/sent.svg";
import AppUser from "./../../images/home/app-user-icon.svg";
import DesktopUser from "./../../images/home/desktop-icon.svg";
import FilterIcon from "./../../images/home/filter-icon.svg";
import { PAGE_URL, CUSTOMER_TABS } from "../../constants/constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLocalStorageElement,
  handleDownload,
  handleNavigate,
  isExpired,
  sortAlphabet,
  sortFullNames,
} from "../../utils/utils";
import Button from "../common/button/Button";
import { getBrandOfficesApi } from "../../services/api-service";
import toast from "react-hot-toast";
import {
  BackgroundTaskDTO,
  BackgroundTaskStatus,
  BackgroundTaskType,
  DineiroApp,
  UserDTO,
} from "@dineiro/dineiro-sdk-mobile";
import useUserInfoTK from "../../hooks/useUserInfoTK";

const Customers: React.FC<{
  data: UserDTO[];
  pageSize: number;
  alphabet: string[];
}> = ({ data, pageSize, alphabet }) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [isLoading, setIsLoading] = useState(false);
  const [alphabetDisplay, setAlphabetDisplay] = useState([]);
  const navigate = useNavigate();
  const loaderRef = useRef(null);
  const [reportStatus, setReportStatus] = useState<BackgroundTaskDTO[]>([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [genratedReports, setGenratedReports] = useState([]);
  const { role, brandOfficeId } = useUserInfoTK();

  const [sortedData, setSortedData] = useState([]);
  const [visibleData, setVisibleData] = useState<UserDTO[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [isAscending, setIsAscending] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const sorted = sortFullNames(data, orderBy, isAscending);
    const sortedAlphabet = sortAlphabet(alphabet, isAscending);
    setAlphabetDisplay(sortedAlphabet);
    setSortedData(sorted);
    setVisibleData(sorted.slice(0, pageSize));
    setHasMore(sorted.length > pageSize);
  }, [data, isAscending, orderBy, pageSize, alphabet]);

  const loadMoreData = useCallback(() => {
    setVisibleData((prev) => {
      const newData = sortedData.slice(prev.length, prev.length + pageSize);
      setHasMore(prev.length + pageSize < sortedData.length);
      return [...prev, ...newData];
    });
  }, [sortedData, pageSize]);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (hasMore) {
          loadMoreData();
        }
      }
    }
  }, [hasMore, loadMoreData]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const onAssetsTab = (customerName, id, tab) => {
    try {
      navigate(
        handleNavigate(
          PAGE_URL.CUSTOMER_ASSETS.replace(
            ":customername",
            customerName.replace(/\s/g, "-")
          )
            .replace(":id", id)
            .replace(":tab", tab.toLowerCase())
        )
      );
    } catch (err) {}
  };

  const onOrder = (customerId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersBackgroundTasksPost({
          id: brandOfficeId,
          userId: customerId,
          brandOfficeCustomersBackgroundTasksPostViewModel: {
            type: BackgroundTaskType.GenerateUserAnnualReport,
          },
        })
        .then((respone) => {
          setReportStatus((prevReportStatus) => [
            ...prevReportStatus,
            respone.data,
          ]);
          setTimeout(() => {}, 1000);
        }),
      {
        loading: "Saving...",
        success: <b>Order report successful.</b>,
        error: <b>Failed to order report.</b>,
      }
    );
  };
  const onInvitation = (customerId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeCustomersDesktopInvitationsPost({
          id: brandOfficeId,
          userId: customerId,
        })
        .then((respone) => {
          setInvitedUsers((invitedUsers) => [...invitedUsers, customerId]);
          setTimeout(() => {}, 1000);
        }),
      {
        loading: "Inviting...",
        success: <b>Invite user successful.</b>,
        error: <b>Failed to invite user.</b>,
      }
    );
  };

  const isOutOfDateReport = (
    backgroundProcesses: BackgroundTaskDTO[],
    customer
  ) => {
    const annualReportBackGround = backgroundProcesses?.filter(
      (process) => process.type === BackgroundTaskType.GenerateUserAnnualReport
    );
    if (reportStatus.some((status) => status.userId === customer.id))
      return false;
    if (
      annualReportBackGround.length === 0 &&
      !reportStatus.some((status) => status.userId === customer.id)
    )
      return true;
    if (annualReportBackGround.length > 0) {
      const customerStatus = annualReportBackGround?.some(
        (process) => process.status === BackgroundTaskStatus.Canceled
      );
      if (customerStatus) return true;
      const latestReport = annualReportBackGround.sort(
        (a, b) => b.id - a.id
      )[0];
      if (isExpired(latestReport.createdAt)) return true;
    }
    return false;
  };

  const isInprocess = (
    backgroundProcesses: BackgroundTaskDTO[],
    customerId
  ) => {
    let report = reportStatus.some(
      (status) =>
        status.userId === customerId &&
        status.status === BackgroundTaskStatus.InProgress
    );
    let customerStatus = false;
    if (backgroundProcesses.length > 0) {
      const latestReport = backgroundProcesses.sort((a, b) => b.id - a.id)[0];
      customerStatus =
        latestReport.status === BackgroundTaskStatus.InProgress &&
        latestReport.type === BackgroundTaskType.GenerateUserAnnualReport;
    }
    return report || customerStatus;
  };

  const isCompleted = (backgroundProcesses: BackgroundTaskDTO[]) => {
    let customerStatus = false;
    if (backgroundProcesses.length > 0) {
      const latestReport = backgroundProcesses.sort((a, b) => b.id - a.id)[0];
      customerStatus =
        latestReport.status === BackgroundTaskStatus.Completed &&
        latestReport.type === BackgroundTaskType.GenerateUserAnnualReport;
    }

    return customerStatus;
  };

  const isOutOfDateInvitation = (
    backgroundProcesses: BackgroundTaskDTO[],
    customer
  ) => {
    const invitationBackgrounds = backgroundProcesses.filter(
      (process) => process.type === BackgroundTaskType.DesktopInvitationSend
    );
    if (invitedUsers.some((userId) => userId === customer.id)) return false;
    if (
      invitationBackgrounds.length === 0 &&
      !invitedUsers.some((userId) => userId === customer.id)
    )
      return true;
    if (invitationBackgrounds.length > 0) {
      const customerStatus = invitationBackgrounds?.some(
        (process) => process.status === BackgroundTaskStatus.Canceled
      );
      if (customerStatus) return true;
      const latestReport = invitationBackgrounds.sort((a, b) => b.id - a.id)[0];
      if (isExpired(latestReport.createdAt)) return true;
    }
    return false;
  };

  const isCompletedInvitation = (backgroundProcesses: BackgroundTaskDTO[]) => {
    let customerStatus = false;
    const invitationBackgrounds = backgroundProcesses.filter(
      (process) => process.type === BackgroundTaskType.DesktopInvitationSend
    );
    if (invitationBackgrounds.length > 0) {
      const latestReport = invitationBackgrounds.sort((a, b) => b.id - a.id)[0];
      customerStatus =
        latestReport.status === BackgroundTaskStatus.Completed ||
        latestReport.status === BackgroundTaskStatus.InProgress;
    }

    return customerStatus;
  };

  const getReportFile = () => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeLoanStatusReportGet({
          id: brandOfficeId,
        })
        .then((respone) => {
          handleDownload(respone.data);
        }),
      {
        loading: "Downloading...",
        success: <b>File downloaded successfully.</b>,
        error: <b>Failed to download file.</b>,
      }
    );
  };

  const onSumerizeExport = (userId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeSumerizeReportGet({
          id: brandOfficeId,
          userId: userId,
        })
        .then((respone) => {
          handleDownload(respone.data);
        }),
      {
        loading: "Downloading...",
        success: <b>Sumerize report downloaded successfully.</b>,
        error: <b>Failed to download report.</b>,
      }
    );
  };

  const onTransactionReport = (customerId) => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeReportsTransactionPost({
          id: brandOfficeId,
          userId: customerId,
        })
        .then((respone) => {
          setGenratedReports((genratedReports) => [
            ...genratedReports,
            customerId,
          ]);
          handleDownload(respone.data);
        }),
      {
        loading: "Downloading...",
        success: <b>Transaction report downloaded successfully.</b>,
        error: (error) => (
          <b>
            {error.response && error.response.status === 404
              ? "The user doesn't have an account to export the report."
              : error.response && error.response.status === 500
              ? "Server not responding"
              : "Failed to download the report."}
          </b>
        ),
      }
    );
  };

  return (
    <div className="mt-5 mb-5">
      <div className="flex gap-[40px] justify-center ">
        <div className="flex flex-col gap-3 items-center justify-center p-2 text-xs ">
          {alphabetDisplay.map((letter) => (
            <div key={letter} className="text-[13px] font-semibold">
              {letter}
            </div>
          ))}
        </div>
        <div className="max-h-[1000px] w-full min-h-[500px] min-w-[800px]">
          <div className="flex justify-between items-center">
            <span className="text-2xl font-semibold">All Customers</span>
            <Button
              w=""
              h="40px"
              px="18px"
              borderRadius="0.375rem"
              onClick={() => getReportFile()}
            >
              <img src={ExportLoanStatusIcon} alt="" />
              <span>Loan Status Report</span>
            </Button>
          </div>
          <div
            ref={containerRef}
            className="w-full min-w-[800px] min-h-[400px] max-h-[900px] overflow-auto"
          >
            <table className=" border-collapse border-white/40 mt-4 table-auto w-full min-w-[800px] text-sm">
              <thead className="customer-table">
                {/*TODO: <th className=" font-bold w-1/3 p-2"> */}
                <tr>
                  <th className=" font-bold w-5/12 p-2">
                    <button
                      className="flex items-center space-x-1"
                      onClick={() => {
                        setIsAscending(orderBy !== 'name' ? true : !isAscending);
                        setOrderBy('name');
                      }}
                    >
                      <span>Customer Name</span>
                      {
                        orderBy === 'name' && <img src={FilterIcon} alt="" className={isAscending ? 'rotate-180' : ''} />
                      }
                    </button>
                  </th>
                  <th className="font-bold py-2 w-[130px] text-center">
                    <button className="flex items-center space-x-1" onClick={() => {
                      setIsAscending(orderBy !== 'accounts' ? true : !isAscending);
                      setOrderBy('accounts');
                    }}>
                      <span>Accounts</span>
                      {
                          orderBy === 'accounts' && <img src={FilterIcon} alt="" className={isAscending ? 'rotate-180' : ''} />
                      }
                    </button>
                  </th>
                  <th className="font-bold w-28 text-center">Invitation</th>
                  {/* <th className=" font-bold pl-7 w-80">Reports</th> */}
                  <th className=" font-bold pl-8 w-[160px]">Reports</th>
                  <th className="font-bold py-2 px-4 w-1/5 right-0">
                    Financials
                  </th>
                </tr>
              </thead>

              <tbody className="w-full ">
                {visibleData.map((customer) => {
                  return (
                    <tr key={customer.id} className=" border-b">
                      <td>
                        <div className="p-2 flex item-center space-x-2">
                          <img
                            className="w-5 h-5"
                            src={
                              customer?.dineiroApp &&
                              customer.dineiroApp === DineiroApp.Dosh
                                ? AppUser
                                : DesktopUser
                            }
                            alt=""
                          />
                          <span>
                            {customer.firstName + " " + customer.lastName}
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <span>{customer.activeAccountsCount}</span>
                      </td>
                      <td>
                        <div className="flex items-center border-l-[1px] border-white/40 px-4 my-1 min-h-[30px] gap-1">
                          {customer?.dineiroApp &&
                          customer.dineiroApp === DineiroApp.Portal ? (
                            <div className="flex items-center gap-1">
                              {isOutOfDateInvitation(
                                customer.backgroundTasks,
                                customer
                              ) ? (
                                <Button
                                  h="30px"
                                  w="30px"
                                  px="5px"
                                  py="5px"
                                  borderRadius="5px"
                                  fontSize="10px"
                                  onClick={() => onInvitation(customer.id)}
                                >
                                  <img src={EmailIcon} alt="" />
                                </Button>
                              ) : invitedUsers.includes(customer.id) ||
                                isCompletedInvitation(
                                  customer.backgroundTasks
                                ) ? (
                                <button className=" invitation-button min-h-[30px] w-[30px]">
                                  <img src={SentIcon} alt="" />
                                </button>
                              ) : (
                                <></>
                              )}

                              {customer &&
                              customer?.desktopInvitationsCount > 0 ? (
                                <div className="  text-center">
                                  <span
                                    style={{ fontFamily: "Poppins" }}
                                  >{`(${customer.desktopInvitationsCount})`}</span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      {/* <td id="annualReports">
                        {isOutOfDateReport(
                          customer.backgroundTasks,
                          customer
                        ) ? (
                          <Button
                            h="20px"
                            w="76px"
                            px="16px"
                            borderRadius="0.375rem"
                            fontSize="12px"
                            onClick={() => onOrder(customer.id)}
                          >
                            ORDER
                          </Button>
                        ) : isInprocess(
                            customer.backgroundTasks,
                            customer.id
                          ) ? (
                          <button className="report-button">Ordered</button>
                        ) : isCompleted(customer.backgroundTasks) ? (
                          <button className="report-button">
                            <img src={SentIcon} alt="" />
                            Sent
                          </button>
                        ) : null}
                      </td> */}

                      <td>
                        <div className="flex item-center justify-center space-x-2 border-x border-white/40 my-1 px-4">
                          {/* TODO: Must open when testing finish */}
                          {genratedReports.includes(customer.id) ? (
                            <button className=" transactions-report px-[10px] py-[5px]">
                              Dowloaded
                            </button>
                          ) : !customer.isAllowedTransactionReport ? (
                            <button className="transactions-report">
                              <img src={SentIcon} alt="" />
                              Dowloaded
                            </button>
                          ) : (
                            <Button
                              h="30px"
                              w="95px"
                              px="10px"
                              py="5px"
                              borderRadius="3px"
                              fontSize="10px"
                              onClick={() => onTransactionReport(customer.id)}
                            >
                              Transactions
                            </Button>
                          )}

                          {/* <Button
                            h="30px"
                            w="95px"
                            px="10px"
                            py="5px"
                            borderRadius="3px"
                            fontSize="10px"
                            // onClick={() => onOrder(customer.id)}
                          >
                            Transactions
                          </Button> */}
                          {isOutOfDateReport(
                            customer.backgroundTasks,
                            customer
                          ) ? (
                            <Button
                              h="30px"
                              w="100px"
                              px="10px"
                              py="5px"
                              borderRadius="3px"
                              fontSize="10px"
                              onClick={() => onOrder(customer.id)}
                            >
                              Annual Report
                            </Button>
                          ) : isInprocess(
                              customer.backgroundTasks,
                              customer.id
                            ) ? (
                            <button className="annual-report">Ordered</button>
                          ) : isCompleted(customer.backgroundTasks) ? (
                            <button className="annual-report">
                              <img src={SentIcon} alt="" />
                              Sent
                            </button>
                          ) : null}
                          {/* <Button
                            h="30px"
                            w="80px"
                            px="10px"
                            py="5px"
                            borderRadius="3px"
                            fontSize="10px"
                            // onClick={() => onOrder(customer.id)}
                          >
                            Fact Find
                          </Button> */}
                        </div>
                      </td>
                      <td className=" py-1 px-4">
                        <div className=" flex gap-[30px]">
                          <button
                            className="flex items-center justify-center w-5 h-5"
                            onClick={() =>
                              onAssetsTab(
                                `${customer.firstName} ${customer.lastName}`,
                                customer.id,
                                CUSTOMER_TABS.assets
                              )
                            }
                          >
                            <img
                              className="w-5 h-5"
                              src={FinancialIcon}
                              alt=""
                            />
                          </button>
                          <button
                            className="flex justify-center items-center w-5 h-5"
                            onClick={() =>
                              onAssetsTab(
                                `${customer.firstName} ${customer.lastName}`,
                                customer.id,
                                CUSTOMER_TABS.goalsInsights
                              )
                            }
                          >
                            <img className="w-5 h-5" src={BuldIcon} alt="" />
                          </button>
                          <button
                            className="flex items-center justify-center w-5 h-5"
                            onClick={() => onSumerizeExport(customer.id)}
                          >
                            <img className="w-5 h-5" src={ExportIcon} alt="" />
                          </button>
                          <button
                            className="flex items-center justify-center w-5 h-5"
                            onClick={() =>
                              onAssetsTab(
                                `${customer.firstName} ${customer.lastName}`,
                                customer.id,
                                CUSTOMER_TABS.documents
                              )
                            }
                          >
                            <img
                              className="w-5 h-5"
                              src={DocumentIcon}
                              alt=""
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div ref={loaderRef}>{isLoading && <Spinner />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
