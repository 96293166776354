import { useState, useEffect } from "react";
import { UserDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useGetCustomerById = ({ officeId, userId }) => {
  const [userInfoById, setUserInfoById] = useState<UserDTO>();
  const [shouldRefetchGetUserInfo, refetchGetUserInfo] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;

  const GetCustomerByIdComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersDetailsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data) {
                  setUserInfoById(response.data);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, shouldRefetchGetUserInfo]);

    return null;
  };

  GetCustomerByIdComponent();

  return {
    userInfoById: userInfoById,
    shouldRefetchGetUserInfo: shouldRefetchGetUserInfo,
    refetchGetUserInfo,
  };
};

export default useGetCustomerById;
