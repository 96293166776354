import Button from "../common/button/Button";
import { useForm } from "react-hook-form";
import { VALIDATION } from "../../constants/constants";
import { getBrandOfficesApi } from "../../services/api-service";
import { useParams } from "react-router-dom";
import useBrokers from "../../hooks/useBrokers";
import toast, { Toaster } from "react-hot-toast";
import useBrandInfo from "../../hooks/useBrandInfo";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import { getLocalStorageElement, reformatPhoneNumber } from "../../utils/utils";
import { DineiroApp } from "@dineiro/dineiro-sdk-mobile";

const AddUserForm = ({ refetch, shouldRefetch }) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { officeid } = useParams();
  const { role, userId } = useUserInfoTK(storedIdToken);
  const { brokers } = useBrokers({
    brandOfficeId: officeid,
    userRole: role,
  });
  const { brandInfo } = useBrandInfo({ brandOfficeId: officeid });

  const onSubmit = async (data) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    try {
      const response = await brandApi.brandOfficeCustomersPost({
        id: parseInt(officeid),
        brandOfficeCustomersPostViewModel: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.emailCustomer,
          refererSource: "temple",
          subReferer: "temple",
          phoneNumber: reformatPhoneNumber(data.phone),
          brokerId: role === "broker" ? userId : data.broker,
          filter1Value: data.filter1,
          filter2Value: data.filter2,
          filter3Value: data.filter3,
          dineiroApp: data.dineiroApp,
        },
      });

      if (response.data.id) {
        refetch(!shouldRefetch);
        toast.success(
          `Successfully added user ${data.firstName} ${data.lastName}.`
        );
      }
    } catch (error) {
      error.response.json().then((x: any) => {
        toast.error(x.errors[""][0]);
      });
    }
  };

  return (
    <div className="mt-5">
      {(role === "office-manager" ||
        role === "broker" ||
        role === "brand-manager") && (
        <div>
          <div className="flex justify-center">
            <span className=" text-4xl text-center font-semibold my-5">
              Add New User
            </span>
          </div>

          <form
            className=" max-w-2xl mx-auto "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-2 gap-2">
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  {...register("firstName", {
                    required: VALIDATION.required.firstName,
                  })}
                />
                {errors.firstName && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.firstName.message.toString()}
                  </span>
                )}
              </div>

              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  {...register("lastName", {
                    required: VALIDATION.required.lastName,
                  })}
                />

                {errors.lastName && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.lastName.message.toString()}
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              {brandInfo && brandInfo.filterConfig1 && (
                <div className="mt-2 flex-grow" style={{ flexBasis: "33.33%" }}>
                  <select
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id="filter1"
                    {...register("filter1", {
                      required: VALIDATION.required.regular,
                    })}
                  >
                    <option value="">{brandInfo.filterConfig1.name}</option>
                    {brandInfo.filterConfig1.values ? (
                      brandInfo.filterConfig1.values.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <option value="">Options for Filter 1</option>
                    )}
                  </select>
                  {errors.filter1 && (
                    <span className="px-2 text-red-400 text-sm font-bold">
                      {errors.filter1.message.toString()}
                    </span>
                  )}
                </div>
              )}
              {brandInfo && brandInfo.filterConfig2 && (
                <div className="mt-2 flex-grow" style={{ flexBasis: "33.33%" }}>
                  <select
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id="filter2"
                    {...register("filter2", {
                      required: VALIDATION.required.regular,
                    })}
                  >
                    <option value="">{brandInfo.filterConfig2.name}</option>
                    {brandInfo.filterConfig2.values ? (
                      brandInfo.filterConfig2.values.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <option value="">Options for Filter 1</option>
                    )}
                  </select>
                  {errors.filter2 && (
                    <span className="px-2 text-red-400 text-sm font-bold">
                      {errors.filter2.message.toString()}
                    </span>
                  )}
                </div>
              )}
              {brandInfo && brandInfo.filterConfig3 && (
                <div className="mt-2 flex-grow" style={{ flexBasis: "33.33%" }}>
                  <select
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id="filter3"
                    {...register("filter3", {
                      required: VALIDATION.required.regular,
                    })}
                  >
                    <option value="">{brandInfo.filterConfig1.name}</option>
                    {brandInfo.filterConfig3.values ? (
                      brandInfo.filterConfig3.values.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <option value="">Options for Filter 1</option>
                    )}
                  </select>

                  {errors.filter3 && (
                    <span className="px-2 text-red-400 text-sm font-bold">
                      {errors.filter3.message.toString()}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className="mb-1 grid grid-cols-2 gap-2 my-2">
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id="phone"
                  placeholder="Mobile Number"
                  {...register("phone", {
                    required: VALIDATION.required.phone,
                    pattern: VALIDATION.phone,
                  })}
                />
                {errors.phone && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.phone.message.toString()}
                  </span>
                )}
              </div>
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                  type="text"
                  id="email"
                  placeholder="Email Address"
                  {...register("emailCustomer", {
                    required: VALIDATION.required.emailCustomer,
                    pattern: VALIDATION.email,
                  })}
                />
                {errors.emailCustomer && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.emailCustomer.message.toString()}
                  </span>
                )}
              </div>
            </div>
            {(role === "office-manager" || role === "brand-manager") && (
              <div className="mb-1 my-2">
                <select
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                  id="broker"
                  {...register("broker", {
                    required: VALIDATION.required.selectBroker,
                  })}
                >
                  <option value="">Select Broker</option>
                  {brokers.map((broker) => {
                    return (
                      <option value={broker.id} key={broker.id}>
                        {broker.firstName + " " + broker.lastName}
                      </option>
                    );
                  })}
                </select>
                {errors.broker && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.broker.message.toString()}
                  </span>
                )}
              </div>
            )}

            <div className=" flex justify-center gap-5 mt-4">
              <label htmlFor="field-app">
                <input
                  {...register("dineiroApp", {
                    required: VALIDATION.required.customerType,
                  })}
                  type="radio"
                  value={DineiroApp.Dosh}
                  id="field-app"
                  className=" mr-2 border-white bg-transparent focus:ring-0"
                />
                App User
              </label>
              <label htmlFor="field-desktop">
                <input
                  {...register("dineiroApp")}
                  type="radio"
                  value={DineiroApp.Portal}
                  id="field-desktop"
                  className=" mr-2 border-white bg-transparent focus:ring-0"
                />
                Desktop Only User
              </label>
            </div>
            <div className="flex justify-center mt-2">
              {errors.dineiroApp && (
                <span className="text-red-400 text-sm font-bold px-2">
                  {errors.dineiroApp.message.toString()}
                </span>
              )}
            </div>

            <div className="flex justify-center my-5">
              <Button px="18px" borderRadius="8px">
                Add User
              </Button>
            </div>
          </form>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};
export default AddUserForm;
