import React, { useEffect } from "react";
import { fastlink } from "../../../environment";
import useGetYodleeToken from "../../../hooks/useGetYodleeToken";
import { useNavigate, useParams } from "react-router-dom";
import { getLocalStorageElement } from "../../../utils/utils";
import {
  ACCOUNT_SETUP_TABS,
  CUSTOMER_TABS,
  PAGE_URL,
} from "../../../constants/constants";
import toast from "react-hot-toast";
import { getBrandOfficesApi } from "../../../services/api-service";

declare global {
  interface Window {
    fastlink: any;
  }
}

const FastLinkPage: React.FC<{
  shouldRefetchGet?;
  refetchGet?;
  accountUpdateds: number[];
  setAccountUpdateds;
  refetchGetUserInfo;
  shouldRefetchGetUserInfo;
}> = ({
  accountUpdateds,
  setAccountUpdateds,
  shouldRefetchGet,
  refetchGet,
  refetchGetUserInfo,
  shouldRefetchGetUserInfo,
}) => {
  const {
    brand,
    brandid,
    officeid,
    tab,
    id,
    customername,
    subtab,
    providerAccountId,
  } = useParams();
  const { yodleeToken } = useGetYodleeToken({
    officeId: officeid,
    userId: id,
  });
  const navigate = useNavigate();
  const checkSubTab = subtab === "fastLink";
  const onSubmit = async () => {
    const storedIdToken = getLocalStorageElement("idToken");
    const brandApi = getBrandOfficesApi(storedIdToken);

    try {
      await brandApi.brandOfficeCustomersAccountsLinkPost({
        id: parseInt(officeid),
        userId: id,
      });
      toast.success(`Link account successfully .`);
    } catch (error) {
      toast.error("Failed to link account data.");
    }
  };
  const navigateToMangeAccounts = () => {
    refetchGetUserInfo(!shouldRefetchGetUserInfo);
    let navigateTab = `${PAGE_URL.HOME.replace(":brand", brand)
      .replace(":brandid", brandid)
      .replace(":office", customername.toLowerCase().replace(/\s/g, "-"))
      .replace(":officeid", officeid)}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
      ":customername",
      customername ? customername.replace(/\s/g, "-") : "undefind"
    )
      .replace(":id", id)
      .replace(":tab?", CUSTOMER_TABS.accountSetup)
      .replace(":subtab?", ACCOUNT_SETUP_TABS.manageAccounts)
      .replace("/:providerAccountId?", "")}`;
    navigate(navigateTab);
  };

  const onFinishSetupConsent = async () => {
    await onSubmit();
    refetchGet(!shouldRefetchGet);
    if (!accountUpdateds.includes(parseInt(providerAccountId))) {
      setAccountUpdateds((accountUpdateds) => [
        ...accountUpdateds,
        parseInt(providerAccountId),
      ]);
    }
    navigateToMangeAccounts();
  };

  useEffect(() => {
    if (yodleeToken) {
      const script = document.createElement("script");
      script.src = "https://cdn.yodlee.com/fastlink/v4/initialize.js";
      script.async = true;
      script.id = "fask-link-script";
      document.head.appendChild(script);

      script.onload = () => {
        function accountStatus(data) {
          if (data.status === "USER_CLOSE_ACTION") {
            navigateToMangeAccounts();
            return;
          }
          if (data.action == "exit") {
            if (data?.sites && data?.sites[0]?.status == "SUCCESS") {
              onFinishSetupConsent();
              return;
            }
          }
        }

        function errorHandler(data) {
          const code = data.code;
          const title = data.title;
          const message = data.message;
          if (
            code === "E405" ||
            code.startsWith("E7") ||
            code.startsWith("E8") ||
            code.startsWith("E9")
          ) {
            console.log("Deep-Link Error");
          } else if (code.startsWith("E6")) {
            console.log("Flow Invocation Error");
          } else if (code.startsWith("E4")) {
            console.log("Add Ons Error");
          } else if (code.startsWith("E1")) {
            console.log("Functional Error");
          } else if (code.startsWith("N1")) {
            console.log("Technical Error");
          } else if (code.startsWith("N4")) {
            console.log("Login Error");
          } else if (code.startsWith("N5")) {
            console.log("Session Error");
          } else if (code.startsWith("N7")) {
            console.log("Server Error");
          } else {
            console.log("Unknown Error");
          }
        }
        if (providerAccountId) {
          window.fastlink.open(
            {
              fastLinkURL: fastlink,
              accessToken: `Bearer ${yodleeToken.accessToken}`,
              params: {
                configName: "V2Build",
                flow: "edit",
                providerAccountId: providerAccountId,
              },
              onSuccess: function (data) {},
              onError: function (data) {
                const fnToCall = data.fnToCall;
                if (fnToCall === "accountStatus") {
                  accountStatus(data);
                } else if (fnToCall === "errorHandler") {
                  errorHandler(data);
                } else if (fnToCall === "launchErrorHandler") {
                  errorHandler(data);
                }
              },
              onClose: function (data) {
                accountStatus(data);
              },
              onEvent: function (data) {},
            },
            "container-fastlink"
          );
        } else
          window.fastlink.open(
            {
              fastLinkURL: fastlink,
              accessToken: `Bearer ${yodleeToken.accessToken}`,
              params: {
                configName: "V2Build",
              },
              onSuccess: function (data) {
                accountStatus(data);
              },
              onError: function (data) {
                const fnToCall = data.fnToCall;
                if (fnToCall === "accountStatus") {
                  accountStatus(data);
                } else if (fnToCall === "errorHandler") {
                  errorHandler(data);
                } else if (fnToCall === "launchErrorHandler") {
                  errorHandler(data);
                }
              },
              onClose: function (data) {
                accountStatus(data);
              },
              onEvent: function (data) {},
            },
            "container-fastlink"
          );

        return () => {};
      };

      return () => {
        const script = document.getElementById("fask-link-script");
        if (script) {
          script.parentNode.removeChild(script);
        }
      };
    }
  }, [yodleeToken, providerAccountId, checkSubTab]);

  return (
    <div className="grid justify-center">
      <div
        id="container-fastlink"
        className="w-[750px] max-h-[2000px] border-none"
      >
        <div style={{ textAlign: "center" }}></div>
      </div>
    </div>
  );
};

export default FastLinkPage;
