import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { getLocalStorageElement } from "../utils/utils";
import { isDeveloper } from "../environment";

const useUserInfoTK = (token = "") => {
  const [userName, setName] = useState("");
  const [userId, setId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [role, setUserRole] = useState("");
  const [brandOfficeId, setBrandOfficeId] = useState(0);
  const storedIdToken = getLocalStorageElement("idToken");

  const UserInfoTKComponent = () => {
    useEffect(() => {
      if (token === "") {
        const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
        if (user && storedIdToken) {
          const fetchData = async () => {
            try {
              setName(user["name"]);
              setEmailAddress(user["email"]);
              setBrandOfficeId(user["brandOfficeId"]);
              setId(user["user_id"]);
              const roles = user["roles"].filter(
                (role) => role["brandOfficeId"] === user["brandOfficeId"]
              );

              let highestRole = "customer";
              if (roles.some((role) => role?.role.name === "brand-manager"))
                highestRole = "brand-manager";
              else if (
                roles.some((role) => role?.role.name === "office-manager")
              )
                highestRole = "office-manager";
              else if (roles.some((role) => role?.role.name === "broker"))
                highestRole = "broker";

              setUserRole(highestRole);
            } catch (err: any) {
              console.log(err);
            }
          };
          fetchData();
        } else {
          if (isDeveloper) console.log("No current user.");
        }
      } else {
        const user = jwtDecode(token);
        try {
          setName(user["name"]);
          setEmailAddress(user["email"]);
          setBrandOfficeId(user["brandOfficeId"]);
          setId(user["user_id"]);
          const roles = user["roles"].filter(
            (role) => role["brandOfficeId"] === user["brandOfficeId"]
          );

          let highestRole = "customer";
          if (roles.some((role) => role?.role.name === "brand-manager"))
            highestRole = "brand-manager";
          else if (roles.some((role) => role?.role.name === "office-manager"))
            highestRole = "office-manager";
          else if (roles.some((role) => role?.role.name === "broker"))
            highestRole = "broker";

          setUserRole(highestRole);
        } catch (err: any) {
          console.log(err);
        }
      }
    }, [storedIdToken, token]);

    return null;
  };
  UserInfoTKComponent();

  return {
    userName: userName,
    brandOfficeId: brandOfficeId,
    emailAddress: emailAddress,
    role: role,
    userId: userId,
  };
};

export default useUserInfoTK;
