import {
  BrandOfficeApi,
  FactFindFactFindDTO,
  Section,
} from "@dineiro/dineiro-sdk-mobile";
import {
  FACTFIND_LIST_TABS,
  FACTFIND_TABS,
} from "../../../constants/constants";
import { comparePositions } from "../../../utils/utils";
import toast from "react-hot-toast";

export async function updateFactFindSection(
  brandApi: BrandOfficeApi,
  factFind: FactFindFactFindDTO,
  officeid,
  userId,
  nextSection
) {
  const factFindLastSection =
    factFind?.lastSection === Section.Business ||
    factFind?.lastSection === Section.Trust
      ? FACTFIND_TABS.companytrust
      : factFind?.lastSection.toLowerCase();
  const factFindNextSection = nextSection.toLowerCase();
  if (
    comparePositions(
      FACTFIND_LIST_TABS,
      factFindLastSection,
      factFindNextSection
    )
  )
    try {
      await brandApi.brandOfficeCustomersFactFindPut({
        id: parseInt(officeid),
        userId: userId,
        fullFactFindPutViewModel: {
          lastSection: nextSection,
        },
      });
    } catch (error) {
      toast.error("Failed to save working section.");
    }
}
