import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  comparePositions,
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { useEffect, useState } from "react";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import EmployeeForm from "../../../components/fact-find/EmploymentForm.tsx/EmployementForm";
import {
  FactFindEmploymentSectionDTO,
  EmploymentSectionViewModel,
  EmploymentType,
  FactFindFactFindDTO,
  FullFactFindEmploymentPostViewModel,
  SelfEmployedDataViewModel,
  BrandOfficeApi,
  Section,
  ApplyThrough,
} from "@dineiro/dineiro-sdk-mobile";
import { useNavigate, useParams } from "react-router-dom";
import { getBrandOfficesApi } from "../../../services/api-service";
import {
  FACTFIND_TABS,
  FACTFIND_LIST_TABS,
} from "../../../constants/constants";

const EmploymentSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
}> = ({ numberOfApplicants, factFind, refetchGet, shouldRefetchGet }) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindEmploymentSectionDTO[]>(
    []
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const employmentSection = { ...s.employmentSection };
          return employmentSection;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const storedIdToken = getLocalStorageElement("idToken");
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);

  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);

  const convertToBaseEmployee = (dataOfType, dataSubmitType, index) => {
    return {
      currentEmployer: dataOfType?.currentEmployer
        ? dataOfType?.currentEmployer
        : dataSubmitType?.currentEmployer,
      abn: "",
      hrPayrollContact: dataOfType?.hrPayrollContact
        ? dataOfType?.hrPayrollContact
        : dataSubmitType?.hrPayrollContact,
      phoneNumber: dataOfType?.phoneNumber
        ? dataOfType?.phoneNumber
        : dataSubmitType?.phoneNumber,
      occupation: dataOfType?.occupation
        ? dataOfType?.occupation
        : dataSubmitType?.occupation,
      timeAtCurrentJob: dataOfType?.timeAtCurrentJob
        ? dataOfType?.timeAtCurrentJob
        : dataSubmitType?.timeAtCurrentJob,
    };
  };

  const convertDataToSubmit = (data) => {
    let dataConverted: EmploymentSectionViewModel[] = [];
    if (data) {
      for (let i = 0; i < numberOfApplicants; i++) {
        let employeeTypeSubmit = data[i]?.employmentType
          ? data[i]?.employmentType
          : dataSubmit[i].employmentType;
        let dataMap = data[i]
          ? ({
              applicantId: factFind.applicants[i].id,
              employmentType: employeeTypeSubmit,
              fullTime: null,
              partTime: null,
              casual: null,
              selfEmployed: null,
            } as FactFindEmploymentSectionDTO)
          : dataSubmit[i];

        if (employeeTypeSubmit === EmploymentType.SelfEmployed) {
          dataMap.selfEmployed =
            data[i]?.selfEmployed !== undefined
              ? ({
                  companyName:
                    data[i]?.selfEmployed?.companyName !== undefined
                      ? data[i]?.selfEmployed?.companyName
                      : dataSubmit[i]?.selfEmployed?.companyName,
                  abn:
                    data[i]?.selfEmployed?.abn !== undefined
                      ? data[i]?.selfEmployed?.abn
                      : dataSubmit[i]?.selfEmployed?.abn,
                  natureOfBusiness:
                    data[i]?.selfEmployed?.natureOfBusiness !== undefined
                      ? data[i]?.selfEmployed?.natureOfBusiness
                      : dataSubmit[i]?.selfEmployed?.natureOfBusiness,
                  yearsInOperation:
                    data[i]?.selfEmployed?.yearsInOperation !== undefined
                      ? parseInt(data[i]?.selfEmployed?.yearsInOperation) ??
                        null
                      : dataSubmit[i]?.selfEmployed?.yearsInOperation,
                  accountantName:
                    data[i]?.selfEmployed?.accountantName !== undefined
                      ? data[i]?.selfEmployed?.accountantName
                      : dataSubmit[i]?.selfEmployed?.accountantName,
                  accountantPhoneNumber:
                    data[i]?.selfEmployed?.accountantPhoneNumber !== undefined
                      ? data[i]?.selfEmployed?.accountantPhoneNumber
                      : dataSubmit[i]?.selfEmployed?.accountantPhoneNumber,
                } as SelfEmployedDataViewModel)
              : dataSubmit[i].selfEmployed;
        }
        if (employeeTypeSubmit === EmploymentType.FullTime) {
          dataMap.fullTime =
            data[i]?.fullTime !== undefined
              ? convertToBaseEmployee(
                  data[i]?.fullTime,
                  dataSubmit[i].fullTime,
                  i
                )
              : dataSubmit[i].fullTime;
          dataMap.fullTime.startedDate = data[i]?.fullTime?.startedDate
            ? new Date(data[i]?.fullTime.startedDate)
            : dataSubmit[i]?.fullTime?.startedDate
            ? new Date(dataSubmit[i]?.fullTime?.startedDate)
            : null;
        }
        if (employeeTypeSubmit === EmploymentType.Casual) {
          dataMap.casual =
            data[i]?.casual !== undefined
              ? convertToBaseEmployee(data[i]?.casual, dataSubmit[i].casual, i)
              : dataSubmit[i].casual;
          dataMap.casual.startedDate = data[i]?.casual?.startedDate
            ? new Date(data[i]?.casual.startedDate)
            : dataSubmit[i]?.casual?.startedDate
            ? new Date(dataSubmit[i]?.casual?.startedDate)
            : null;
        }
        if (employeeTypeSubmit === EmploymentType.PartTime) {
          dataMap.partTime =
            data[i]?.partTime !== undefined
              ? convertToBaseEmployee(
                  data[i]?.partTime,
                  dataSubmit[i].partTime,
                  i
                )
              : dataSubmit[i].partTime;
          dataMap.partTime.startedDate = data[i]?.partTime?.startedDate
            ? new Date(data[i]?.partTime.startedDate)
            : dataSubmit[i]?.partTime?.startedDate
            ? new Date(dataSubmit[i]?.partTime?.startedDate)
            : null;
        }
        dataConverted.push(dataMap);
      }
    }

    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.employment) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.employment?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const updateFactFindSection = async (brandApi: BrandOfficeApi) => {
    const factFindLastSection =
      factFind?.lastSection === Section.Business ||
      factFind?.lastSection === Section.Trust
        ? FACTFIND_TABS.companytrust
        : factFind?.lastSection.toLowerCase();
    const factFindNextSection =
      factFind?.applyThrough &&
      (factFind?.applyThrough === ApplyThrough.YesBusiness ||
        factFind?.applyThrough === ApplyThrough.YesTrust)
        ? FACTFIND_TABS.companytrust
        : factFind?.applyThrough && factFind?.applyThrough === ApplyThrough.No
        ? Section.Assets.toLowerCase()
        : null;
    if (
      comparePositions(
        FACTFIND_LIST_TABS,
        factFindLastSection,
        factFindNextSection
      )
    )
      try {
        const nextSection =
          factFind?.applyThrough &&
          factFind?.applyThrough === ApplyThrough.YesBusiness
            ? Section.Business
            : factFind?.applyThrough &&
              factFind?.applyThrough === ApplyThrough.YesTrust
            ? Section.Trust
            : factFind?.applyThrough &&
              factFind?.applyThrough === ApplyThrough.No
            ? Section.Assets
            : null;
        await brandApi.brandOfficeCustomersFactFindPut({
          id: parseInt(officeid),
          userId: id,
          fullFactFindPutViewModel: {
            lastSection: nextSection,
          },
        });
      } catch (error) {
        toast.error("Failed to save working section.");
      }
  };

  const onNavigateToNextSection = () => {
    const navigateTab =
      factFind?.applyThrough &&
      (factFind?.applyThrough === ApplyThrough.YesBusiness ||
        factFind?.applyThrough === ApplyThrough.YesTrust)
        ? FACTFIND_TABS.companytrust
        : factFind?.applyThrough && factFind?.applyThrough === ApplyThrough.No
        ? FACTFIND_TABS.assets
        : FACTFIND_TABS.employment;
    navigate(handleFactFindNavigate(navigateTab, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    const dataForm =
      value && value?.employment ? value?.employment : dataSubmit;
    const dataSentSubmit: FullFactFindEmploymentPostViewModel = {
      id: factFind?.id,
      employmentSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindEmploymentPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindEmploymentPostViewModel: dataSentSubmit,
      });
      toast.success(`Submit employment data successfully .`);
      setIsShowButtonEdit(true);
      setDataSubmit(dataSentSubmit.employmentSections);
      await updateFactFindSection(brandApi);
      refetchGet(!shouldRefetchGet);
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to employment address data.");
    }
  };

  const onSaveAsDraft = async () => {
    setIsShowButtonEdit(true);

    const data = getValues();
    const dataForm = data && data?.employment ? data?.employment : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    const dataSent: FullFactFindEmploymentPostViewModel = {
      id: factFind?.id,
      employmentSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };
    clearErrors();
    try {
      await brandApi.brandOfficeCustomersFactFindEmploymentPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindEmploymentPostViewModel: dataSent,
      });
      toast.success(`Save employment data successfully .`);
      setDataSubmit(dataSent.employmentSections);
    } catch (error) {
      toast.error("Failed to save employment data.");
    }
  };

  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <EmployeeForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          isSubmitted={isSubmitted}
          watch={watch}
        ></EmployeeForm>
      </div>
    ));
  };
  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default EmploymentSession;
