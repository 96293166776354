import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  checkObjectHasData,
  getLocalStorageElement,
  handleFactFindNavigate,
} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import QuestionsForm from "../../../components/fact-find/QuestionsForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  FactFindFactFindDTO,
  FactFindQuestionsSectionDTO,
  FullFactFindQuestionsPostViewModel,
  QuestionsSectionViewModel,
  Section,
} from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../../../services/api-service";
import { FACTFIND_TABS } from "../../../constants/constants";
import { updateFactFindSection } from "./ApiService";

const QuestionsSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
}> = ({ numberOfApplicants, factFind, refetchGet, shouldRefetchGet }) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindQuestionsSectionDTO>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  let dataSetup =
    factFind && factFind?.questionsSection ? factFind.questionsSection : null;

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const storedIdToken = getLocalStorageElement("idToken");
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const {
    register,
    handleSubmit,
    resetField,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);

  const convertDataToSubmit = (data) => {
    let dataConverted: QuestionsSectionViewModel = {};
    if (data) {
      dataConverted = {
        conveyancer:
          data?.conveyancer !== undefined &&
          checkObjectHasData(data?.conveyancer)
            ? data?.conveyancer
            : dataSubmit?.conveyancer,
        questionsSectionQuestions:
          data?.questions !== undefined && checkObjectHasData(data?.questions)
            ? Object.entries(data?.questions).map(([key, value], index) => {
                const numericId = parseInt(key.replace("q_", ""));
                const dataSubmitAnswer =
                  dataSubmit?.questionsSectionQuestions.find(
                    (s) => s.id === numericId
                  );

                return {
                  id: numericId,
                  answer: value ? value : dataSubmitAnswer?.answer ?? null,
                };
              })
            : dataSubmit?.questionsSectionQuestions,
      } as QuestionsSectionViewModel;
    }
    return dataConverted;
  };

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.disclaimer, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value ? value : dataSubmit;
    let dataSent: FullFactFindQuestionsPostViewModel = {
      id: factFind?.id,
      questionsSection: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindQuestionsPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindQuestionsPostViewModel: dataSent,
      });
      toast.success(`Submit question section data successfully .`);
      setIsShowButtonEdit(true);
      await updateFactFindSection(
        brandApi,
        factFind,
        officeid,
        id,
        Section.Disclaimer
      );
      refetchGet(!shouldRefetchGet);
      onNavigateToNextSection();
    } catch (error) {
      toast.error("Failed to submit question section data.");
    }
  };
  const onSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindQuestionsPostViewModel = {
      id: factFind?.id,
      questionsSection: convertDataToSubmit(data),
      isSaveAndNext: false,
    };

    try {
      await brandApi.brandOfficeCustomersFactFindQuestionsPost({
        id: parseInt(officeid),
        userId: id,
        fullFactFindQuestionsPostViewModel: dataSent,
      });
      toast.success(`Save question data successfully .`);
      refetchGet(!shouldRefetchGet);
      setIsShowButtonEdit(true);
    } catch (error) {
      toast.error("Failed to submit question section data.");
    }
  };

  return (
    <div className="mt-5">
      <div>
        <div className="tab-content tab-space">
          <div className={"block"}>
            <QuestionsForm
              register={register}
              handleSubmit={handleSubmit}
              onFinalSubmit={onSubmit}
              dataSubmit={dataSubmit}
              isShowButtonEdit={isShowButtonEdit}
              setIsShowButtonEdit={setIsShowButtonEdit}
              errors={errors}
              control={control}
              resetField={resetField}
              isSubmitted={isSubmitted}
            ></QuestionsForm>
          </div>
        </div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm ${
          isSubmitted ? "hidden" : ""
        }`}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default QuestionsSession;
