import { useState, useEffect } from "react";
import { AccountDTO } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useGetAccounts = ({ officeId, userId }) => {
  const [accounts, setAccountss] = useState<AccountDTO[]>([]);
  const [shouldRefetchGetAccounts, refetchGetAccounts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;

  const UserGetAccountsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersAccountsGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data.length > 0) {
                  setAccountss(response.data);
                }
              });
            setIsLoading(false);
          } catch (err: any) {
            console.log(err);
            setIsLoading(false);
          }
        };
        setIsLoading(true);
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, shouldRefetchGetAccounts]);

    return null;
  };

  UserGetAccountsComponent();

  return {
    accounts: accounts,
    shouldRefetchGetAccounts: shouldRefetchGetAccounts,
    refetchGetAccounts,
    isLoading,
  };
};

export default useGetAccounts;
