import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLocalStorageElement } from "../utils/utils";
import { PAGE_URL } from "../constants/constants";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { FirebaseConfig } from "../environment";

export interface RoleBaseGuardProps {
  children: ReactNode;
}

const RefreshToken: FC<RoleBaseGuardProps> = ({ children }) => {
  const [spinner, setSpinner] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const storedReFreshToken = getLocalStorageElement("reFreshToken");
  const navigate = useNavigate();
  const param = useParams();

  const refreshToken = async () => {
    try {
      const url = `https://securetoken.googleapis.com/v1/token?key=${FirebaseConfig.apiKey}`;
      const response = await axios.post(
        url,
        {
          grant_type: "refresh_token",
          refresh_token: storedReFreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("idToken", response.data?.access_token);
      localStorage.setItem("reFreshToken", response.data?.refresh_token);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.log(error.response.data.error.message);
      } else {
        console.log("An unknown error occurred");
      }
    }
  };
  useEffect(() => {
    const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
    if (user && storedIdToken) {
      const fetchData = async () => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        let exp = user["exp"];
        //TODO: Manage token when user close browser more than 30m
        // if (currentTimestamp - 30 * 60 >= exp) {
        //   alert("Your session has expired. Please log in again.");
        //   localStorage.setItem("idToken", "");
        //   localStorage.setItem("reFreshToken", "");
        //   dispatch(removeAccessToken());
        //   return param.brandid && param.officeid
        //     ? navigate(
        //         PAGE_URL.LOGIN.replace(":brand", param.brand)
        //           .replace(":brandid", param.brandid)
        //           .replace(":office", param.office)
        //           .replace(":officeid", param.officeid)
        //       )
        //     : navigate("/");
        // } else
        if (currentTimestamp + 5 * 60 >= exp) {
          await refreshToken();
        }
      };
      setTimeout(fetchData, 0);
    } else {
      console.log("No current user.");
    }
  });

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (storedIdToken === "") {
        return navigate(
          PAGE_URL.LOGIN.replace(":brand", param.brand)
            .replace(":brandid", param.brandid)
            .replace(":office", param.office)
            .replace(":officeid", param.officeid)
        );
      }
      setSpinner(false);
    }, 1500);
  }, [storedIdToken]);
  return (
    <>
      <>{children}</>
    </>
  );
};

export default RefreshToken;
