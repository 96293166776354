import { useState, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PAGE_URL } from "../constants/constants";
import useUserInfoTK from "../hooks/useUserInfoTK";
import useBrandInfo from "../hooks/useBrandInfo";
import { getLocalStorageElement } from "../utils/utils";

const HomePage = (props) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const param = useParams();
  const { role, brandOfficeId, userName, userId } =
    useUserInfoTK(storedIdToken);
  let officeId = parseInt(param.officeid);
  const { brandInfo, officeName, id } = useBrandInfo({
    brandOfficeId: param.officeid,
  });
  const [accessible, setAccessible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (storedIdToken === "") {
      return navigate(
        PAGE_URL.LOGIN.replace(":brand", param.brand)
          .replace(":brandid", param.brandid)
          .replace(":office", param.office)
          .replace(":officeid", param.officeid)
      );
    }
  }, [storedIdToken, brandInfo]);

  useEffect(() => {
    if (storedIdToken && brandInfo !== undefined) {
      if (parseInt(param.brandid) !== brandInfo.id) {
        setAccessible(false);
      } else {
        if (officeId !== brandOfficeId && brandOfficeId !== 0) {
          setAccessible(false);
        } else setAccessible(true);
      }
    }

    if (accessible)
      if (storedIdToken && brandInfo) {
        if (role === "marketer") {
          navigate(
            `${PAGE_URL.HOME.replace(
              ":brand",
              brandInfo.name.toLowerCase().replace(/\s/g, "-")
            )
              .replace(":brandid", brandInfo.id.toString())
              .replace(":office", officeName.toLowerCase().replace(/\s/g, "-"))
              .replace(":officeid", id.toString())}/${PAGE_URL.ADMIN_CONTENT}`
          );
        } else if (role === "customer") {
          navigate(
            `${PAGE_URL.HOME.replace(
              ":brand",
              brandInfo.name.toLowerCase().replace(/\s/g, "-")
            )
              .replace(":brandid", brandInfo.id.toString())
              .replace(":office", officeName.toLowerCase().replace(/\s/g, "-"))
              .replace(
                ":officeid",
                id.toString()
              )}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
              ":customername",
              userName ? userName.replace(/\s/g, "-") : "undefind"
            ).replace(":id", userId)}`
          );
        } else if (role !== "")
          navigate(
            `${PAGE_URL.HOME.replace(
              ":brand",
              brandInfo.name.toLowerCase().replace(/\s/g, "-")
            )
              .replace(":brandid", brandInfo.id.toString())
              .replace(":office", officeName.toLowerCase().replace(/\s/g, "-"))
              .replace(":officeid", id.toString())}/${PAGE_URL.CUSTOMERS}`
          );
      }
  }, [storedIdToken, brandInfo]);
  return <Outlet></Outlet>;
};

export default HomePage;
