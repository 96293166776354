import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../constants/constants";
import useBrandInfo from "../hooks/useBrandInfo";
import {
  getStoredProps,
  setStoredProps,
} from "../components/common/change-themes/styles-component";
import Spinner from "../components/common/spinner/Spinner";
import useUserInfoTK from "../hooks/useUserInfoTK";
import { getLocalStorageElement } from "../utils/utils";

const IndexComponent = (props) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [spinner, setSpinner] = useState(false);
  const { brandOfficeId, role, userId, userName } =
    useUserInfoTK(storedIdToken);
  const { id, officeName, brandInfo } = useBrandInfo({ brandOfficeId });

  const storedProps = getStoredProps();

  const setStored = () => {
    localStorage.removeItem("styledComponentProps");
    if (brandInfo.isWhiteLabel) {
      setStoredProps({
        ...storedProps,
        topLeftColor: brandInfo.style.topLeftColor,
        bottomRightColor: brandInfo.style.bottomRightColor,
        buttonColor: brandInfo.style.buttonColor,
        logoUrl: brandInfo.style.logoUrl,
        portalAccountSetupImageUrl: brandInfo.style.portalAccountSetupImageUrl,
      });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (storedIdToken === "")
        return navigate("/dosh/1/dosh-head-office/1/login");
      if (storedIdToken && brandInfo) {
        try {
          setStored();
        } catch (err) {
        } finally {
          if (role === "marketer") {
            navigate(
              `${PAGE_URL.HOME.replace(
                ":brand",
                brandInfo.name.toLowerCase().replace(/\s/g, "-")
              )
                .replace(":brandid", brandInfo.id.toString())
                .replace(
                  ":office",
                  officeName.toLowerCase().replace(/\s/g, "-")
                )
                .replace(":officeid", id.toString())}/${PAGE_URL.ADMIN_CONTENT}`
            );
          } else if (role === "customer") {
            navigate(
              `${PAGE_URL.HOME.replace(
                ":brand",
                brandInfo?.name.toLowerCase().replace(/\s/g, "-")
              )
                .replace(":brandid", brandInfo.id.toString())
                .replace(
                  ":office",
                  officeName.toLowerCase().replace(/\s/g, "-")
                )
                .replace(
                  ":officeid",
                  id.toString()
                )}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
                ":customername",
                userName ? userName.replace(/\s/g, "-") : "undefind"
              ).replace(":id", userId)}`
            );
          } else
            navigate(
              PAGE_URL.HOME.replace(
                ":brand",
                brandInfo.name.toLowerCase().replace(/\s/g, "-")
              )
                .replace(":brandid", brandInfo.id.toString())
                .replace(
                  ":office",
                  officeName.toLowerCase().replace(/\s/g, "-")
                )
                .replace(":officeid", id.toString())
            );

          setSpinner(false);
        }
      }
    }, 1200);
  }, [storedIdToken, brandInfo]);

  return <>{spinner && <Spinner></Spinner>}</>;
};

export default IndexComponent;
