import { useState, useEffect } from "react";
import { getBrandOfficesApi } from "../services/api-service";
import { BinaryFileDTO } from "@dineiro/dineiro-sdk-mobile";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useDocuments = ({ officeId, userId, query = "" }) => {
  const [files, setFiles] = useState<BinaryFileDTO[]>([]);
  const [folders, setFolders] = useState<BinaryFileDTO[]>([]);
  const [shouldRefetch, refetch] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;

  const UserDocumentsComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeCustomersFilesGet({
                id: officeId,
                userId: userId,
                q: query,
              })
              .then((response) => {
                const filesData = [];
                const foldersData = [];
                if (response.data.length > 0) {
                  response.data.map((item) => {
                    if (item.isDirectory) {
                      foldersData.push(item);
                    } else {
                      filesData.push(item);
                    }
                  });
                  setFiles(filesData);
                  setFolders(foldersData);
                } else {
                  setFolders(foldersData);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, query, shouldRefetch]);

    return null;
  };

  UserDocumentsComponent();
  return {
    files: files,
    folders: folders,
    refetch,
    shouldRefetch: shouldRefetch,
  };
};

export default useDocuments;
