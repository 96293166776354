import React, { useEffect, useRef, useState } from "react";
import { getValocitysApi } from "../../../services/api-service";
import { getLocalStorageElement } from "../../../utils/utils";

interface PropertyOption {
  displayText: string;
  propertyIdentifier: string;
  suggestionType: string;
}

const AddressInput: React.FC<{
  name;
  data?: string;
  onChange?;
  watchValue?: string;
}> = ({ name, data, onChange, watchValue }) => {
  const [searchResults, setSearchResults] = useState<PropertyOption[]>([]);
  const [externalPropertyId, setExternalPropertyId] = useState(null);
  const [value, setValue] = useState(data);
  const wrapperRef = useRef(null);
  const storedIdToken = getLocalStorageElement("idToken");
  const [showResults, setShowResults] = useState(false);
  const onSearchAddress = async (address) => {
    const valocitysApi = getValocitysApi(storedIdToken);
    try {
      await valocitysApi
        .valocitySearchGet({ q: address })
        .then((rep) => {
          console.log(rep);
        })
        .catch((reason: any) => {
          reason.response.json().then((x: any) => {
            if (x.status && x.status === 300) {
              setSearchResults(x.data);
            }
          });
        });
    } catch (error) {
      setSearchResults([]);
    }
  };

  const handleInputChange = (e) => {
    setExternalPropertyId(null);
    setShowResults(true);
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const handleSelectAddress = (address: PropertyOption) => {
    setSearchResults([]);
    setShowResults(false);
    setExternalPropertyId(address.propertyIdentifier);
    setValue(address.displayText);
    onChange(address.displayText);
  };

  useEffect(() => {
    if (watchValue && watchValue?.trim().length >= 3 && showResults) {
      onSearchAddress(watchValue);
    } else {
      setShowResults(false);
      setSearchResults([]);
    }
  }, [watchValue]);

  return (
    <div className=" relative" ref={wrapperRef}>
      <input
        className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
        type="text"
        id={name}
        placeholder="Enter Address"
        value={value}
        onChange={(e) => {
          handleInputChange(e);
        }}
      />
      {showResults && searchResults.length > 0 && (
        <ul className="absolute z-20 w-full bg-white text-black border-[1px] rounded-md shadow-lg p-4 max-h-[200px] overflow-y-auto mt-1">
          {searchResults.map((item) => (
            <li
              key={item.propertyIdentifier}
              className="min-h-10 w-full border-b-[1px] border-solid border-gray-300 py-2 cursor-pointer"
              onClick={() => handleSelectAddress(item)}
            >
              {item.displayText}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressInput;
