import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import AssetsTab from "../customer-tabs/AssetsTab";
import ExpensesTab from "../customer-tabs/ExpensesTab";
import LiabilitiesTab from "../customer-tabs/LiabilitiesTab";
import GoalAndInsightsTab from "../customer-tabs/GoalAndInsightsTab";
import { useNavigate, useParams } from "react-router-dom";
import { CUSTOMER_TABS, BROKERTABS } from "../../constants/constants";
import DocumentTab from "../customer-tabs/DocumentTab";
import { StyledTabBar } from "../../components/common/change-themes/styles-component";
import useHoldings from "../../hooks/useHoldings";
import useUserSuperAccounts from "../../hooks/useUserSuperAccounts";
import Spinner from "../../components/common/spinner/Spinner";
import { AccountDTO, CONTAINEREnum, Period } from "@dineiro/dineiro-sdk-mobile";
import useUserExpenses from "../../hooks/useUserExpenses";
import useSavingGoals from "../../hooks/useSavingGoals";
import useDocuments from "../../hooks/useDocuments";
import useGetAccounts from "../../hooks/useGetAccounts";
import toast, { Toaster } from "react-hot-toast";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import {
  getLocalStorageElement,
  handleDownload,
  handleNavigate,
} from "../../utils/utils";
import { getBrandOfficesApi } from "../../services/api-service";
import useGetProperties from "../../hooks/useGetProperties";
import useGetVehicles from "../../hooks/useUserProperties";

const BrokerViewPage = () => {
  const { officeid, tab, id, customername } = useParams();
  const [openTab, setOpenTab] = React.useState(CUSTOMER_TABS.assets);
  const [period, setPeriod] = useState<Period>(Period.ThirtyDays);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [changeTypes, setChangeTypes] = useState(false);
  const [loans, setLoans] = useState<AccountDTO[]>([]);
  const [banks, setBanks] = useState<AccountDTO[]>([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const tabsRef = useRef([]);
  const storedIdToken = getLocalStorageElement("idToken");

  const { vehicles } = useGetVehicles({
    officeId: officeid,
    userId: id,
  });

  const { properties, shouldRefetchGetProperties, refetchGetProperties } =
    useGetProperties({
      officeId: officeid,
      userId: id,
    });

  const { accounts, shouldRefetchGetAccounts, refetchGetAccounts } =
    useGetAccounts({
      officeId: officeid,
      userId: id,
    });
  const { holdings, shouldRefetchGetHolding, refetchRefetchGetHolding } =
    useHoldings({
      officeId: officeid,
      userId: id,
      accounts: accounts,
    });
  const {
    supperAccounts,
    shouldRefetchSupperAccounts,
    refetchGetSupperAccount,
  } = useUserSuperAccounts({
    officeId: officeid,
    userId: id,
  });
  const { expenses } = useUserExpenses({
    officeId: officeid,
    userId: id,
    period: period,
  });

  const { savingGoals } = useSavingGoals({
    officeId: officeid,
    userId: id,
  });

  const { folders, files, shouldRefetch, refetch } = useDocuments({
    officeId: officeid,
    userId: id,
    query: searchValue,
  });
  const { role } = useUserInfoTK();

  const setActive = () => {
    setActiveTabIndex(BROKERTABS.findIndex((x) => x.id === tab));
  };

  const setTabPosition = () => {
    setOpenTab(tab);
    const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp)
      document.title = `${customername.replace(/-/g, " ")} - ${storedProp}`;
  }, []);

  useEffect(() => {
    setActive();
    setTabPosition();
  }, [activeTabIndex]);

  const handleTabClick = (index, tab) => {
    setActiveTabIndex(index);
    setOpenTab(tab);

    navigate(handleNavigate(tab.toLowerCase()));
  };

  const getLoans = () => {
    if (accounts.length > 0) {
      const loans = accounts.filter(
        (account) =>
          account.container === CONTAINEREnum.Loan ||
          account.container === CONTAINEREnum.OtherLiabilities ||
          account.container === CONTAINEREnum.CreditCard
      );
      setLoans(loans);
    }
  };
  const getBanks = () => {
    if (accounts.length > 0) {
      const banks = accounts.filter(
        (account) => account.container === CONTAINEREnum.Bank
      );
      setBanks(banks);
    }
  };

  const getExpenseReport = () => {
    const userReportApi = getBrandOfficesApi(storedIdToken);
    toast.promise(
      userReportApi
        .brandOfficeExpenseReportGet({
          id: parseInt(officeid),
          userId: id,
          period: period,
        })
        .then((respone) => {
          handleDownload(respone.data);
        }),
      {
        loading: "Downloading...",
        success: <b>File downloaded successfully.</b>,
        error: <b>Failed to download file.</b>,
      }
    );
  };

  useEffect(() => {
    getLoans();
    getBanks();
  }, [accounts]);

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      setSpinner(false);
    }, 2500);
  }, [id]);

  useEffect(() => {
    refetchGetAccounts(!shouldRefetchGetAccounts);
    refetchRefetchGetHolding(!shouldRefetchGetHolding);
    refetchGetSupperAccount(!shouldRefetchSupperAccounts);
  }, [changeTypes]);

  return (
    <div className="items-center justify-center p-3">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">FINANCIAL DATA</h6>
        <span className=" text-5xl text-center font-bold">
          {customername.replace(/-/g, " ")}
        </span>
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        {role !== "customer" && (
          <StyledTabBar className="flex-row relative mx-auto flex h-12 w-max rounded-3xl backdrop-blur-sm my-3">
            <span
              className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-3xl transition-all duration-300"
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            >
              <span className="h-full w-full rounded-3xl bg-white" />
            </span>
            {BROKERTABS.map((tab, index) => {
              const isActive = activeTabIndex === index;

              return (
                <button
                  key={index}
                  ref={(el) => (tabsRef.current[index] = el)}
                  className={`text-sm w-[180px] font-bold uppercase px-3 py-3 rounded-full block transition-all duration-300 ${
                    isActive ? "text-dark bg-white" : "text-white"
                  }`}
                  onClick={() => handleTabClick(index, tab.id)}
                >
                  {tab.name}
                </button>
              );
            })}
          </StyledTabBar>
        )}
        <div className="relative break-words min-w-[70%] mb-6 rounded">
          <div className="mx-4 my-5 flex-auto">
            <div className="tab-content tab-space">
              <div
                className={
                  openTab === CUSTOMER_TABS.assets ? "block" : "hidden"
                }
              >
                <AssetsTab
                  properties={properties}
                  supperAccounts={supperAccounts}
                  holdings={holdings}
                  banks={banks}
                  vehicles={vehicles}
                  accounts={accounts}
                  setChangeTypes={setChangeTypes}
                  changeTypes={changeTypes}
                ></AssetsTab>
              </div>
              <div
                className={
                  openTab === CUSTOMER_TABS.expenses ? "block" : "hidden"
                }
              >
                <ExpensesTab
                  expenses={expenses}
                  setPeriod={setPeriod}
                  period={period}
                  getExpenseReport={getExpenseReport}
                ></ExpensesTab>
              </div>
              <div
                className={
                  openTab === CUSTOMER_TABS.liabilities ? "block" : "hidden"
                }
              >
                <LiabilitiesTab
                  loans={loans}
                  setChangeTypes={setChangeTypes}
                  changeTypes={changeTypes}
                ></LiabilitiesTab>
              </div>
              <div
                className={
                  openTab === CUSTOMER_TABS.goalsInsights ? "block" : "hidden"
                }
              >
                <GoalAndInsightsTab
                  savingGoals={savingGoals}
                  accounts={loans}
                ></GoalAndInsightsTab>
              </div>
              <div
                className={
                  openTab === CUSTOMER_TABS.documents ? "block" : "hidden"
                }
              >
                <DocumentTab
                  folders={folders}
                  files={files}
                  shouldRefetch={shouldRefetch}
                  refetch={refetch}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                ></DocumentTab>
              </div>
            </div>
          </div>
        </div>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default BrokerViewPage;
