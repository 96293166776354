import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { FactFindLiabilitiesSectionDTO } from "@dineiro/dineiro-sdk-mobile";
import {
  RequiredValidationMessage,
  checkObjectHasData,
  checkObjectHasNull,
  isValidNumber,
} from "../../utils/utils";
import { StyledButton } from "../common/change-themes/styles-component";
import ReviewData from "../common/review-form/ReviewData";
import { NumericFormat } from "react-number-format";

const LiabilitesForm: React.FC<{
  keyValue;
  register: UseFormRegister<FieldValues>;
  handleSubmit;
  onFinalSubmit;
  setIsShowButtonEdit;
  isShowButtonEdit;
  dataSubmit?: FactFindLiabilitiesSectionDTO;
  errors;
  unregister?: UseFormUnregister<FieldValues>;
  numberOfOtherLiabilities?: number;
  isSubmitted?: boolean;
  watch: UseFormWatch<FieldValues>;
  control: Control<FieldValues, any>;
}> = ({
  keyValue,
  register,
  handleSubmit,
  onFinalSubmit,
  setIsShowButtonEdit,
  isShowButtonEdit,
  dataSubmit,
  errors,
  unregister,
  isSubmitted,
  numberOfOtherLiabilities = 2,
  watch,
  control,
}) => {
  const [numberOfLoans, setNumberOfLoans] = useState(1);
  const [numberOfCredits, setNumberOfCredits] = useState(1);
  let checkData = dataSubmit ? checkObjectHasData(dataSubmit) : false;
  let totalMonthlyLiabilities = 0;
  let renderedLoans = [];
  let renderedCreditCards = [];

  const onEdit = () => {
    setIsShowButtonEdit(false);
  };

  const watchLoans = watch(`liabilities[${keyValue}].loans`) || [];
  const watchCreditCards = watch(`liabilities[${keyValue}].creditCards`) || [];
  const watchOtherLiabilities =
    watch(`liabilities[${keyValue}].otherLiabilities`) || [];
  const watchOverdraftBalance =
    watch(`liabilities[${keyValue}].overdraftBalance`) || 0;
  const watchTotalPersonalLoans =
    watch(`liabilities[${keyValue}].totalPersonalLoans`) || 0;
  const watchOutstandingTaxes =
    watch(`liabilities[${keyValue}].outstandingTaxes`) || 0;
  let watchTotalLoans = 0;
  let watchTotalCreditCards = 0;
  let watchTotalOtherLiabilities = 0;

  if (watchLoans?.length > 0) {
    let sum = 0;
    watchLoans.forEach((element, index) => {
      const watchLoan =
        watch(`liabilities[${keyValue}].loans[${index}].balanceOwing`) || 0;
      sum = sum + watchLoan;
    });
    watchTotalLoans = sum;
  }
  if (watchCreditCards?.length > 0) {
    let sum = 0;
    watchCreditCards.forEach((element, index) => {
      const watchCreditCard =
        watch(`liabilities[${keyValue}].creditCards[${index}].balance`) || 0;
      sum = sum + watchCreditCard;
    });
    watchTotalCreditCards = sum;
  }
  if (watchOtherLiabilities?.length > 0) {
    let sum = 0;
    watchOtherLiabilities.forEach((element, index) => {
      const watchOrther =
        watch(`liabilities[${keyValue}].otherLiabilities[${index}].balance`) ||
        0;
      sum = sum + watchOrther;
    });
    watchTotalOtherLiabilities = sum;
  }

  if (
    watchTotalLoans ||
    watchTotalCreditCards ||
    watchTotalOtherLiabilities ||
    watchOverdraftBalance ||
    watchTotalPersonalLoans ||
    watchOutstandingTaxes
  ) {
    const totalTemp =
      watchTotalLoans +
      watchTotalCreditCards +
      watchTotalOtherLiabilities +
      watchOverdraftBalance +
      watchTotalPersonalLoans +
      watchOutstandingTaxes;

    totalMonthlyLiabilities =
      dataSubmit &&
      dataSubmit.totalLiabilities &&
      isShowButtonEdit &&
      totalTemp !== dataSubmit.totalLiabilities
        ? dataSubmit.totalLiabilities + totalTemp
        : totalTemp;
  } else if (dataSubmit && isValidNumber(dataSubmit.totalLiabilities)) {
    totalMonthlyLiabilities = dataSubmit.totalLiabilities;
  }
  const renderLoansInputs = () => {
    Array.from({ length: numberOfLoans }, (_, i) => {
      if (dataSubmit && dataSubmit?.numberOfLoans === 0 && isShowButtonEdit) {
        renderedLoans = [];
      } else if (numberOfLoans > 1) {
        renderedLoans.push(
          (dataSubmit === undefined ||
            !dataSubmit?.loans ||
            !dataSubmit?.loans[i] ||
            !isValidNumber(dataSubmit?.loans[i]?.balanceOwing) ||
            !dataSubmit?.loans[i]?.lenderName ||
            !isShowButtonEdit) && (
            <div key={`loan_input_${i}`}>
              <div className="p-2">
                <span>Loan {i + 1}</span>
              </div>
              <div className="grid grid-cols-2 gap-1">
                <div>
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    type="text"
                    id={`liabilities[${keyValue}].loans[${i}].lenderName`}
                    placeholder="Lender Name"
                    {...register(
                      `liabilities[${keyValue}].loans[${i}].lenderName`,
                      {
                        value:
                          dataSubmit?.loans && dataSubmit?.loans[i]
                            ? dataSubmit?.loans[i]?.lenderName
                            : null,
                        required: RequiredValidationMessage("Lender Name"),
                      }
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name={`liabilities[${keyValue}].loans[${i}].balanceOwing`}
                  defaultValue={
                    dataSubmit?.loans && dataSubmit?.loans[i]
                      ? dataSubmit?.loans[i]?.balanceOwing
                      : null
                  }
                  rules={{
                    required: RequiredValidationMessage("Balance Owing"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`liabilities[${keyValue}].loans[${i}].balanceOwing`}
                        name={name}
                        placeholder={"Balance Owing"}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                      ></NumericFormat>
                    </div>
                  )}
                />
              </div>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.liabilities?.[keyValue]?.loans &&
                errors.liabilities?.[keyValue]?.loans[i] && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    All the loan input must be filled
                  </span>
                )}
            </div>
          )
        );
      } else
        renderedLoans.push(
          (dataSubmit === undefined ||
            !dataSubmit?.loans ||
            !dataSubmit?.loans[i] ||
            !isValidNumber(dataSubmit?.loans[i]?.balanceOwing) ||
            !dataSubmit?.loans[i]?.lenderName ||
            !isShowButtonEdit) && (
            <div key={`loan_input_${i}`}>
              <div className="grid grid-cols-2 gap-1">
                <div>
                  <input
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    type="text"
                    id={`liabilities[${keyValue}].loans[${i}].lenderName`}
                    placeholder="Lender Name"
                    {...register(
                      `liabilities[${keyValue}].loans[${i}].lenderName`,
                      {
                        value:
                          dataSubmit?.loans && dataSubmit?.loans[i]
                            ? dataSubmit?.loans[i]?.lenderName
                            : null,
                        required:
                          numberOfLoans === 1
                            ? false
                            : RequiredValidationMessage("Lender Name"),
                      }
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name={`liabilities[${keyValue}].loans[${i}].balanceOwing`}
                  defaultValue={
                    dataSubmit?.loans && dataSubmit?.loans[i]
                      ? dataSubmit?.loans[i]?.balanceOwing
                      : null
                  }
                  rules={{
                    required:
                      numberOfLoans === 1
                        ? false
                        : RequiredValidationMessage("Balance Owing"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`liabilities[${keyValue}].loans[${i}].balanceOwing`}
                        name={name}
                        placeholder={"Balance Owing"}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <div></div>
              </div>
            </div>
          )
        );
    });
    return renderedLoans;
  };
  const renderCreditsInputs = () => {
    Array.from({ length: numberOfCredits }, (_, i) => {
      if (
        dataSubmit &&
        dataSubmit?.numberOfCreditCards === 0 &&
        isShowButtonEdit
      ) {
        renderedCreditCards = [];
      } else if (numberOfCredits > 1)
        renderedCreditCards.push(
          (dataSubmit === undefined ||
            !dataSubmit?.creditCards ||
            !dataSubmit?.creditCards[i] ||
            !isValidNumber(dataSubmit?.creditCards[i]?.balance) ||
            !isValidNumber(dataSubmit?.creditCards[i]?.limit) ||
            !isShowButtonEdit) && (
            <div key={`credit_input_${i}`}>
              <div className="p-2">
                <span>Credit Card {i + 1}</span>
              </div>
              <div className="grid grid-cols-2 gap-1">
                <Controller
                  control={control}
                  name={`liabilities[${keyValue}].creditCards[${i}].limit`}
                  defaultValue={
                    dataSubmit?.creditCards && dataSubmit?.creditCards[i]
                      ? dataSubmit?.creditCards[i]?.limit
                      : null
                  }
                  rules={{
                    required: RequiredValidationMessage("Limit"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`liabilities[${keyValue}].creditCards[${i}].limit`}
                        name={name}
                        placeholder={"Limit"}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name={`liabilities[${keyValue}].creditCards[${i}].balance`}
                  defaultValue={
                    dataSubmit?.creditCards && dataSubmit?.creditCards[i]
                      ? dataSubmit?.creditCards[i]?.balance
                      : null
                  }
                  rules={{
                    required: RequiredValidationMessage("Balance"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`liabilities[${keyValue}].creditCards[${i}].balance`}
                        name={name}
                        placeholder={"Balance"}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                      ></NumericFormat>
                    </div>
                  )}
                />
              </div>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.liabilities?.[keyValue]?.creditCards &&
                errors.liabilities?.[keyValue]?.creditCards[i] && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    All the credit card inputs must be filled
                  </span>
                )}
            </div>
          )
        );
      else
        renderedCreditCards.push(
          (dataSubmit === undefined ||
            !dataSubmit?.creditCards ||
            !dataSubmit?.creditCards[i] ||
            !isValidNumber(dataSubmit?.creditCards[i]?.balance) ||
            !isValidNumber(dataSubmit?.creditCards[i]?.limit) ||
            !isShowButtonEdit) && (
            <div key={`credit_input_${i}`}>
              <div className="grid grid-cols-2 gap-1">
                <Controller
                  control={control}
                  name={`liabilities[${keyValue}].creditCards[${i}].limit`}
                  defaultValue={
                    dataSubmit?.creditCards && dataSubmit?.creditCards[i]
                      ? dataSubmit?.creditCards[i]?.limit
                      : null
                  }
                  rules={{
                    required: RequiredValidationMessage("Limit"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`liabilities[${keyValue}].creditCards[${i}].limit`}
                        name={name}
                        placeholder={"Limit"}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                      ></NumericFormat>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name={`liabilities[${keyValue}].creditCards[${i}].balance`}
                  defaultValue={
                    dataSubmit?.creditCards && dataSubmit?.creditCards[i]
                      ? dataSubmit?.creditCards[i]?.balance
                      : null
                  }
                  rules={{
                    required: RequiredValidationMessage("Balance"),
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <div>
                      <NumericFormat
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                        id={`liabilities[${keyValue}].creditCards[${i}].balance`}
                        name={name}
                        placeholder={"Balance"}
                        thousandSeparator=","
                        value={value}
                        prefix="$"
                        onValueChange={(values, sourceInfo) => {
                          onChange(
                            isValidNumber(values.floatValue)
                              ? values.floatValue
                              : null
                          );
                        }}
                      ></NumericFormat>
                    </div>
                  )}
                />
              </div>
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.liabilities?.[keyValue]?.creditCards &&
                errors.liabilities?.[keyValue]?.creditCards[i] && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    All the credit card inputs must be filled
                  </span>
                )}
            </div>
          )
        );
    });
    return renderedCreditCards;
  };

  const renderOtherLiabilitiesInputs = () => {
    return Array.from({ length: numberOfOtherLiabilities }, (_, index) =>
      dataSubmit === undefined ||
      !dataSubmit?.otherLiabilities ||
      !dataSubmit?.otherLiabilities[index] ||
      !isValidNumber(dataSubmit?.otherLiabilities[index]?.balance) ||
      !dataSubmit?.otherLiabilities[index]?.name ||
      !isShowButtonEdit ? (
        <div className="grid grid-cols-2 gap-1" key={index}>
          <div>
            <input
              className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
              type="text"
              id={`liabilities[${keyValue}].otherLiabilities[${index}].name`}
              placeholder="Name"
              {...register(
                `liabilities[${keyValue}].otherLiabilities[${index}].name`,
                {
                  value:
                    dataSubmit?.otherLiabilities &&
                    dataSubmit?.otherLiabilities[index]
                      ? dataSubmit?.otherLiabilities[index]?.name
                      : null,
                }
              )}
            />
          </div>
          <Controller
            control={control}
            name={`liabilities[${keyValue}].otherLiabilities[${index}].balance`}
            defaultValue={
              dataSubmit?.otherLiabilities &&
              dataSubmit?.otherLiabilities[index]
                ? dataSubmit?.otherLiabilities[index]?.balance
                : null
            }
            render={({ field: { onChange, name, value } }) => (
              <div>
                <NumericFormat
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  id={`liabilities[${keyValue}].otherLiabilities[${index}].balance`}
                  name={name}
                  placeholder={"Balance"}
                  thousandSeparator=","
                  value={value}
                  prefix="$"
                  onValueChange={(values, sourceInfo) => {
                    onChange(
                      isValidNumber(values.floatValue)
                        ? values.floatValue
                        : null
                    );
                  }}
                ></NumericFormat>
              </div>
            )}
          />
        </div>
      ) : (
        <></>
      )
    );
  };

  const renderLoanReviews = () => {
    const numberOfLoanValue = numberOfLoans;
    return Array.from(
      { length: numberOfLoanValue },
      (_, index) =>
        dataSubmit?.loans &&
        dataSubmit?.loans[index] &&
        dataSubmit?.loans[index]?.lenderName &&
        isValidNumber(dataSubmit?.loans[index]?.balanceOwing) && (
          <div key={index}>
            <div className="col-span-1 font-bold ">
              <span>{`Loan (${index + 1})`} :</span>
            </div>
            <div className="p-2">
              <div className="font-light flex flex-col">
                {dataSubmit?.loans[index]?.lenderName !== null &&
                  dataSubmit?.loans[index]?.lenderName && (
                    <ReviewData
                      title={"Lender Name"}
                      value={dataSubmit?.loans[index]?.lenderName}
                    ></ReviewData>
                  )}
                {isValidNumber(dataSubmit?.loans[index]?.balanceOwing) ? (
                  <ReviewData
                    title={"Balance Owing"}
                    value={dataSubmit?.loans[
                      index
                    ]?.balanceOwing.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  ></ReviewData>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )
    );
  };
  const renderCreditCardReviews = () => {
    const numberOfCreditsValue = numberOfCredits;
    return Array.from(
      { length: numberOfCreditsValue },
      (_, index) =>
        dataSubmit?.creditCards &&
        dataSubmit?.creditCards[index] &&
        isValidNumber(dataSubmit?.creditCards[index]?.limit) &&
        isValidNumber(dataSubmit?.creditCards[index]?.balance) && (
          <div key={index}>
            <div className="col-span-1 font-bold ">
              <span>{`Credit Card (${index + 1})`} :</span>
            </div>
            <div className="p-2">
              <div className="font-light flex flex-col">
                {isValidNumber(dataSubmit?.creditCards[index]?.limit) && (
                  <ReviewData
                    title={"Limit"}
                    value={dataSubmit?.creditCards[index]?.limit.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  ></ReviewData>
                )}
                {isValidNumber(dataSubmit?.creditCards[index]?.balance) && (
                  <ReviewData
                    title={"Balance"}
                    value={dataSubmit?.creditCards[
                      index
                    ]?.balance.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  ></ReviewData>
                )}
              </div>
            </div>
          </div>
        )
    );
  };
  const renderOtherLiabilityReviews = () => {
    const numberOfOtherLiabilitiesValue = numberOfOtherLiabilities;
    return Array.from(
      { length: numberOfOtherLiabilitiesValue },
      (_, index) =>
        dataSubmit?.otherLiabilities &&
        dataSubmit?.otherLiabilities[index] &&
        dataSubmit?.otherLiabilities[index]?.name &&
        isValidNumber(dataSubmit?.otherLiabilities[index]?.balance) && (
          <div key={index}>
            {index === 0 && (
              <div className=" font-bold mt-1 mb-2">
                <span>Other :</span>
              </div>
            )}
            <div className="col-span-1 font-bold ">
              <span>{`Liability (${index + 1})`} :</span>
            </div>
            <div className="p-2">
              <div className="font-light flex flex-col">
                {dataSubmit?.otherLiabilities[index]?.name !== null &&
                  dataSubmit?.otherLiabilities[index]?.name && (
                    <ReviewData
                      title={"Name"}
                      value={dataSubmit?.otherLiabilities[index]?.name}
                    ></ReviewData>
                  )}
                {isValidNumber(
                  dataSubmit?.otherLiabilities[index]?.balance
                ) && (
                  <ReviewData
                    title={"Balance"}
                    value={dataSubmit?.otherLiabilities[
                      index
                    ]?.balance.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  ></ReviewData>
                )}
              </div>
            </div>
          </div>
        )
    );
  };

  return (
    <div className="mt-5">
      <form
        className="space-y-1 max-w-sm mx-auto  text-sm"
        onSubmit={handleSubmit(onFinalSubmit)}
      >
        <div className=" flex justify-end">
          {checkData && isShowButtonEdit && !isSubmitted && (
            <StyledButton
              type="button"
              className="flex font-semibold gap-2 justify-center items-center uppercase w-[50px] h-[36px] p-[10px] rounded-md"
              onClick={onEdit}
            >
              Edit
            </StyledButton>
          )}
        </div>
        {dataSubmit && checkData && isShowButtonEdit && (
          <div id="showData" className="flex flex-col gap-1 mb-2">
            {isValidNumber(dataSubmit?.numberOfLoans) ? (
              <ReviewData
                title={"Number Of Loans"}
                value={dataSubmit?.numberOfLoans}
              ></ReviewData>
            ) : (
              <></>
            )}
            {renderLoanReviews()}
            {isValidNumber(dataSubmit?.overdraftLimit) ? (
              <ReviewData
                title={"Overdraft Limit"}
                value={dataSubmit?.overdraftLimit.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.overdraftBalance) ? (
              <ReviewData
                title={"Overdraft Balance"}
                value={dataSubmit?.overdraftBalance.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.numberOfCreditCards) ? (
              <ReviewData
                title={"Number Of CreditCards"}
                value={dataSubmit?.numberOfCreditCards}
              ></ReviewData>
            ) : (
              <></>
            )}
            {renderCreditCardReviews()}
            {isValidNumber(dataSubmit?.totalPersonalLoans) ? (
              <ReviewData
                title={"Total Personal Loans"}
                value={dataSubmit?.totalPersonalLoans.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {isValidNumber(dataSubmit?.outstandingTaxes) ? (
              <ReviewData
                title={"Outstanding Taxes"}
                value={dataSubmit?.outstandingTaxes.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              ></ReviewData>
            ) : (
              <></>
            )}
            {renderOtherLiabilityReviews()}
          </div>
        )}
        <div className="space-y-1 pb-3 border-b-[1px] border-white/60">
          <div>
            {dataSubmit === undefined ||
            !isValidNumber(dataSubmit?.numberOfLoans) ||
            !isShowButtonEdit ? (
              <div>
                <input
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  type="number"
                  id={`liabilities[${keyValue}].numberOfLoans`}
                  placeholder="Number of Loans"
                  {...register(`liabilities[${keyValue}].numberOfLoans`, {
                    value: dataSubmit?.numberOfLoans ?? null,
                    required: RequiredValidationMessage("Number of Loans"),
                    valueAsNumber: true,
                    onChange: (e) => {
                      if (
                        e.target.valueAsNumber === 0 ||
                        !e.target.valueAsNumber
                      )
                        setNumberOfLoans(1);
                      else if (
                        e.target.valueAsNumber > 0 &&
                        e.target.valueAsNumber < 5
                      )
                        setNumberOfLoans(e.target.valueAsNumber);
                    },
                    min: {
                      value: 0,
                      message: "Min value is 0",
                    },
                    max: {
                      value: 4,
                      message: "Max value is 4",
                    },
                  })}
                />
                {keyValue !== null &&
                  keyValue !== undefined &&
                  errors.liabilities?.[keyValue]?.numberOfLoans && (
                    <span className="text-red-400 text-sm font-bold px-2">
                      {errors.liabilities?.[
                        keyValue
                      ]?.numberOfLoans.message.toString()}
                    </span>
                  )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {renderLoansInputs()}
        </div>
        <div className="grid grid-cols-2 gap-1 mt-3">
          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.overdraftLimit) ||
          !isShowButtonEdit ? (
            <div>
              <Controller
                control={control}
                name={`liabilities[${keyValue}].overdraftLimit`}
                defaultValue={dataSubmit?.overdraftLimit ?? null}
                rules={{
                  required: RequiredValidationMessage("Overdraft Limit"),
                }}
                render={({ field: { onChange, name, value } }) => (
                  <div>
                    <NumericFormat
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                      id={`liabilities[${keyValue}].overdraftLimit`}
                      name={name}
                      placeholder={"Overdraft Limit"}
                      thousandSeparator=","
                      value={value}
                      prefix="$"
                      onValueChange={(values, sourceInfo) => {
                        onChange(
                          isValidNumber(values.floatValue)
                            ? values.floatValue
                            : null
                        );
                      }}
                    ></NumericFormat>
                  </div>
                )}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.liabilities?.[keyValue]?.overdraftLimit && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.liabilities?.[
                      keyValue
                    ]?.overdraftLimit.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}

          {dataSubmit === undefined ||
          !isValidNumber(dataSubmit?.overdraftBalance) ||
          !isShowButtonEdit ? (
            <div>
              <Controller
                control={control}
                name={`liabilities[${keyValue}].overdraftBalance`}
                defaultValue={dataSubmit?.overdraftBalance ?? null}
                rules={{
                  required: RequiredValidationMessage("Overdraft Balance"),
                }}
                render={({ field: { onChange, name, value } }) => (
                  <div>
                    <NumericFormat
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                      id={`liabilities[${keyValue}].overdraftBalance`}
                      name={name}
                      placeholder={"Overdraft Balance"}
                      thousandSeparator=","
                      value={value}
                      prefix="$"
                      onValueChange={(values, sourceInfo) => {
                        onChange(
                          isValidNumber(values.floatValue)
                            ? values.floatValue
                            : null
                        );
                      }}
                    ></NumericFormat>
                  </div>
                )}
              />
              {keyValue !== null &&
                keyValue !== undefined &&
                errors.liabilities?.[keyValue]?.overdraftBalance && (
                  <span className="text-red-400 text-sm font-bold px-2">
                    {errors.liabilities?.[
                      keyValue
                    ]?.overdraftBalance.message.toString()}
                  </span>
                )}
            </div>
          ) : (
            <></>
          )}
        </div>

        {dataSubmit === undefined ||
        !isValidNumber(dataSubmit?.numberOfCreditCards) ||
        !isShowButtonEdit ? (
          <div>
            <input
              className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
              type="number"
              id={`liabilities[${keyValue}].numberOfCreditCards`}
              placeholder="Number of Credit Cards"
              {...register(`liabilities[${keyValue}].numberOfCreditCards`, {
                value: dataSubmit?.numberOfCreditCards ?? null,
                required: RequiredValidationMessage("Number of Credit Cards"),
                valueAsNumber: true,
                onChange: (e) => {
                  if (e.target.valueAsNumber === 0 || !e.target.valueAsNumber)
                    setNumberOfCredits(1);
                  else if (
                    e.target.valueAsNumber > 0 &&
                    e.target.valueAsNumber < 5
                  )
                    setNumberOfCredits(e.target.valueAsNumber);
                },
                min: {
                  value: 0,
                  message: "Min value is 0",
                },
                max: {
                  value: 4,
                  message: "Max value is 4",
                },
              })}
            />
            {keyValue !== null &&
              keyValue !== undefined &&
              errors.liabilities?.[keyValue]?.numberOfCreditCards && (
                <span className="text-red-400 text-sm font-bold px-2">
                  {errors.liabilities?.[
                    keyValue
                  ]?.numberOfCreditCards.message.toString()}
                </span>
              )}
          </div>
        ) : (
          <></>
        )}

        {renderCreditsInputs()}

        {dataSubmit === undefined ||
        !isValidNumber(dataSubmit?.totalPersonalLoans) ||
        !isShowButtonEdit ? (
          <div>
            <Controller
              control={control}
              name={`liabilities[${keyValue}].totalPersonalLoans`}
              defaultValue={dataSubmit?.totalPersonalLoans ?? null}
              rules={{
                required: RequiredValidationMessage("Total Personal Loans"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`liabilities[${keyValue}].totalPersonalLoans`}
                    name={name}
                    placeholder={"Total Personal Loans"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                </div>
              )}
            />
            {keyValue !== null &&
              keyValue !== undefined &&
              errors.liabilities?.[keyValue]?.totalPersonalLoans && (
                <span className="text-red-400 text-sm font-bold px-2">
                  {errors.liabilities?.[
                    keyValue
                  ]?.totalPersonalLoans.message.toString()}
                </span>
              )}
          </div>
        ) : (
          <></>
        )}

        {dataSubmit === undefined ||
        !isValidNumber(dataSubmit?.outstandingTaxes) ||
        !isShowButtonEdit ? (
          <div>
            <Controller
              control={control}
              name={`liabilities[${keyValue}].outstandingTaxes`}
              defaultValue={dataSubmit?.outstandingTaxes ?? null}
              rules={{
                required: RequiredValidationMessage("Outstanding Taxes"),
              }}
              render={({ field: { onChange, name, value } }) => (
                <div>
                  <NumericFormat
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    id={`liabilities[${keyValue}].outstandingTaxes`}
                    name={name}
                    placeholder={"Outstanding Taxes"}
                    thousandSeparator=","
                    value={value}
                    prefix="$"
                    onValueChange={(values, sourceInfo) => {
                      onChange(
                        isValidNumber(values.floatValue)
                          ? values.floatValue
                          : null
                      );
                    }}
                  ></NumericFormat>
                </div>
              )}
            />
            {keyValue !== null &&
              keyValue !== undefined &&
              errors.liabilities?.[keyValue]?.outstandingTaxes && (
                <span className="text-red-400 text-sm font-bold px-2">
                  {errors.liabilities?.[
                    keyValue
                  ]?.outstandingTaxes.message.toString()}
                </span>
              )}
          </div>
        ) : (
          <></>
        )}

        {dataSubmit === undefined ||
        !dataSubmit?.otherLiabilities ||
        dataSubmit?.otherLiabilities?.some((s) => checkObjectHasNull(s)) ||
        !isShowButtonEdit ? (
          <div className=" mt-4 space-y-1">
            <div className="p-2">
              <span>Other (please specify)</span>
            </div>

            {renderOtherLiabilitiesInputs()}
          </div>
        ) : (
          <></>
        )}
      </form>
      <div className="flex flex-col max-w-sm mx-auto pb-2 font-bold my-5 gap-4">
        <div className="flex justify-between text-sm">
          <span>Total Liabilities</span>
          <span>
            {totalMonthlyLiabilities.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
export default LiabilitesForm;
