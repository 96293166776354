import React, { useEffect, useState } from "react";
import Button from "../../../components/common/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import {
  ACCOUNT_SETUP_TABS,
  CUSTOMER_TABS,
  PAGE_URL,
} from "../../../constants/constants";
import { AccountDTO } from "@dineiro/dineiro-sdk-mobile";
import SentIcon from "../../../images/home/sent.svg";

const ManageAccounts: React.FC<{
  accountUpdateds: number[];
  accounts: AccountDTO[];
  shouldRefetchGet;
  refetchGet?;
  isLoading?;
}> = ({
  accounts,
  shouldRefetchGet,
  refetchGet,
  accountUpdateds,
  isLoading,
}) => {
  const { brand, brandid, officeid, tab, id, customername, subtab } =
    useParams();

  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      refetchGet(!shouldRefetchGet);
    }, 6000);
    setSpinner(false);
  }, [accountUpdateds]);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      refetchGet(!shouldRefetchGet);
    }, 5000);
  });

  const onManageConsent = (accountId) => {
    if (accountId !== "") {
      window.fastlink.close();
    }
    let navigateTab = `${PAGE_URL.HOME.replace(":brand", brand)
      .replace(":brandid", brandid)
      .replace(":office", customername.toLowerCase().replace(/\s/g, "-"))
      .replace(":officeid", officeid)}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
      ":customername",
      customername ? customername.replace(/\s/g, "-") : "undefind"
    )
      .replace(":id", id)
      .replace(":tab?", CUSTOMER_TABS.accountSetup)
      .replace(":subtab?", ACCOUNT_SETUP_TABS.fastLink)
      .replace(":providerAccountId?", accountId)}`;
    navigate(navigateTab);
  };
  return (
    <div className="space-y-1 max-w-2xl mx-auto py-5">
      {accounts && accounts.length > 0 ? (
        <ul>
          <div className="mb-2">
            <span className="uppercase font-bold">Accounts</span>
          </div>
          {accounts.map((account) => {
            return (
              <li
                className="flex items-center justify-between py-2 border-b border-white/40"
                key={account.id}
              >
                <span className="truncate">{`${account?.providerName}-${account?.accountName}`}</span>
                {accountUpdateds.includes(account.providerAccountId) ? (
                  <button className="w-[150px] min-h-[30px] bg-opacity-25 py-2 bg-black rounded flex items-center justify-center gap-1 uppercase font-semibold text-xs cursor-not-allowed">
                    <img src={SentIcon} alt="" />
                    Updated
                  </button>
                ) : (
                  <Button
                    w="150px"
                    h="30px"
                    fontSize="12px"
                    borderRadius="4px"
                    px="10px"
                    onClick={() => onManageConsent(account.providerAccountId)}
                  >
                    Manage consent
                  </Button>
                )}
              </li>
            );
          })}

          <div className="my-4 items-center flex justify-center">
            <Button
              w="225px"
              h="30px"
              fontSize="12px"
              borderRadius="4px"
              px="10px"
              onClick={() => onManageConsent("")}
            >
              Add Additional Accounts
            </Button>
          </div>
        </ul>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <span className="text-lg mb-2">You don't have any accounts.</span>
          <span className="text-sm ">Start linking your account here.</span>
          <div className="mt-4">
            <Button
              w="225px"
              h="30px"
              fontSize="12px"
              borderRadius="4px"
              px="10px"
              onClick={() => onManageConsent("")}
            >
              Add Additional Accounts
            </Button>
          </div>
        </div>
      )}

      {spinner && <Spinner></Spinner>}
    </div>
  );
};

export default ManageAccounts;
