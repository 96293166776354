import { useEffect, useState } from "react";
import {
  StyledButton,
  StyledCheckBox,
} from "../../../components/common/change-themes/styles-component";
import { getLocalStorageElement } from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  ACCOUNT_SETUP_TABS,
  CUSTOMER_TABS,
  PAGE_URL,
} from "../../../constants/constants";
import Spinner from "../../../components/common/spinner/Spinner";
import { getBrandOfficesApi } from "../../../services/api-service";
import toast from "react-hot-toast";

const TermsAndConditions = ({
  refetchGetUserInfo,
  shouldRefetchGetUserInfo,
}) => {
  const navigate = useNavigate();
  const { brand, brandid, officeid, tab, id, customername, subtab } =
    useParams();
  const [spinner, setSpinner] = useState(false);
  const [isAcceptTerm, setIsAcceptTerm] = useState(false);

  const onAcceptTermAndCondition = async () => {
    const storedIdToken = getLocalStorageElement("idToken");
    const brandApi = getBrandOfficesApi(storedIdToken);

    try {
      await brandApi.brandOfficeCustomersDineiroTermsAndConditionsPost({
        id: parseInt(officeid),
        userId: id,
      });
      toast.success(`You are accepted terms and conditions .`);
      refetchGetUserInfo(!shouldRefetchGetUserInfo);
      let navigateTab = `${PAGE_URL.HOME.replace(":brand", brand)
        .replace(":brandid", brandid)
        .replace(":office", customername.toLowerCase().replace(/\s/g, "-"))
        .replace(":officeid", officeid)}/${PAGE_URL.CUSTOMER_MAIN_PAGE.replace(
        ":customername",
        customername ? customername.replace(/\s/g, "-") : "undefind"
      )
        .replace(":id", id)
        .replace(":tab?", CUSTOMER_TABS.accountSetup)
        .replace(":subtab?", ACCOUNT_SETUP_TABS.fastLink)
        .replace("/:providerAccountId?", "")}`;
      navigate(navigateTab);
    } catch (error) {
      toast.error("Failed to update term and conditions status.");
    }
  };

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      setSpinner(false);
    }, 2500);
  }, [subtab]);
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center">
        <p className=" uppercase text-xs">Dosh</p>
        <p
          style={{ fontSize: "30px", lineHeight: "34px" }}
          className=" header-h3 w-[390px] font-bold text-center "
        >
          Please read and accept our terms & conditions
        </p>
        <div className="h-1 bg-yellow-500 w-28"></div>
      </div>
      <div
        className="h-[460px] max-w-[400px] overflow-auto border-dashed border-1 p-2 mt-[50px] "
        style={{ fontSize: "14px", lineHeight: "20px" }}
      >
        <p> Document date: JULY 2022 </p>
        <p>OVERVIEW</p>
        <ol>
          <li>
            <p>
              • We are dineiro Pty Ltd ACN 646 413 087 (Provider, we, us and
              our). We are committed to respecting Your privacy. This page
              informs You of our policies regarding the collection, use and
              disclosure of personal data when You use our Services and the
              choices You have associated with that data.
            </p>
          </li>
          <li>
            <p>
              • This agreement contains the terms, conditions, and disclaimers
              (Terms) for accessing and using Services by You.
            </p>
          </li>
          <li>
            <p>
              • The Provider separately maintains its privacy policy regarding
              the collection and use of data.
            </p>
          </li>
          <li>
            <p>
              {" "}
              • If You do not agree to these Terms then You must stop using the
              Services.
            </p>
          </li>
          <li>
            <p>
              {" "}
              • If You do not agree to these Terms then You must stop using the
              Services.
            </p>
          </li>
          <li>
            <p>
              • If You do not agree to these Terms then You must stop using the
              Services.
            </p>
          </li>
        </ol>
        <div>
          <p>DEFINITIONS </p>
          <span>Application</span>
        </div>
        <ul>
          <li>
            <p>
              1. The Application is the wealth management and finance
              application operated by us. Intellectual Property
            </p>
          </li>
          <li>
            <p>2. Includes all:</p>
            <ol>
              <li>
                <p>
                  a. inventions, discoveries, innovations, novel or technical
                  information and data, prototypes, processes, improvements, and
                  patents, including all patents and patented applications,
                  processes and products within the meaning of the Patents Act
                  1990 (Cth);
                </p>
              </li>
              <li>
                <p>
                  b. circuitry and circuit layouts, computer programs, software,
                  code, drawings, plans, and specifications;
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  c. domain names, business names, trade marks, including any
                  trade name, brand name, common law trade mark, or trade mark
                  within the meaning of the Trade Marks Act 1995 (Cth);
                </p>
              </li>
              <li>
                <p>
                  d. designs, including all designs within the meaning of the
                  Designs Act 2003 (Cth);
                </p>
              </li>
              <li>
                <p>
                  e. copyright material within the meaning of the Copyright Act
                  1968 (Cth);
                </p>
              </li>
              <li>
                <p>f. trade secrets and know-how; and</p>
              </li>
              <li>
                <p>
                  g. works, and all other works resulting from intellectual
                  activity in the industrial, scientific, education, literary,
                  or artistic fields.
                </p>
              </li>
            </ol>
          </li>

          <li> Privacy Policy</li>
          <li>
            <p>
              3. Privacy Policy means our privacy policy relating to the
              collection and use of data, which can be found at: [insert link to
              policy].
            </p>
          </li>
          <li>Services</li>
          <li>
            <p>4. The Services are the Application and Website.</p>
          </li>
          <li>Territory</li>
          <li>
            <p>
              {" "}
              5. This agreement for the Terms shall be governed by the exclusive
              jurisdiction of the courts of the state of Queensland, Australia
              and those Courts that hear appeals from these Courts, including
              the Federal Court of Australia (the Territory).
            </p>
          </li>
        </ul>
      </div>
      <div className="flex items-center p-3 text-sm w-[390px] mt-3">
        <StyledCheckBox
          id="default-checkbox"
          type="checkbox"
          className="w-4 h-4 bg-gray-100 border-gray-300 rounded-none dark:bg-gray-700 dark:border-gray-600"
          checked={isAcceptTerm}
          onChange={() => setIsAcceptTerm(!isAcceptTerm)}
        ></StyledCheckBox>
        <label htmlFor="default-checkbox" className="ms-2 ">
          I accept the terms of use
        </label>
      </div>
      <div className="w-[390px] p-3">
        <StyledButton
          type="button"
          className="flex font-bold text-xs justify-center items-center uppercase w-[130px] h-[45px] px-[18px] rounded-md"
          disabled={!isAcceptTerm}
          onClick={onAcceptTermAndCondition}
        >
          Accept
        </StyledButton>
      </div>
      {spinner && <Spinner></Spinner>}
    </div>
  );
};

export default TermsAndConditions;
