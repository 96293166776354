import { useState, useEffect } from "react";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { UserDTO } from "@dineiro/dineiro-sdk-mobile";
import { isDeveloper } from "../environment";

const useCustomers = ({ officeId }) => {
  const [customers, setCustomers] = useState<UserDTO[]>([]);
  const [shouldRefetch, refetch] = useState(false);
  const [search, setSearch] = useState("");
  const [firstFilter, setFirstFilter] = useState("");
  const [secondFilter, setSecondFilter] = useState("");
  const [thirdFilter, setThirdFilter] = useState("");
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const CustomersComponent = () => {
    useEffect(() => {
      if (user) {
        const fetchData = async () => {
          const brandApi = getBrandOfficesApi(storedIdToken);
          try {
            const response = await brandApi.brandOfficeCustomersGet({
              id: officeId,
              q: search,
              ft1: firstFilter,
              ft2: secondFilter,
              ft3: thirdFilter,
            });
            setCustomers(response.data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [
      storedIdToken,
      shouldRefetch,
      search,
      firstFilter,
      secondFilter,
      thirdFilter,
    ]);

    return null;
  };

  CustomersComponent();

  return {
    customers: customers,
    refetch,
    setSearch,
    setFirstFilter,
    setSecondFilter,
    setThirdFilter,
    shouldRefetch: shouldRefetch,
  };
};

export default useCustomers;
