import React, { useEffect, useState } from "react";
import ReviewData from "../../common/review-form/ReviewData";
import moment from "moment";

const BaseEmploymentReviewForm: React.FC<{
  dataSubmit?;
  employmentType?;
}> = ({ dataSubmit, employmentType }) => {
  const [dateView, setDateView] = useState("");
  useEffect(() => {
    if (dataSubmit?.startedDate)
      if (moment(new Date(dataSubmit?.startedDate)).isValid())
        setDateView(
          moment(new Date(dataSubmit.startedDate)).format("DD/MM/YYYY")
        );
      else if (moment(dataSubmit.startedDate, "DD/MM/YYYY").isValid()) {
        setDateView(dataSubmit.startedDate);
      } else if (moment(dataSubmit.startedDate.toString()).isValid()) {
        setDateView(
          moment(dataSubmit.startedDate.toString()).format("DD/MM/YYYY")
        );
      }
  }, [dataSubmit?.startedDate]);
  return (
    <div id="showData" className="flex flex-col gap-1 mb-4 ">
      {employmentType && employmentType !== null && (
        <ReviewData
          title={"EmploymentType"}
          value={employmentType}
        ></ReviewData>
      )}
      {dataSubmit?.currentEmployer !== "" && dataSubmit?.currentEmployer && (
        <ReviewData
          title={"Current Employer"}
          value={dataSubmit?.currentEmployer}
        ></ReviewData>
      )}
      {dataSubmit?.hrPayrollContact !== "" && dataSubmit?.hrPayrollContact && (
        <ReviewData
          title={"HR/Payroll Contact"}
          value={dataSubmit?.hrPayrollContact}
        ></ReviewData>
      )}
      {dataSubmit?.phoneNumber !== "" && dataSubmit?.phoneNumber && (
        <ReviewData
          title={"Phone Number"}
          value={dataSubmit?.phoneNumber}
        ></ReviewData>
      )}
      {dataSubmit?.occupation !== "" && dataSubmit?.occupation && (
        <ReviewData
          title={"Occupation"}
          value={dataSubmit?.occupation}
        ></ReviewData>
      )}
      {dataSubmit?.timeAtCurrentJob !== "" && dataSubmit?.timeAtCurrentJob && (
        <ReviewData
          title={"Time At Current Job"}
          value={dataSubmit?.timeAtCurrentJob}
        ></ReviewData>
      )}
      {dataSubmit?.startedDate && dataSubmit?.startedDate !== null && (
        <ReviewData title={"Start Date"} value={dateView}></ReviewData>
      )}
    </div>
  );
};

export default BaseEmploymentReviewForm;
