import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FACTFIND_TABS } from "../../../constants/constants";
import {
  getLocalStorageElement,
  handleFactFindNavigate,
  isValidNumber,
} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LiabilitesForm from "../../../components/fact-find/LiabilitesForm";
import { StyledButton } from "../../../components/common/change-themes/styles-component";
import {
  FactFindFactFindDTO,
  FactFindLiabilitiesSectionDTO,
  FullFactFindLiabilitiesPostViewModel,
  LiabilitiesSectionViewModel,
  Section,
} from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../../../services/api-service";
import { updateFactFindSection } from "./ApiService";

const LiabilitiesSession: React.FC<{
  numberOfApplicants: number;
  factFind: FactFindFactFindDTO;
  refetchGet;
  shouldRefetchGet;
}> = ({ numberOfApplicants, factFind, refetchGet, shouldRefetchGet }) => {
  const [dataSubmit, setDataSubmit] = useState<FactFindLiabilitiesSectionDTO[]>(
    []
  );
  const navigate = useNavigate();
  const { officeid, id, subtab } = useParams();
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    trigger,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onChange",
  });
  const [isActiveApp, setIsActiveApp] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isShowButtonEdit, setIsShowButtonEdit] = useState(true);
  let dataSetup =
    factFind && factFind?.applicants?.length > 0
      ? factFind.applicants.map((s) => {
          const liabilitiesSection = { ...s.liabilitiesSection };
          return liabilitiesSection;
        })
      : [];

  useEffect(() => {
    setDataSubmit(dataSetup);
    const isFactFindSubmit = factFind?.isSubmitted ?? false;
    setIsSubmitted(isFactFindSubmit);
  }, [factFind]);

  const calculationTotalLiabilities = (
    liability: LiabilitiesSectionViewModel
  ) => {
    const totalPersonalLoans = liability?.totalPersonalLoans || 0;
    const outstandingTaxes = liability?.outstandingTaxes || 0;
    const overdraftBalance = liability?.overdraftBalance || 0;
    const totalLoans = liability?.loans?.reduce(
      (acc, obj) =>
        acc + (isValidNumber(obj?.balanceOwing) ? obj?.balanceOwing : 0),
      0
    );
    const totalCreditCards = liability?.creditCards?.reduce(
      (acc, obj) => acc + (isValidNumber(obj?.balance) ? obj?.balance : 0),
      0
    );
    const totalOther = liability?.otherLiabilities?.reduce(
      (acc, obj) => acc + (isValidNumber(obj?.balance) ? obj?.balance : 0),
      0
    );

    return (
      totalPersonalLoans +
      overdraftBalance +
      outstandingTaxes +
      totalLoans +
      totalCreditCards +
      totalOther
    );
  };

  const getValuesMapWithNumber = (numberOf, values) => {
    if (values && numberOf) {
      let listValue = [];
      for (let index = 0; index < numberOf; index++) {
        listValue.push(values[index]);
      }
      return listValue;
    }
    return [];
  };

  const convertDataToSubmit = (data) => {
    let dataConverted: LiabilitiesSectionViewModel[] = [];
    if (data) {
      for (let index = 0; index < numberOfApplicants; index++) {
        let liability = {
          applicantId: factFind.applicants[index].id,
          numberOfLoans:
            data[index]?.numberOfLoans !== undefined
              ? data[index]?.numberOfLoans
              : dataSubmit[index]?.numberOfLoans,

          overdraftLimit:
            data[index]?.overdraftLimit !== undefined
              ? data[index]?.overdraftLimit ?? null
              : dataSubmit[index]?.overdraftLimit,
          overdraftBalance:
            data[index]?.overdraftBalance !== undefined
              ? data[index]?.overdraftBalance ?? null
              : dataSubmit[index]?.overdraftBalance,
          numberOfCreditCards:
            data[index]?.numberOfCreditCards !== undefined
              ? data[index]?.numberOfCreditCards ?? null
              : dataSubmit[index]?.numberOfCreditCards,
          totalPersonalLoans:
            data[index]?.totalPersonalLoans !== undefined
              ? data[index]?.totalPersonalLoans ?? null
              : dataSubmit[index]?.totalPersonalLoans,
          outstandingTaxes:
            data[index]?.outstandingTaxes !== undefined
              ? data[index]?.outstandingTaxes ?? null
              : dataSubmit[index]?.outstandingTaxes,
          otherLiabilities: data[index]?.otherLiabilities.map((s, i) => {
            const watchOtherLiability =
              watch(`liabilities[${index}].otherLiabilities[${i}].balance`) ||
              0;
            s.name = s.name ? s.name : "";
            s.balance = watchOtherLiability ?? null;
            return s;
          }),
        } as LiabilitiesSectionViewModel;
        liability.loans = getValuesMapWithNumber(
          liability.numberOfLoans,
          data[index]?.loans
        );

        liability.loans = liability.loans.map((s, i) => {
          const watchLoanBalanceOwing =
            watch(`liabilities[${index}].loans[${i}].balanceOwing`) || 0;
          s.lenderName = s.lenderName ? s.lenderName : "";
          s.balanceOwing = watchLoanBalanceOwing ?? null;
          return s;
        });

        liability.creditCards = getValuesMapWithNumber(
          liability.numberOfCreditCards,
          data[index]?.creditCards
        );

        liability.creditCards = liability.creditCards.map((s, i) => {
          const watchLimit =
            watch(`liabilities[${index}].creditCards[${i}].limit`) || 0;
          const watchBalance =
            watch(`liabilities[${index}].creditCards[${i}].balance`) || 0;
          s.limit = watchLimit ?? null;
          s.balance = watchBalance ?? null;
          return s;
        });
        liability.totalLiabilities = calculationTotalLiabilities(liability);
        dataConverted.push(liability);
      }
    }

    return dataConverted;
  };

  useEffect(() => {
    if (errors && errors?.liabilities) {
      for (let index = 0; index < numberOfApplicants; index++) {
        if (errors?.liabilities?.[index]) {
          setIsActiveApp(index);
          return;
        }
      }
    }
  }, [errors, isSubmitting]);

  const triggerValueBeforSaving = (
    liabilities: LiabilitiesSectionViewModel[]
  ) => {
    for (let index = 0; index < numberOfApplicants; index++) {
      if (
        isValidNumber(liabilities[index].numberOfCreditCards) &&
        liabilities[index].numberOfCreditCards < 0
      ) {
        trigger(`liabilities[${index}].numberOfCreditCards`);
        return false;
      }
      if (
        isValidNumber(liabilities[index].numberOfLoans) &&
        liabilities[index].numberOfLoans < 0
      ) {
        trigger(`liabilities[${index}].numberOfLoans`);
        return false;
      }
      return true;
    }
  };

  const onNavigateToNextSection = () => {
    navigate(handleFactFindNavigate(FACTFIND_TABS.expenses, subtab));
  };

  const onSubmit = async (value) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataForm = value && value.liabilities ? value.liabilities : dataSubmit;
    let dataSent: FullFactFindLiabilitiesPostViewModel = {
      id: factFind?.id,
      liabilitiesSections: convertDataToSubmit(dataForm),
      isSaveAndNext: true,
    };
    const triggerBeforSaving = triggerValueBeforSaving(
      dataSent.liabilitiesSections
    );
    if (triggerBeforSaving)
      try {
        await brandApi.brandOfficeCustomersFactFindLiabilitiesPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindLiabilitiesPostViewModel: dataSent,
        });
        toast.success(`Submit liabilities sections data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.liabilitiesSections);
        await updateFactFindSection(
          brandApi,
          factFind,
          officeid,
          id,
          Section.Expenses
        );
        reset();
        refetchGet(!shouldRefetchGet);
        onNavigateToNextSection();
      } catch (error) {
        toast.error("Failed to submit liabilities sections data.");
      }
    else if (!triggerBeforSaving) {
      toast("Please provide correct data before saving.", {
        icon: "❗️",
      });
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();
    const data = getValues();
    let dataForm = data && data.liabilities ? data.liabilities : dataSubmit;
    const brandApi = getBrandOfficesApi(storedIdToken);
    let dataSent: FullFactFindLiabilitiesPostViewModel = {
      id: factFind?.id,
      liabilitiesSections: convertDataToSubmit(dataForm),
      isSaveAndNext: false,
    };
    const triggerBeforSaving = triggerValueBeforSaving(
      dataSent.liabilitiesSections
    );
    if (triggerBeforSaving)
      try {
        await brandApi.brandOfficeCustomersFactFindLiabilitiesPost({
          id: parseInt(officeid),
          userId: id,
          fullFactFindLiabilitiesPostViewModel: dataSent,
        });
        toast.success(`Submit liabilities sections data successfully .`);
        setIsShowButtonEdit(true);
        setDataSubmit(dataSent.liabilitiesSections);
        reset();
      } catch (error) {
        toast.error("Failed to submit liabilities sections data.");
      }
    else if (!triggerBeforSaving) {
      toast("Please provide correct data before saving.", {
        icon: "❗️",
      });
    }
  };

  const renderButtons = () => {
    if (numberOfApplicants < 2) return;
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <button
        key={i}
        className={` tab-font-family text-xs w-[120px] font-bold uppercase px-[15px] py-[10px]  block transition-all duration-300 ${
          isActiveApp === i
            ? "text-black bg-white/60"
            : "text-black/30 bg-white/75"
        } ${
          i === 0
            ? "rounded-l-md"
            : i + 1 >= numberOfApplicants
            ? "rounded-r-md"
            : " "
        }`}
        onClick={() => setIsActiveApp(i)}
      >
        Applicant {i + 1}
      </button>
    ));
  };

  const renderForms = () => {
    return Array.from({ length: numberOfApplicants }, (_, i) => (
      <div key={i} className={isActiveApp === i ? "block" : "hidden"}>
        <LiabilitesForm
          keyValue={i}
          register={register}
          handleSubmit={handleSubmit}
          onFinalSubmit={onSubmit}
          dataSubmit={dataSubmit[i]}
          isShowButtonEdit={isShowButtonEdit}
          setIsShowButtonEdit={setIsShowButtonEdit}
          errors={errors}
          isSubmitted={isSubmitted}
          watch={watch}
          control={control}
        ></LiabilitesForm>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <div>
        <div className="flex justify-center">{renderButtons()}</div>
        <div className="tab-content tab-space">{renderForms()}</div>
      </div>
      <div
        className={`space-y-1 max-w-sm mx-auto my-5 text-sm 
          
        `}
      >
        <div className="flex justify-center">
          <StyledButton
            type="submit"
            className="flex font-semibold gap-2 justify-center items-center uppercase w-[250px] h-[45px] px-[18px] rounded-md"
            onClick={handleSubmit(onSubmit)}
          >
            Save & Next Section
          </StyledButton>
        </div>
        <div className="flex justify-center">
          <button
            name="saveLater"
            type="button"
            className="w-[250px] h-[45px] px-[18px] border font-semibold text-sm border-white rounded-md uppercase"
            onClick={onSaveAsDraft}
          >
            Save & Continue Later
          </button>
        </div>
      </div>
    </div>
  );
};
export default LiabilitiesSession;
