import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AcceptancePage from "./AcceptancePage";
import { ACCOUNT_SETUP_TABS } from "../../../constants/constants";
import TermsAndConditions from "./TermsAndConditions";
import FastLinkPage from "./FastLinkPage";
import ManageAccounts from "./ManageAccounts";
import useGetAccounts from "../../../hooks/useGetAccounts";
const AccountSetupTabs: React.FC<{
  shouldRefetchGetUserInfo?;
  refetchGetUserInfo;
}> = ({ shouldRefetchGetUserInfo, refetchGetUserInfo }) => {
  const { officeid, tab, id, customername, subtab } = useParams();
  const [openTab, setOpenTab] = React.useState(ACCOUNT_SETUP_TABS.fastLink);
  const [accountUpdateds, setAccountUpdateds] = React.useState<number[]>([]);
  const { accounts, shouldRefetchGetAccounts, refetchGetAccounts, isLoading } =
    useGetAccounts({
      officeId: officeid,
      userId: id,
    });
  useEffect(() => {
    setOpenTab(subtab);
  }, [subtab]);
  return (
    <div>
      <div
        className={
          openTab === ACCOUNT_SETUP_TABS.acceptance ? "block" : "hidden"
        }
      >
        <AcceptancePage></AcceptancePage>
      </div>
      <div
        className={
          openTab === ACCOUNT_SETUP_TABS.termAndCondition ? "block" : "hidden"
        }
      >
        <TermsAndConditions
          shouldRefetchGetUserInfo={shouldRefetchGetUserInfo}
          refetchGetUserInfo={refetchGetUserInfo}
        ></TermsAndConditions>
      </div>
      <div
        className={openTab === ACCOUNT_SETUP_TABS.fastLink ? "block" : "hidden"}
      >
        <FastLinkPage
          accountUpdateds={accountUpdateds}
          setAccountUpdateds={setAccountUpdateds}
          shouldRefetchGet={shouldRefetchGetAccounts}
          shouldRefetchGetUserInfo={shouldRefetchGetUserInfo}
          refetchGetUserInfo={refetchGetUserInfo}
          refetchGet={refetchGetAccounts}
        ></FastLinkPage>
      </div>
      <div
        className={
          openTab === ACCOUNT_SETUP_TABS.manageAccounts ? "block" : "hidden"
        }
      >
        <ManageAccounts
          accounts={accounts}
          accountUpdateds={accountUpdateds}
          shouldRefetchGet={shouldRefetchGetAccounts}
          refetchGet={refetchGetAccounts}
          isLoading={isLoading}
        ></ManageAccounts>
      </div>
    </div>
  );
};

export default AccountSetupTabs;
