import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { StyledModal } from "../change-themes/styles-component";
import { classNames } from "../../../utils/utils";

const Modal = (props) => {
  const { content, action, show, toggle, width, height } = props;

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => toggle(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  width ? width : "w-full max-w-lg",
                  height ? height : "",
                  "relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all my-8"
                )}
              >
                <StyledModal>
                  <div>{content}</div>
                </StyledModal>
                {action}
                {/* Ensure that the modal does not scroll the screen when the content is too long. */}
                <div ref={cancelButtonRef}></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
