import { useEffect, useState } from "react";
import "../../styles.scss";
import LogoImage from "../../images/logo/dosh-logo.svg";
import PhoneIcon from "../../images/login/phone.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PAGE_URL, VALIDATION } from "../../constants/constants";
import { useForm } from "react-hook-form";
import Spinner from "../../components/common/spinner/Spinner";
import { connect, useSelector } from "react-redux";
import {
  loginWithEmailRequest,
  loginWithEmailFailure,
} from "../../store/actions/authenticate-actions";
import {
  loginFailSelector,
  userSelector,
} from "../../store/selectors/authSelector";
import {
  StyledComponent,
  getStoredProps,
  setStoredProps,
} from "../../components/common/change-themes/styles-component";
import useBrandInfo from "../../hooks/useBrandInfo";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import Button from "../../components/common/button/Button";
import { getLocalStorageElement } from "../../utils/utils";
import pak from "../../../package.json";

const Login = ({ userLogin }) => {
  const loginError = useSelector(loginFailSelector);
  const loginToken = useSelector(userSelector);
  const storedIdToken = getLocalStorageElement("idToken");
  const navigate = useNavigate();
  const [failed, setFailed] = useState(false);
  const [isAccessible, setIsAccessible] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const { brand, brandid, office, officeid } = useParams();
  let officeId = parseInt(officeid);
  const { role, brandOfficeId } = useUserInfoTK(loginToken);
  const { brandInfo, officeName } = useBrandInfo({ brandOfficeId: officeId });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFailed(false);
    try {
      let userInfo = {
        officeId: officeid,
        email: data.email,
        password: data.password,
      };
      userLogin(userInfo);
    } catch (loginError) {
      setFailed(true);
    }
  };

  const storedProps = getStoredProps();

  const setStored = () => {
    localStorage.removeItem("styledComponentProps");
    if (brandInfo.isWhiteLabel) {
      setStoredProps({
        ...storedProps,
        topLeftColor: brandInfo.style.topLeftColor,
        bottomRightColor: brandInfo.style.bottomRightColor,
        buttonColor: brandInfo.style.buttonColor,
        logoUrl: brandInfo.style.logoUrl,
        portalAccountSetupImageUrl: brandInfo.style.portalAccountSetupImageUrl,
      });
    }
  };

  const onLoginWithPhoneNumber = () => {
    setSpinner(true);
    setTimeout(async () => {
      try {
        navigate(
          PAGE_URL.LOGIN_WITH_PHONE_NUMBER.replace(":brand", brand)
            .replace(":brandid", brandid)
            .replace(":office", office)
            .replace(":officeid", officeid)
        );
      } catch (err) {
        setFailed(true);
      } finally {
        setSpinner(false);
      }
    }, 1500);
  };

  useEffect(() => {
    if (brandOfficeId !== 0) {
      if (storedIdToken) {
        navigate(
          PAGE_URL.HOME.replace(":brand", brand)
            .replace(":brandid", brandid)
            .replace(":office", office)
            .replace(":officeid", officeid)
        );
      } else if (loginToken && brandOfficeId === officeId) {
        navigate(
          PAGE_URL.VERIFY.replace(":brand", brand)
            .replace(":brandid", brandid)
            .replace(":office", office)
            .replace(":officeid", officeid)
        );
      } else {
        setIsAccessible(false);
      }
    }
  });

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      if (brandInfo) {
        try {
          setStored();
          localStorage.setItem("brandOfficeName", officeName);
          if (officeName) document.title = `Login - ${officeName}`;
        } catch (err) {
        } finally {
          setSpinner(false);
        }
      }
    }, 1200);
  }, [brandInfo]);

  return (
    <StyledComponent className=" min-h-screen flex flex-col justify-center items-center">
      <div className=" flex justify-center mb-16 ">
        <img src={storedProps.logoUrl ?? LogoImage} alt="Logo" />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="flex justify-center header-h3 font-bold uppercase mb-6"
          style={{ lineHeight: "25px", fontSize: "16px" }}
        >
          <span>Advisor Login</span>
        </div>
        <div className="flex flex-col space-y-2 items-center w-[250px] ">
          <div className="w-full flex-col">
            <input
              type="email"
              id="userName"
              className="bg-black/20 w-full min-h-[44px] rounded text-white placeholder-gray-200 dark:text-white border-transparent focus:border-white focus:ring-0"
              placeholder="Username"
              {...register("email", {
                required: VALIDATION.required.email,
                pattern: VALIDATION.email,
              })}
            />
            {errors.email && (
              <span className="text-red-400 text-sm font-bold">
                {errors.email.message.toString()}
              </span>
            )}
          </div>
          <div className="w-full flex-col">
            <input
              type="password"
              id="password"
              className="bg-black/20 w-full h-11  rounded text-white placeholder-gray-200 dark:text-white border-transparent focus:border-white focus:ring-0"
              placeholder="Password"
              {...register("password", {
                required: VALIDATION.required.password,
              })}
            />
            {errors.password && (
              <span className=" text-red-400 text-sm font-bold">
                {errors.password.message.toString()}
              </span>
            )}
          </div>
          {!loginError && (
            <div className="text-center mb-4">
              <span className="text-red-400 text-sm font-bold">
                Email or password is incorrect.
              </span>
            </div>
          )}
          {!isAccessible && (
            <div className="text-center mb-4">
              <span className="text-red-400 text-sm font-bold">
                You not have permission to login into brand office
              </span>
            </div>
          )}
        </div>
        {/* <div className="flex justify-end mt-2">
          <span
            className="cursor-pointer underline text-xs text-white dark:text-blue-500"
            onClick={() => onResetPassword()}
          >
            Forgot password
          </span>
        </div> */}
        <div className="flex justify-center my-4">
          <Button
            w="33.333333%"
            h="40px"
            px="16px "
            py="8px"
            borderRadius="0.375rem"
            fontSize="12px"
            lineHeight="16px"
          >
            LOGIN
          </Button>
        </div>
      </form>
      <div className=" w-[250px] flex flex-col justify-center my-4 mt-3">
        <div className="flex w-full justify-center items-center">
          <div className="w-[30%] bg-white h-[1px]"></div>
          <span className=" w-2/5 text-center font-bold text-sm uppercase text-white">
            Client Login
          </span>
          <div className="w-[30%] bg-white h-[1px]"></div>
        </div>
        <div className="flex w-full justify-center items-center mt-3">
          <button
            className="border w-full min-h-[40px] text-white px-4 py-2 rounded-md font-bold flex items-center justify-center gap-1"
            style={{ fontSize: "12px", lineHeight: "25px" }}
            onClick={() => onLoginWithPhoneNumber()}
          >
            <img className="w-6" src={PhoneIcon} alt="" />
            Phone Number
          </button>
        </div>
      </div>
      <div className="flex justify-center p-2 absolute bottom-5">
        <span className="cursor-pointer no-underline text-sm text-white dark:text-blue-500 font-semibold">
          Powered by diñeiro - v{pak.version}
        </span>
      </div>
      {spinner && <Spinner></Spinner>}
    </StyledComponent>
  );
};

type UseInfo = {
  officeId: string;
  email: string;
  password: string;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    userLogin: (data: UseInfo) => {
      dispatch(loginWithEmailRequest(data));
    },
    loginFail: (error) => {
      dispatch(loginWithEmailFailure(error));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    error: state.login.error,
  };
};
export default connect(null, mapDispatchToProps)(Login);
