import Button from "../common/button/Button";
import MessageIcon from "./../../images/home/message-icon.svg";
import ContentIcon from "./../../images/home/content.svg";
import SearchIcon from "./../../images/home/search-icon.svg";

const FilterBar = ({
  brand,
  setFirstFilter,
  setSecondFilter,
  setThirdFilter,
  onContent,
  handleChange,
  searchValue,
  onMessage,
  onSearch,
}) => {
  return (
    <>
      {brand &&
        (brand.filterConfig1 || brand.filterConfig2 || brand.filter3) && (
          <span className="p-2 text-xs font-bold uppercase">Filter by:</span>
        )}
      <div className="flex space-x-2 items-center justify-between">
        <div className="space-x-5">
          {brand && brand.filterConfig1 && (
            <select
              id="filter1"
              className="bg-black/20 w-48 h-11 px-3 py-2 rounded placeholder-gray-200 text-sm border-none focus:ring-white"
              onChange={(e) => setFirstFilter(e.target.value)}
            >
              <option value="">{brand.filterConfig1.name}</option>
              {brand.filterConfig1.values ? (
                brand.filterConfig1.values.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))
              ) : (
                <option value="">Options for Filter 1</option>
              )}
            </select>
          )}
          {brand && brand.filterConfig2 && (
            <select
              id="filter2"
              className="bg-black/20 w-48 h-11 px-3 py-2 rounded text-sm border-none focus:ring-white"
              onChange={(e) => setSecondFilter(e.target.value)}
            >
              <option value="">{brand.filterConfig2.name}</option>
              {brand.filterConfig2.values ? (
                brand.filterConfig2.values.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))
              ) : (
                <option value="">Options for Filter 1</option>
              )}
              {/* Options for Filter 2 */}
            </select>
          )}
          {brand && brand.filterConfig3 && (
            <select
              id="filter3"
              className="bg-black/20 w-48 h-11 px-3 py-2 rounded text-sm border-none focus:ring-white"
              onChange={(e) => setThirdFilter(e.target.value)}
            >
              <option value="">{brand.filterConfig3.name}</option>
              {brand.filterConfig3.values ? (
                brand.filterConfig3.values.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))
              ) : (
                <option value="">Options for Filter 1</option>
              )}
              {/* Options for Filter 3 */}
            </select>
          )}
        </div>
        <div className="flex space-x-2">
          <Button
            w=""
            h="40px"
            px="18px "
            borderRadius="0.375rem"
            onClick={() => onContent()}
          >
            <img src={ContentIcon} alt="" />
            <span>content</span>
          </Button>
          <Button
            w="full"
            h="40px"
            px="18px "
            borderRadius="0.375rem"
            onClick={() => onMessage()}
          >
            <img src={MessageIcon} alt="" />
            <span>MESSAGES</span>
          </Button>
        </div>
      </div>
      <div className="flex text-center">
        <input
          type="text"
          placeholder="Or Search Customers"
          className="bg-black/20 w-full h-11 rounded-l-lg text-sm border-none focus:ring-white"
          value={searchValue}
          onChange={handleChange}
        />
        <Button
          borderBottomRightRadius="8px"
          borderTopRightRadius="8px"
          px="14px "
          onClick={(e: Event) => onSearch()}
        >
          <img src={SearchIcon} alt="" />
        </Button>
      </div>
    </>
  );
};

export default FilterBar;
