import React, { useState, useEffect, useRef } from "react";
import {
  AccountDTO,
  LivingStatusEnum,
  PropertyAccountViewModel,
  PropertyDTO,
  PropertyDTOResponse,
  PropertyTypeEnum,
  SavingGoalDTO,
} from "@dineiro/dineiro-sdk-mobile";
import GoalSavingComponent from "../../components/goals-insights/GoalSavingComponent";
import OwnedProperty from "../../components/goals-insights/OwnedProperty";
import MortgagedProperty from "../../components/goals-insights/MortgagedProperty";
import Modal from "../../components/common/modal/Modal";
import { StyledComponent } from "../../components/common/change-themes/styles-component";
import Button from "../../components/common/button/Button";
import { useForm } from "react-hook-form";
import useModal from "../../hooks/useModal";
import {
  getBrandOfficesApi,
  getValocitysApi,
} from "../../services/api-service";
import { getLocalStorageElement, isValidNumber } from "../../utils/utils";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useGetProperties from "../../hooks/useGetProperties";

interface GroupedAccountLoan {
  providerAccount: any;
  accounts: AccountDTO[];
}
interface PropertyOption {
  displayText: string;
  propertyIdentifier: string;
  suggestionType: string;
}

const GoalAndInsightsTab: React.FC<{
  savingGoals: SavingGoalDTO[];
  accounts?: AccountDTO[];
}> = ({ savingGoals, accounts }) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const { officeid, id } = useParams();
  const [ownedProperties, setOwnedProperties] = useState<PropertyDTO[]>([]);
  const [mortgagedProperties, setMortgagedProperties] = useState<PropertyDTO[]>(
    []
  );
  const [groupedAccounts, setGroupedAccounts] = useState<GroupedAccountLoan[]>(
    []
  );
  const [selectedAccounts, setSelectedAccounts] = useState<
    PropertyAccountViewModel[]
  >([]);
  const { properties, shouldRefetchGetProperties, refetchGetProperties } =
    useGetProperties({
      officeId: officeid,
      userId: id,
    });
  const {
    show: showAddMortgagedPropertyModal,
    toggle: toggleAddMortgagedPropertyModal,
  } = useModal();
  const {
    show: showAddOwnedPropertyModal,
    toggle: toggleAddOwnedPropertyModal,
  } = useModal();
  const [searchResults, setSearchResults] = useState<PropertyOption[]>([]);
  const [externalPropertyId, setExternalPropertyId] = useState(null);
  const wrapperRef = useRef(null);
  const [showResults, setShowResults] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const searchAddress = watch("ownedProperty.address");
  const searchMortgagedAddress = watch("mortgagedProperty.address");
  const watchMortgagedPropertyLivingStatus = watch(
    "mortgagedProperty.livingStatus",
    false
  );
  const watchOnwedLivingStatus = watch("ownedProperty.livingStatus", false);

  useEffect(() => {
    if (properties.length > 0) {
      const listOwned = [];
      const listMortgaged = [];
      properties.map((property) => {
        if (property.type === PropertyTypeEnum.Owned) {
          listOwned.push(property);
        }
        if (property.type === PropertyTypeEnum.Financed) {
          listMortgaged.push(property);
        }
      });
      setOwnedProperties(listOwned);
      setMortgagedProperties(listMortgaged);
    }
  }, [properties, shouldRefetchGetProperties]);

  const groupLoans = () => {
    if (accounts.length > 0) {
      const sortedAccounts = accounts.sort((a, b) =>
        a?.providerName?.localeCompare(b?.providerName)
      );

      const groupedAccounts = new Map<any, AccountDTO[]>();

      sortedAccounts.forEach((account) => {
        groupedAccounts.has(account?.providerName)
          ? groupedAccounts.get(account?.providerName).push(account)
          : groupedAccounts.set(account?.providerName, [account]);
      });
      let index = 0;
      const result = Array.from(
        groupedAccounts.entries()
      ).map<GroupedAccountLoan>(([providerAccount, accountList]) => ({
        providerAccount,
        accounts: accountList,
      }));
      setGroupedAccounts(result);
    }
  };

  const onSearchAddress = async (address) => {
    const valocitysApi = getValocitysApi(storedIdToken);
    try {
      await valocitysApi
        .valocitySearchGet({ q: address })
        .then((rep) => {
          console.log(rep);
        })
        .catch((reason: any) => {
          reason.response.json().then((x: any) => {
            if (x.status && x.status === 300) {
              setSearchResults(x.data);
            }
          });
        });
    } catch (error) {
      setSearchResults([]);
    }
  };

  const getValocityAddress = async (valocityId) => {
    const valocitysApi = getValocitysApi(storedIdToken);
    try {
      const result = await valocitysApi.valocityGet({ id: valocityId });
      return result.data as PropertyDTO;
    } catch (error) {
      return null;
    }
  };

  const handleInputChange = (e) => {
    setExternalPropertyId(null);
    setShowResults(true);
  };

  useEffect(() => {
    groupLoans();
  }, [accounts]);

  useEffect(() => {
    if (searchAddress && searchAddress?.trim().length >= 3 && showResults) {
      onSearchAddress(searchAddress);
    } else {
      setShowResults(false);
      setSearchResults([]);
    }
  }, [searchAddress]);
  useEffect(() => {
    if (
      searchMortgagedAddress &&
      searchMortgagedAddress?.trim().length >= 3 &&
      showResults
    ) {
      onSearchAddress(searchMortgagedAddress);
    } else {
      setShowResults(false);
      setSearchResults([]);
    }
  }, [searchMortgagedAddress]);

  const handleSelectAddress = (
    address: PropertyOption,
    isFinancialProperty
  ) => {
    setSearchResults([]);
    setShowResults(false);
    setExternalPropertyId(address.propertyIdentifier);
    if (isFinancialProperty) {
      setValue("mortgagedProperty.address", address.displayText);
    } else setValue("ownedProperty.address", address.displayText);
  };

  const onSubmitFinancialProperty = async () => {
    const data = getValues();
    const validatedResult = await trigger("mortgagedProperty");
    const brandApi = getBrandOfficesApi(storedIdToken);
    // const valocityAddress = await getValocityAddress(externalPropertyId);
    if (validatedResult)
      toast.promise(
        getValocityAddress(externalPropertyId).then((valocityAddress) =>
          brandApi
            .brandOfficeCustomersPropertiesPost({
              id: parseInt(officeid),
              userId: id,
              brandOfficeCustomersPropertiesPostViewModel: {
                livingStatus: data?.mortgagedProperty.livingStatus,
                address: data?.mortgagedProperty?.address,
                purchasePrice: data?.mortgagedProperty?.purchasePrice,
                propertyAccounts: selectedAccounts,
                rentPrice: data?.mortgagedProperty?.rentPrice ?? null,
                livedYears:
                  data?.mortgagedProperty?.livedYears &&
                  data?.mortgagedProperty?.livedYears?.trim() !== ""
                    ? data?.mortgagedProperty?.livedYears
                    : null,
                externalId: externalPropertyId,
                carParks: valocityAddress?.carParks ?? null,
                bedrooms: valocityAddress?.bedrooms ?? null,
                bathrooms: valocityAddress?.bathrooms ?? null,
                floorArea: valocityAddress?.floorArea ?? null,
                yearBuilt: valocityAddress?.yearBuilt ?? null,
                type: "Financed",
              },
            })
            .then(() => {
              refetchGetProperties(!shouldRefetchGetProperties);
              toggleAddMortgagedPropertyModal();
              setSelectedAccounts([]);
            })
        ),
        {
          loading: "Saving...",
          success: <b>Successfully added property.</b>,
          error: <b>Failed save property.</b>,
        }
      );
  };

  const onSubmitOwnedProperty = async () => {
    const data = getValues();
    const validatedResult = await trigger("ownedProperty");
    const brandApi = getBrandOfficesApi(storedIdToken);
    if (validatedResult)
      toast.promise(
        getValocityAddress(externalPropertyId).then((valocityAddress) =>
          brandApi
            .brandOfficeCustomersPropertiesPost({
              id: parseInt(officeid),
              userId: id,
              brandOfficeCustomersPropertiesPostViewModel: {
                livingStatus: data?.ownedProperty?.livingStatus,
                address: data?.ownedProperty?.address,
                purchasePrice: data?.ownedProperty?.purchasePrice,
                rentPrice: data?.ownedProperty?.rentPrice ?? null,
                livedYears:
                  data?.ownedProperty?.livedYears &&
                  data?.ownedProperty?.livedYears?.trim() !== ""
                    ? data?.ownedProperty?.livedYears
                    : null,
                externalId: externalPropertyId,
                carParks: valocityAddress?.carParks ?? null,
                bedrooms: valocityAddress?.bedrooms ?? null,
                bathrooms: valocityAddress?.bathrooms ?? null,
                floorArea: valocityAddress?.floorArea ?? null,
                yearBuilt: valocityAddress?.yearBuilt ?? null,
                type: "Owned",
              },
            })
            .then(() => {
              refetchGetProperties(!shouldRefetchGetProperties);
              toggleAddOwnedPropertyModal();
            })
        ),
        {
          loading: "Saving...",
          success: <b>Successfully added property.</b>,
          error: <b>Failed save property.</b>,
        }
      );
  };

  const handleOptionChange = (accountId, isMain) => {
    if (isMain) {
      const haveMainAccount = selectedAccounts.find(
        (acc) => acc.isMain === true
      );
      if (accountId.length === 0)
        setSelectedAccounts(
          selectedAccounts.filter((acc) => acc.isMain !== true)
        );
      else if (haveMainAccount) {
        const newListAccount = selectedAccounts.filter(
          (acc) => acc.isMain !== true && acc.accountId !== accountId
        );
        newListAccount.push({ accountId: accountId, isMain: true });
        setSelectedAccounts(newListAccount);
      } else {
        const newListAccount = selectedAccounts.filter(
          (acc) => acc.accountId !== accountId
        );
        newListAccount.push({ accountId: accountId, isMain: true });
        setSelectedAccounts(newListAccount);
      }
    } else {
      const isAccountSelected = selectedAccounts.some(
        (acc) => acc.accountId === accountId
      );
      if (isAccountSelected) {
        setSelectedAccounts(
          selectedAccounts.filter((acc) => acc.accountId !== accountId)
        );
      } else {
        setSelectedAccounts([
          ...selectedAccounts,
          { accountId: accountId, isMain: false },
        ]);
      }
    }
  };

  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div>
      <div className=" my-10">
        <div className="flex justify-center text-sm font-semibold">
          <h3 className=" text-3xl header-h3 font-bold">Goal Savings</h3>
        </div>
        {savingGoals.length > 0 ? (
          savingGoals.map((item, index) => {
            return (
              <GoalSavingComponent
                key={index}
                item={item}
              ></GoalSavingComponent>
            );
          })
        ) : (
          <div className="flex justify-center my-10 text-[15px]">
            This user does not have any savings goals.
          </div>
        )}
      </div>
      <div className=" mt-48 ">
        <div className="flex justify-center text-sm font-semibold">
          <h3 className=" text-3xl header-h3 font-bold">Property Insights</h3>
        </div>
        <div className="mb-5 py-2">
          <div className="p-2 space-y-28">
            {ownedProperties.length > 0 ? (
              <div className="mb-2 space-y-5">
                <span className="text-2xl font-[700] leading-5">
                  Owned Properties
                </span>
                {ownedProperties.map((ownedProperty) => {
                  return (
                    <OwnedProperty
                      key={ownedProperty.id}
                      groupedAccounts={groupedAccounts}
                      shouldRefetchGetProperties={shouldRefetchGetProperties}
                      refetchGetProperties={refetchGetProperties}
                      ownedProperty={ownedProperty}
                    ></OwnedProperty>
                  );
                })}
                <div className="flex justify-center">
                  <Button
                    h="45px"
                    px="16px "
                    py="8px"
                    borderRadius="4px"
                    fontSize="12px"
                    lineHeight="20px"
                    onClick={() => {
                      reset();
                      toggleAddOwnedPropertyModal();
                    }}
                  >
                    Add Owned property
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mb-2 space-y-5">
                <span className="text-2xl font-[700] leading-5">
                  Owned Properties
                </span>
                <div className="flex justify-center my-10 text-[15px]">
                  This user does not have any owned properties.
                </div>
                <div className="flex justify-center">
                  <Button
                    h="45px"
                    px="16px "
                    py="8px"
                    borderRadius="4px"
                    fontSize="12px"
                    lineHeight="20px"
                    onClick={() => {
                      reset();
                      toggleAddOwnedPropertyModal();
                    }}
                  >
                    Add Owned property
                  </Button>
                </div>
              </div>
            )}
            {mortgagedProperties.length > 0 ? (
              <div className="mb-2 space-y-5">
                <span className="text-2xl font-[700] leading-5">
                  Mortgaged Properties
                </span>
                {mortgagedProperties.map((item) => {
                  return (
                    <MortgagedProperty
                      key={item.id}
                      mortgagedProperty={item}
                      handleOptionChange={handleOptionChange}
                      groupedAccounts={groupedAccounts}
                      accounts={accounts}
                      shouldRefetchGetProperties={shouldRefetchGetProperties}
                      refetchGetProperties={refetchGetProperties}
                    ></MortgagedProperty>
                  );
                })}
                <div className="flex justify-center">
                  <Button
                    h="45px"
                    px="16px "
                    py="8px"
                    borderRadius="4px"
                    fontSize="12px"
                    lineHeight="20px"
                    onClick={() => {
                      reset();
                      toggleAddMortgagedPropertyModal();
                    }}
                  >
                    Add Financed property
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mb-2 space-y-5">
                <span className="text-2xl font-[700] leading-5">
                  Mortgaged Properties
                </span>
                <div className="flex justify-center my-10 text-[15px]">
                  This user does not have any mortgaged properties.
                </div>
                <div className="flex justify-center">
                  <Button
                    h="45px"
                    px="16px "
                    py="8px"
                    borderRadius="4px"
                    fontSize="12px"
                    lineHeight="20px"
                    onClick={() => {
                      reset();
                      toggleAddMortgagedPropertyModal();
                    }}
                  >
                    Add Financed property
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showAddMortgagedPropertyModal}
        toggle={toggleAddMortgagedPropertyModal}
        width={"w-[495px]"}
        content={
          <StyledComponent>
            <div className="p-5 space-y-5">
              <div className="flex flex-col m-2">
                <span className=" uppercase text-xs text-white/70">
                  property
                </span>
                <p
                  style={{ fontSize: "30px", lineHeight: "34px" }}
                  className=" header-h3 w-[335px] font-bold"
                >
                  Let’s add a mortgaged property
                </p>
                <div className="h-1 bg-yellow-500 w-[100px] mt-1"></div>
              </div>
              <form className="text-xs">
                <div className="flex flex-col gap-2 mb-4 m-2">
                  <div className="flex flex-col">
                    <select
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      id="mortgagedProperty.livingStatus"
                      {...register("mortgagedProperty.livingStatus", {
                        required: "Your must select Living Status ",
                      })}
                    >
                      <option value="">Living Status</option>
                      <option value={LivingStatusEnum.PrimaryDwelling}>
                        I live here (primary dwelling)
                      </option>
                      <option value={LivingStatusEnum.InvestmentProperty}>
                        This is an investment property
                      </option>
                      <option value={LivingStatusEnum.SecondHome}>
                        This is a secondary home
                      </option>
                    </select>
                    {errors?.mortgagedProperty?.["livingStatus"] && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors.mortgagedProperty?.[
                          "livingStatus"
                        ].message.toString()}
                      </span>
                    )}
                  </div>
                  {watchMortgagedPropertyLivingStatus ===
                    LivingStatusEnum.PrimaryDwelling && (
                    <div className="flex flex-col">
                      <input
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                        type="number"
                        id="mortgagedProperty.livedYears"
                        placeholder="Enter the number of years you have lived"
                        {...register("mortgagedProperty.livedYears", {
                          required: "Your must enter years you have lived",
                          min: {
                            value: 0,
                            message: "Min value is 0",
                          },
                        })}
                      />
                      {errors.mortgagedProperty?.["livedYears"] && (
                        <span className="text-red-400 text-sm font-bold  px-1 ">
                          {errors.mortgagedProperty?.[
                            "livedYears"
                          ].message.toString()}
                        </span>
                      )}
                    </div>
                  )}
                  {watchMortgagedPropertyLivingStatus ===
                    LivingStatusEnum.InvestmentProperty && (
                    <div className="flex flex-col">
                      <input
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                        type="number"
                        id="mortgagedProperty.rentPrice"
                        placeholder="Rent Price"
                        {...register("mortgagedProperty.rentPrice", {
                          required: "Your must enter rent price",
                          min: {
                            value: 0,
                            message: "Min value is 0",
                          },
                        })}
                      />
                      {errors.mortgagedProperty?.["rentPrice"] && (
                        <span className="text-red-400 text-sm font-bold  px-1 ">
                          {errors.mortgagedProperty?.[
                            "rentPrice"
                          ].message.toString()}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col">
                    <select
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      id="mortgagedProperty.primaryLoanAccount"
                      {...register("mortgagedProperty.primaryLoanAccount", {
                        required: "Your must select your primary loan account ",
                        onChange: (e) =>
                          handleOptionChange(e.target.value, true),
                      })}
                    >
                      <option value="">Select your primary loan account</option>
                      {accounts.map((account) => {
                        return (
                          <option value={account.id} key={account.id}>
                            {`${account?.providerName ?? ""} (
                      ${account?.accountNumber ?? ""}
                      )`}
                          </option>
                        );
                      })}
                    </select>
                    {errors.mortgagedProperty?.["primaryLoanAccount"] && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors.mortgagedProperty?.[
                          "primaryLoanAccount"
                        ].message.toString()}
                      </span>
                    )}
                  </div>
                  <div className=" relative" ref={wrapperRef}>
                    <input
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      type="text"
                      id="mortgagedProperty.address"
                      placeholder="Enter Address"
                      {...register("mortgagedProperty.address", {
                        required: "You must enter property's address",
                        onChange: handleInputChange,
                      })}
                    />
                    {showResults && searchResults.length > 0 && (
                      <ul className="absolute z-20 w-full bg-white text-black border-[1px] rounded-md shadow-lg p-4 max-h-[200px] overflow-y-auto mt-1">
                        {searchResults.map((item) => (
                          <li
                            key={item?.propertyIdentifier}
                            className="min-h-10 w-full border-b-[1px] border-solid border-gray-300 py-2 cursor-pointer"
                            onClick={() => handleSelectAddress(item, true)}
                          >
                            {item.displayText}
                          </li>
                        ))}
                      </ul>
                    )}
                    {errors.mortgagedProperty?.["address"] && (
                      <div className="text-red-400 text-sm font-bold px-1">
                        {errors.mortgagedProperty?.[
                          "address"
                        ].message.toString()}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <input
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      type="number"
                      id="mortgagedProperty.purchasePrice"
                      placeholder="Purchase Price"
                      {...register("mortgagedProperty.purchasePrice", {
                        required: "Your must enter purchase price ",
                        min: {
                          value: 0,
                          message: "Min value is 0",
                        },
                      })}
                    />
                    {errors.mortgagedProperty?.["purchasePrice"] && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors.mortgagedProperty?.[
                          "purchasePrice"
                        ].message.toString()}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="mb-4 p-2">
                    <span
                      style={{ fontSize: "22px", lineHeight: "25px" }}
                      className=" font-bold "
                    >
                      Do you have any other accounts to link to this property?
                    </span>
                  </div>
                  {groupedAccounts.map((groupedAccount) => (
                    <div key={groupedAccount.providerAccount}>
                      <div
                        style={{ fontSize: "16px", lineHeight: "16px" }}
                        className="flex items-center font-bold h-[40px] p-3 bg-black"
                      >
                        <span>{groupedAccount.providerAccount}</span>
                      </div>
                      {groupedAccount.accounts.map((account, index) => {
                        let isMainAccount = selectedAccounts.some(
                          (acc) =>
                            acc.accountId.toString() ===
                              account.id.toString() && acc.isMain === true
                        );
                        return (
                          <div
                            style={{ fontSize: "13px", lineHeight: "12px" }}
                            className="flex justify-between gap-4 bg-white h-[40px] items-center p-3"
                          >
                            <span className=" text-black font-bold">{`${
                              account.accountName ?? ""
                            } (
                      ${account.accountNumber ?? ""}
                      )`}</span>
                            <label
                              htmlFor={account.id.toString()}
                              className="flex cursor-pointer select-none items-center space-x-2"
                            >
                              <div className="relative">
                                <input
                                  id={account.id.toString()}
                                  type="checkbox"
                                  value={account.id}
                                  checked={selectedAccounts.some(
                                    (acc) =>
                                      acc.accountId.toString() ===
                                      account.id.toString()
                                  )}
                                  onChange={(e) => {
                                    if (!isMainAccount) {
                                      handleOptionChange(e.target.value, false);
                                    }
                                  }}
                                  disabled={isMainAccount}
                                  className="peer sr-only"
                                />
                                <div className="block h-[24px] w-[42px] rounded-full bg-gray-300"></div>
                                <div className="absolute w-[24px] h-[24px] transition rounded-full left-[-2px] dot-property top-[0px] peer-checked:translate-x-full peer-checked:bg-green-500"></div>
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </form>
              <div className="flex justify-center gap-2">
                <Button
                  h="45px"
                  px="16px "
                  py="8px"
                  borderRadius="4px"
                  fontSize="14px"
                  lineHeight="20px"
                  onClick={onSubmitFinancialProperty}
                >
                  Save Property
                </Button>
              </div>
            </div>
          </StyledComponent>
        }
      ></Modal>
      <Modal
        show={showAddOwnedPropertyModal}
        toggle={toggleAddOwnedPropertyModal}
        width={"w-[495px]"}
        content={
          <StyledComponent>
            <div className="p-5 space-y-5">
              <div className="flex flex-col m-2">
                <span className=" uppercase text-xs text-white/70">
                  property
                </span>
                <p
                  style={{ fontSize: "30px", lineHeight: "34px" }}
                  className=" header-h3 w-[335px] font-bold"
                >
                  Let’s add a property to your account
                </p>
                <div className="h-1 bg-yellow-500 w-[100px]  mt-1"></div>
              </div>
              <form className="text-xs">
                <div className="flex flex-col gap-2 mb-4 m-2">
                  <div className="flex flex-col">
                    <select
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      id="ownedProperty.livingStatus"
                      {...register("ownedProperty.livingStatus", {
                        required: "Your must select Living Status ",
                      })}
                    >
                      <option value="">Living Status</option>
                      <option value={LivingStatusEnum.PrimaryDwelling}>
                        I live here (primary dwelling)
                      </option>
                      <option value={LivingStatusEnum.InvestmentProperty}>
                        This is an investment property
                      </option>
                      <option value={LivingStatusEnum.SecondHome}>
                        This is a secondary home
                      </option>
                    </select>
                    {errors?.ownedProperty?.["livingStatus"] && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors.ownedProperty?.[
                          "livingStatus"
                        ].message.toString()}
                      </span>
                    )}
                  </div>
                  {watchOnwedLivingStatus ===
                    LivingStatusEnum.PrimaryDwelling && (
                    <div className="flex flex-col">
                      <input
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                        type="number"
                        id="ownedProperty.livedYears"
                        placeholder="Enter the number of years you have lived"
                        {...register("ownedProperty.livedYears", {
                          required: "Your must enter years you have lived",
                          min: {
                            value: 0,
                            message: "Min value is 0",
                          },
                        })}
                      />
                      {errors.ownedProperty?.["livedYears"] && (
                        <span className="text-red-400 text-sm font-bold  px-1 ">
                          {errors.ownedProperty?.[
                            "livedYears"
                          ].message.toString()}
                        </span>
                      )}
                    </div>
                  )}
                  {watchOnwedLivingStatus ===
                    LivingStatusEnum.InvestmentProperty && (
                    <div className="flex flex-col">
                      <input
                        className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                        type="number"
                        id="ownedProperty.rentPrice"
                        placeholder="Rent Price"
                        {...register("ownedProperty.rentPrice", {
                          required: "Your must enter rent price",
                          min: {
                            value: 0,
                            message: "Min value is 0",
                          },
                        })}
                      />
                      {errors.ownedProperty?.["rentPrice"] && (
                        <span className="text-red-400 text-sm font-bold  px-1 ">
                          {errors.ownedProperty?.[
                            "rentPrice"
                          ].message.toString()}
                        </span>
                      )}
                    </div>
                  )}
                  <div className=" relative" ref={wrapperRef}>
                    <input
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      type="text"
                      id="ownedProperty.address"
                      placeholder="Enter Address"
                      {...register("ownedProperty.address", {
                        required: "You must enter property's address",
                        onChange: handleInputChange,
                      })}
                    />
                    {showResults && searchResults.length > 0 && (
                      <ul className="absolute z-20 w-full bg-white text-black border-[1px] rounded-md shadow-lg p-4 max-h-[200px] overflow-y-auto mt-1">
                        {searchResults.map((item) => (
                          <li
                            key={item.propertyIdentifier}
                            className="min-h-10 w-full border-b-[1px] border-solid border-gray-300 py-2 cursor-pointer"
                            onClick={() => handleSelectAddress(item, false)}
                          >
                            {item.displayText}
                          </li>
                        ))}
                      </ul>
                    )}
                    {errors.ownedProperty?.["address"] && (
                      <div className="text-red-400 text-sm font-bold px-1">
                        {errors.ownedProperty?.["address"].message.toString()}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <input
                      className="w-full h-12 px-3 py-2 bg-black/20 rounded dark:text-white text-sm border-none focus:ring-white"
                      type="number"
                      id="ownedProperty.purchasePrice"
                      placeholder="Purchase Price"
                      {...register("ownedProperty.purchasePrice", {
                        required: "Your must enter purchase price ",
                        min: {
                          value: 0,
                          message: "Min value is 0",
                        },
                      })}
                    />
                    {errors.ownedProperty?.["purchasePrice"] && (
                      <span className="text-red-400 text-sm font-bold  px-1 ">
                        {errors.ownedProperty?.[
                          "purchasePrice"
                        ].message.toString()}
                      </span>
                    )}
                  </div>
                </div>
              </form>
              <div className="flex justify-center gap-2">
                <Button
                  h="45px"
                  px="16px "
                  py="8px"
                  borderRadius="4px"
                  fontSize="14px"
                  lineHeight="20px"
                  onClick={onSubmitOwnedProperty}
                >
                  Save Property
                </Button>
              </div>
            </div>
          </StyledComponent>
        }
      ></Modal>
    </div>
  );
};

export default GoalAndInsightsTab;
