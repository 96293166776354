import { useState } from "react";
import UserIcon from "../../../images/home/userIcon.svg";

const CustomerTable = ({
  customers,
  selectedCustomers,
  handleCustomerSelect,
  alphabet,
  tableHeight,
}) => {
  const [selectedAlphabet, selectAlphabet] = useState(undefined);

  const selectedAlphabetHandler = (char: string) => {
    if (char === selectedAlphabet) {
      selectAlphabet(undefined);
      return;
    }
    selectAlphabet(char);
  };

  return (
    <div className="flex gap-[30px]">
      <div className={`w-full max-h-[${tableHeight}] overflow-auto`}>
        <table className="border-collapse mt-1 table-auto w-full text-sm">
          <tbody>
            {customers.filter(s => !selectedAlphabet || s.firstName.startsWith(selectedAlphabet)).map((customer) => {
              const isSelected = selectedCustomers.some(c => c.id === customer.id);

              return (
                <tr
                  key={customer.id}
                  className={` ${isSelected ? "pick-user" : ""
                    } cursor-pointer border-b border-white/40`}
                  onClick={() => handleCustomerSelect(customer)}
                >
                  <td className=" py-2">
                    <div className="flex item-center space-x-2 px-2">
                      <img className="w-5 h-5" src={UserIcon} alt="" />
                      <span>
                        {customer.firstName + " " + customer.lastName}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col gap-2 items-center justify-center my-5 text-xs ">
        {alphabet.map((letter) => (
          <div key={letter} className={letter === selectedAlphabet ? "cursor-pointer text-xl font-bold" : "cursor-pointer text-[13px] font-semibold"} onClick={() => selectedAlphabetHandler(letter)}>
            {letter}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerTable;
