import { useEffect, useLayoutEffect, useRef, useState } from "react";
import FilterIcon from "./../../images/home/filter-icon.svg";
import EditorComponent from "../../components/common/editor/EditorComponent";
import ContentTable from "../../components/content-table/ContentTable";
import Button from "../../components/common/button/Button";
import { useParams } from "react-router-dom";
import useCustomers from "../../hooks/useCustomers";
import {
  getLocalStorageElement,
  getUniqueStartCharacters,
} from "../../utils/utils";
import CustomerTable from "../../components/common/customer-list/CustomersList";
import { StyledCheckBox } from "../../components/common/change-themes/styles-component";
import { getBrandOfficesApi } from "../../services/api-service";
import toast, { Toaster } from "react-hot-toast";
import useBrandContents from "../../hooks/useBrandContents";
import Spinner from "../../components/common/spinner/Spinner";
import PendingReleases from "../../components/content-table/PendingRelease";

const AdminContent = () => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [alphabet, setAlphabet] = useState([]);
  const [reloadContent, setReloadContent] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageName, setImageName] = useState("");
  const [primaryImage, setPrimaryImage] = useState<Blob>();
  const { officeid } = useParams();

  const { customers } = useCustomers({ officeId: officeid });
  const { shouldRefetch, contents, pendingContents, refetch } =
    useBrandContents({
      officeId: officeid,
    });
  const [selectAll, setSelectAll] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const handleCustomerSelect = (customerId) => {
    if (selectedCustomers.includes(customerId)) {
      setSelectedCustomers(selectedCustomers.filter((id) => id !== customerId));
    } else {
      setSelectedCustomers([...selectedCustomers, customerId]);
    }
  };

  const onClearSelection = () => {
    setSelectedCustomers([]);
    if (selectAll) {
      setSelectAll(!selectAll);
    }
  };

  useEffect(() => {
    setSpinner(true);
    if (customers.length > 0) {
      const getAlphabet = getUniqueStartCharacters(customers);
      setAlphabet(getAlphabet);
    }
    setTimeout(async () => {
      setSpinner(false);
    }, 2000);
  }, [customers]);

  const handleSentContent = async () => {
    if (selectedCustomers.length > 0) {
      if (title === "") {
        toast.error("Please enter a title for the content");
      } else if (primaryImage === null || primaryImage === undefined) {
        toast.error("Please upload the primary image");
      } else if (content === "" || content === "<p></p>") {
        toast.error("Please enter the content");
      } else {
        const selectedIds = selectedCustomers.map((customer) => customer.id);
        const userReportApi = getBrandOfficesApi(storedIdToken);
        try {
          await userReportApi
            .brandOfficeArticlesPost({
              id: parseInt(officeid),
              title: title,
              primaryImage: primaryImage,
              content: content,
              targetUserIds: selectedIds,
            })
            .then((respone) => {
              refetch(!shouldRefetch);
              setTitle("");
              setContent("");
              setPrimaryImage(null);
              setImageName("");
              setReloadContent(!reloadContent);
            });
          toast.success("Sent content successful");
        } catch (error) {
          error.response.json().then((x: any) => {
            toast.error(x.errors[""][0]);
          });
        }
      }
    } else toast.error("Please select user to sent!");
  };

  const handleRecallMessage = (contentiId) => {
    if (contentiId) {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      toast.promise(
        userReportApi
          .brandOfficeArticlesStatusTogglePost({
            id: parseInt(officeid),
            officeArticleId: contentiId,
          })
          .then((respone) => {
            refetch(!shouldRefetch);
          }),
        {
          loading: "Saving...",
          success: <b>Recall content successful.</b>,
          error: <b>Failed to recall content.</b>,
        }
      );
    } else toast.error("Please select user to sent!");
  };

  const handleApproveContent = (contentiId) => {
    if (contentiId) {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      toast.promise(
        userReportApi
          .brandOfficeArticlesIsApprovedPost({
            id: parseInt(officeid),
            officeArticleId: contentiId,
          })
          .then((respone) => {
            refetch(!shouldRefetch);
          }),
        {
          loading: "Saving...",
          success: <b>Recall content successful.</b>,
          error: <b>Failed to recall content.</b>,
        }
      );
    } else toast.error("Please select user to sent!");
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedCustomers(customers);
    } else setSelectedCustomers([]);
  }, [selectAll]);

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.includes("image")) {
      const uploadedImage = new Blob([file], {
        type: file.type,
      });
      setImageName(file.name);
      setPrimaryImage(uploadedImage);
    } else {
      toast.error("Upload failed. Please upload image only");
    }
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp) document.title = `Contents - ${storedProp}`;
  }, []);

  return (
    <div className="items-center justify-center p-3">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">DINEIRO ADMIN</h6>
        <span className=" text-5xl text-center font-bold">
          Content Manager.
        </span>
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="grid grid-cols-3 w-2/3 mt-5 gap-[40px]">
          <div className="flex flex-col col-span-2">
            <span className="mb-2 text-xl font-semibold">New Message</span>
            <div className="w-full space-y-2 mb-2">
              <input
                type="text"
                placeholder="Message Headline"
                value={title}
                className="bg-black/20 w-full h-11 rounded placeholder-gray-200 text-sm border-none focus:ring-white"
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="flex">
                <input
                  type="text"
                  placeholder="Primary Image"
                  className="bg-black/20 w-full h-11 rounded-l-md placeholder-gray-200 text-sm border-none focus:ring-white"
                  value={imageName}
                  onChange={(e) => e}
                />
                <Button
                  h="40px"
                  w="1/5"
                  px="12px"
                  borderBottomRightRadius="8px"
                  borderTopRightRadius="8px"
                  onClick={handleClick}
                >
                  UPLOAD
                </Button>
                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
            </div>
            <div className=" w-full my-3">
              <EditorComponent
                setContent={setContent}
                reloadContent={reloadContent}
              />
            </div>
            <div className=" my-2">
              <Button
                h="40px"
                w="1/5"
                px="12px"
                borderRadius="6px"
                onClick={() => handleSentContent()}
              >
                SEND MESSAGE
              </Button>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="py-2 text-xl font-semibold">All Customers</span>
            <div className="flex justify-between font-bold py-2 mt-3 text-sm">
              <div className="flex items-center mb-4">
                <StyledCheckBox
                  id="default-checkbox"
                  type="checkbox"
                  className="w-5 h-5 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                  checked={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                ></StyledCheckBox>
                <label htmlFor="default-checkbox" className="ms-2 ">
                  Select all
                </label>
              </div>
              <div>
                <button
                  className="underline"
                  onClick={() => onClearSelection()}
                >
                  Clear Selection
                </button>
              </div>
            </div>
            <div className="flex items-center space-x-1 font-bold text-sm">
              <span>Customer Name</span>
              <img src={FilterIcon} alt="" />
            </div>
            <CustomerTable
              customers={customers}
              selectedCustomers={selectedCustomers}
              alphabet={alphabet}
              handleCustomerSelect={handleCustomerSelect}
              tableHeight={"750px"}
            ></CustomerTable>
          </div>
        </div>
        <div className="w-2/3 my-5">
          <PendingReleases
            contents={pendingContents}
            handleApproveContent={handleApproveContent}
          />
        </div>
        <div className="w-2/3 my-5">
          <ContentTable
            contents={contents}
            handleRecallMessage={handleRecallMessage}
          />
        </div>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default AdminContent;
