import { useState, useEffect } from "react";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useBrokers = ({ brandOfficeId, userRole }) => {
  const [brokers, setBrokers] = useState([
    {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      userRoles: [],
    },
  ]);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;
  const BrokersComponent = () => {
    useEffect(() => {
      if (
        user &&
        brandOfficeId !== 0 &&
        (userRole === "office-manager" || userRole === "brand-manager")
      ) {
        const fetchData = async () => {
          const brandApi = getBrandOfficesApi(storedIdToken);

          try {
            const response = await brandApi.brandOfficeBrokersGet({
              id: brandOfficeId,
            });
            const updatedBrokers = response.data.map((item) => ({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              email: item.email,
              userRoles: item.userRoles,
            }));
            setBrokers(updatedBrokers);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userRole]);

    return null;
  };

  BrokersComponent();

  return { brokers: brokers };
};

export default useBrokers;
