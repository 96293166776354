import {
  CONTAINEREnum,
  OverriddenAccountTypeValue,
} from "@dineiro/dineiro-sdk-mobile";
import React, { useEffect, useState } from "react";
import { generateRandomCode } from "../../utils/utils";

const LoanAccountType = ({
  accountType,
  container = "",
  accountId = 0,
  onChangeTypes,
}) => {
  const [accountTypes, setAccountTypes] = useState([]);
  useEffect(() => {
    var valueArray = Object.entries(OverriddenAccountTypeValue).map(
      ([key, value]) => ({
        id: generateRandomCode(),
        key,
        value,
      })
    );
    if (
      container === CONTAINEREnum.CreditCard ||
      container === CONTAINEREnum.Loan
    ) {
      const list = valueArray.filter(
        (type) =>
          type.value.includes("CREDIT") ||
          type.value.includes("LOAN") ||
          type.value === "MORTGAGE"
      );
      setAccountTypes(list);
    }

    if (container === CONTAINEREnum.Investment) {
      const list = valueArray.filter(
        (type) =>
          type.value === "INDIVIDUAL" ||
          type.value === "TRUST" ||
          type.value === "ANNUITY" ||
          type.value === "SUPER_ANNUATION" ||
          type.value === "SHARES"
      );
      setAccountTypes(list);
    }
  }, [container]);
  return (
    <div>
      {accountTypes.length > 0 ? (
        <select
          className="text-sm w-full text-left font-semibold bg-transparent border-none outline-none focus:ring-0 p-0"
          onChange={(e) => onChangeTypes(e.target.value, accountId)}
        >
          <option value="">{accountType.replace(/_/g, " ")}</option>
          {accountTypes.map((type) => (
            <option value={type.key} key={type.id}>
              {type.value.replace(/_/g, " ")}
            </option>
          ))}
        </select>
      ) : (
        <select className="text-sm w-full text-left font-semibold bg-transparent border-none outline-none focus:ring-0 p-0">
          <option value="">{accountType.replace(/_/g, " ")}</option>
        </select>
      )}
    </div>
  );
};

export default LoanAccountType;
