import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CUSTOMER_TABS,
  FACTFIND_TABS,
  FACTFIND_LIST_TABS,
} from "../../../constants/constants";
import { StyledFactFindBar } from "../../../components/common/change-themes/styles-component";
import Spinner from "../../../components/common/spinner/Spinner";
import { Toaster } from "react-hot-toast";
import PersonalSession from "./PersonalSession";
import StartUpTab from "./StartUpTab";
import AdressSession from "./AdressSession";
import EmploymentSession from "./EmploymentSession";
import CompanyOrTrustSession from "./CompanyOrTrustSession";
import AssetsSession from "./AssetsSession";
import IncomeSession from "./IncomeSession";
import LiabilitiesSession from "./LiabilitiesSession";
import ExpensesSession from "./ExpensesSession";
import QuestionsSession from "./QuestionsSession";
import DisclaimerSession from "./DisclaimerSession";
import {
  AccountDTO,
  ApplyThrough,
  CONTAINEREnum,
  Period,
  Section,
} from "@dineiro/dineiro-sdk-mobile";
import useGetFactFind from "../../../hooks/useGetFactFind";
import useGetProperties from "../../../hooks/useGetProperties";
import useGetAccounts from "../../../hooks/useGetAccounts";
import useHoldings from "../../../hooks/useHoldings";
import useUserSuperAccounts from "../../../hooks/useUserSuperAccounts";
import useGetCustomerById from "../../../hooks/useGetCustomerById";
import useGetVehicles from "../../../hooks/useUserProperties";
import useUserExpenses from "../../../hooks/useUserExpenses";
import useGetExpensesDetail from "../../../hooks/useGetExpensesDetail";

const FactFind = () => {
  const { officeid, tab, id, customername, subtab } = useParams();
  const [openTab, setOpenTab] = React.useState(FACTFIND_TABS.personal);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [numberOfApplicants, setNumberOfApplicants] = useState(0);
  const [isStartUpFactFind, setIsStartUpFactFind] = useState(true);
  const [applyingBy, setApplyingBy] = useState<ApplyThrough>();
  const [banks, setBanks] = useState<AccountDTO[]>([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const tabsRef = useRef([]);
  const [workingSections, setWorkingSections] = useState([]);
  const { factFind, refetchGet, shouldRefetchGet } = useGetFactFind({
    officeId: officeid,
    userId: id,
  });

  const validateionSubTab = () => {
    if (
      ((subtab && !workingSections.includes(subtab)) || !subtab) &&
      factFind?.lastSection &&
      tab === CUSTOMER_TABS.factfind
    ) {
      const factFindLastSection =
        factFind?.lastSection === Section.Business ||
        factFind?.lastSection === Section.Trust
          ? FACTFIND_TABS.companytrust
          : factFind?.lastSection.toLowerCase();
      navigate(handleNavigate(factFindLastSection));
    }
  };
  const setActive = () => {
    setActiveTabIndex(FACTFIND_LIST_TABS.findIndex((x) => x.id === subtab));
  };

  const { userInfoById } = useGetCustomerById({
    officeId: officeid,
    userId: id,
  });

  const { vehicles } = useGetVehicles({
    officeId: officeid,
    userId: id,
  });
  const { properties } = useGetProperties({
    officeId: officeid,
    userId: id,
  });

  const { accounts } = useGetAccounts({
    officeId: officeid,
    userId: id,
  });
  const { holdings } = useHoldings({
    officeId: officeid,
    userId: id,
    accounts: accounts,
  });
  const { supperAccounts } = useUserSuperAccounts({
    officeId: officeid,
    userId: id,
  });

  const { expenses } = useUserExpenses({
    officeId: officeid,
    userId: id,
    period: Period.NinetyDays,
  });

  const { expenseDetails } = useGetExpensesDetail({
    officeId: officeid,
    userId: id,
    period: Period.NinetyDays,
  });

  const getBanks = () => {
    if (accounts.length > 0) {
      const banks = accounts.filter(
        (account) => account.container === CONTAINEREnum.Bank
      );
      setBanks(banks);
    }
  };
  useEffect(() => {
    getBanks();
  }, [accounts]);

  const setTabPosition = () => {
    setOpenTab(subtab);
    const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp)
      document.title = `${customername.replace(/-/g, " ")} - ${storedProp}`;
  }, []);

  const saveProcessingFactFind = () => {
    if (factFind?.isSubmitted) {
      let newWorkingSections = [...workingSections];

      for (let index = 0; index < FACTFIND_LIST_TABS.length; index++) {
        if (!newWorkingSections.includes(FACTFIND_LIST_TABS[index].id)) {
          newWorkingSections.push(FACTFIND_LIST_TABS[index].id);
        }
      }

      setWorkingSections(newWorkingSections);
    } else if (
      factFind?.lastSection &&
      factFind?.lastSection === Section.Personal
    ) {
      setWorkingSections([factFind?.lastSection.toLowerCase()]);
    } else if (
      factFind?.lastSection &&
      !workingSections.includes(factFind?.lastSection.toLowerCase())
    ) {
      const updatedWorkingSections = [...workingSections];

      for (let index = 0; index < FACTFIND_LIST_TABS.length; index++) {
        const factFindLastSection =
          factFind?.lastSection === Section.Business ||
          factFind?.lastSection === Section.Trust
            ? FACTFIND_TABS.companytrust
            : factFind?.lastSection.toLowerCase();
        const lastSection = FACTFIND_LIST_TABS.find(
          (s) => s.id === factFindLastSection
        );

        if (
          !updatedWorkingSections.includes(FACTFIND_LIST_TABS[index].id) &&
          index <= FACTFIND_LIST_TABS.indexOf(lastSection)
        ) {
          updatedWorkingSections.push(FACTFIND_LIST_TABS[index].id);
        }
      }

      setWorkingSections(updatedWorkingSections);
    }
  };

  useEffect(() => {
    setSpinner(true);
    setTimeout(async () => {
      saveProcessingFactFind();
    }, 2500);
    validateionSubTab();
    setSpinner(false);
  }, [factFind?.lastSection, factFind]);

  useEffect(() => {
    setActive();
    setTabPosition();
  }, [activeTabIndex, subtab]);

  const handleNavigate = (tab) => {
    const currentUrl = window.location.pathname;
    const hasIdParam = currentUrl.includes("/");

    let cleanUrl;
    let navigateTab = tab;

    if (hasIdParam) {
      const idSubParamIndex = currentUrl.lastIndexOf("/");
      cleanUrl = currentUrl.substring(0, idSubParamIndex);
      if (!subtab) {
        navigateTab = `${CUSTOMER_TABS.factfind}/${FACTFIND_TABS.personal}`;
      }
    } else {
      cleanUrl = currentUrl;
    }

    return `${cleanUrl}/${navigateTab}`;
  };

  const handleTabClick = (index, tab) => {
    if (workingSections.includes(tab)) {
      setActiveTabIndex(index);
      setOpenTab(tab);
      navigate(handleNavigate(tab.toLowerCase()));
    }
  };

  return (
    <div className="items-center justify-center">
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="text-center mb-10">
          <StyledFactFindBar className="flex-row relative mx-auto flex h-12 w-max  backdrop-blur-sm mb-3">
            <span
              className="absolute bottom-0 top-0 -z-10 flex overflow-hidden transition-all duration-300"
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            >
              <span className="h-full w-full " />
            </span>
            {FACTFIND_LIST_TABS.map((tab, index) => {
              const isActive =
                activeTabIndex === index && workingSections.includes(tab.id);

              if (applyingBy === ApplyThrough.No) {
                if (tab.id !== "companytrust") {
                  return (
                    <button
                      key={index}
                      ref={(el) => (tabsRef.current[index] = el)}
                      className={`tab-font-family text-sm font-bold uppercase px-3 py-3 block transition-all duration-300 border-b-2 ${
                        isActive ? "border-yellow-600" : "border-white/30"
                      }`}
                      onClick={() => handleTabClick(index, tab.id)}
                    >
                      {tab.name}
                    </button>
                  );
                }
              } else {
                return (
                  <button
                    key={index}
                    ref={(el) => (tabsRef.current[index] = el)}
                    className={`tab-font-family text-sm font-bold uppercase px-3 py-3 block transition-all duration-300 border-b-2 ${
                      isActive ? "border-yellow-600" : "border-white/30"
                    }`}
                    onClick={() => handleTabClick(index, tab.id)}
                  >
                    {tab.name}
                  </button>
                );
              }
            })}
          </StyledFactFindBar>
        </div>
        <div className="relative break-words min-w-[70%] mb-6 rounded">
          <div className="mx-4 my-5 flex-auto">
            <div className="tab-content tab-space tab-font-family">
              <div className={isStartUpFactFind ? "block" : "hidden"}>
                <StartUpTab
                  setIsStartUpFactFind={setIsStartUpFactFind}
                  setNumberOfApplicants={setNumberOfApplicants}
                  setApplyingBy={setApplyingBy}
                  existFactFind={factFind}
                  refetchGet={refetchGet}
                  shouldRefetchGet={shouldRefetchGet}
                  isSubmited={factFind?.isSubmitted}
                ></StartUpTab>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.personal && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <PersonalSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                  userInfoById={userInfoById}
                ></PersonalSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.address && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <AdressSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></AdressSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.employment && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <EmploymentSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></EmploymentSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.companytrust && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <CompanyOrTrustSession
                  numberOfApplicants={numberOfApplicants}
                  applyingBy={applyingBy}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></CompanyOrTrustSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.assets && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <AssetsSession
                  numberOfApplicants={numberOfApplicants}
                  properties={properties}
                  supperAccounts={supperAccounts}
                  holdings={holdings}
                  banks={banks}
                  vehicles={vehicles}
                  accounts={accounts}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></AssetsSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.income && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <IncomeSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></IncomeSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.liabilities && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <LiabilitiesSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></LiabilitiesSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.expenses && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <ExpensesSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                  expenses={expenses}
                  expenseDetails={expenseDetails}
                ></ExpensesSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.questions && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <QuestionsSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                ></QuestionsSession>
              </div>
              <div
                className={
                  openTab === FACTFIND_TABS.disclaimer && !isStartUpFactFind
                    ? "block"
                    : "hidden"
                }
              >
                <DisclaimerSession
                  numberOfApplicants={numberOfApplicants}
                  factFind={factFind}
                  shouldRefetchGet={shouldRefetchGet}
                  refetchGet={refetchGet}
                  setIsStartUpFactFind={setIsStartUpFactFind}
                ></DisclaimerSession>
              </div>
            </div>
          </div>
        </div>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default FactFind;
