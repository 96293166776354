import { useState, useEffect } from "react";
import { YodleeToken } from "@dineiro/dineiro-sdk-mobile";
import { getBrandOfficesApi } from "../services/api-service";
import { getLocalStorageElement } from "../utils/utils";
import { jwtDecode } from "jwt-decode";
import { isDeveloper } from "../environment";

const useGetYodleeToken = ({ officeId, userId }) => {
  const [yodleeToken, setYodleeToken] = useState<YodleeToken>();
  const [shouldRefetchGet, refetchGet] = useState(false);
  const storedIdToken = getLocalStorageElement("idToken");
  const user = storedIdToken !== "" ? jwtDecode(storedIdToken) : null;

  const UseGetYodleeTokenComponent = () => {
    useEffect(() => {
      if (user && storedIdToken) {
        const fetchData = async () => {
          const brandOfficesApi = getBrandOfficesApi(storedIdToken);

          try {
            await brandOfficesApi
              .brandOfficeYodleeTokenGet({
                id: officeId,
                userId: userId,
              })
              .then((response) => {
                if (response.data) {
                  setYodleeToken(response?.data?.yodleeToken);
                }
              });
          } catch (err: any) {
            console.log(err);
          }
        };
        fetchData();
      } else {
        if (isDeveloper) console.log("No current user.");
      }
    }, [storedIdToken, userId, shouldRefetchGet]);

    return null;
  };

  UseGetYodleeTokenComponent();

  return {
    yodleeToken: yodleeToken,
    shouldRefetchGet: shouldRefetchGet,
    refetchGet,
  };
};

export default useGetYodleeToken;
