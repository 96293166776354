import React from "react";
import { getLocalStorageElement, isValidNumber } from "../../utils/utils";
import { StyledProperty } from "../common/change-themes/styles-component";
import BedroomIcon from "../../images/goal-insight/bedroom.svg";
import BathroomIcon from "../../images/goal-insight/bathroom.svg";
import GarageIcon from "../../images/goal-insight/house-garage.svg";
import EditPropertyModal from "../common/modal/EditPropertyModal";
import useModal from "../../hooks/useModal";
import { useForm } from "react-hook-form";
import { getBrandOfficesApi } from "../../services/api-service";
import OptionIcon from "../../images/goal-insight/option-edit-icon.svg";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Button from "../common/button/Button";

const OwnedProperty: React.FC<{
  groupedAccounts?;
  shouldRefetchGetProperties;
  refetchGetProperties;
  ownedProperty;
}> = ({
  ownedProperty,
  groupedAccounts,
  shouldRefetchGetProperties,
  refetchGetProperties,
}) => {
  const storedIdToken = getLocalStorageElement("idToken");
  const { officeid, id } = useParams();
  const { show: showEditPropertyModal, toggle: toggleEditPropertyModal } =
    useModal();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const watchLivingStatus = watch("livingStatus");

  const onEditProperty = (propertyId) => {
    toggleEditPropertyModal();
  };

  const onSubmitEdit = async (data) => {
    const brandApi = getBrandOfficesApi(storedIdToken);
    try {
      await brandApi.brandOfficeCustomersPropertiesPut({
        id: parseInt(officeid),
        userId: id,
        propertyId: ownedProperty.id,
        brandOfficeCustomersPropertiesPutViewModel: {
          livingStatus: data.livingStatus,
          address: ownedProperty.address,
          estimatePrice: data.estimatePrice,
          rentPrice: data.rentPrice ?? null,
          livedYears: data.livedYears ?? null,
          carParks: isValidNumber(parseInt(data.carParks))
            ? data.carParks
            : null,
          bathrooms: isValidNumber(parseInt(data.bathrooms))
            ? data.bathrooms
            : null,
          bedrooms: isValidNumber(parseInt(data.bedrooms))
            ? data.bedrooms
            : null,
        },
      });

      refetchGetProperties(!shouldRefetchGetProperties);
      toast.success(`Successfully save property.`);
      toggleEditPropertyModal();
    } catch (error) {
      toast.error(`Failed save property.`);
    }
  };
  return (
    <div className="h-full" key={ownedProperty.id}>
      <div className="flex">
        <div className="flex justify-between w-full bg-black rounded-l h-10 py-2 px-4 text-sm mb-1">
          <span>{ownedProperty.address}</span>
          <div className="flex w-1/5 h-5 gap-4 justify-end">
            <div className="flex space-x-1">
              <img src={BedroomIcon} alt="" />
              <span>{ownedProperty.bedrooms ?? 0}</span>
            </div>
            <div className="flex space-x-1">
              <img src={BathroomIcon} alt="" />
              <span>{ownedProperty.bathrooms ?? 0}</span>
            </div>
            <div className="flex space-x-1">
              <img src={GarageIcon} alt="" />
              <span>{ownedProperty.carParks ?? 0}</span>
            </div>
          </div>
        </div>
        <div className="h-10 w-10 flex items-center">
          <Button
            borderTopRightRadius="4px"
            borderBottomRightRadius="4px"
            w="40px"
            onClick={() => onEditProperty(ownedProperty.id)}
          >
            <img className="" src={OptionIcon} alt="" />
          </Button>
        </div>
      </div>
      <StyledProperty className="w-full p-4 rounded">
        <div className="flex gap-5 text-sm">
          <div className=" flex flex-col uppercase text-xs">
            <span className="upercase ">Purchase Price</span>
            <span className=" text-xl font-semibold">
              {ownedProperty.purchasePrice
                ? ownedProperty.purchasePrice.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  })
                : "$0"}
            </span>
          </div>
          <div className=" flex flex-col uppercase text-xs">
            <span className="upercase ">Estimated Value*</span>
            <span className=" text-xl font-semibold">
              {ownedProperty.estimate
                ? ownedProperty.estimate.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  })
                : "$0"}
            </span>
          </div>
          <div className=" flex flex-col uppercase text-xs">
            <span className="upercase ">Capital Growth</span>
            <span className=" text-xl font-semibold">
              {(
                (ownedProperty.estimate.amount ?? 0) -
                (ownedProperty.purchasePrice.amount ?? 0)
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        </div>
      </StyledProperty>
      <EditPropertyModal
        showEditPropertyModal={showEditPropertyModal}
        toggleEditPropertyModal={toggleEditPropertyModal}
        handleSubmit={handleSubmit}
        register={register}
        onSubmit={onSubmitEdit}
        groupedAccounts={groupedAccounts}
        property={ownedProperty}
        errors={errors}
        watchLivingStatus={watchLivingStatus}
      ></EditPropertyModal>
    </div>
  );
};

export default OwnedProperty;
